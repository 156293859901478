import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  AppBar,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
  Container,
  Button,
} from "@material-ui/core";
import { Alert, Pagination } from "@material-ui/lab";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import EventIcon from "@material-ui/icons/Event";
// import store from '../../store/index'
import { useHistory, useParams } from "react-router-dom";
// import { useDispatch } from 'react-redux';
import axios from "axios";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import BlockIcon from "@material-ui/icons/Block";

import LoadingContainer from "../shared/loadingContainer";
import TooltipWrapper from "./components/tooltipWrapper";
import { BACKEND_GET_ORDERS_API } from "../../Utils/apiUrl";
import { getHeaders, getURLParams, errHandling } from "../../Utils/fetchUtil";
import LoadingDock from "../Images/icons/LoadingDock.png";
import NoLoadingDock from "../Images/icons/NoLoadingDock.png";

const tabNumber = ["active", "pause", "cancelled"];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2} pt={1}>
          {children}
        </Box>
      )}
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const OrderList = () => {
  const history = useHistory();
  // const dispatch = useDispatch();
  const { tab: defaultTab } = useParams();

  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(0);

  // const [userType, setuserType] = useState('')
  // const [isLoading, setLoadingState] = useState(true)
  const [errorMsg, setErrorMsg] = useState("");
  const [userOrderData, setUserOrderData] = useState([]);
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const classes = useStyles({ matches });
  const theme = useTheme();
  const value =
    tabNumber.indexOf(defaultTab || "active") === -1
      ? 0
      : tabNumber.indexOf(defaultTab || "active");

  const [pageLoading, setPageLoading] = useState(false);

  const [tabClick, setTabClick] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    const getParam = (tabNum) => {
      if (tabNum === 0) return { status: ["Active"] };
      if (tabNum === 1) return { status: ["Pause"] };
      if (tabNum === 2)
        return { status: ["Cancelled_by_Backend", "Cancelled_by_Owner"] };
    };
    setUserOrderData([]);

    axios
      .get(
        getURLParams(BACKEND_GET_ORDERS_API, {
          page: page,
          ...getParam(value),
        }),
        { headers: getHeaders() }
      )
      .then((response) => {
        console.log(response);
        // response.data.data && dispatch(setOrders({orders: response.data.data}));
        response.data.data && setUserOrderData(response.data.data);
        setLastPage(response.data.last_page);
        // setLoadingState(false);
        setPageLoading(false);
        setTabClick(false);
      })
      .catch((err) => {
        errHandling(err, setErrorMsg);
        // setErrorMsg(err.msg);
        // setLoadingState(false);
        setPageLoading(false);
        setTabClick(false);
      });
  }, [page, value]);

  const handleChange = (event, newValue) => {
    if (newValue !== value) {
      setPage(1);
      setTabClick(true);
    }
    // setValue(newValue);
    history.replace(`/my-orders/${tabNumber[newValue]}`);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  // const handleChangeIndex = (index) => {
  //     setValue(index);
  // };

  const goOrderDetailPage = (orderNumber, index, pkgSection = false) => {
    let path = `/order-details/${orderNumber}`;
    history.push({
      pathname: path,
      state: { activeIndex: index },
      hash: pkgSection ? "#packageSection" : "",
    });
    // dispatch(selectOrder({selected_order_number: orderNumber}))
  };

  const panelContent =
    userOrderData.length === 0 ? (
      <Box>
        <br />
        <br />
        There is no order for this type.
      </Box>
    ) : (
      <>
        {userOrderData.map((order, key) => {
          let pickupDockText = "";
          let deliveryDockText = "";
          if (
            order.option_list?.option_pickup_loading_dock === "n" &&
            order.option_list?.option_deliver_loading_dock === "n"
          )
            pickupDockText =
              "No loading dock at pickup and delivery destination";
          else if (
            order.option_list?.option_pickup_loading_dock === "y" &&
            order.option_list?.option_deliver_loading_dock === "y"
          )
            pickupDockText =
              "Has loading dock at pickup and delivery destination";
          else if (
            order.option_list?.option_pickup_loading_dock === "y" &&
            order.option_list?.option_deliver_loading_dock === "n"
          ) {
            pickupDockText = "Has loading dock at pickup destination";
            deliveryDockText = "No loading dock at delivery destination";
          } else if (
            order.option_list?.option_pickup_loading_dock === "n" &&
            order.option_list?.option_deliver_loading_dock === "y"
          ) {
            pickupDockText = "No loading dock at pickup destination";
            deliveryDockText = "Has loading dock at pickup destination";
          }

          const noDockForPickUp =
            order.option_list?.option_pickup_loading_dock === "n";

          const noDockForDelivery =
            order.option_list?.option_deliver_loading_dock === "n";

          return (
            <Box
              key={order.id}
              // borderBottom="1px solid rgba(61, 21, 21, 0.3)"
              pt="1.5vh"
              className={classes.itemWrapper}
            >
              <Box
                className="order-list-text"
                display="flex"
                justifyContent="space-between"
                flexDirection={matches ? "column" : "row"}
              >
                {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
                <Box
                  display={matches ? "flex" : "block"}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box
                    content="span"
                    fontWeight="700"
                    // className={matches ? classes.textTitle : ""}
                  >
                    Order #:
                  </Box>{" "}
                  {/* {matches ? "" : <br />} */}
                  <Box flexGrow="1">
                    <Button
                      variant="text"
                      onClick={() => goOrderDetailPage(order.order_number)}
                      className={classes.linkText}
                    >
                      {order.order_number}
                    </Button>
                  </Box>
                </Box>
                <Box
                  display={matches ? "flex" : "block"}
                  justifyContent="space-between"
                >
                  <Box
                    content="span"
                    className={matches ? classes.textTitle : ""}
                  >
                    Pickup Date:
                  </Box>{" "}
                  {/* {matches ? "" : <br />} */}
                  <Box style={{ fontStyle: "italic" }} flexGrow="1">
                    {getDate(order.scheduled_pickup_date)}
                  </Box>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  mt={1}
                  alignItems="center"
                >
                  {/* {matches ? "" : <br />} */}
                  <TooltipWrapper title={order.status}>
                    {order.status === "active" ? (
                      <CheckCircleIcon style={{ color: "seagreen" }} />
                    ) : (
                      <BlockIcon style={{ color: "grey" }} />
                    )}
                  </TooltipWrapper>
                  {order.option_list?.option_pickup_loading_dock && (
                    <Box ml={2}>
                      <TooltipWrapper title={pickupDockText}>
                        <img
                          src={noDockForPickUp ? NoLoadingDock : LoadingDock}
                          alt={
                            noDockForPickUp
                              ? "No Loading Dock"
                              : "Has Loading Dock"
                          }
                          width="40px"
                          align="bottom"
                          style={
                            noDockForPickUp
                              ? {
                                  backgroundColor: "grey",
                                }
                              : {
                                  backgroundColor: "seagreen",
                                }
                          }
                        />
                      </TooltipWrapper>
                    </Box>
                  )}
                  {order.option_list?.option_pickup_loading_dock !==
                    order.option_list?.option_deliver_loading_dock &&
                    order.option_list?.option_deliver_loading_dock && (
                      <Box ml={2}>
                        <TooltipWrapper title={deliveryDockText}>
                          <img
                            src={
                              noDockForDelivery ? NoLoadingDock : LoadingDock
                            }
                            alt={
                              noDockForDelivery
                                ? "No Loading Dock"
                                : "Has loading Dock"
                            }
                            width="40px"
                            align="bottom"
                            style={
                              noDockForDelivery
                                ? {
                                    backgroundColor: "grey",
                                  }
                                : {
                                    backgroundColor: "seagreen",
                                  }
                            }
                          />
                        </TooltipWrapper>
                      </Box>
                    )}
                  {(order.option_list?.option_pickup_time_range ||
                    order.option_list?.option_deliver_time_range) && (
                    <Box ml={2}>
                      <TooltipWrapper
                        // title={`${
                        //   order.option_list?.option_pickup_time_range
                        //     ? order.option_list?.option_pickup_time_range
                        //     : ""
                        // } <br/> ${
                        //   order.option_list?.option_deliver_time_range
                        //     ? order.option_list?.option_deliver_time_range
                        //     : ""
                        // }`}
                        title={
                          <>
                            {order.option_list?.option_pickup_time_range && (
                              <Box>
                                Pickup time range:{" "}
                                {order.option_list?.option_pickup_time_range}
                              </Box>
                            )}
                            {order.option_list?.option_deliver_time_range && (
                              <Box>
                                Delivery time range:{" "}
                                {order.option_list?.option_deliver_time_range}
                              </Box>
                            )}
                          </>
                        }
                      >
                        <AccessAlarmsIcon />
                      </TooltipWrapper>
                    </Box>
                  )}
                  {order.option_list?.phone_deliver_appointment && (
                    <Box ml={2}>
                      <TooltipWrapper title={"Deliver appointment"}>
                        <EventIcon />
                      </TooltipWrapper>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box m={1}>
                {order.packages.map((item, index) => {
                  return (
                    <Box
                      className={classes.pkgWrapper}
                      key={item.package_number}
                      mb="10px"
                    >
                      <Box
                        className={classes.cardContent}
                        display="flex"
                        alignItems="center"
                        role="button"
                        width="100%"
                        onClick={() =>
                          goOrderDetailPage(order.order_number, index, true)
                        }
                      >
                        <Box
                          height="70px"
                          width="70px"
                          position="relative"
                          ml={1}
                          mr={2}
                        >
                          <img
                            src={item.cover_image}
                            alt={`order-${order.id}`}
                            className={classes.pkgImg}
                          />
                        </Box>
                        <Box color="seagreen" textAlign="center" flexGrow="1">
                          {item.item_name}
                        </Box>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
              <hr />
            </Box>
          );
        })}
      </>
    );

  const pageSelection = lastPage > 1 && !tabClick && (
    <div className={classes.pagination}>
      <Pagination
        className={classes.pagination}
        count={lastPage}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </div>
  );

  return (
    <Container>
      {/* <div className="order-list-container"> */}
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/confirm-shipments/pickup")}
          style={{
            textTransform: "unset",
            fontSize: "1.1rem",
          }}
        >
          Confirm shipments pickup
        </Button>
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => history.push("/confirm-shipments/receipt")}
          style={{
            textTransform: "unset",
            fontSize: "1.1rem",
          }}
        >
          Confirm shipments receipt
        </Button>
      </Box>
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            // centered
            // scrollButtons="auto"
          >
            <Tab label="Active" {...a11yProps(0)} />
            <Tab label="Pause" {...a11yProps(1)} />
            <Tab label="Cancelled " {...a11yProps(2)} />
            {/* <Tab label="Canceled by backend" {...a11yProps(3)} /> */}
          </Tabs>
        </AppBar>
        {/* {isLoading ? <LoadingContainer /> : */}
        {/* (<> */}
        <TabPanel
          value={value}
          index={0}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        <TabPanel
          value={value}
          index={2}
          dir={theme.direction}
          classes={{ root: classes.tabRoot }}
        >
          <Box>
            {pageLoading ? <LoadingContainer /> : panelContent}
            {pageSelection}
          </Box>
        </TabPanel>
        {/* </>)} */}
        <Box pt="1vh" width="100%">
          {errorMsg ? (
            <Alert severity="error">
              Please enter information. <br /> * Mandatory
            </Alert>
          ) : (
            ""
          )}
        </Box>
      </div>
      {/* </div> */}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    // backgroundColor: 'white',
    // border: "2px solid #000",
    // boxShadow: theme.shadows[5],
    // padding: theme.spacing(2, 4, 2),
    // width: props => props.matches ? '90vw': '80vw',
    width: "100%",
    // height: '80vh',
    // borderRadius: '20px',
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    // overflow: "scroll",
    // margin: '0 3vw'
  },
  panel: {
    backgroundColor: "white",
  },
  tabRoot: {
    // height: '100%',
    overflow: "auto",
    height: "70vh",
  },
  // headerRoot: {
  //     marginTop: '0',
  // },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  linkText: {
    fontSize: "1rem !important",
    color: "blue",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  textTitle: {
    flexBasis: "40%",
    textAlign: "left",
  },
  activeWrapper: {
    backgroundColor: "grey",
    color: "white",
    borderRadius: 5,
    padding: theme.spacing(0.5),
    height: "40px",
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
  itemWrapper: {
    "& hr": {
      // border: '1px solid rgba(0,0,0,0.3)',
      marginLeft: -theme.spacing(2),
      marginRight: -theme.spacing(2),
      borderTop: "3px solid rgba(0,0,0,.1)",
    },
  },
}));

// const mapStateToProps = state => ({
//     orders: state.orders,
//     packages: state.packages
// })

// export default connect(mapStateToProps, {getOrders, getPackages, getOrderShipments})(OrderList)
export default OrderList;

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "./store/";
import ReactGA from "react-ga";
// import UserSignUp from './components/SignUp/userSignUp'

import { createMuiTheme } from "@material-ui/core/styles";
// import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/styles.css";
import Routes from "./routes/routes";
import MainContainer from "./components/mainContainer";

import { pink } from "@material-ui/core/colors";
// import axios from 'axios'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffa726",
    },
    secondary: pink,
  },
});

ReactGA.initialize("UA-203680197-1");

function App() {
  //   useEffect(() => {
  //     axios.get('/api/sign-in')
  //     .then(response => {
  //         setToken(response.data.token, 'local')
  //     })
  // }, [])
  return (
    <div>
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <BrowserRouter>
            <MainContainer>
              <Routes />
            </MainContainer>
          </BrowserRouter>
        </Provider>
      </MuiThemeProvider>
      <br />
      <br />
      <br />
    </div>
  );
}

export default App;

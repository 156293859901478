import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
// import { createUser } from "../../../actions/newUserAction";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Button,
  Box,
  Divider,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
// import Slide from 'react-reveal';
// import UserBar from '../../NavBars/userBar';
// import Facebook from '../SocialMediaLogin/facebook';
import SocialLogin from "../SocialMediaLogin/socialLogin";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/styles.css";
import axios from "axios";

import { setTokeninCookie } from "../../../Utils/doToken";
import {
  BACKEND_LOGIN_API,
  // BACKEND_ME_API,
  BACKEND_DRIVER_LOGIN_API,
} from "../../../Utils/apiUrl";
import { updateUserInfo } from "../../../slices";
import { errHandling } from "../../../Utils/fetchUtil";

// import {redirectSignInSuccessful} from '../../Utils/redirect';
import { CustomInput } from "../../shared";
import UserSignUp from "../SignUp/userSignUp";

const jumboStyle = {
  color: "green",
  maxheight: "-webkit-fill-available",
  // fontFamily: "Montserrat",
  letterSpacing: "2px",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
};

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    borderRadius: "25px",
    textAlign: "center",
    width: "100%",
  },
  button: {
    borderRadius: 20,
    color: "white",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

const UserSignIn = ({ driver = false }) => {
  //User Data that get saved as object
  // const [userData, setUserData] = useState();
  const [confirmUser, setConfirmUser] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [socialEmail, setSocialEmail] = useState(null);
  const [validLength, setValidLength] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  // const [signInResponse, setSignInResponse] = useState(0);
  const [signInErrorMessage, setSignInErrorMessage] = useState("");
  const [signInFail, setSignInFail] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  // let login = {
  //     email: '',
  //     password: ''
  // }
  const classes = useStyles();

  //useEffect handler which makes sure the userData state is first updated then sends the data to the MongoDB :D! FIXED
  useEffect(() => {
    if (confirmUser === true) {
      setConfirmUser(false);
    }
    if (signInErrorMessage.length > 2) {
      setSignInFail(true);
    }
  }, [signInFail]);

  const checkPasswordLength = (password) => {
    if (password.length >= 6) {
      setValidLength(true);
    } else {
      setValidLength(false);
    }
  };

  const handleEmail = (e) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
    checkPasswordLength(e.target.value);
  };

  /*Function that sets the login object to the inputted e-mail and password
        login object is passed in to a POST redux action where the data is delivered to the express server
        then, the response from the express server is received and the user either logs in or it produces an error
    */
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setSignInErrorMessage("");
    const loginInfo = {
      email: email,
      password: password,
    };
    axios({
      method: "post",
      url: `${
        driver ? BACKEND_DRIVER_LOGIN_API : BACKEND_LOGIN_API
      }?source=Email`,
      data: JSON.stringify(loginInfo),
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        setTokeninCookie("access_token", response.data.access_token, 30);
        setSignInFail(false);
        checkUserRole(response.data?.user?.role);
      })
      .catch((error) => {
        // console.log(`sign in error ${error}`);
        // setSignInResponse(error.response.status)
        // setSignInErrorMessage(error.response.data.message);
        errHandling(error, setSignInErrorMessage);
        setSignInFail(true);
        setLoading(false);
        return;
      });
  };

  const responseSocial =
    (type = "google") =>
    (response) => {
      let email, token;
      // {profileObj:{email}, tokenId: token} = response;
      if (type === "google") {
        if (!response.profileObj.email) {
          setSignInErrorMessage(
            "The email address is invalid, please login by other means"
          );
          return;
        }
        email = response.profileObj.email;
        token = response.tokenId;
      }
      if (type === "facebook" || type === "microsoft") {
        if (!response.email) {
          setSignInErrorMessage(
            "The email address is invalid, please login by other means"
          );
          return;
        }
        email = response.email;
        token = response.accessToken;
      }

      setLoading(true);
      setSignInErrorMessage("");

      const loginInfo = {
        email: email,
        social: token,
      };

      axios({
        method: "post",
        // url: `${BACKEND_LOGIN_API}?source=Google&email=${email}&social=${token}`,
        url: `${
          driver ? BACKEND_DRIVER_LOGIN_API : BACKEND_LOGIN_API
        }?source=${type}`,
        data: JSON.stringify(loginInfo),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        // headers: { 'Content-Type' : 'application/json;charset=UTF-16', 'X-Requested-With': 'XMLHttpRequest' }
        // headers: { 'X-Requested-With': 'XMLHttpRequest' }
      })
        // axios.get(BACKEND_LOGIN_API)
        .then((response) => {
          setTokeninCookie("access_token", response.data.access_token, 30);
          setSignInFail(false);
          // dispatch(updateUserInfo({ field: "login", value: true }));
          checkUserRole(response.data?.user?.role);
        })
        .catch((error) => {
          // console.log(error.response.data.message);
          errHandling(error, setSignInErrorMessage);
        });
    };

  const checkUserRole = (role) => {
    // axios.get(`https://api.uucargo.ca/api/userprofiles/${email}`, {headers: { "Authorization": `Bearer ${token}` }})
    // axios
    //   .get(BACKEND_ME_API, { headers: { Authorization: `Bearer ${token}` } })
    //   .then((response) => {
    //     setLoading(false);
    //     dispatch(updateUserInfo({ field: "signIn", value: true }));
    //     if (response.data && response.data.role === "End User") {
    //       if (location.state) history.replace(location.state.from.pathname);
    //       else history.replace("/");
    //     } else if (response.data && response.data.role === "Driver") {
    //       if (location.state) history.replace(location.state.from.pathname);
    //       else history.replace("/driver");
    //     } else {
    //       //should be handling who does not have both roles
    //       if (location.state) history.replace(location.state.from.pathname);
    //       history.replace("/profile");
    //     }
    //   });
    dispatch(updateUserInfo({ field: "role", value: role }));
    dispatch(updateUserInfo({ field: "signIn", value: true }));
    if (role === "End User") {
      if (location.state) history.replace(location.state.from.pathname);
      else history.replace("/");
    } else if (role === "Driver") {
      if (location.state) history.replace(location.state.from.pathname);
      else history.replace("/driver");
    } else {
      //should be handling who does not have both roles
      if (location.state) history.replace(location.state.from.pathname);
      history.replace("/profile");
    }
  };

  return (
    <Container maxWidth="sm">
      <Box mt={3}>
        {/* <UserBar /> */}
        <div style={jumboStyle}>
          {location.pathname === "/sign-up" ? (
            <UserSignUp />
          ) : (
            <div className="signUpContainer">
              {driver && (
                <Box fontSize="3rem" color="seagreen" mb={1} textAlign="center">
                  Driver Sign In
                </Box>
              )}
              <div>
                <div>
                  <p className="subtitle">E-mail</p>
                </div>
                <CustomInput
                  onChange={(e) => handleEmail(e)}
                  placeholderValue="Email"
                  value={email}
                ></CustomInput>
              </div>
              <div>
                <div>
                  <p className="subtitle">Password</p>
                </div>
                <CustomInput
                  onChange={(e) => handlePasswordChange(e)}
                  placeholderValue="Enter Password"
                  value={password}
                  type="password"
                  // className={classes.input}
                  // id="content1"
                ></CustomInput>
              </div>
              <Box fontSize="1rem" textAlign="right">
                <Link to="/forgot-password">Forget password</Link>
              </Box>
              <br />
              <Button
                disabled={loading}
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {loading ? <CircularProgress size={24} /> : "Sign In"}
              </Button>
              {signInErrorMessage ? (
                <Alert severity="error" classes={{ root: classes.alertRoot }}>
                  {signInErrorMessage}
                </Alert>
              ) : (
                ""
              )}
              <div style={{ textAlign: "center" }}>
                <h3>OR</h3>
              </div>
              <div>
                <SocialLogin
                  // parentCallback={handleCallback}
                  responseSocial={responseSocial}
                />
              </div>
              {/* <AzureAuthenticationButton onAuthenticated={onAuthenticated} /> */}
              <br />
              {!driver && (
                <>
                  <Box display="flex" justifyContent="center">
                    <Divider style={{ width: "60%" }} />
                  </Box>
                  <Box fontSize="1rem" display="flex" justifyContent="center">
                    <Link to="/driver/sign-in">Sign in as driver</Link>
                  </Box>
                </>
              )}
              <Divider />
              <Box textAlign="center">
                Don't have an Account? <Link to="/sign-up">Sign up</Link>
              </Box>
            </div>
          )}
        </div>
      </Box>
    </Container>
  );
};

export default UserSignIn;

import React from "react";
import { useLocation, useHistory } from 'react-router-dom'
import { Button, Box } from '@material-ui/core';

// import Slide from 'react-reveal';

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

const ActiveResultPage = () => {
    let query = useQuery();
    const history = useHistory();

    const handleClick = () => {
        history.push('/sign-in');
    }

    return (
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' mt='40vh'>
                <Box fontSize='3rem' color='green' mb='5vh'>
                    {query.get("status_code") === '200' ? 'Email Activation Success' : 'Email Activation Failed'}
                </Box>
                <Button variant="contained" color="primary" onClick={handleClick} style={{color: 'white'}}>
                    Go to sign-in page
                </Button>
            </Box>
    )
}

export default ActiveResultPage;
import React from "react";
import { Box, Button } from "@material-ui/core";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { format } from "date-fns";

import { ordinal_suffix_of } from "../../../Utils/addOrdinalSuffix";

//getPickUpEmptyStatus(pickUpDetails)

const ReviewOrderHeader = ({
  pickupEmpty,
  deliverEmpty,
  toFirst,
  toFourth,
  pickUpDetails,
  pallets,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className="subtitle">
        <Box pt={1} width="100%">
          {pickupEmpty ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              Please enter all the pick up address information.
            </Alert>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Pickup</Box>
          <Box fontWeight="400" className="bodytext">
            <Button
              color="primary"
              onClick={toFirst}
              style={{ color: "seagreen" }}
            >
              Edit
            </Button>
          </Box>
        </Box>

        <Box className={classes.summaryBodyText} mb={2}>
          Phone number: {pickUpDetails.pickUp.phone}
          <br />
          {`${pickUpDetails.pickUp.address}`} <br />
          {`${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province} ${pickUpDetails.pickUp.zipCode}`}{" "}
          <br />
          Requested for {format(pickUpDetails.selectedDate, "dd/MM/yyyy")}
        </Box>
      </div>
      <div className="subtitle">
        <Box pt={1} width="100%">
          {deliverEmpty ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              Please enter all the deliver address information.
            </Alert>
          ) : (
            ""
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Delivery</Box>
          <Box fontWeight="400" className="bodytext">
            <Button
              color="primary"
              onClick={toFirst}
              style={{ color: "seagreen" }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box className={classes.summaryBodyText} mb={2}>
          Phone number: {pickUpDetails.deliver.phone}
          <br />
          {`${pickUpDetails.deliver.address}`} <br />
          {`${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province} ${pickUpDetails.deliver.zipCode}`}
        </Box>
      </div>
      <div className="bodytext"></div>
      <div className="subtitle">
        <Box display="flex" justifyContent="space-between">
          <Box className={classes.summaryHeader}>Items</Box>
          <Box fontWeight="400" className="bodytext">
            <Button
              color="primary"
              onClick={toFourth}
              style={{ color: "seagreen" }}
            >
              Edit
            </Button>
          </Box>
        </Box>
        <Box className={classes.summaryBodyText} mb={1}>
          {pallets.map((item, key) => {
            return (
              <div key={key}>
                <p>
                  <Box component="span" fontWeight="700">
                    {ordinal_suffix_of(key + 1)} Package:
                  </Box>{" "}
                  <Box component="span" fontWeight="400">
                    {item.description} - {item.quantity} Pallet(s) &times;{" "}
                    {item.weight} lbs
                  </Box>
                </p>
              </div>
            );
          })}
        </Box>
      </div>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
}));

ReviewOrderHeader.propTypes = {
  pickupEmpty: PropTypes.bool.isRequired,
  toFirst: PropTypes.func.isRequired,
  pickUpDetails: PropTypes.object.isRequired,
  toFourth: PropTypes.func.isRequired,
  pallets: PropTypes.array.isRequired,
};

export default ReviewOrderHeader;

import React, { useState, useEffect } from "react";
// import { Container, Row, Col } from 'react-bootstrap';
// import { Button, Modal, Backdrop, Box, TextField } from '@material-ui/core';
import { Button, Box } from "@material-ui/core";
import { makeStyles, useMediaQuery, Snackbar } from "@material-ui/core";
// import Cards from 'react-credit-cards'
import "react-credit-cards/es/styles-compiled.css";
import "../../../styles/creditStyles.css";
// import CardSetupForm from '../../Payment/cardForm';
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';
import { useHistory, useLocation } from "react-router";
import axios from "axios";
import PropTypes from "prop-types";
// import {Redirect} from 'react-router-dom';

import { getHeaders, errHandling } from "../../../Utils/fetchUtil";
import { LIST_CREDIT_CARD_API } from "../../../Utils/apiUrl";
import LoadingContainer from "../../shared/loadingContainer";
import { CardImages } from "../../Images/cards/";

// const creditCardType = require("credit-card-type");

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "white",
    textAlign: "center",
  },
  paper: {
    backgroundColor: "beige",
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    justifyContent: "center",
    width: "60%",
  },
  button: {
    fontSize: "12pt",
    // color: "white",
  },
  //Fills textfield input area to match size of container in CSS
  textField: {
    width: "90%",
  },
  bodyText: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  formWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 5,
    backgroundColor: "white",
    maxWidth: "720px",
    margin: "0 auto",
  },
  itemWrapper: {
    display: "flex",
    alignItems: "center",
    "& > div:nth-child(1)": {
      flexBasis: "40%",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
}));

// const row = {
//     border: "2px"
// }

/*Component for the Individual User Type. Should run Stripe functions here to save credit */
const IndividualUser = ({ cardStatus }) => {
  // const [open, setOpen] = useState(false);
  // const [individualData, setIndividualData] = useState({
  //     instituteNum: "005",
  //     transitNum: "222",
  // });

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const matches = useMediaQuery("(max-width:600px)");

  const [loading, setLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);

  const [cardErrMsg, setCardErrMsg] = useState("");

  const haveCard = cardStatus === "active";

  // Fetches data from backend/api/me to display userprofile data
  useEffect(() => {
    if (cardStatus === "active") {
      axios
        .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
        .then((response) => {
          setLoading(false);
          setCardInfo(response.data[0]);
        })
        .catch((err) => {
          //   console.log(err);
          errHandling(err, setCardErrMsg);
          setLoading(false);
        });
    } else setLoading(false);
  }, [cardStatus]);

  const firstLetterUpper = (_string) => {
    return _string.replace(/^\S/, (s) => s.toUpperCase());
  };

  const onRedicrect = () => {
    history.push({
      pathname: "/profile/add-card",
      state: { from: location.pathname },
    });
  };

  const handleBarClose = () => {
    setCardErrMsg("");
  };

  const cardContainer = (_loading, _cardInfo, _haveCard) => {
    if (!_loading && !_cardInfo)
      return (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onRedicrect}
        >
          Add a Card
        </Button>
      );

    if (!_loading && _cardInfo)
      return (
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems={matches ? "start" : "center"}
          flexGrow="1"
          flexDirection={matches ? "column" : "row"}
          width="100%"
        >
          <Box
            className={classes.bodyText}
            mb={matches ? "16px" : "0"}
            mt={matches ? "12px" : "0"}
          >
            {_haveCard ? (
              <>
                <img
                  src={CardImages[firstLetterUpper(cardInfo.card_type)]}
                  alt={firstLetterUpper(cardInfo.card_type)}
                  width="50px"
                  align="bottom"
                  style={{ padding: "0 5px" }}
                />
                {`${firstLetterUpper(cardInfo.card_type)} ending in ${
                  cardInfo.card_last_four_digits
                }`}
              </>
            ) : (
              "You don't have any credit card registerd"
            )}
          </Box>
          <Box alignSelf="center">
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={onRedicrect}
            >
              {_haveCard ? "Replace" : "Add"}
            </Button>
          </Box>
        </Box>
      );

    return <LoadingContainer />;
  };

  return (
    <div>
      <br />
      <div className={classes.formWrapper}>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">Payment Options</div>
          {cardContainer(loading, cardInfo, haveCard)}
        </Box>
      </div>
      {/* <div className="container">
            </div> */}
      <br />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(cardErrMsg)}
        onClose={handleBarClose}
        message={cardErrMsg}
        key={"bottom center"}
      />
    </div>
  );
};

IndividualUser.propTypes = {
  getShipmentItems: PropTypes.bool,
};

export default IndividualUser;

import React, { useState } from "react";
import { connect } from "react-redux";
import GooglePlaceInput from "../shared/AddressInputCombo/googlePlacesAutoComplete";
import {
  Slider,
  Typography,
  makeStyles,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  Chip,
  TextField,
  Button,
} from "@material-ui/core";
import store from "../../store";
import { updateSearchFilterSetting as updateFilter } from "../../actions/searchFilterAction";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import produce from "immer";

import "../../styles/searchFilter.css";

const useStyle = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    position: "fixed",
    top: "0px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2,
  },
  dialogHeader: {
    display: "flex",
  },
  cityInput: {
    width: "100%",
  },
  cityChips: {
    marginTop: "5px",
    display: "flex",
    flexWrap: "wrap",
    gap: "5px",
  },
  textField: {
    width: 200,
  },
  datePickerContainer: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
  },
  inputFieldsContainer: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  saveButton: {
    width: "90px",
    color: "white",
    margin: "5px auto",
  },
});

const sliderStyle = makeStyles({
  root: {
    width: "90%",
    "margin-left": "5%",
    "margin-top": "4%",
  },
});

const checkBoxStyle = makeStyles({
  root: {
    flexDirection: "row",
    "margin-left": "20px",
  },
});

const priceValueText = value => {
  return `$${value}`;
};

const SearchFilter = ({ display, searchFilter }) => {
  const classes = useStyle();
  const sliderStyleClasses = sliderStyle();
  const checkBoxStyleClasses = checkBoxStyle();
  const [cityValue, setCityValue] = useState("");
  const [inputPickupCity, setInputPickupCity] = useState("default city");

  const initialShipmentSizeCheckboxes = () => {
    let arr, checkCount;
    if (searchFilter.shipmentSize.length !== 0) {
      arr = new Array(8).fill(false);
      for (let i = 0; i < searchFilter.shipmentSize.length; i++) {
        arr[searchFilter.shipmentSize[i]] = true;
      }
      checkCount = searchFilter.shipmentSize.length;
    } else {
      arr = new Array(8).fill(false);
      arr[0] = true;
      checkCount = 1;
    }
    return {
      shipmentSizeArray: arr,
      checkCount: checkCount,
    };
  };

  const getPickupDateFrom = () => {
    //check if the state of pickup date existed
    if (searchFilter.pickupDateFrom === "") {
      return getToday();
    } else {
      return searchFilter.pickupDateFrom;
    }
  };

  const getPickupDateTo = () => {
    if (searchFilter.pickupDateTo === "") {
      return getNextWeek();
    } else {
      return searchFilter.pickupDateTo;
    }
  };

  const getToday = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    return (today = yyyy + "-" + mm + "-" + dd);
  };

  const getNextWeek = () => {
    var today = new Date();
    var nextweek = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() + 7
    );
    const dd = String(nextweek.getDate()).padStart(2, "0");
    const mm = String(nextweek.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = nextweek.getFullYear();
    return (nextweek = yyyy + "-" + mm + "-" + dd);
  };

  const [unitPrice, setUnitPrice] = useState([
    searchFilter.unitPrice[0],
    searchFilter.unitPrice[1],
  ]);
  const [totalPrice, setTotalPrice] = useState([
    searchFilter.totalPrice[0],
    searchFilter.totalPrice[1],
  ]);
  const [shipmentSize, setShipmentSize] = useState("");
  const [checkBoxState, setCheckBoxState] = useState(
    initialShipmentSizeCheckboxes()
  );
  const [pickupCity, setPickupCity] = useState(searchFilter.pickupCity);
  const [deliverCity, setDeliverCity] = useState(searchFilter.deliverCity);
  const [pickupDateFrom, setPickupDateFrom] = useState(getPickupDateFrom());
  const [pickupDateTo, setPickupDateTo] = useState(getNextWeek());
  const [optionCheckBox, setOptionCheckBox] = useState({
    pickupLoadingDock: searchFilter.pickupLoadingDock
      ? searchFilter.pickupLoadingDock
      : false,
    deliverLoadingDock: searchFilter.deliverLoadingDock
      ? searchFilter.deliverLoadingDock
      : false,
    deliverAppointment: searchFilter.deliverAppointment
      ? searchFilter.deliverAppointment
      : false,
  });
  const generateMarks = (type, name) => {
    const priceMarks = [];

    //get unit price range
    const min = searchFilter.static[`min_${name}`];
    const max = searchFilter.static[`max_${name}`];
    const range = max - min;
    const gap = Math.floor(range / 5);
    priceMarks.push({
      //push min unit price
      value: min,
      label: type === "price" ? `$${min}` : `${min}lb`,
    });
    for (let i = 1; i < 5; i++) {
      let v = min + gap * i;
      priceMarks.push({
        //push max unit price
        value: v,
        label: type === "price" ? `$${v}` : `${v}lb`,
      });
    }
    priceMarks.push({
      //push max unit price
      value: max,
      label: type === "price" ? `$${max}` : `${max}lb`,
    });
    return priceMarks;
  };

  const changeUnitPrice = (event, newValue) => {
    setUnitPrice(newValue);
  };

  const changeTotalPrice = (event, newValue) => {
    setTotalPrice(newValue);
  };

  const handleOptionsChange = target => {
    setOptionCheckBox(preState => {
      return {
        ...preState,
        [target]: !preState[target],
      };
    });
  };

  const handleChange = position => {
    if (position === 0) {
      setCheckBoxState(
        produce(draft => {
          if (draft.shipmentSizeArray[position] === false) {
            draft.shipmentSizeArray[position] = true;
            draft.shipmentSizeArray.fill(false, 1);
            draft.checkCount = 1;
          }
        })
      );
    } else {
      setCheckBoxState(
        produce(draft => {
          if (draft.shipmentSizeArray[position] === false) {
            draft.shipmentSizeArray[position] = true;
            draft.checkCount = draft.checkCount + 1;
            if (draft.shipmentSizeArray[0] === true) {
              draft.shipmentSizeArray[0] = false;
              draft.checkCount = 1;
            }
          } else if (draft.shipmentSizeArray[position] === true) {
            draft.shipmentSizeArray[position] = false;
            draft.checkCount = draft.checkCount - 1;
            if (draft.checkCount === 0) {
              draft.shipmentSizeArray[0] = true;
              draft.checkCount = 1;
            }
          }
        })
      );
    }
  };

  const convertShipmentSizeArray = array => {
    let res = [];
    for (let i = 0; i < array.length; i++) {
      if (array[i] === true) {
        res.push(i);
      }
    }
    return res;
  };

  //submite filter setting
  const saveSearchFilterSetting = () => {
    const setting = {
      unitPrice: unitPrice,
      totalPrice: totalPrice,
      shipmentSize: convertShipmentSizeArray(checkBoxState.shipmentSizeArray),
      pickupCity: pickupCity,
      deliverCity: deliverCity,
      pickupDateFrom: pickupDateFrom,
      pickupDateTo: pickupDateTo,
      pickupLoadingDock: optionCheckBox.pickupLoadingDock,
      deliverLoadingDock: optionCheckBox.deliverLoadingDock,
      deliverAppointment: optionCheckBox.deliverAppointment,
    };
    store.dispatch(updateFilter(setting));

    //passing to parent that dont display the filter
    display(false);
  };

  const handleChangePickupCity = value => {
    if (value !== "") {
      let currentCities = [...pickupCity];
      currentCities.push(value);
      currentCities = [...new Set(currentCities)];
      setPickupCity(currentCities);
    }
  };

  const handleDeletePickupCity = index => {
    const temp = [...pickupCity];
    temp.splice(index, 1);
    setPickupCity(temp);
  };

  const handleChangeDeliverCity = value => {
    if (value !== "") {
      let currentCities = [...deliverCity];
      currentCities.push(value);
      currentCities = [...new Set(currentCities)];
      setDeliverCity(currentCities);
    }
  };

  const handleDeleteDeliverCity = index => {
    const temp = [...deliverCity];
    temp.splice(index, 1);
    setDeliverCity(temp);
  };

  const handlePickupDateChange = event => {
    setPickupDateFrom(event.target.value);
  };

  const handleDeliverDateChange = event => {
    setPickupDateTo(event.target.value);
  };

  return (
    <div className={classes.root}>
      <div className="search-filter-container">
        <div className={classes.dialogHeader}>
          <Typography
            variant="h5"
            style={{ marginTop: "5px", textAlign: "center", flexGrow: "1" }}
          >
            Search filter
          </Typography>
          <IconButton
            color="secondary"
            aria-label="close filter dialog"
            onClick={() => display(false)}
          >
            <CloseRoundedIcon />
          </IconButton>
        </div>
        <div className={classes.inputFieldsContainer}>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Pick-up City
            </Typography>
            <div className={classes.cityInput}>
              <GooglePlaceInput
                type="city"
                handleChange={value => handleChangePickupCity(value)}
              />
            </div>
            <div className={classes.cityChips}>
              {pickupCity.length > 0 &&
                pickupCity.map((city, index) => {
                  return (
                    <div key={index}>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={city}
                        color="secondary"
                        onDelete={() => handleDeletePickupCity(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Deliver City
            </Typography>
            <div className={classes.cityInput}>
              <GooglePlaceInput
                type="city"
                handleChange={value => handleChangeDeliverCity(value)}
                cityValue={cityValue}
                filter={true}
              />
            </div>
            <div className={classes.cityChips}>
              {deliverCity.length > 0 &&
                deliverCity.map((city, index) => {
                  return (
                    <div key={index}>
                      <Chip
                        variant="outlined"
                        size="small"
                        label={city}
                        color="secondary"
                        onDelete={() => handleDeleteDeliverCity(index)}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Pickup Date Range:
            </Typography>
            <div className={classes.datePickerContainer}>
              <form className={classes.container} noValidate>
                <TextField
                  id="date"
                  label="From:"
                  type="date"
                  defaultValue={pickupDateFrom}
                  onChange={handlePickupDateChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
              <form className={classes.container} noValidate>
                <TextField
                  id="date"
                  label="To:"
                  type="date"
                  defaultValue={pickupDateTo}
                  onChange={handleDeliverDateChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </form>
            </div>
          </div>
          {/* <div className='filter-field'>
          <Typography variant='h6' gutterBottom style={{'marginTop':'2px', 'marginLeft':'5px'}}>
            Unit Price
          </Typography>
          <Slider
            classes={{root:sliderStyleClasses.root}}
            value={unitPrice}
            onChange={changeUnitPrice}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={priceValueText}
            min={searchFilter.static === null ? 0 : searchFilter.static.min_unit_price}
            max={searchFilter.static === null ? 1000 : searchFilter.static.max_unit_price}
            marks={generateMarks('price','unit_price')}
          />
        </div>
        <div className='filter-field'>
          <Typography variant='h6' gutterBottom style={{'marginTop':'2px', 'marginLeft':'5px'}}>
            Total Price
          </Typography>
          <Slider
            classes={{root:sliderStyleClasses.root}}
            value={totalPrice}
            onChange={changeTotalPrice}
            valueLabelDisplay="on"
            aria-labelledby="range-slider"
            getAriaValueText={priceValueText}
            min={searchFilter.static === null ? 0 : searchFilter.static.min_total_price}
            max={searchFilter.static === null ? 1000 : searchFilter.static.max_total_price}
            marks={generateMarks('price','total_price')}
          />
        </div> */}
          <div className="filter-field">
            <Typography
              variant="h6"
              gutterBottom
              style={{ marginTop: "2px", marginLeft: "5px" }}
            >
              Shipment Types
            </Typography>
            <FormGroup classes={{ root: checkBoxStyleClasses.root }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[0]}
                    onChange={() => handleChange(0)}
                    name="all_shipment_size"
                  />
                }
                label="All Size Pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[1]}
                    onChange={() => handleChange(1)}
                    name="pallet_48_x_40"
                  />
                }
                label="Pallet(48''x40'')"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[2]}
                    onChange={() => handleChange(2)}
                    name="pallet_48_x_48"
                  />
                }
                label="Pallet(48''x48'')"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[3]}
                    onChange={() => handleChange(3)}
                    name="Oversize Pallet"
                  />
                }
                label="Oversize Pallet"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[4]}
                    onChange={() => handleChange(4)}
                    name="Overweight Pallet"
                  />
                }
                label="Overweight Pallet"
              />
              {/* <FormControlLabel
              control={<Checkbox checked={checkBoxState[5]} onChange={() => handleChange(5)} name="Oversize & Overweight Pallet" />}
              label="Oversize & Overweight Pallet"
            /> */}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[6]}
                    onChange={() => handleChange(6)}
                    name="Full Truck(5 ton)"
                  />
                }
                label="Full Truck(5 ton)"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxState.shipmentSizeArray[7]}
                    onChange={() => handleChange(7)}
                    name="Full Trailer (FTL)"
                  />
                }
                label="Full Trailer (FTL)"
              />
            </FormGroup>
          </div>
        </div>
        <div className="filter-field">
          <Typography
            variant="h6"
            gutterBottom
            style={{ marginTop: "2px", marginLeft: "5px" }}
          >
            Options
          </Typography>
          <FormGroup classes={{ root: checkBoxStyleClasses.root }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.pickupLoadingDock}
                  onChange={() => handleOptionsChange("pickupLoadingDock")}
                  name="pickup Loading Dock"
                />
              }
              label="Pickup Loading Dock"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.deliverLoadingDock}
                  onChange={() => handleOptionsChange("deliverLoadingDock")}
                  name="deliver loading dock"
                />
              }
              label="Deliver Loading Dock"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={optionCheckBox.deliverAppointment}
                  onChange={() => handleOptionsChange("deliverAppointment")}
                  name="deliver appointment"
                />
              }
              label="Deliver Appointment"
            />
          </FormGroup>
        </div>
        <Button
          className={classes.saveButton}
          variant="contained"
          color="primary"
          onClick={saveSearchFilterSetting}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchFilter: state.searchFilter,
  };
};

export default connect(mapStateToProps)(SearchFilter);

import { DRIVER_ADD_LOADED_SHIPMENTS, DRIVER_REMOVE_LOADED_SHIPMENTS, DRIVER_CLEAN_LOADED_SHIPMENTS_DATA } from "../actions/types"
import produce from 'immer'

const initalState = {
  driver_Loaded_packages: {},
  loaded_shipment_numbers:[]
}

export const driverLoadedShipmentsReducer = (state = initalState, action) => {
  switch(action.type){
    case DRIVER_ADD_LOADED_SHIPMENTS:
      const {package_number, item_name, cover_image, shipment_number, tracking_number} = action.payload
      
      //check if record existed
      if(state.driver_Loaded_packages[package_number] === undefined){
        //no record of package number
        const temp = {}
        tracking_number.forEach(num => {
          temp[num] = ''
        })
        return produce(state, draft => {
          draft['loaded_shipment_numbers'].push(shipment_number)
          draft['driver_Loaded_packages'][package_number] = {
            'item_name':item_name,
            'cover_image':cover_image,
            'shipment_numbers':{
              [shipment_number]:temp
            }
          }
        })
        
      }else if(state.driver_Loaded_packages[package_number].shipment_numbers[shipment_number] === undefined){
        //no record of shipment number
        const newState = produce(state, draft => {

          for(let i = 0; i < tracking_number.length; i++){
            if(i === 0) {
              //first defined shipment number as new property
              draft['driver_Loaded_packages'][package_number].shipment_numbers[shipment_number] = {[tracking_number[i]]:''}
            }else{
              draft['driver_Loaded_packages'][package_number].shipment_numbers[shipment_number][tracking_number[i]] = ''
            }
          }
          draft['loaded_shipment_numbers'].push(shipment_number)
        })
        return newState
      }
      else{
        const newState = produce(state, draft => {
          tracking_number.forEach(t => {
            draft['driver_Loaded_packages'][package_number].shipment_numbers[shipment_number][t] = ''
          })
        })
        return newState
      }
      
    case DRIVER_REMOVE_LOADED_SHIPMENTS:

      return produce(state, draft => {
        //remove tracking number in selected packages
        delete draft.driver_Loaded_packages[action.payload.package_number].shipment_numbers[action.payload.shipment_number][action.payload.tracking_number]

        //remove shipment if shipment has no tracking numbers
        if(Object.keys(draft.driver_Loaded_packages[action.payload.package_number].shipment_numbers[action.payload.shipment_number]).length === 0){
          delete draft.driver_Loaded_packages[action.payload.package_number].shipment_numbers[action.payload.shipment_number]
          const index = state.loaded_shipment_numbers.indexOf(action.payload.shipment_number)
          delete draft.loaded_shipment_numbers[index]
        }
  
        //remove package if package has no shipments
        if(Object.keys(draft.driver_Loaded_packages[action.payload.package_number].shipment_numbers).length === 0){
          delete draft.driver_Loaded_packages[action.payload.package_number]
        }
      })

    case DRIVER_CLEAN_LOADED_SHIPMENTS_DATA:
      return{
        driver_Loaded_packages:{},
        loaded_shipment_numbers:[]
      }
    default:
      return state
  }
}
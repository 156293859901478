import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import SearchHeader from "./SearchHeader";
import ScrollComponent from "./ScrollComponent";
import ShipmentMapLayout from "../Map/ShipmentMapLayout";
import SearchFilter from "./SearchFilter";
import {
  Paper,
  Tabs,
  Tab,
  makeStyles,
  Typography,
  Button,
  Backdrop,
} from "@material-ui/core";
import axios from "axios";
import "../../styles/searchFilter.css";
import { SHIPMENT_STATIC } from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";
import { fetchSearchFilterStatic } from "../../actions/searchFilterAction";
import FilterListRoundedIcon from "@material-ui/icons/FilterListRounded";

const useStyles = makeStyles({
  root: {
    width: "40%",
    margin: "5px auto",
  },
  filterButton: {
    position: "fixed",
    bottom: "1%",
    width: "90px",
    left: "calc(50% - 45px)",
    color: "white",
  },
  backdrop: {
    zIndex: 1,
    color: "#fff",
  },
});

export const ShipmentMainPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [tabValue, setTabValue] = useState(0);
  const history = useHistory();
  const [displayFilter, setDisplayFilter] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const clickBackdrop = () => {
    console.log("clicked back drop..");
    setDisplayFilter(false);
    setOpenBackdrop(false);
  };

  useState(() => {
    const header = {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      },
    };
    //fetch shipment static for filter
    axios
      .get(SHIPMENT_STATIC, header)
      .then((res) => {
        dispatch(fetchSearchFilterStatic(res.data));
      })
      .catch((error) => {
        console.log("Error when trying to get shipment static", error);
      });
  }, []);

  const toSearchSettingPage = () => {
    let path = `/driver/search-setting`;
    history.push(path);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const showHideSearchFilter = (display) => {
    if (!displayFilter && display) {
      setOpenBackdrop(true);
      setDisplayFilter(true);
    } else {
      setDisplayFilter(false);
    }
  };

  return (
    <div className="shipment-list-main-page">
      {/* <SearchHeader handleClick = {toSearchSettingPage} address='Vancouver, Richmond' timeStart='24 Mar, 10:00'
      timeEnd='26 Mar 12:00'/> */}
      <Typography variant="h6" align="center">
        Search Shipments
      </Typography>
      <Paper className={classes.root}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="List" />
          <Tab label="Map" />
        </Tabs>
      </Paper>
      {tabValue === 0 ? (
        <ScrollComponent type="available_shipments" />
      ) : (
        <ShipmentMapLayout type="available_shipments" />
      )}
      <Button
        className={classes.filterButton}
        variant="contained"
        color="primary"
        onClick={showHideSearchFilter}
        endIcon={<FilterListRoundedIcon />}
      >
        Filter
      </Button>
      {displayFilter ? (
        <>
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={clickBackdrop}
          ></Backdrop>
          <SearchFilter display={showHideSearchFilter} />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default ShipmentMainPage;

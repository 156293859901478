import React, { useState, useEffect } from "react";
// import {createMuiTheme } from '@material-ui/core/';
// import { useDispatch, useSelector } from "react-redux";
import {
  // TextField,
  Button,
  // Switch,
  Box,
} from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";

// import Fade from 'react-reveal';
import PropTypes from "prop-types";
import produce from "immer";
import { v4 as uuidv4 } from "uuid";

import { CustomInput } from "../../shared";
import {
  // addShippingItem,
  defaultShippingItem,
  // updateShippingItem,
} from "../../../slices";
import { shipmentTypesDetails } from "../../shared/constInfo";
import { inputChecker } from "../../../Utils/inputChecker";

// import nextId from "react-id-generator";
// import '../../styles/shippingItem.css';

const shipmentTypes = [
  { label: 'Pallet 48" x 40"', value: "1" },
  { label: 'Pallet 48" x 48"', value: "2" },
  { label: "Oversize Pallet", value: "3" },
  { label: "Overweight Pallet", value: "4" },
  // { label: "Oversize & Overweight Pallet", value: "5" },
  { label: "Full Truck (5 ton)", value: "5" },
  { label: "Full Trailer (FTL)", value: "6" },
];

const ShippingItemInput = ({
  edit = false,
  item,
  onCancel,
  errorMsg,
  setErrorMsg,
  small = false,
  guest = false,
  changeItem,
  setShippingItems,
}) => {
  // const dispatch = useDispatch();

  const [shippingItem, setShippingItem] = useState(item || defaultShippingItem);
  const [selectedType, setSelectedType] = useState(item?.selectedTypeID || "");
  const [userInputError, setUserInputError] = useState({
    emtpyError: false,
    heightError: "",
    weightError: "",
    quantityError: "",
    lengthError: "",
    widthError: "",
  });
  const classes = useStyles();

  // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const emptyField =
      shippingItem.description === null ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      shippingItem.quantity === null ||
      selectedType === "";

    if (!emptyField) {
      setUserInputError((prev) => {
        return { ...prev, emtpyError: false };
      });
    }

    const weightLimit = selectedType === "4" ? 3000 : 1200;

    const hasError =
      Boolean(inputChecker(shippingItem.height, 72)) ||
      Boolean(inputChecker(shippingItem.weight, weightLimit)) ||
      Boolean(inputChecker(shippingItem.quantity)) ||
      Boolean(inputChecker(shippingItem.length)) ||
      Boolean(inputChecker(shippingItem.width));

    setUserInputError((prev) => {
      return {
        ...prev,
        heightError: inputChecker(shippingItem.height, 72),
        weightError: inputChecker(shippingItem.weight, weightLimit),
        quantityError: inputChecker(shippingItem.quantity),
        lengthError: inputChecker(shippingItem.length),
        widthError: inputChecker(shippingItem.width),
      };
    });

    if (!emptyField && !hasError) {
      setErrorMsg("");
    }
  }, [shippingItem, selectedType, setErrorMsg, setUserInputError]);

  // useEffect(() => {
  //   if (shippingItem.height > 72) {
  //     setUserInputError((prev) => {
  //       return {
  //         ...prev,
  //         heightError: true,
  //       };
  //     });
  //   } else if (shippingItem.height <= 72) {
  //     setUserInputError((prev) => {
  //       return {
  //         ...prev,
  //         heightError: false,
  //       };
  //     });
  //   }
  //   if (shippingItem.weight > weightLimit) {
  //     setUserInputError((prev) => {
  //       return {
  //         ...prev,
  //         weightError: true,
  //       };
  //     });
  //   } else if (shippingItem.weight <= weightLimit) {
  //     setUserInputError((prev) => {
  //       return {
  //         ...prev,
  //         weightError: false,
  //       };
  //     });
  //   }
  // }, [shippingItem, selectedType, setUserInputError]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    if (fieldName === "hazardous" || fieldName === "tempSensitive") {
      value = e.target.checked;
    }
    if (fieldName === "quantity") value = parseInt(value);
    e.preventDefault();
    setShippingItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  //setSampleData({...sampleData, address: editAddress})
  const handleListItemSelect = (e) => {
    const typeValue = e.target.value;
    setSelectedType(typeValue);
    setShippingItem(
      produce((draft) => {
        draft.length = shipmentTypesDetails[typeValue].length || 48;
        draft.width = shipmentTypesDetails[typeValue].width || 48;
        // draft.height = shipmentTypesDetails[typeValue].height || 72;
        // draft.weight = shipmentTypesDetails[typeValue].weight;
        draft.quantity = draft.quantity || null;
        if (typeValue === "6" || typeValue === "7") {
          draft.quantity = typeValue === "6" ? 12 : 26;
        }
      })
    );
  };

  // Disable the input area based on the type chosen
  const disableInput = (_selectedType, _shipmentTypesDetails, field) => {
    if (!_selectedType) return true;
    return Boolean(shipmentTypesDetails[selectedType][field]);
  };

  //Checks if pallet weight,height and num pallets is not a number and if they aren't, return some error
  //Sets the Redux dispatch pallet state
  const handlePalletData = () => {
    const emptyField =
      shippingItem.description === null ||
      shippingItem.height === null ||
      shippingItem.length === null ||
      shippingItem.width === null ||
      shippingItem.weight === null ||
      shippingItem.quantity === null ||
      selectedType === "";
    if (emptyField) {
      setErrorMsg("Fields with * cannot be empty.");
      setUserInputError((prev) => {
        return { ...prev, emtpyError: true };
      });
      // } else if (
      //   shippingItem.description.length < 2 ||
      //   shippingItem.height < 1 ||
      //   shippingItem.weight < 1 ||
      //   shippingItem.quantity < 1 ||
      //   shippingItem.length < 1 ||
      //   shippingItem.width < 1
      // ) {
      //   setErrorMsg("Invalid shipping item values.");
      // } else if (
      //   isNaN(shippingItem.height) ||
      //   isNaN(shippingItem.weight) ||
      //   isNaN(shippingItem.length) ||
      //   isNaN(shippingItem.width) ||
      //   isNaN(shippingItem.quantity)
      // ) {
      //   setErrorMsg("Not a valid number");
    } else if (
      userInputError.heightError === "" &&
      userInputError.weightError === "" &&
      userInputError.quantityError === "" &&
      userInputError.lengthError === "" &&
      userInputError.widthError === ""
    ) {
      // setUserInputError({
      //   heightError: false,
      //   weightError: false,
      // });
      const newItem = {
        ...shippingItem,
        shipmentType: selectedType,
        packageID: shippingItem.packageID || uuidv4(),
        color: "orange",
      };
      console.log(newItem);
      // setAddConfirm(false)
      // edit ? dispatch(updateShippingItem({newItem})) : dispatch(addShippingItem({newItem}))
      if (edit) {
        // dispatch(updateShippingItem({ newItem }));
        setShippingItems(
          produce((draft) => {
            const index = draft.findIndex(
              (item) => item.packageID === newItem.packageID
            );
            if (index !== -1) draft[index] = newItem;
          })
        );
        setErrorMsg("");
        onCancel();
      } else if (guest) {
        setErrorMsg("");
        changeItem(newItem);
      } else {
        // dispatch(addShippingItem({ newItem }));
        setShippingItems(
          produce((draft) => {
            draft.push(newItem);
          })
        );
        setShippingItem(defaultShippingItem);
        setSelectedType("");
        setErrorMsg("");
      }
    }
  };

  // const numberExceed = (num, limit) => {
  //   if (parseInt(num) > limit) return true;
  //   return false;
  // };

  return (
    <Box
      display="flex"
      justifyContent="center"
      heigh="100%"
      m={!guest ? "0" : "0 3vw"}
    >
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            width={matches ? "60vw" : "30vw"}
            mr="1.5vw"
            mb="2vh"
            minWidth={matches ? "" : "360px"}
          >
            <Box pb={1} pt={1}>
              <div className={classes.subTitle}>Item Description *</div>
              <CustomInput
                // className={classes.textfield}
                value={shippingItem.description}
                onChange={handleItemChange("description")}
              />
            </Box>
            <Box>
              <div className={classes.subTitle}>Packaging type *</div>
              <CustomInput
                onChange={handleListItemSelect}
                options={shipmentTypes}
                value={selectedType}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" mb={1}>
              <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Length of pallet *
                </div>
                <CustomInput
                  // className={classes.textfield}
                  value={shippingItem.length}
                  onChange={handleItemChange("length")}
                  disabled={disableInput(
                    selectedType,
                    shipmentTypesDetails,
                    "length"
                  )}
                  type="number"
                />
              </Box>
              <Box width={matches ? "" : "15vw"} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Width of pallet *
                </div>
                <CustomInput
                  value={shippingItem.width}
                  onChange={handleItemChange("width")}
                  disabled={disableInput(
                    selectedType,
                    shipmentTypesDetails,
                    "width"
                  )}
                  type="number"
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Height of pallet *
                </div>
                <CustomInput
                  value={shippingItem.height}
                  onChange={handleItemChange("height")}
                  // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                  disabled={!selectedType}
                  error={
                    userInputError.heightError ||
                    (userInputError.emtpyError && shippingItem.height === null)
                  }
                  onKeyDown={(evt) => {
                    (evt.key === "e" || evt.key === "-" || evt.key === "E") &&
                      evt.preventDefault();
                  }}
                  type="size"
                  placeholderValue={
                    selectedType && parseInt(selectedType) < 5
                      ? `Maximum: 72`
                      : ""
                  }
                />
                <Box
                  pl={1}
                  color="red"
                  visibility={userInputError.heightError ? "visible" : "hidden"}
                >
                  {userInputError.heightError}
                </Box>
              </Box>
              <Box width={matches ? "" : "15vw"} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Weight of pallet *
                </div>
                <CustomInput
                  // className={classes.textfield}
                  value={shippingItem.weight}
                  onChange={handleItemChange("weight")}
                  // disabled={disableInput(selectedType, shipmentTypesDetails, 'weight')}
                  disabled={!selectedType}
                  error={
                    userInputError.weightError ||
                    (userInputError.emtpyError && shippingItem.weight === null)
                  }
                  type="weight"
                  onKeyDown={(evt) => {
                    (evt.key === "e" || evt.key === "-" || evt.key === "E") &&
                      evt.preventDefault();
                  }}
                  placeholderValue={
                    selectedType && parseInt(selectedType) < 5
                      ? `Maximum: ${selectedType === "4" ? "3000" : "1200"}`
                      : ""
                  }
                />
                <Box
                  pl={1}
                  color="red"
                  visibility={userInputError.weightError ? "visible" : "hidden"}
                >
                  {userInputError.weightError}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          width={matches ? "60vw" : "21vw"}
          mb={2}
          mt={1}
          minWidth={matches ? "" : "360px"}
        >
          <div className={classes.subTitle}>Number of pallets *</div>
          <CustomInput
            // className={classes.textfield}
            value={shippingItem.quantity}
            onChange={handleItemChange("quantity")}
            disabled={selectedType === "6" || selectedType === "7"}
            type="number"
          />
        </Box>
        <Box width="100%">
          {errorMsg ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              {errorMsg}
            </Alert>
          ) : (
            ""
          )}
        </Box>
        <Box mb={2} mt={2} display="flex" justifyContent="center">
          {edit && (
            <Box mr={2}>
              <Button variant="outlined" color="primary" onClick={onCancel}>
                CANCEL
              </Button>
            </Box>
          )}

          <Box ml={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={handlePalletData}
            >
              {edit || guest ? "UPDATE" : "ADD"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

ShippingItemInput.propTypes = {
  item: PropTypes.object,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  small: PropTypes.bool,
  guest: PropTypes.bool,
  changeItem: PropTypes.func,
  setShippingItems: PropTypes.func,
};

export default ShippingItemInput;

import React, { useEffect, useState } from "react";
import ShipmentDetailLayout from "../Layouts/ShipmentDetailLayout";
import { SummarySection } from "./SummarySection";
import { useParams } from "react-router-dom";
import { connect, useSelector, useDispatch } from "react-redux";
import { driverClearAllShipmentsFromSummary } from "../../actions/driverClaimShipmentAction";
import { makeStyles } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    lineHeight: 1,
    margin: "5px 2%",
  },
}));

const ShipmentDetailPage = ({ claimSummary }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadPackage, setLoadPackage] = useState(true);
  const [loadShipments, setloadShipments] = useState(true);
  const [loadOtherShipments, setLoadOtherShipments] = useState(true);
  const [displaySummary, setDisplaySummary] = useState(false);

  const [shipmentInfo, setShipmentInfo] = useState([]);
  const [packageInfo, setPackageInfo] = useState([]);
  const [order_number, setOrderNumber] = useState("");
  const [otherShipments, setOtherShipments] = useState([]);

  const shipment_id = useParams().id;

  const reflashCallback = (action) => {
    if (action === true) {
      fetchShipmentInfo();
    }
  };

  const fetchShipmentInfo = () => {
    //fetch shipment and its package
    setloadShipments(true);
    setLoadPackage(true);
    axios
      .get(`https://api.uucargo.ca/api/shipments/${shipment_id}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      })
      .then((res) => {
        setShipmentInfo(res.data);
        //setOrderNumber(res.data.order_number)
        setloadShipments(false);
        getPackageInfo(res.data.package_number);
      });
  };

  useEffect(() => {
    fetchShipmentInfo();

    //clean up summary
    return () => {
      dispatch(driverClearAllShipmentsFromSummary());
    };
  }, [shipment_id]);

  const getPackageInfo = (pNum) => {
    axios
      .get(`https://api.uucargo.ca/api/packages/${pNum}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      })
      .then((res) => {
        setPackageInfo(res.data);
        setLoadPackage(false);
      });
  };

  // useEffect(() => {
  //   //fetch order
  //   if(order_number === '') return
  //   setLoadOtherShipments(true)
  //   axios.get(`https://api.uucargo.ca/api/orders/${order_number}`)
  //   .then(res => {
  //     const orderInfo = res.data
  //     console.log('fetched order information: ', orderInfo)
  //     let tempOtherShipments = []
  //     if(orderInfo.shipments.data.length > 0){
  //       tempOtherShipments.push(...orderInfo.shipments.data)
  //       console.log('shipments before filtered',tempOtherShipments)
  //       //filter out current selected shipment
  //       tempOtherShipments = tempOtherShipments.filter(shipment => shipment.shipment_number !== shipment_id &&
  //         shipment.status === 'Active' && shipment.shipping_status === 'Unclaimed')

  //       console.log('shipments after filtered',tempOtherShipments)
  //     }

  //     if(tempOtherShipments.length > 0 && orderInfo.packages.data.length > 0){
  //       tempOtherShipments.map(shipment =>
  //         shipment.package = orderInfo.packages.data.find(p => p.package_number === shipment.package_number)
  //       )
  //     }else if(tempOtherShipments.length === 0){
  //       console.log('no other Active shipments under this order')
  //       setOtherShipments([])
  //       setLoadOtherShipments(false)
  //       return
  //     }else if(orderInfo.packages.data.length === 0){
  //       console.log('no package information under this order')
  //     }

  //     console.log('final other shipments',tempOtherShipments)
  //     setOtherShipments(tempOtherShipments)
  //     setLoadOtherShipments(false)
  //   }).catch(err => {
  //     console.log(`fetch order errer, ${err}`)
  //   })

  // },[order_number])

  return (
    <>
      {!loadShipments && !loadPackage ? (
        <ShipmentDetailLayout
          sectionTitle={"Shipment Detail"}
          packageInfo={packageInfo}
          shipmentInfo={shipmentInfo}
        />
      ) : (
        <LoadingCircle />
      )}
      {/* {
      (!loadOtherShipments && otherShipments.length > 0) && <Typography className={classes.title}>Other Shipments</Typography>
    }
    {
      !loadOtherShipments ?
        otherShipments.map(shipment => <ShipmentDetailLayout packageInfo={shipment.package} shipmentInfo={shipment}/>)
      :
        <LoadingCircle/>
    }   */}
      {claimSummary.driver_claimed_shipments.length > 0 && (
        <SummarySection
          sectionTitle={"Summary"}
          packages={claimSummary.driver_claimed_shipments}
          reflash={(action) => reflashCallback(action)}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    claimSummary: state.driver_claim_summary,
  };
};

export default connect(mapStateToProps)(ShipmentDetailPage);

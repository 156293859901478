import React, { useEffect, useState } from "react";
import IndividualUser from "./userType/individualUser";
// import CompanyUser from "./userType/companyUser";
import {
  Box,
  IconButton,
  Input,
  // CircularProgress,
  Button,
  TextField,
  Snackbar,
  // useMediaQuery,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { Edit, Check, Cancel } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";

import "../../styles/styles.css";

// import axios from 'axios';
import { errHandling, getHeaders, getURLParams } from "../../Utils/fetchUtil";
import {
  BACKEND_PROFILE_API,
  BACKEND_CHANGE_ROLE_API,
} from "../../Utils/apiUrl";
import { updateUserInfo } from "../../slices";
import { CustomInput } from "../shared";
import LicenseInfoForm from "./components/licenseInfoForm";

import { useSelector, useDispatch } from "react-redux";
import axios from "axios";

// const row = {
//     border: "2px",
// };

const useStyles = makeStyles((theme) => ({
  itemWrapper: {
    display: "flex",
    paddingBottom: theme.spacing(1),
    alignItems: "center",
    "& > div:nth-child(1)": {
      flexBasis: "40%",
    },
    "& > div:nth-child(2)": {
      flexBasis: "60%",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  formWrapper: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderRadius: 5,
    backgroundColor: "white",
    maxWidth: "720px",
    margin: "0 auto",
  },
  button: {
    fontSize: "12pt",
    // color: "white",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

const vehicleTypes = [
  { label: "Full (5 Ton) Truck", value: "1" },
  { label: "Full Trailer", value: "2" },
  { label: "Other Truck", value: "3" },
];

const defaultProfile = {
  firstname: null,
  lastname: null,
  address: null,
  phone: null,
  email: null,
  role: null,
};

const UserProfile = () => {
  // const [confirm, setConfirm] = useState();
  // const [selectedUser, setSelectedUser] = useState();
  //Dummy userdata object for testing
  const userInfo = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  // const matches = useMediaQuery("(max-width:600px)");

  const [sampleData, setSampleData] = useState(defaultProfile);
  const [licenseDialogOpen, setLicenseDialogOpen] = useState(false);
  const [updateErrMsg, setUpdateErrMsg] = useState("");

  useEffect(() => {
    setSampleData({
      firstname: userInfo.firstname,
      lastname: userInfo.lastname,
      phone: userInfo.phone,
      email: userInfo.email,
      role: userInfo.role === "End User" ? "User" : "Driver",
      address: userInfo.address,
      licenseNum: "",
      vehicleType: userInfo.vehicleType,
      licenseImg:
        "https://storage.googleapis.com/uucargo-resource/image/default/package.png",
    });
  }, [userInfo]);

  const [editValue, setEditValue] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    address: "",
    licenseNum: "",
    vehicleType: null,
    role: "",
  });
  const [editMode, setEditMode] = useState({
    firstname: false,
    lastname: false,
    phone: false,
    address: false,
    licenseNum: false,
    vehicleType: false,
    licenseImg: false,
    role: false,
  });

  const renderView = (attr) => {
    let value;
    if (attr === "vehicleType")
      value =
        vehicleTypes.find((ele) => ele.value === sampleData[attr])?.label || "";
    else value = sampleData[attr];
    return (
      <div>
        {value}{" "}
        <IconButton onClick={() => changeEditMode(attr)}>
          <Edit />
        </IconButton>
      </div>
    );
  };

  // const renderImgView = () => {
  //   return (
  //     <Box height="150px" width="150px" position="relative" ml={1} mr={2}>
  //       <img
  //         src={sampleData.licenseImg}
  //         alt="License"
  //         className={classes.pkgImg}
  //       />
  //     </Box>
  //   );
  // };

  const setMode = (attr, value = null) => {
    setEditMode((prev) => {
      const newObj = { ...prev };
      newObj[attr] = value !== null ? value : !prev[attr];
      return newObj;
    });
  };

  //**EDIT ADDRESS */
  const handleValueChange = (field) => (e) => {
    setEditValue((prev) => {
      const newObj = { ...prev };
      newObj[field] = e.target.value;
      return newObj;
    });
  };
  const changeEditMode = (attr) => {
    setEditValue((prev) => {
      const newObj = { ...prev };
      newObj[attr] = sampleData[attr];
      return newObj;
    });
    setMode(attr, true);
  };

  const updateAttr = (attr, putObj, closeOper) => () => {
    axios({
      method: "put",
      url: `${BACKEND_PROFILE_API}${sampleData.email}`,
      data: JSON.stringify(putObj),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        if (attr === "vehicleType")
          dispatch(
            updateUserInfo({ field: attr, value: res.data.data.vehicle })
          );
        else
          dispatch(updateUserInfo({ field: attr, value: res.data.data[attr] }));
        closeOper();
      })
      .catch((error) => {
        // console.log(error.message);
        errHandling(error, setUpdateErrMsg);
        closeOper();
      });
  };

  const updateUserRole = (role, closeOper) => () => {
    const remoteRole = role === "User" ? "End User" : "Driver";
    axios({
      method: "put",
      url: getURLParams(BACKEND_CHANGE_ROLE_API(sampleData.email), {
        role: remoteRole,
      }),
      headers: {
        ...getHeaders(),
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        dispatch(updateUserInfo({ field: "role", value: res.data.user.role }));
        closeOper();
      })
      .catch((error) => {
        // console.log(error.message);
        errHandling(error, setUpdateErrMsg);
        closeOper();
      });
  };

  const renderAddressEditView = () => {
    return (
      <Box display="flex" flexDirection="column">
        <TextField
          // className={classes.textfield}
          InputProps={{ disableUnderline: true }}
          onChange={handleValueChange("address")}
          value={editValue.address}
          variant="filled"
          rows={2}
          label="Text here"
          multiline
        />
        <Box alignSelf="end">
          <IconButton onClick={() => setMode("address", false)}>
            <Cancel />
          </IconButton>
          <IconButton
            onClick={updateAttr("address", { address: editValue.address }, () =>
              setMode("address", false)
            )}
          >
            <Check />
          </IconButton>
        </Box>
      </Box>
    );
  };

  const renderSimpleEditView = (attr) => {
    const newUpdateObj = {};
    newUpdateObj[attr] = editValue[attr];
    return (
      <div>
        <Input
          type="text"
          value={editValue[attr]}
          onChange={handleValueChange(attr)}
        />
        <IconButton onClick={() => setMode(attr, false)}>
          <Cancel />
        </IconButton>
        <IconButton
          onClick={updateAttr(attr, newUpdateObj, () => setMode(attr, false))}
        >
          <Check />
        </IconButton>
      </div>
    );
  };

  const renderTypeEditView = (options) => {
    return (
      <div>
        <CustomInput
          onChange={handleValueChange("vehicleType")}
          options={options}
          value={editValue.vehicleType}
          vehicleType
        />
        <IconButton onClick={() => setMode("vehicleType", false)}>
          <Cancel />
        </IconButton>
        <IconButton
          onClick={updateAttr(
            "vehicleType",
            { vehicle: editValue.vehicleType },
            () => setMode("vehicleType", false)
          )}
        >
          <Check />
        </IconButton>
      </div>
    );
  };

  const renderRadioEditView = () => {
    return (
      <Box display="flex" alignItems="center">
        <RadioGroup
          row
          aria-label="role"
          name="role"
          value={editValue.role}
          onChange={handleValueChange("role")}
        >
          <FormControlLabel
            value="User"
            control={<Radio color="primary" />}
            label="User"
          />
          <FormControlLabel
            value="Driver"
            control={<Radio color="primary" />}
            label="Driver"
          />
        </RadioGroup>
        <Box ml={1} pb={1}>
          <IconButton onClick={() => setMode("role", false)}>
            <Cancel />
          </IconButton>
          <IconButton
            onClick={updateUserRole(editValue.role, () =>
              setMode("role", false)
            )}
          >
            <Check />
          </IconButton>
        </Box>
      </Box>
    );
  };

  // const onDrop = (image) => {
  //   setMode("licenseImg", false);
  // };

  // const renderImgEditView = () => {
  //   return (
  //     <Box width="100%">
  //       <ImageUploader
  //         withIcon={true}
  //         fileContainerStyle={{ width: "60%", height: "40%" }}
  //         buttonText="Choose Images"
  //         onChange={onDrop}
  //         label="Max file size: 10mb. Accepted image types: .JPG, .PNG"
  //         imgExtension={[".jpg", ".png"]}
  //         maxFileSize={10242880}
  //         fileTypeError=" - Image extension is not supported"
  //         singleImage
  //       />
  //     </Box>
  //   );
  // };

  const handleBarClose = () => {
    setUpdateErrMsg("");
  };

  return (
    <Box ml="1vw" mr="1vw">
      <br />
      <Box className={classes.formWrapper}>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">First Name</div>
          <div className="bodytext">
            {editMode.firstname
              ? renderSimpleEditView("firstname")
              : renderView("firstname")}
          </div>
        </Box>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">Last Name</div>
          <div className="bodytext">
            {editMode.lastname
              ? renderSimpleEditView("lastname")
              : renderView("lastname")}
          </div>
        </Box>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">Address</div>
          <Box className="bodytext" width="100%">
            {editMode.address ? renderAddressEditView() : renderView("address")}
          </Box>
        </Box>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">Phone</div>
          <div className="bodytext">
            {editMode.phone
              ? renderSimpleEditView("phone")
              : renderView("phone")}
          </div>
        </Box>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">E-mail</div>
          <div className="bodytext">{sampleData.email}</div>
        </Box>
        <Box className={classes.itemWrapper}>
          <div className="subtitle">Role</div>
          <div className="bodytext">
            {editMode.role ? renderRadioEditView() : renderView("role")}
          </div>
        </Box>
      </Box>
      <br />
      {userInfo.source === "Email" && (
        <Box className={classes.formWrapper}>
          <Box className={classes.itemWrapper}>
            <div className="subtitle">Change Password</div>
            <Box alignSelf="center" pt={1}>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => history.push("/change-password")}
              >
                Change
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <IndividualUser cardStatus={userInfo.activeCard} />

      {sampleData.role !== "User" && (
        <Box className={classes.formWrapper}>
          <Box className={classes.itemWrapper}>
            <div className="subtitle">Driver License No.</div>
            {userInfo.licenseNumber && (
              <div className="bodytext">Ending in {userInfo.licenseNumber}</div>
            )}
            {/* <div className="bodytext">
              {editMode.licenseNum
                ? renderSimpleEditView("licenseNum")
                : renderView("licenseNum")}
            </div> */}
          </Box>
          <Box className={classes.itemWrapper}>
            <div className="subtitle">Legal Name</div>
            {userInfo.legalName && (
              <div className="bodytext">{userInfo.legalName}</div>
            )}
          </Box>
          <Box display="flex" justifyContent="center" pt={1} pb={1}>
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={() => setLicenseDialogOpen(true)}
            >
              {userInfo.licenseNumber ? "Update license" : "Add license"}
            </Button>
          </Box>
          <hr />
          <Box className={classes.itemWrapper}>
            <div className="subtitle">Vehicle Type</div>
            <div className="bodytext">
              {editMode.vehicleType
                ? renderTypeEditView(vehicleTypes)
                : renderView("vehicleType")}
            </div>
          </Box>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(updateErrMsg)}
        onClose={handleBarClose}
        message={updateErrMsg}
        autoHideDuration={3000}
      />

      <LicenseInfoForm
        dialogOpen={licenseDialogOpen}
        setDialogOpen={setLicenseDialogOpen}
        email={sampleData.email}
        update={Boolean(userInfo.licenseNumber)}
      />
    </Box>
  );
};

export default UserProfile;

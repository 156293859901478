import React, { useState } from "react";
import { IconButton, Typography, Button, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CancelIcon from "@material-ui/icons/Cancel";
import {
  driverUploadImages,
  driverRemoveImages,
} from "../../actions/driverDeliverUploadImagesAction";
import { useDispatch, connect } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "700px",
    padding: "3px",
  },
  imgeInput: {
    display: "none",
  },
  uploadButton: {
    marginLeft: "11px",
  },
  uploadedImage: {
    border: "2px solid #D3D3D3",
    borderRadius: "2%",
    width: "80vw",
    height: "50vh",
    maxHeight: "200px",
    maxWidth: "250px",
    "&:hover": {
      border: "2px solid #FFC300",
    },
  },
  uploadImagesContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "center",
    marginTop: "10px",
  },
  cancelIcon: {
    position: "absolute",
    right: "-10%",
    top: "-12%",
    zIndex: 1,
  },
  imageListItem: {
    position: "relative",
  },
  uploadButtonWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonAndImagesWrapper: {
    border: "2px dashed #d3d3d3",
    borderRadius: "2px",
    background: "#fdf5e8",
    padding: "5px",
  },
  uploadInfo: {
    border: "1px solid #f50057",
    margin: "5px",
    borderRadius: "5px",
    color: "white",
    background: "#F50058",
    padding: "5px",
  },
}));

const DriverUploadImages = ({ driverUploadedImages, type }) => {
  let dataSource;
  if (type === "load") {
    dataSource = driverUploadedImages.loadShipments;
  } else if (type === "deliver") {
    dataSource = driverUploadedImages.deliverShipments;
  }

  const dispatch = useDispatch();

  const MAX_IMAGES_UPLOAD = 5;
  const classes = useStyles();
  const [uploadInfo, setUploadInfo] = useState("");

  const handleImageSelect = e => {
    console.log("selected imgs: ", e.target.files);
    //maximum 5 images
    if (dataSource.length + e.target.files?.length <= MAX_IMAGES_UPLOAD) {
      let tempImages = [];
      console.log("tempImages: ", tempImages);
      for (let i = 0; i < e.target.files.length; i++) {
        let imgObj = {
          name: e.target.files[i].name,
          preview: URL.createObjectURL(e.target.files[i]),
          raw: e.target.files[i],
        };

        if (tempImages.length < MAX_IMAGES_UPLOAD) {
          tempImages.push(imgObj);
        }
      }

      dispatch(driverUploadImages([...tempImages], type));
    } else {
      //more than 5 images are selected
      if (MAX_IMAGES_UPLOAD - dataSource.length > 0) {
        let tempImages = [];

        for (let i = 0; i < MAX_IMAGES_UPLOAD - dataSource.length; i++) {
          let imgObj = {
            preview: URL.createObjectURL(e.target.files[i]),
            raw: e.target.files[i],
          };

          if (tempImages.length < MAX_IMAGES_UPLOAD) {
            tempImages.push(imgObj);
          }
        }

        dispatch(driverUploadImages([...tempImages], type));
      }
      setUploadInfo(
        "You have reached maximum uploading 5 images. Please only uploading 5 images."
      );
      setTimeout(() => {
        setUploadInfo("");
      }, 1000 * 10);
    }
  };

  const removeImage = index => {
    dispatch(driverRemoveImages(index, type));
  };

  return (
    <Paper className={classes.root}>
      <div className={classes.buttonAndImagesWrapper}>
        <div className={classes.uploadButtonWrapper}>
          <Typography variant="caption">
            File Formats Accepted <b>PNG, JPG</b>
          </Typography>
          <Typography variant="caption" align="center">
            Max. file size <b>10MB</b> each, Max. file upload{" "}
            <b>{MAX_IMAGES_UPLOAD} images</b>
          </Typography>
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload Image
            </Button>
          </label>
          <Typography variant="caption">
            <i>(Optional)</i>
          </Typography>
          {dataSource.length > 0 && (
            <Typography variant="caption">
              <b>
                ({dataSource.length} / {MAX_IMAGES_UPLOAD}) images uploaded
              </b>
            </Typography>
          )}
          {uploadInfo !== "" && (
            <div className={classes.uploadInfo}>
              <Typography variant="body2" align="center">
                {uploadInfo}
              </Typography>
            </div>
          )}
        </div>
        <div className={classes.uploadImagesContainer}>
          {dataSource.length > 0 &&
            dataSource.map((img, index) => {
              return (
                <div className={classes.imageListItem} key={index}>
                  <IconButton
                    color="secondary"
                    className={classes.cancelIcon}
                    onClick={() => removeImage(index)}
                  >
                    <CancelIcon />
                  </IconButton>
                  <img
                    src={dataSource[index].preview}
                    alt="shipment"
                    width="300"
                    height="300"
                    className={classes.uploadedImage}
                  />
                </div>
              );
            })}
        </div>
      </div>
      <div className="uploadButton">
        <input
          accept=".jpg, .png"
          className={classes.imgeInput}
          id="contained-button-file"
          multiple
          type="file"
          onChange={handleImageSelect}
        />
      </div>
    </Paper>
  );
};

const mapStateToProps = state => {
  return {
    driverUploadedImages: state.driver_uploaded_images,
  };
};

export default connect(mapStateToProps)(DriverUploadImages);

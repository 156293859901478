export const amzFulfillCenterList = [
  "6000 W Van Buren St, Phoenix, AZ",
  "4750 W Mohave St, Phoenix, AZ",
  "16920 W Commerce Dr, Goodyear, AZ",
  "4750 W. Mohave St, Phoenix, AZ",
  "800 N. 75th Ave, Phoenix, AZ",
  "777 S 79th Ave, Tolleson, AZ",
  "3333 S 7th St, Phoenix, AZ",
  "5050 W. Mohave St, Phoenix, AZ",
  "533 W Lower Buckeye Rd, Phoenix, AZ",
  "6701 S. Kolb Rd, Tucson, AZ 85756",
  "500 S 48th St, Phoenix, AZ 85034",
  "1920 N Locust St, North Little Rock, AR",
  "1601 Petrol Rd, Bakersfield, CA",
  "2405 Conejo Spectrum St, Thousand Oaks, CA",
  "5250 Goodman Rd, Eastvale, CA",
  "3575 S Orange Ave, Fresno, CA",
  "11263 Oleander Ave, Fontana, CA",
  "2417 E. Carson St, Long Beach, CA",
  "4950 Goodman Rd, Eastvale, CA",
  "27517 Pioneer Ave, Redlands, CA",
  "20901 Krameria Ave, Riverside, CA",
  "1660 N. Locust Ave, Rialto, CA",
  "4375 N Perris Blvd, Perris, CA",
  "255 Park Center Dr, Patterson, CA",
  "1555 N. Chrisman Rd, Tracy, CA",
  "1910 & 2020 E Central Ave, San Bernardino, CA",
  "24208 San Michele Rd, Moreno Valley, CA",
  "2125 W. San Bernardino Ave, Redlands, CA",
  "1565 N MacArthur Dr, Tracy, CA",
  "1650 East Central Ave, San Bernardino, CA",
  "1010 West Fourth St, Beaumont, CA",
  "4611 Newcastle Rd, Stockton, CA",
  "3565 N Airport Way, Manteca, CA",
  "188 Mountain House Pkwy, Tracy, CA",
  "4900 W Elkhorn Blvd, Metro Air Park, Sacramento, CA",
  "4723 S B St, Stockton, CA",
  "2496 W Walnut St, Rialto, CA",
  "5250 Goodman Road, Eastvale, CA",
  "555 East Orange Show Rd, San Bernardino, CA",
  "1909 Zephyr St, Stockton, CA",
  "2889 Himalaya Dr, Aurora, CO",
  "24006 E. 19th Ave, Aurora, CO",
  "14601 Grant St, Thornton CO",
  "19799 E 36th Dr, Aurora, CO",
  "4303 Grinnell Blvd, Colorado Springs, CO",
  "200 Old Iron Ore Rd, Windsor, CT",
  "415 Washington Ave, Building 3, North Haven, CT",
  "1 Centerpoint Blvd, New Castle, DE",
  "1600 Johnson Way, New Castle, DE",
  "560 Merrimac Ave, Middletown, DE",
  "727 N. Broad St, Middletown, DE",
  "1760 County Line Rd, Lakeland, FL",
  "3350 Laurel Ridge Ave, Ruskin, FL",
  "12900 Pecan Park Rd, Jacksonville, FL",
  "13333 103rd St, Cecil Commerce Center, Jacksonville, FL",
  "4948 Bulls Bay Hwy, Jacksonville, FL",
  " 12340 Boggy Creek Rd, Orlando, FL",
  "305 Deen Still Rd, Davenport, FL",
  "2841 Access Rd, Davenport, FL",
  "14000 NW 37th Ave, Opa Locka, FL",
  "7469 Kingspointe Pkwy, Orlando, FL",
  "2255 W Park Blvd, Stone Mountain, GA",
  "4200 N Commerce Dr, East Point, GA",
  "6855 Shannon Pkwy S, Union City, GA",
  "2201 Thornton Road, Lithia Springs, GA",
  "650 Broadway Ave, Braselton, GA",
  "808 Hog Mountain Rd, Jefferson, GA",
  "6200 Fulton Industrial Blvd, Atlanta, GA",
  "7001 Skipper Rd, Macon, GA",
  "5319 E Franklin Rd, Nampa, ID",
  "30260 Graaskamp Blvd, Wilmington, IL",
  "201 Emerald Dr, Joliet, IL",
  "16825 Churnovic Ln, Crest Hill, IL",
  "1125 W Remington Blvd, Romeoville, IL",
  "6605 or 6521 W Monee Manhattan Rd, Monee, IL",
  "1750 Bridge Dr, Waukegan, IL",
  "2865 Duke Pkwy, Aurora, IL",
  "801 Midpoint Rd, Minooka, IL",
  "3050 Gateway Commerce Center Dr S, Edwardsville, IL",
  "3931 Lakeview Corporate Dr, Edwardsville, IL",
  "23714 W Amoco Rd, Channahon, IL",
  "23700 W Bluff Rd Bldg A, Channahon, IL",
  "3601 Howard St, Skokie, IL",
  "4255 Anson Blvd, Whitestown, IN",
  "715 Airtech Pkwy, Plainfield, IN",
  "710 South Girls School Rd, Indianapolis, IN",
  "800 S Perry Rd, Plainfield, IN",
  "9101 Orly Dr, Indianapolis, IN",
  "2140 Stacie’s Way, Greenwood, IN",
  "6161 Decatur Blvd, Indianapolis, IN",
  "900 Patrol Rd, Jeffersonville, IN",
  "5100 S Indianapolis Rd, Whitestown, IN",
  "5850 W 80th St, Indianapolis, IN",
  "#200, 5545 Chet Waggoner Court, South Bend, IN",
  "500 32nd St SW, Bondurant, IA",
  "19645 Waverly Rd, Edgerton, KS",
  "6925 Riverview Ave, Kansas City, KS",
  "16851 W 113th St, Lenexa, KS",
  "1155 Worldwide Blvd, Hebron, KY",
  "1600 Worldwide Blvd, Hebron, KY",
  "3680 Langley Dr, Hebron, KY",
  "7968 Kentucky Dr, Florence, KY",
  "4620 Olympic Blvd, Erlanger, KY",
  "3521 Point Pleasant Road, Hebron, KY",
  "1850 Mercer Rd, Lexington, KY",
  "172 Trade St, Lexington, KY",
  "1105 S Columbia Ave, Campbellsville, KY",
  "4360 Robards Ln Louisville, KY",
  "376 Zappos.com Blvd Shepherdesville, KY",
  "271 Omega Pkwy, Shepherdsville, KY",
  "300 Omicron Court, Shepherdsville, KY",
  "100 W. Thomas P. Echols Ln, Shepherdsville, KY",
  "2010 Broening Hwy, Baltimore, MD",
  "1100 Woodley Rd, Aberdeen, MD",
  "13905 Crayton Blvd, Hagerstown, MD",
  "600 Principio Parkway West, North East, MD",
  "1700 Sparrows Point Blve, Sparrows Point, MD",
  "1180 Innovation Way, Fall River, MA",
  "1000 Technology Center Dr, Stoughton, MA",
  "500 Sprague St, Dedham, MA",
  "39000 Amrhein Rd, Livonia, MI",
  "50500 Mound Rd, Shelby Township, MI",
  "32801 Ecorse Road, Romulus, MI",
  "19991 Brownstown Center Dr, Brownstown Charter Twp, MI",
  "4500 68th St. SE, Caledonia, MI",
  "2601 4th Ave E, Shakopee, MN",
  "9001 Wyoming Ave N, Brooklyn Park, MN",
  "191 Norfolk Southern Way, Byhalia, MS",
  "11505 Progress Way, Olive Branch, MS",
  "4000 Premier Pkwy, St Charles, MO",
  "462 Hazelwood Logistics Center Dr, Hazelwood, MO",
  "555 Milan Dr, Sparks, NV",
  "8000 N Virginia St, Reno, NV",
  "12300 Bermuda Rd, Henderson, NV",
  "3837 Bay Lake Trail Suite 115, North Las Vegas, NV",
  "4550 Nexus Way, North Las Vegas, NV",
  "6001 E. Tropical Pkwy, North Las Vegas, NV",
  "10 State St, Nashua, NH",
  "50 New Canton Way, Robbinsville Twp, NJ",
  "275 Omar Ave, Avenel, NJ",
  "698 Route 46 West, Teterboro, NJ",
  "8003 Industrial Ave, Carteret, NJ",
  "380 Middlesex Ave, Carteret, NJ",
  "2170 State Route 27, Edison, NJ",
  "2651 Oldmans Creek Rd, Logan Township, NJ",
  "22 Hightstown-Cranbury Station Rd, Cranbury, NJ",
  "240 Mantua Grove Rd, West Deptfort, NJ",
  "1101 E. Pearl St, Burlington, NJ",
  "546 Gulf Ave, Staten Island, NY",
  "4201 Walden Ave, Lancaster, NY",
  "7211 Morgan Rd, Liverpool, NY",
  "6500 Davidson Hwy, Concord, NC",
  "8000 Tuckaseegee Road, Charlotte, NC",
  "1745 Derita Rd, Concord, NC",
  "3620 Reeves Ridge Dr, Charlotte, NC",
  "4851 Jones Sausage Rd, Garner, NC",
  "1805 TW Alexander Dr, Durham, NC",
  "1656 Snow Bridge Ln, Kernersville, NC",
  "11903 National Rd SW, Etna, OH",
  "6050 Gateway Court, Obetz, OH",
  "700 Gateway Blvd, Monroe, OH",
  "1550 W Main St, West Jefferson, OH",
  "3538 TradePort Ct, Lockbourne, OH",
  "21500 Emery Rd, North Randall, OH",
  "1155 Babbitt Rd, Euclid, OH",
  "8685 Independence Pkwy, Twinsburg, OH",
  "3880 Groveport Road, Obetz, OH",
  "2450 Romig Rd, Akron, OH",
  "9201 S. Portland Ave, Oklahoma City, OK",
  "4401C E Hefner Rd, Oklahoma City, OK",
  "1414 S Council Rd, Oklahoma City, OK",
  "11920 E 43rd St N, Tulsa, OK",
  "5647 NE Huffman St, Hillsboro, OR",
  "15000 N. Lombard St, Portland, OR",
  "4775 Depot Court SE, Salem, OR",
  "1250 NW Swigert Way, Troutdale, OR",
  "550 Oak Ridge Rd, Hazleton, PA",
  "298 First Ave, Covington Township, PA",
  "1 Commerce Rd, Pittston, PA",
  "250 Enterprise Way, Pittston, PA",
  "705 Boulder Dr, Breinigsville, PA",
  "650 Boulder Dr, Breinigsville, PA",
  "1610 Van Buren Rd, Easton, PA",
  "6455 Allentown Blvd, Harrisburg, PA",
  "501 North Dr, Sewickley, PA",
  "2 Ames Dr, Carlisle, PA",
  "21 Roadway Dr, Carlisle, PA",
  "500 McCarthy Dr, Lewisberry, PA",
  "675 Allen Rd, Carlisle, PA",
  "545 Oak Hill Rd, Mountaintop, PA",
  "1200 Westport Rd, Imperial, PA",
  "2250 Roswell Dr, Pittsburgh, PA",
  "40 Logistics Dr, Carlisle, PA",
  "4400 12th Street Extension, West Columbia, SC",
  "402 John Dodd Rd, Spartanburg, SC",
  "14840 Central Pike, Lebanon, TN",
  "500 Duke Dr, Lebanon, TN",
  "2020 Joe B Jackson Pkwy, Murfreesboro, TN",
  "50 Airways Blvd, Nashville, TN",
  "7200 Discovery Dr, Chattanooga, TN",
  "225 Infinity Dr, Charleston, TN",
  "2813 Brick Church Pike, Nashville, TN",
  "5155 Citation Dr, Memphis, TN",
  "10 Dell Pkwy, Nashville, TN",
  "2601 S Airfield Dr, Irving, TX",
  "1301 Chalk Hill Rd, Dallas, TX",
  "1400 Southport Pkwy, Wilmer, TX",
  "3838 W Miller Rd, Garland, TX",
  "8901 Forney Rd, Dallas, TX",
  "2700 Regent Blvd, Irving, TX",
  "940 W Bethel Rd, Coppell, TX",
  "700 Westport Pkwy, Fort Worth, TX",
  "2701 W Bethel Rd, Coppell, TX",
  "15201 Heritage Pkwy, Fort Worth, TX",
  "2601 W Bethel Rd, Grapevine, TX",
  "944 W. Sandy Lake Rd, Coppell, TX",
  "3351 Balmorhea Dr, Dallas, TX",
  "9155 Southlink Dr, Dallas, TX",
  "2101 Danieldate Rd, Lancaster, TX",
  "6000 Enterprise Ave Schertz, TX",
  "1401 E McCarty Ln, San Marcos, TX",
  "1625 Hutton Dr, Carrollton, TX",
  "14900 Frye Rd, Fort Worth, TX",
  "10550 Ella St, Houston, TX",
  "31819 Highway Blvd, Katy, TX",
  "777 N. 5600 W, Salt Lake City, UT",
  "6802 W Old Bingham Hwy, West Jordan, UT",
  "355 John Glenn Rd, Salt Lake City, UT",
  "770 Gladiola St, Salt Lake City, UT",
  "165 Business Blvd, Clear Brook, VA",
  "45121 Global Plaza, Sterling, VA",
  "44301 Mercure Cir, Sterling, VA",
  "6885 Commercial Dr, Springfield, VA",
  "7000 Hardware Dr, Prince George, VA",
  "5000 Commerce Way, Petersburg, VA",
  "1901 Meadowville Technology Pkwy, Chester, VA",
  "4949 Commerce Rd, Richmond, VA",
  "11600 N Lakeridge Pkwy, Ashland, VA",
  "2700 Center Drive, Dupont, WA",
  "21005 64th St, Kent, WA",
  "20526 59th Pl S, Kent, WA",
  "20202 84th Ave S, Kent, WA",
  "1901 140th Ave E, Sumner, WA",
  "20529 24th Ave S, SeaTac, WA",
  "2201 140th Ave E, Sumner, WA",
  "6611 Associated Blvd, Everett, WA",
  "10010 W Geiger Blvd, Spokane, WA",
  "2309 Milwaukee Way, Tacoma, WA",
  "1227 124th Ave, Northeast Bellevue, WA",
  "3501 120th Ave, Kenosha, WI",
  "9700 South 13th St, Oak Creek, WI",
  "3935 W Mitchell St, Milwaukee, WI",
  "1750 Bridge Dr, Waukegan, IL",
  "4189 Salish Sea Way, Delta, BC",
  "4189 Salish Sea Dr, Delta, BC",
  "4189 Salish Sea Drive, Delta, BC",
  "6363 Millcreek Dr, Mississauga, ON",
  "6363 Millcreek Drive, Mississauga, ON",
  "2750 Peddie Rd, Milton, ON",
  "2750 Peddie Road, Milton, ON",
  "7995 Winston Churchill Blvd, Brampton, ON",
  "7995 Winston Churchill Boulevard, Brampton, ON",
  "8050 Heritage Rd, Brampton, ON",
  "8050 Heritage Road, Brampton, ON",
  "12724 Coleraine Dr, Bolton, ON",
  "12724 Coleraine Drive, Bolton, ON",
  //   "6110 Cantay Rd, Mississauga, ON",
  "293069 Col Robertson Way, Balzac, AB",
  "5225 Boundary Rd, Navan, ON",
  "5225 Boundary Road, Navan, ON",
  "1440 39 Ave, Nisku, AB",
  "1440 39 Avenue, Nisku, AB",
  "450 Derwent Place, Delta, BC",
  "3000 Rue Louis A Amos, Lachine, QC",
  "900 Passmore Ave, Scarborough, ON",
  "900 Passmore Avenue, Scarborough, ON",
  "109 Braid St, New Westminster, BC",
  "109 Braid Street, New Westminster, BC",
  "16131 Blundell Rd, Richmond, BC",
  "16131 Blundell Road, Richmond, BC",
];

import React, { useState, useEffect } from "react";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import { useHistory, useLocation, Link } from "react-router-dom";
// import Slide from 'react-reveal';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  IconButton,
  // Collapse,
  Paper,
  Input,
  Dialog,
  Divider,
} from "@material-ui/core";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Cancel } from "@material-ui/icons";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import orange from "@material-ui/core/colors/orange";
import ClearIcon from "@material-ui/icons/Clear";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import EventIcon from "@material-ui/icons/Event";

// import PropTypes from "prop-types";
import { format } from "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import enGBLocale from "date-fns/locale/en-GB";
import axios from "axios";
import produce from "immer";
// import NewCredit from '../CreditCard/newCredit'
import {
  BACKEND_GUEST_ORDERS_API,
  BACKEND_GUEST_PACKAGES_API,
  BACKEND_GUEST_PRICE_ESTIMATE_API,
} from "../../Utils/apiUrl";
// import { shipmentTypesDetails } from "./shared/constInfo";
// import LoadingContainer from "../shared/loadingContainer";
// import { add } from "../Utils/decimalOperation";
import { redirectHome } from "../../Utils/redirect";

import { shipmentTypesDetails } from "../shared/constInfo";
// import EditView from "../shared/editView";
import { AddressInputCombo } from "../shared";
import { errHandling } from "../../Utils/fetchUtil";
import { getAccessToken } from "../../Utils/doToken";
import {
  GUEST_ORDER_PRICE_CHECK_COOKIE_NAME,
  GUEST_ORDER_PRICE_CHECK_COOKIE_TIME,
} from "../../Utils/config";
import ShippingItemInputStateless from "./components/shippingItemInputStateless";
import PriceModule from "../shared/OrderDetail/priceModule";
import TooltipWrapper from "../MyOrders/components/tooltipWrapper";
import LoadingDock from "../Images/icons/LoadingDock.png";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

// const initAddress = {
//   address: "",
//   zipCode: "",
//   city: "",
//   province: "",
// };

const GuestCheckout = () => {
  const query = useQuery();

  const initEmail = query.get("email");
  const initName = query.get("fullname");

  const [userEmail, setUserEmail] = useState(initEmail || "");
  const [phone, setPhone] = useState("");
  const initPickUpCity = query.get("pickup_city");
  const initDeliverCity = query.get("deliver_city");
  const initPickUpProvince = query.get("pickup_province");
  const initDeliverProvince = query.get("deliver_province");

  const [pickUpObj, setPickUpObj] = useState({
    address: "",
    city: initPickUpCity,
    province: initPickUpProvince,
    zipCode: "",
    phone: "",
  });

  const [deliverObj, setDeliverObj] = useState({
    address: "",
    city: initDeliverCity,
    province: initDeliverProvince,
    zipCode: "",
    phone: "",
  });
  const shipmentTypeID = query.get("shipment_type_id");
  const quantity = query.get("quantity");
  // const selectedDate = query.get("selectedDate");
  // const [selectedDate, setSelectDate] = useState(
  //   new Date(Date.now() + 1000 * 60 * 60 * 24)
  // );
  const [fullName, setFullName] = useState(initName || "");
  const [dateSelection, setDateSelection] = useState(
    new Date(Date.now() + 1000 * 60 * 60 * 24)
  );

  const [orderEdit, setOrderEdit] = useState(false);

  // Error check for height and weight input
  const [userInputError, setUserInputError] = useState({
    heightError: false,
    weightError: false,
  });

  //  The form validation flag
  const [personalEmtpy, setPersonalEmpty] = useState(false);
  const [pickUpEmpty, setPickUpEmpty] = useState(false);
  const [deliverEmpty, setDeliverEmpty] = useState(false);

  // console.log(pickUpObj, deliverObj, "OK");
  const pickUpInitObj = {
    street: "",
    zipCode: "",
    city: initPickUpCity,
    province: initPickUpCity,
  };

  const deliverInitObj = {
    street: "",
    zipCode: "",
    city: initDeliverCity,
    province: initDeliverProvince,
  };

  const getName = (name) => {
    const sName = name.split(" ");
    if (sName.length === 1) {
      return [sName[0], sName[0]];
    }
    return [sName[0], sName[1]];
  };

  const shipmentTypeLabel = shipmentTypeID || "1";

  const [shipItem, setShipItem] = useState({
    description: "",
    typeID: shipmentTypeLabel,
    length: shipmentTypesDetails[shipmentTypeLabel].length || 48,
    width: shipmentTypesDetails[shipmentTypeLabel].width || 48,
    weight: shipmentTypesDetails[shipmentTypeLabel].weight || 200,
    height: shipmentTypesDetails[shipmentTypeLabel].height || 78,
    quantity: quantity,
  });

  // useEffect(() => console.log(shipItem, "aaaaaaaaa"), [shipItem]);

  const [itemErrorMsg, setItemErrorMsg] = useState("");

  const [firstname, lastname] = getName(fullName);

  // schedule_pickup: format(pickUpDetails.selectedDate, "yyyy-MM-dd HH:mm"),

  const [extraMsg, setExtraMsg] = useState("");

  const [fetchingPrice, setFetchingPriceState] = useState(true);
  const [fetchingFinalPrice, setFetchingFinalPriceState] = useState(false);
  const [creatingOrder, setCreatingStatus] = useState(false);
  const [shippingPrice, setShippingPrice] = useState({});
  const [finalShippingPrice, setFinalShippingPrice] = useState({});

  const [priceErrMsg, setPriceErrMsg] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [optionDialogOpen, setOptionDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  const history = useHistory();

  // Get the estimate price using parameter from URL
  useEffect(() => {
    const setCookie = (name) => {
      let date = new Date();
      date.setTime(date.getTime() + GUEST_ORDER_PRICE_CHECK_COOKIE_TIME);
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=checked; " + expires + "; path=/";
    };
    if (!getAccessToken(GUEST_ORDER_PRICE_CHECK_COOKIE_NAME)) {
      setFetchingPriceState(true);
      const postData = {
        pickup_area: `${initPickUpCity}, ${initPickUpProvince}`,
        deliver_area: `${initDeliverCity}, ${initDeliverProvince}`,
        goods: [
          {
            shipment_type_id: shipmentTypeID,
            quantity: quantity,
          },
        ],
      };
      axios({
        method: "post",
        url: BACKEND_GUEST_PRICE_ESTIMATE_API,
        data: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          setFetchingPriceState(false);
          setShippingPrice(response.data || {});
          setCookie(GUEST_ORDER_PRICE_CHECK_COOKIE_NAME);
        })
        .catch((err) => {
          errHandling(err, setPriceErrMsg);
          // setPriceErrMsg(err.message);
          setFetchingPriceState(false);
        });
    } else {
      setChecked(true);
    }
  }, []);

  // Get the estimate price using parameter from User Input
  // useEffect(() => {
  //   if (dialogOpen) {
  //   }
  // }, [dialogOpen]);

  const submitOrder = () => {
    setPersonalEmpty(false);
    setPickUpEmpty(false);
    setDeliverEmpty(false);
    setItemErrorMsg("");
    const personalEpt = getPersonalEmptyStatus(userEmail, fullName, phone);
    const pickUpEpt = getAddressEmptyStatus(pickUpObj, true);
    const deliverEpt = getAddressEmptyStatus(deliverObj);
    const invalidItem = getInvalidItemValues(shipItem);
    const invalidItemNumber = getInvalidItemNumberValues(shipItem);
    if (personalEpt || pickUpEpt || deliverEpt) {
      personalEpt && setPersonalEmpty(true);
      pickUpEpt && setPickUpEmpty(true);
      deliverEpt && setDeliverEmpty(true);
      invalidItem && setItemErrorMsg("Invalid shipping item values.");
      invalidItemNumber && setItemErrorMsg("Not a valid number.");
    } else {
      setFetchingFinalPriceState(true);
      const postData = {
        pickup_area: `${pickUpObj.city}, ${pickUpObj.province}`,
        deliver_area: `${deliverObj.city}, ${deliverObj.province}`,
        goods: [
          {
            shipment_type_id: shipItem.typeID,
            quantity: shipItem.quantity,
          },
        ],
      };

      axios({
        method: "post",
        url: BACKEND_GUEST_PRICE_ESTIMATE_API,
        data: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          setFetchingFinalPriceState(false);
          setFinalShippingPrice(response.data || {});
          setDialogOpen(true);
        })
        .catch((err) => {
          errHandling(err, setPriceErrMsg);
          setDialogOpen(true);
          // setPriceErrMsg(err.message);
          setFetchingFinalPriceState(false);
        });
    }
  };

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handlePickUpChange = (fieldName) => (e) => {
    setPickUpObj(
      produce((draft) => {
        draft[fieldName] = e.target.value;
      })
    );
  };

  const handleDeliverChange = (fieldName) => (e) => {
    setDeliverObj(
      produce((draft) => {
        draft[fieldName] = e.target.value;
      })
    );
  };

  const handlePickUpChangeFromSelection = (fieldName) => (newValue) => {
    setPickUpObj(
      produce((draft) => {
        draft[fieldName] = newValue;
      })
    );
  };

  const handleDeliverChangeFromSelection = (fieldName) => (newValue) => {
    setDeliverObj(
      produce((draft) => {
        draft[fieldName] = newValue;
      })
    );
  };

  // const changeItem = (item) => {
  //   setShipItem(item);
  //   setItemEdit((prev) => !prev);
  // };
  const getInvalidItemValues = (item) => {
    const emptyField =
      item.description === null ||
      item.height === null ||
      item.length === null ||
      item.width === null ||
      item.weight === null ||
      item.quantity === null ||
      item.typeID === "";
    return (
      emptyField ||
      item.description.length < 2 ||
      item.height < 1 ||
      item.weight < 1 ||
      item.quantity < 1 ||
      item.length < 1 ||
      item.width < 1
    );
  };

  const getInvalidItemNumberValues = (item) => {
    return (
      isNaN(item.height) ||
      isNaN(item.weight) ||
      isNaN(item.length) ||
      isNaN(item.width) ||
      isNaN(item.quantity)
    );
  };

  const handleRedirection = () => {
    localStorage.setItem(
      "shippingData",
      JSON.stringify({
        estimateDetails: {
          pickupCity: initPickUpCity,
          pickupProvince: initPickUpProvince,
          deliverCity: initDeliverCity,
          deliverProvince: initDeliverProvince,
        },
        itemInfo: { quantity: quantity, selectedTypeID: shipmentTypeID },
      })
    );
    history.push({
      pathname: "/sign-in",
      state: { from: { pathname: "/shipping/start-a-quote" } },
    });
  };

  const handlePlaceOrder = async () => {
    setCreatingStatus(true);
    const postData = {
      // sender: "Individual",
      user_email: userEmail,
      message: extraMsg,
      pickup_address: `${pickUpObj.address}, ${pickUpObj.city}, ${
        pickUpObj.province
      }${pickUpObj.zipCode ? " " + pickUpObj.zipCode : ""}`,
      pickup_area: `${pickUpObj.city}, ${pickUpObj.province}`,
      pickup_phone: pickUpObj.phone,
      deliver_address: `${deliverObj.address}, ${deliverObj.city}, ${
        deliverObj.province
      }${deliverObj.zipCode ? " " + deliverObj.zipCode : ""}`,
      deliver_area: `${deliverObj.city}, ${deliverObj.province}`,
      delivery_phone: deliverObj.phone,
      schedule_pickup: format(dateSelection, "yyyy-MM-dd HH:mm"),
      firstname: firstname,
      lastname: lastname,
    };
    console.log(postData);
    try {
      const orderResponse = await axios({
        method: "post",
        url: BACKEND_GUEST_ORDERS_API,
        data: JSON.stringify(postData),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      const orderNum = orderResponse.data.order.order_number;
      console.log(orderNum);
      const palletInfo = {
        order_number: orderNum,
        item_number: shipItem.quantity,
        item_name: shipItem.description,
        shipment_type_id: shipItem.typeID,
        length: shipItem.length || 48,
        width: shipItem.width || 48,
        weight: shipItem.weight || 1500,
        height: shipItem.height || 72,
        number: shipItem.quantity,
        available_count: shipItem.quantity,
        status: "created",
      };
      console.log(palletInfo);

      await axios({
        method: "post",
        url: BACKEND_GUEST_PACKAGES_API,
        data: JSON.stringify(palletInfo),
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      // console.log(packageRes);
      //   await axios.all(packageData);
      // setCreatingStatus(false);
      // toConfirm(orderNum);
      history.push({
        pathname: "/guest-payment",
        state: { orderNum: orderNum, email: userEmail },
      });
      setCreatingStatus(false);
    } catch (error) {
      console.log(error);
      setCreatingStatus(false);
    }
  };

  const handleChange = (event) => {
    setExtraMsg(event.target.value);
  };

  const getPersonalEmptyStatus = (_email, _name, _phone) => {
    const personalEmpty =
      _email.trim() === "" || _name.trim() === "" || _phone.trim() === "";
    return personalEmpty;
  };

  const getAddressEmptyStatus = (_address, pickUp = false) => {
    const addressEmpty =
      (pickUp && _address.phone.trim() === "") ||
      _address.address.trim() === "" ||
      _address.city.trim() === "" ||
      _address.province.trim() === "";
    return addressEmpty;
  };

  const priceComponent = checked ? (
    <Box mt={2}>
      <Box m={1} fontSize="1.5rem">
        Or sign in to get more information.
      </Box>
      <Box display="flex" justifyContent="center" mb="2vh">
        <Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirection}
          >
            Sign In
          </Button>
        </Box>
      </Box>
      <Box ml={3} mr={3} mt={1}>
        <Divider />
      </Box>
      <Box textAlign="center" pt={2} pb={3}>
        <Box component="span" color="rgba(0,0,0,0.6)">
          Don't have an Account?{" "}
        </Box>
        <Link to="/sign-up">Sign up</Link>
      </Box>
    </Box>
  ) : (
    <>
      <h4 className="orange-title">Price:</h4>
      <PriceModule
        priceErrMsg={priceErrMsg}
        fetchingPrice={fetchingPrice}
        shippingPrice={shippingPrice}
      />
    </>
  );

  const finalPriceComponent = (
    <>
      <h4 className="orange-title">Price:</h4>
      <PriceModule
        priceErrMsg={priceErrMsg}
        fetchingPrice={fetchingFinalPrice}
        shippingPrice={finalShippingPrice}
      />
    </>
  );

  return (
    <Box width="100%">
      <div
        className={matches ? classes.smRootContainer : classes.rootContainer}
      >
        <h2 className="orange-title">Quote Summary:</h2>
        <div className="subtitle">
          <Box
            className={classes.summaryHeader}
            pb={1}
            display="flex"
            justifyContent="space-between"
          >
            <Box>Item</Box>
          </Box>
          <Box pb={1} pl={1} className={classes.summaryBodyText}>
            {shipItem.quantity} Pallet &times; {shipItem.weight} lbs
          </Box>
        </div>
        <hr />
        <Box display="flex" justifyContent="end" mt="2vh">
          {!orderEdit ? (
            <Button
              color="primary"
              variant="contained"
              className={matches ? classes.smButton : classes.button}
              // onClick={() => history.push("/guest-payment")}
              onClick={() => setOrderEdit(true)}
            >
              Continue
            </Button>
          ) : (
            <Button
              color="primary"
              variant="contained"
              className={matches ? classes.smButton : classes.button}
              // onClick={() => history.push("/guest-payment")}
              onClick={() => setOrderEdit(false)}
            >
              Cancel <Cancel />
            </Button>
          )}
        </Box>
        {!orderEdit && priceComponent}
        {orderEdit && (
          <>
            <Box className="subtitle">
              <Box className={classes.summaryHeader}>Personal Information</Box>
              <Box pt="1vh" width="100%">
                {personalEmtpy ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    Please enter all the personal information.
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box className={classes.summaryBodyText} ml={1}>
                <Box display="flex" alignItems="baseline">
                  <Box component="span" mr={2}>
                    Email * :
                  </Box>
                  <Input
                    type="text"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </Box>
                <Box display="flex" alignItems="baseline">
                  <Box component="span" mr={2}>
                    Name * :
                  </Box>
                  <Input
                    type="text"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                  />
                </Box>
                <Box display="flex" alignItems="baseline" mb={2}>
                  <Box component="span" mr={2}>
                    Phone * :
                  </Box>
                  <Input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Box>
              </Box>
            </Box>
            <Box className={`subtitle ${classes.editBorder}`} mb={2}>
              <Box
                className={classes.summaryHeader}
                mb={1}
                display="flex"
                justifyContent="space-between"
              >
                <Box>Pickup</Box>
              </Box>
              {pickUpEmpty ? (
                <Box pt="1vh" width="100%">
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    Please enter all the pick up address information.
                  </Alert>
                </Box>
              ) : (
                ""
              )}
              <Box pb={1}>
                <AddressInputCombo
                  handleChange={handlePickUpChange}
                  handleChangeFromSelection={handlePickUpChangeFromSelection}
                  addressObject={pickUpObj}
                  userLocation={pickUpInitObj}
                  freeSolo={true}
                  pickUp
                />
              </Box>
            </Box>
            <Box className={`subtitle ${classes.editBorder}`} mb={2}>
              <Box pt="1vh" width="100%">
                {deliverEmpty ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    Please enter all the deliver address information.
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box
                className={classes.summaryHeader}
                mb={1}
                display="flex"
                justifyContent="space-between"
              >
                <Box>Delivery</Box>
              </Box>
              {/* <div id="review-content5" className="bodytext">
              <a onClick={toFirst}>Edit</a>
              </div> */}
              <Box pb={1}>
                <AddressInputCombo
                  handleChange={handleDeliverChange}
                  handleChangeFromSelection={handleDeliverChangeFromSelection}
                  addressObject={deliverObj}
                  userLocation={deliverInitObj}
                  freeSolo={true}
                />
              </Box>
            </Box>
            <Box className={`subtitle ${classes.editBorder}`} mb={2}>
              {/* <Box pt="1vh" width="100%">
              {getDeliverEmptyStatus(pickUpDetails) ? (
                <Alert severity="error" classes={{ root: classes.alertRoot }}>
                  Please enter all the deliver address information.
                </Alert>
              ) : (
                ""
              )}
              </Box> */}
              <Box
                className={classes.summaryHeader}
                pb={1}
                display="flex"
                justifyContent="space-between"
              >
                <Box>Pickup Date</Box>
              </Box>
              <div id="quote-content2">
                <MuiThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={enGBLocale}
                  >
                    <Paper className={classes.paper}>
                      <DatePicker
                        autoOk
                        variant="static"
                        value={dateSelection}
                        onChange={setDateSelection}
                      />
                    </Paper>
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
              </div>
              <Box mt="2vh">
                <div className={classes.subTitle}>Requested loading date</div>
                <TextField
                  value={format(dateSelection, "dd/MM/yyyy")}
                  className={classes.input}
                />
              </Box>
            </Box>
            <div className={`subtitle ${classes.editBorder}`}>
              <Box
                className={classes.summaryHeader}
                pb={1}
                display="flex"
                justifyContent="space-between"
              >
                <Box>Item</Box>
              </Box>
              <ShippingItemInputStateless
                item={shipItem}
                errorMsg={itemErrorMsg}
                setErrorMsg={setItemErrorMsg}
                changeItem={setShipItem}
                userInputError={userInputError}
                setUserInputError={setUserInputError}
                small
              />
            </div>
            <hr />
            <Box className="subtitle" mb={1}>
              <Box>Additional Message</Box>
              <TextField
                className={classes.textfield}
                InputProps={{ disableUnderline: true }}
                onChange={handleChange}
                variant="filled"
                rows={2}
                label="Text here"
                multiline
              />
            </Box>

            <hr />
            <Box className="subtitle" mb={1} display="flex" alignItems="center">
              <Box mr={1}>Need optional services?</Box>
              <Button
                color="primary"
                variant="contained"
                className={classes.addButton}
                // onClick={() => history.push("/guest-payment")}
                onClick={() => {
                  setOptionDialogOpen(true);
                }}
              >
                Add
              </Button>
            </Box>
            <Box display="flex" alignItems="center">
              <TooltipWrapper title="Loading dock">
                <img
                  src={LoadingDock}
                  alt="Loading Dock"
                  width="40px"
                  align="bottom"
                  style={{
                    backgroundColor: "seagreen",
                  }}
                />
              </TooltipWrapper>
              <TooltipWrapper title="Time range for pickup and delivery">
                <AccessAlarmsIcon />
              </TooltipWrapper>
              <TooltipWrapper title="Delivery appointment">
                <EventIcon />
              </TooltipWrapper>
            </Box>
          </>
        )}
        <Box pt="1vh" width="100%">
          {personalEmtpy || pickUpEmpty || deliverEmpty || itemErrorMsg ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              Some of the information is not valid.
            </Alert>
          ) : (
            ""
          )}
        </Box>
        {orderEdit && (
          <Box display="flex" justifyContent="space-between" mt="2vh">
            <Box fontSize="1rem" mr="10vw">
              <Button
                onClick={() => redirectHome(history)}
                className={classes.textButton}
              >
                Go to home page
              </Button>
            </Box>
            <Button
              // disabled={
              //   getEmptyStatus(pickUpDetails) ||
              //   creatingOrder ||
              //   cardStatus !== "active"
              // }
              disabled={
                userInputError.weightError || userInputError.heightError
              }
              color="primary"
              variant="contained"
              className={matches ? classes.smButton : classes.button}
              // onClick={() => history.push("/guest-payment")}
              // onClick={handleSubmitOrder}
              onClick={submitOrder}
            >
              {fetchingFinalPrice ? (
                <CircularProgress style={{ color: "white" }} size={24} />
              ) : (
                "Check Price"
              )}
            </Button>
          </Box>
        )}
      </div>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem">Order</Box>
          <IconButton
            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => setDialogOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Box m={1}>
          {/* {priceErrMsg && !fetchingPrice ? (
            <Box pt="1vh" width="100%">
              <Alert severity="error" classes={{ root: classes.alertRoot }}>
                {priceErrMsg}
              </Alert>
            </Box>
          ) : ( */}
          {finalPriceComponent}
        </Box>
        <Box display="flex" justifyContent="center" mb="2vh">
          <Button
            variant="contained"
            color="primary"
            onClick={handlePlaceOrder}
            className={`${classes.bigButton}`}
          >
            {creatingOrder ? (
              <CircularProgress style={{ color: "white" }} size={24} />
            ) : (
              "Choose a payment"
            )}
          </Button>
        </Box>
      </Dialog>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={optionDialogOpen}
        onClose={() => setOptionDialogOpen(false)}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem">Optional Services</Box>
          <IconButton
            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => setOptionDialogOpen(false)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Box fontSize="1.5rem" m={2}>
          You have to sign in to get the optional services.
        </Box>
        <Box display="flex" justifyContent="center" mb="2vh">
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirection}
          >
            Sign In
          </Button>
        </Box>
      </Dialog>
    </Box>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: { light: orange[300], main: orange[500], dark: orange[700] },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    margin: "0 auto",
    width: "40vw",
    minWidth: "500px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  alertRoot: {
    width: "100%",
    minWidth: "200px",
    margin: "10px auto",
  },
  fixWidthButton: {
    width: "15rem",
  },
  fixWidthBigButton: {
    width: "20rem",
  },
  // paper: {
  //   backgroundColor: theme.palette.background.paper,
  //   border: "2px solid #000",
  //   boxShadow: theme.shadows[5],
  //   padding: theme.spacing(2, 4, 3),
  // },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
    margin: "0 auto",
    overflow: "hidden",
    width: "100%",
  },
  bigButton: {
    borderRadius: 20,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "18rem",
  },
  button: {
    borderRadius: 20,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "14rem",
  },
  smButton: {
    borderRadius: 20,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "12rem",
  },
  addButton: {
    borderRadius: 20,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    color: "white",
    width: "8rem",
    height: "2.4rem",
  },
  textfield: {
    width: "100%",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "seagreen",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  editBorder: {
    borderRadius: "5px",
    border: "1px solid rgba(0,0,0,0.5)",
    borderBottom: "2px solid rgba(0,0,0,0.5)",
    padding: theme.spacing(1),
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
}));

GuestCheckout.propTypes = {
  // toFirst: PropTypes.func.isRequired,
  // toFourth: PropTypes.func.isRequired,
  // toConfirm: PropTypes.func.isRequired,
};

export default GuestCheckout;

import React, { useEffect, useState } from "react";
import DeliveredShipmentDetailLayout from "../Layouts/DeliveredShipmentDetailLayout";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    fontSize: "30px",
    fontWeight: "bold",
    letterSpacing: "-1px",
    lineHeight: 1,
    margin: "5px 2%",
  },
}));

export default function DeliveredShipmentsDetailPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loadPackage, setLoadPackage] = useState(true);
  const [loadShipments, setloadShipments] = useState(true);

  const [shipmentInfo, setShipmentInfo] = useState([]);
  const [packageInfo, setPackageInfo] = useState([]);

  const shipment_id = useParams().id;

  const fetchShipmentInfo = () => {
    //fetch shipment and its package
    setloadShipments(true);
    setLoadPackage(true);
    axios
      .get(`https://api.uucargo.ca/api/shipments/${shipment_id}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      })
      .then((res) => {
        setShipmentInfo(res.data);
        //setOrderNumber(res.data.order_number)
        setloadShipments(false);
        getPackageInfo(res.data.package_number);
      });
  };

  useEffect(() => {
    fetchShipmentInfo();

    return () => {};
  }, [shipment_id]);

  const getPackageInfo = (pNum) => {
    axios
      .get(`https://api.uucargo.ca/api/packages/${pNum}`, {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      })
      .then((res) => {
        setPackageInfo(res.data);
        setLoadPackage(false);
      });
  };

  return (
    <>
      {!loadShipments && !loadPackage ? (
        <DeliveredShipmentDetailLayout
          sectionTitle={"Shipment Detail"}
          packageInfo={packageInfo}
          shipmentInfo={shipmentInfo}
        />
      ) : (
        <LoadingCircle />
      )}
    </>
  );
}

import { DRIVER_ADD_DELIVER_SHIPMENTS, DRIVER_SELECT_DELIVER_SHIPMENTS, DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS, 
DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA, DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS } from "../actions/types"
import produce from 'immer'

const initialState = {
  deliverPackages:{},
  selectedPackages:{},
  selectedTrackingNumber:[],
  selectedShipmentNumber: []
}

export const driverDeliverShipmentsReducer = (state = initialState, action) => {
  
  switch(action.type){
    case DRIVER_ADD_DELIVER_SHIPMENTS:
      const {package_number, item_name, cover_image, shipment_number, tracking_number, checked} = action.payload
      const temp = {}
      tracking_number.forEach(num => {
        temp[`${num.tracking_number}`] = checked
      })
      return produce(state, draft => {
        draft['deliverPackages'][package_number] ={
          'item_name': item_name,
          'cover_image': cover_image,
          'shipment_numbers': {
            [shipment_number]: {...temp}
          }
        }
      })

    case DRIVER_SELECT_DELIVER_SHIPMENTS:
      return produce(state, draft => {
        draft['deliverPackages'][action.payload.package_number].shipment_numbers[action.payload.shipment_number][action.payload.tracking_number] = action.payload.checked
        if(draft.selectedPackages[action.payload.package_number] === undefined){
          draft['selectedPackages'][action.payload.package_number] =
            {
              'item_name': action.payload.item_name,
              'cover_image': action.payload.cover_image,
              'shipment_numbers':{
                [action.payload.shipment_number]:{
                  [action.payload.tracking_number] : action.payload.checked
                }
              }
            }          
        }else if (draft.selectedPackages[action.payload.package_number].shipment_numbers[action.payload.shipment_number] === undefined){
          //selecting tracking number, same package but diff shipment number
          draft.selectedPackages[action.payload.package_number].shipment_numbers[action.payload.shipment_number] = {
            [action.payload.tracking_number] : action.payload.checked
          }
        }else{
          //selecting tracking number, same package number, same shipment number
          draft['selectedPackages'][action.payload.package_number].shipment_numbers[action.payload.shipment_number][action.payload.tracking_number] = action.payload.checked
        }
        draft.selectedTrackingNumber.push(action.payload.tracking_number)
        draft.selectedShipmentNumber.push(action.payload.shipment_number)
      })
    case DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS:
      //change deliver packages's tracking number to false
      return produce(state, draft => {
        draft['deliverPackages'][action.payload.package_number]['shipment_numbers'][action.payload.shipment_number][action.payload.tracking_number] = false

        //remove tracking number in selected packages
          delete draft['selectedPackages'][action.payload.package_number]['shipment_numbers'][action.payload.shipment_number][action.payload.tracking_number]

        //remove shipment if shipment has no tracking numbers
        if(Object.keys(draft.selectedPackages[action.payload.package_number].shipment_numbers[action.payload.shipment_number]).length === 0){
          delete draft['selectedPackages'][action.payload.package_number]['shipment_numbers'][action.payload.shipment_number]

          const index = draft.selectedShipmentNumber.findIndex(num => num === action.payload.shipment_number)
          if(index !== -1) draft.selectedShipmentNumber.splice(index, 1)
        }

        //remove package if package has no shipments
        if(Object.keys(draft.selectedPackages[action.payload.package_number].shipment_numbers).length === 0){
          delete draft['selectedPackages'][action.payload.package_number]
        }

        //removing tracking number
        const index = draft.selectedTrackingNumber.findIndex(num => num === action.payload.tracking_number)
        if(index !== -1) draft.selectedTrackingNumber.splice(index, 1)
      })      
      
    case DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA:
      return  {
        deliverPackages:{},
        selectedPackages:{},
        selectedTrackingNumber:[],
        selectedShipmentNumber: []
      }
    case DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS:
      return produce(state, draft => {
        for (const p in state.deliverPackages){
          
          for(const s in state.deliverPackages[p].shipment_numbers){
          
            for(const t in state.deliverPackages[p].shipment_numbers[s]){
              if(state.deliverPackages[p].shipment_numbers[s][t] === true){
                draft.deliverPackages[p].shipment_numbers[s][t] = false
              }
            }
          }
        }

        draft.selectedPackages = {}
        draft.selectedTrackingNumber = []
        draft.selectedShipmentNumber = []
      })
    default:
      return state
  }
}
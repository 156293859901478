import React from "react";
import { useHistory, useParams } from "react-router-dom";
// import LightSpeed from 'react-reveal/LightSpeed'
// import Slide from 'react-reveal'
import { ThumbUp } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { redirectHome } from "../../Utils/redirect";

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: 20,
    fontSize: "1.5rem",
    backgroundColor: "#ffa726",
    color: "white",
  },
}));

const OrderConfirm = () => {
  const classes = useStyles();
  const history = useHistory();
  const { number } = useParams();

  const jumboStyle = {
    color: "green",
    maxheight: "-webkit-fill-available",
    height: "90vh",
    fontFamily: "Montserrat",
    fontSize: "20pt",
    letterSpacing: "2px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    overflow: "hidden",
  };

  return (
    <div style={jumboStyle}>
      {/* <LightSpeed left duration={1000} > */}
      <ThumbUp style={{ fontSize: "100pt" }} />
      {/* </LightSpeed> */}
      {/* <Slide up duration={1000}> */}
      <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
        <p>{`Thank you! Your order number is ${number}.`}</p>
        <p>You'll only be charged when the driver is on the way.</p>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => redirectHome(history)}
        >
          Back to home
        </Button>
        <br />
        <br />
      </div>
      {/* </Slide> */}
    </div>
  );
};

export default OrderConfirm;

import React, { useState, useMemo, useEffect } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";
import {
  DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID,
  BACKEND_ME_API,
  CHECK_BONUS,
} from "../../Utils/apiUrl";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getTokenFromCookie } from "../../Utils/doToken";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { driverClearAllShipmentsFromSummary } from "../../actions/driverClaimShipmentAction";
import LoadingCircle from "../LoadingCircle";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    backgroundColor: "#fdf5e8",
    padding: "8px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const SummarySection = ({ sectionTitle, packages, reflash }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [total, setTotal] = useState(0);
  const [bonus, setBonus] = useState(null);
  const [loadingBonus, setLoadingBonus] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (packages[0].count === packages[0].availableCount) {
      //if getting the last shipment or taking all the shipments
      //check if driver can get bonus
      setLoadingBonus(true);
      const header = {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      };
      const shipmentNumber = packages[0].shipment_number;
      axios
        .get(CHECK_BONUS(shipmentNumber), header)
        .then(res => {
          console.log("bonus res", res);
          setBonus(res.data.bonus);

          //for testing set canGetBonus always true
          //setCanGetBonus(true)
          setLoadingBonus(false);
        })
        .catch(error => {
          setBonus(0);
          setLoadingBonus(false);
        });
    } else {
      //do not display get bonus and clean bonus and canGetBouns
      setBonus(0);
    }
  }, [packages[0].count]);

  const claimShipment = () => {
    setOpenBackDrop(true);
    axios
      .put(
        DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID(
          packages[0].shipment_number,
          packages[0].count
        ),
        "",
        {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        }
      )
      .then(res => {
        setOpenBackDrop(false);
        setOpenDialog(true);
      })
      .catch(error => {
        console.log("Error when trying to claim shipment: ", error);
        setOpenBackDrop(false);
      });
  };

  useMemo(() => {
    let result = 0;
    packages.map(p => {
      result += p.count * p.unit_price;
    });
    result += bonus;
    setTotal(result);
  }, [packages, bonus]);

  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };

  const handleCloseDialog = () => {
    dispatch(driverClearAllShipmentsFromSummary());
    setOpenDialog(false);

    //call back signal that notify page to reflash
    reflash(true);
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container className={classes.blocks}>
          <Grid item xs={12}>
            <Typography className={classes.title}>{sectionTitle}</Typography>
          </Grid>
          {packages.map(p => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div>
                <Typography variant="subtitle1" style={{ paddingLeft: "5px" }}>
                  {p.item_name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{ paddingLeft: "5px" }}
                >
                  {p.shipmentType}/{p.weight} lb
                </Typography>
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Typography variant="subtitle1"> X {p.count}</Typography>
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <Typography
                  variant="subtitle1"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "5px",
                  }}
                >
                  Sub-total: ${p.count * p.unit_price}
                </Typography>
              </div>
              {/* <Divider variant="middle" /> */}
            </div>
          ))}

          <Grid item xs={12}>
            {loadingBonus ? (
              <Typography
                variant="subtitle1"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingRight: "5px",
                }}
              >
                <LoadingCircle style={{ height: "5px", width: "5px" }} />
              </Typography>
            ) : (
              bonus !== 0 && (
                <Typography
                  variant="subtitle1"
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: "5px",
                  }}
                >
                  Bonus: ${bonus}
                </Typography>
              )
            )}
            <Divider />
            <Typography
              variant="subtitle1"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: "5px",
              }}
            >
              Total: ${total}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              color: "white",
              display: "flex",
              justifyContent: "center",
              margin: "5px",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              style={{ color: "white" }}
              onClick={claimShipment}
            >
              Claim
            </Button>
          </Grid>
        </Grid>
      </Paper>
      <Backdrop
        className={classes.backdrop}
        open={openBackDrop}
        onClick={handleCloseBackDrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={classes.dialog}
      >
        <DialogTitle id="alert-dialog-title">{"Awesome"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>You have successfully claimed the shipment!</p>
            <p>
              shipment number: <i>{packages[0].shipment_number}</i>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary" autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

import React, { useState, useEffect } from "react";
// import QuoteSummary from "../Shipping/quoteSummary";
import { useHistory, useParams, useLocation } from "react-router-dom";
import {
  Button,
  TextField,
  Snackbar,
  Dialog,
  // DialogTitle,
  CircularProgress,
  Container,
  Box,
  IconButton,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
// import orange from '@material-ui/core/colors/orange';
import { Alert } from "@material-ui/lab";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import EventIcon from "@material-ui/icons/Event";

import OrderDetailsHeader from "../shared/OrderDetail/orderDetailsHeader";
import "../../styles/myOrders.css";
// import TabShippingItem from "../Shipping/TabbedShippingComponents/tabShippingItem";
// import TabShippingDetails from "../Shipping/TabbedShippingComponents/tabShippingDetails";

// import UpdateOrderTabs from "../shared/Tabs/UpdateOrderTabs";
import PackageNoTabs from "../shared/Tabs/PackageNoTabs";
// import { getOrderShipments } from "../../actions/orderShipmentAction";
// import { shipmentTypesDetails } from "../shared/constInfo";
import LoadingDock from "../Images/icons/LoadingDock.png";
import NoLoadingDock from "../Images/icons/NoLoadingDock.png";
// import InsuranceIcon from "../Images/icons/insuranceIcon";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import { errHandling, getURLParams, getHeaders } from "../../Utils/fetchUtil";
import { CustomInput } from "../shared";

import axios from "axios";

import {
  BACKEND_ORDERS_BY_ORDERNUMBER_API,
  BACKEND_ORDER_PRICE_API,
  BACKEND_ORDER_CANCEL_API,
  BACKEND_GUEST_ORDER_BY_ID_API,
  BACKEND_GUEST_ORDER_PRICE_API,
  BACKEND_GUEST_ORDER_CANCEL_API,
  ADD_SHIPMENTS_TIPS,
} from "../../Utils/apiUrl";
import LoadingContainer from "../shared/loadingContainer";
// import { redirectOrderList } from "../Utils/redirect";
import PriceModule from "../shared/OrderDetail/priceModule";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const OrderDetails = ({ guest = false }) => {
  const [called, setCalled] = useState(false);

  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const matches = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const location = useLocation();
  const query = useQuery();

  const [packageList, setPackageList] = useState([]);
  const [shipmentList, setShipmentList] = useState([]);

  const [isWindowOpen, setWindowOpenState] = useState(false);
  const [isGuestCancelWindowOpen, setGuestCancelWindowOpen] = useState(false);
  const [isCancelWindowOpen, setCancelWindowOpenState] = useState(false);
  const [isSignInRequestWindowOpen, setSignInRequestWindowOpenState] =
    useState(false);
  const [isAllClaimedWindowOpen, setAllClaimedWindowOpenState] =
    useState(false);
  const [fetchingPrice, setFetchingPriceState] = useState(true);
  const [fetchingOrder, setFetchingOrder] = useState(true);
  const [isCancelling, setCancellingStatus] = useState(false);

  const [priceErrMsg, setPriceErrMsg] = useState("");

  const [fetchingPackages, setFetchingPackagesState] = useState(true);

  const [message, setMessage] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [saveMsgErr, setSaveMsgErr] = useState("");

  const [openTipsSnackBar, setOpenTipsSnackBar] = useState(false);
  const [tipsMsgErr, setTipsMsgErr] = useState("");

  const [shippingPrice, setShippingPrice] = useState({});
  const [addon, setAddon] = useState({});
  const [cancelErrMsg, setCancelErrMsg] = useState("");
  const [orderErrMsg, setOrderErrMsg] = useState("");
  const [guestCancelErrMsg, setGuestCancelErrMsg] = useState("");
  const [cancelStatus, setCancelStatus] = useState(false);
  const [refetch, setRefetch] = useState(true);
  const [refetchPrice, setRefetchPrice] = useState(true);
  // const [pkgRefetch, setPkgRefetch] = useState(false);
  const [accessCode, setAccessCode] = useState("");

  const [addTips, setAddTipsStatus] = useState(false);
  const [submittingTips, setSubmittingTipsStatus] = useState(false);
  const [tips, setTips] = useState(0);
  const [newTips, setNewTips] = useState(0);
  const [tipsNumberError, setTipsNumberError] = useState(false);

  const { id: order_num } = useParams();
  const [orderInfo, setOrderInfo] = useState({});

  const activeOrder = orderInfo.status === "active";

  const classes = useStyles();
  // const dispatch = useDispatch();

  // Store the price get from the API call
  const storePrice = (data) => {
    setShippingPrice({
      shipping_fee: data.shipping_fee,
      service_fee: data.service_fee,
      taxes: data.taxes,
      total: data.total,
    });
    setAddon({
      insurance_fee: data.insurance_fee,
      pickupDockFee:
        data.no_pickup_loading_dock_fee === "0.00"
          ? data.use_pickup_loading_dock_fee
          : data.no_pickup_loading_dock_fee,
      deliverDockFee:
        data.no_deliver_loading_dock_fee === "0.00"
          ? data.use_deliver_loading_dock_fee
          : data.no_deliver_loading_dock_fee,
      appointmentFee: data.deliver_appointment_fee,
    });
    setTips(data.tips);
  };

  useEffect(() => {
    if (guest && refetchPrice) {
      const orderNum = query.get("order_no");
      axios
        .get(`${BACKEND_GUEST_ORDER_PRICE_API}/${orderNum}`)
        .then((response) => {
          if (response.data) {
            setShippingPrice({
              shipping_fee: response.data.shipping_fee,
              service_fee: response.data.service_fee,
              taxes: response.data.taxes,
              total: response.data.total,
            });
          }
          setRefetchPrice(false);
          setFetchingPriceState(false);
        })
        .catch((err) => {
          errHandling(err, setPriceErrMsg);
          // setPriceErrMsg(err.response.data.message);
          setFetchingPriceState(false);
        });
    } else if (refetchPrice) {
      axios
        .get(`${BACKEND_ORDER_PRICE_API}${order_num}`, {
          headers: getHeaders(),
        })
        .then((response) => {
          if (response.data) {
            storePrice(response.data);
          }
          setRefetchPrice(false);
          setFetchingPriceState(false);
        })
        .catch((err) => {
          errHandling(err, setPriceErrMsg);
          // setPriceErrMsg(err.response.data.message);
          setFetchingPriceState(false);
        });
    }
  }, [refetchPrice]);

  useEffect(() => {
    if (!fetchingOrder) {
      const anchor = location.hash;
      if (anchor) {
        const domElement = document.querySelector(anchor);
        if (domElement) {
          domElement.scrollIntoView();
        }
      }
    }
  }, [fetchingOrder, location.hash]);

  useEffect(() => {
    if (refetch) {
      setFetchingOrder(true);
      if (guest) {
        const email = query.get("email");
        const secret = query.get("secret");
        const orderNum = query.get("order_no");
        axios
          .get(
            getURLParams(BACKEND_GUEST_ORDER_BY_ID_API, {
              email,
              secret,
              order_no: orderNum,
            })
          )
          // .then(response => response.json())
          .then((response) => {
            setPackageList(response.data.packages.data);
            setShipmentList(response.data.shipments.data);
            setOrderInfo(response.data);
            setMessage(response.data.messages || "");
            // props.getOrderShipments(shipmentList)
            setFetchingPackagesState(false);
            setFetchingOrder(false);
            setRefetch(false);
            // dispatch(setPackages({packages: packageList}))
          })
          .catch((err) => {
            // console.log(err.data);
            errHandling(err, setOrderErrMsg);
            setFetchingOrder(false);
            setRefetch(false);
          });
      } else {
        axios
          .get(BACKEND_ORDERS_BY_ORDERNUMBER_API(order_num), {
            headers: getHeaders(),
          })
          // .then(response => response.json())
          .then((response) => {
            // console.log(response);
            setPackageList(response.data.packages.data);
            setShipmentList(response.data.shipments.data);
            setOrderInfo(response.data);
            setMessage(response.data.messages || "");
            // props.getOrderShipments(shipmentList)
            setFetchingPackagesState(false);
            setFetchingOrder(false);
            setRefetch(false);
            // dispatch(setPackages({packages: packageList}))
          })
          .catch((err) => {
            // console.log(err.data);
            errHandling(err, setOrderErrMsg);
            setFetchingOrder(false);
            setRefetch(false);
          });
      }
    }
  }, [refetch]);

  const handleAccessCode = (e) => {
    e.preventDefault();
    setAccessCode(e.target.value);
  };

  // If the options for delivery and pickup are the same, then
  // only show one icon
  const sameCondition = (_orderInfo, type) => {
    if (!orderInfo.option_list) return false;
    if (type === "dock") {
      return (
        _orderInfo.option_list.option_pickup_loading_dock !==
        _orderInfo.option_list.option_deliver_loading_dock
      );
    }
    if (type === "timeRange") {
      return !_orderInfo.option_list.option_pickup_time_range;
    }
  };

  // Only get the shipments array belong to a certain package
  const getNewPackageList = (pkgList, shmLists) => {
    const getShipments = (pkgNumber) => {
      return shmLists.filter(
        (shipment) => shipment.package_number === pkgNumber
      );
    };

    const newPkgList = [];
    pkgList.forEach((pkg) => {
      const filteredShipmentList = getShipments(pkg.package_number).filter(
        (shipment) =>
          shipment.status !== "cancelled by owner" &&
          shipment.status !== "cancelled by backend"
      );
      if (filteredShipmentList.length !== 0)
        newPkgList.push({ ...pkg, filteredShipmentList: filteredShipmentList });
    });
    return newPkgList;
  };

  // Return false if all the shipments are claimed,
  const noUnclaimed = (shmLists) => {
    const filteredShipmentList = shmLists.filter(
      (shipment) =>
        shipment.status !== "cancelled by owner" &&
        shipment.status !== "cancelled by backend"
    );

    return filteredShipmentList.reduce(
      (prev, cur) => prev || cur.shipping_status === "unclaimed",
      false
    );
  };

  // Return true if all the shipments are unclaimed or claimed,
  const canAddTips = (shmLists) => {
    const filteredShipmentList = shmLists.filter(
      (shipment) =>
        shipment.status !== "cancelled by owner" &&
        shipment.status !== "cancelled by backend"
    );

    return filteredShipmentList.reduce(
      (prev, cur) =>
        prev ||
        cur.shipping_status === "unclaimed" ||
        cur.shipping_status === "claimed",
      false
    );
  };

  const handleAdditionalMessage = (e) => {
    e.preventDefault();
    setMessage(e.target.value);
  };

  const sendAdditionalMessage = () => {
    axios
      .put(
        `https://admin.uucargo.ca/api/messages/${order_num}?message=${message}`,
        { messages: message },
        { headers: getHeaders() }
      )
      .then((res) => {
        // setSubmittingTipsStatus(false);
        setOpenSnackBar(true);
      })
      .catch((error) => {
        errHandling(error, setSaveMsgErr);
        setOpenSnackBar(true);
      });
  };

  const handleAddingTips = () => {
    setSubmittingTipsStatus(true);
    setTipsMsgErr("");
    axios({
      method: "post",
      url: ADD_SHIPMENTS_TIPS(order_num),
      data: JSON.stringify({
        amount: newTips,
      }),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // console.log(res.data);
        storePrice(res.data);
        setOpenTipsSnackBar(true);
        setSubmittingTipsStatus(false);
        setRefetchPrice(true);
        setAddTipsStatus(false);
      })
      .catch((err) => {
        errHandling(err, setTipsMsgErr);
        setSubmittingTipsStatus(false);
      });
  };

  const handleCloseSnackBar = (event) => {
    setSaveMsgErr("");
    setOpenSnackBar(false);
  };

  const handleCloseTipsSnackBar = (event) => {
    setOpenTipsSnackBar(false);
  };

  const displayShipmentsInPackage = () => {
    if (called) {
      return;
    }
    packageList.map((item, key) => {
      shipmentList.forEach((shipment) => {
        if (item.package_number === shipment.package_number) {
          item["numShipments"] = ++item["numShipments"] || 1;
          //how to check for key in object
          if (!("shipments" in item)) {
            item["shipments"] = [];
          }
          item["shipments"].push(shipment.shipment_number);
        }
      });
    });
    setCalled(true);
  };

  const deleteUnclaimed = (e) => {
    e.preventDefault();
    setCancellingStatus(true);
    axios({
      method: "put",
      url: `${BACKEND_ORDER_CANCEL_API}/${order_num}`,
      data: "",
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // setCancelWindowOpenState(false);
        // redirectOrderList(history);
        setCancelStatus(true);
        setCancellingStatus(false);
      })
      .catch((err) => {
        errHandling(err, setCancelErrMsg);
        setCancellingStatus(false);
      });
  };

  const cancelGuestOrder = () => {
    setGuestCancelErrMsg("");
    setCancellingStatus(true);
    const orderNum = query.get("order_no");
    const userEmail = query.get("email");
    axios({
      method: "put",
      url: getURLParams(`${BACKEND_GUEST_ORDER_CANCEL_API}${orderNum}`, {
        email: userEmail,
        code: accessCode,
      }),
      data: "",
      headers: {
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        // setCancelWindowOpenState(false);
        // redirectOrderList(history);
        setGuestCancelWindowOpen(false);
        setCancellingStatus(false);
      })
      .catch((err) => {
        errHandling(err, setGuestCancelErrMsg);
        setCancellingStatus(false);
      });
  };

  const handleTipsInput = (e) => {
    if (e.target.value < 0) setTipsNumberError(true);
    else setTipsNumberError(false);
    setNewTips(e.target.value);
  };

  return (
    <Container>
      {displayShipmentsInPackage()}
      {fetchingOrder ? (
        <LoadingContainer />
      ) : orderErrMsg ? (
        <Box pt="1vh" width="100%">
          <Alert severity="error" classes={{ root: classes.alertRoot }}>
            {orderErrMsg}
          </Alert>
        </Box>
      ) : (
        <>
          <div className={classes.orderContainer}>
            <div
              className={
                matches ? classes.smRootContainer : classes.rootContainer
              }
            >
              <div id="order-content0">
                <Box p="0 1vw">
                  <div id="quote-order-content0">
                    <h2 className="orange-title">Order Summary</h2>
                    <h3 className="orange-title">{orderInfo.status}</h3>
                  </div>
                  <OrderDetailsHeader
                    packageList={packageList}
                    orderInfo={orderInfo}
                  />
                </Box>
                {activeOrder && (
                  <Box className={classes.getCodeButton}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => setWindowOpenState(true)}
                      style={{
                        textTransform: "unset",
                        fontSize: "1.2rem",
                      }}
                    >
                      Get Confirmation Code
                    </Button>
                  </Box>
                )}
                <br />
                <br />
              </div>
              {orderInfo.options === "y" && (
                <>
                  <div id="order-content2">
                    <p className="subtitle"> Options </p>
                  </div>
                  <Box mb={2}>
                    {orderInfo.option_list?.phone_deliver_appointment && (
                      <Box className="bodytext" pb={1}>
                        <EventIcon /> Delivery appointment
                        <Box>
                          Phone:{" "}
                          {orderInfo.option_list?.phone_deliver_appointment}
                        </Box>
                      </Box>
                    )}
                    {orderInfo.option_list?.option_pickup_loading_dock ===
                      "n" && (
                      <Box className="bodytext" pb={1}>
                        <img
                          src={NoLoadingDock}
                          alt="Loading Dock"
                          width="40px"
                          align="bottom"
                          style={{
                            backgroundColor: "grey",
                          }}
                        />{" "}
                        No pickup loading dock
                      </Box>
                    )}
                    {orderInfo.option_list?.option_pickup_loading_dock ===
                      "y" && (
                      <Box className="bodytext" pb={1}>
                        <img
                          src={LoadingDock}
                          alt="Loading Dock"
                          width="40px"
                          align="bottom"
                          style={{
                            backgroundColor: "seagreen",
                          }}
                        />{" "}
                        Pickup loading dock
                      </Box>
                    )}
                    {orderInfo.option_list?.option_deliver_loading_dock ===
                      "n" && (
                      <Box className="bodytext" pb={1}>
                        {sameCondition(orderInfo, "dock") ? (
                          <>
                            <img
                              src={NoLoadingDock}
                              alt="Loading Dock"
                              width="40px"
                              align="bottom"
                              style={{
                                backgroundColor: "grey",
                              }}
                            />{" "}
                          </>
                        ) : (
                          <Box display="inline-block" width="45px" />
                        )}
                        No delivery loading dock
                      </Box>
                    )}
                    {orderInfo.option_list?.option_deliver_loading_dock ===
                      "y" && (
                      <Box className="bodytext" pb={1}>
                        {sameCondition(orderInfo, "dock") ? (
                          <>
                            <img
                              src={LoadingDock}
                              alt="Loading Dock"
                              width="40px"
                              align="bottom"
                              style={{
                                backgroundColor: "seagreen",
                              }}
                            />{" "}
                          </>
                        ) : (
                          <Box display="inline-block" width="45px" />
                        )}
                        Delivery loading dock
                      </Box>
                    )}
                    {orderInfo.option_list?.option_pickup_time_range && (
                      <Box className="bodytext" pb={1}>
                        <AccessAlarmsIcon /> Pickup time range:
                        <Box pl="28px">
                          {orderInfo.option_list?.option_pickup_time_range}
                        </Box>
                      </Box>
                    )}
                    {orderInfo.option_list?.option_deliver_time_range && (
                      <Box className="bodytext" pb={1}>
                        {sameCondition(orderInfo, "timeRange") ? (
                          <>
                            <AccessAlarmsIcon />{" "}
                          </>
                        ) : (
                          <Box display="inline-block" width="30px" />
                        )}
                        Delivery time range:
                        <Box pl="28px">
                          {orderInfo.option_list?.option_deliver_time_range}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              <div id="order-content2">
                <p className="subtitle"> Order Receipt </p>
              </div>
              {addon && shippingPrice && (
                <PriceModule
                  priceErrMsg={priceErrMsg}
                  fetchingPrice={fetchingPrice}
                  shippingPrice={shippingPrice}
                  tips={tips}
                  addon={addon}
                  detailPage
                />
              )}
              {activeOrder && (
                <>
                  <Box className={classes.getCodeButton} mb={4}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (!noUnclaimed(shipmentList)) {
                          setAllClaimedWindowOpenState(true);
                          return;
                        }
                        guest
                          ? setGuestCancelWindowOpen(true)
                          : setCancelWindowOpenState(true);
                      }}
                      className={classes.button}
                      style={{
                        textTransform: "unset",
                        fontSize: "1.2rem",
                        width: "13rem",
                      }}
                    >
                      Request to cancel
                    </Button>
                  </Box>

                  {canAddTips(shipmentList) && (
                    <>
                      <hr />
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        pt={2}
                      >
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setAddTipsStatus((prev) => !prev)}
                          className={classes.button}
                          style={{
                            textTransform: "unset",
                            fontSize: "1.2rem",
                            width: "13rem",
                          }}
                        >
                          Wanna pay more
                        </Button>
                      </Box>
                    </>
                  )}
                </>
              )}
              <br />
              <br />
              <div id="order-content26">
                <div className="subtitle">
                  <p>Additional Message</p>
                  <TextField
                    onChange={handleAdditionalMessage}
                    className={classes.textfield}
                    variant="filled"
                    rows={2}
                    multiline
                    value={message}
                    InputProps={{
                      readOnly: activeOrder ? false : true,
                      disableUnderline: true,
                    }}
                  />

                  <Snackbar
                    anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                    open={openSnackBar}
                    onClose={handleCloseSnackBar}
                    message={saveMsgErr ? saveMsgErr : "Message saved"}
                    autoHideDuration={5000}
                  />
                </div>
                {activeOrder && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() =>
                        guest
                          ? setSignInRequestWindowOpenState(true)
                          : sendAdditionalMessage()
                      }
                      style={{
                        textTransform: "unset",
                        fontSize: "1rem",
                      }}
                    >
                      Save Message
                    </Button>
                  </Box>
                )}
              </div>
            </div>
            <br />
          </div>
          <br />

          <div className={classes.orderContainer}>
            <div id="packageSection">
              {fetchingPackages ? (
                <LoadingContainer />
              ) : (
                <PackageNoTabs
                  tabs={getNewPackageList(packageList, shipmentList)}
                  defaultIndex={location.state?.activeIndex}
                  activeOrder={activeOrder}
                  guest={guest}
                />
              )}
            </div>
          </div>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={isWindowOpen}
            onClose={() => setWindowOpenState(false)}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem">Confirmation Code</Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => setWindowOpenState(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box
              color="orange"
              fontSize="2.5rem"
              minHeight="20vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {orderInfo.receive_confirmation_code}
            </Box>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={isCancelWindowOpen}
            onClose={() => {
              setCancelWindowOpenState(false);
              setCancelErrMsg("");
            }}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem">Confirmation</Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => {
                  setCancelStatus(false);
                  setCancelWindowOpenState(false);
                  setCancelErrMsg("");
                }}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box
              color="seagreen"
              fontSize="1.5rem"
              minHeight="20vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin={1}
            >
              {cancelStatus ? (
                <Box>
                  {cancelErrMsg
                    ? cancelErrMsg
                    : "The unclaimed shipments are cancelled."}
                </Box>
              ) : (
                <Box>
                  All the unclaimed shipments within the order will be
                  cancelled. Do you confirm to cancel?
                  <Box fontSize="1rem" pt={2} pl={1}>
                    See{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.uucargo.ca/how-it-works/"
                    >
                      Order Charge, Cancel and Refund section
                    </a>{" "}
                    on how it works
                  </Box>
                </Box>
              )}
            </Box>
            {/* <Box pt="1vh" width="100%">
              {cancelErrMsg ? (
                <Alert severity="error">{cancelErrMsg}</Alert>
              ) : (
                ""
              )}
            </Box> */}
            <Box display="flex" justifyContent="center" mb="2vh">
              {cancelStatus ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCancelWindowOpenState(false);
                    setCancelErrMsg("");
                    setRefetch(true);
                    // setPkgRefetch(true);
                  }}
                  className={`${classes.button} ${classes.fixWidthButton}`}
                >
                  Close
                </Button>
              ) : (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={deleteUnclaimed}
                    disabled={isCancelling}
                    className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
                  >
                    {isCancelling ? <CircularProgress size={24} /> : "Yes"}
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCancelWindowOpenState(false);
                      setCancelErrMsg("");
                    }}
                    className={`${classes.button} ${classes.fixWidthButton}`}
                  >
                    No
                  </Button>
                </>
              )}
            </Box>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={isSignInRequestWindowOpen}
            onClose={() => setSignInRequestWindowOpenState(false)}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem"></Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => setSignInRequestWindowOpenState(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box
              color="seagreen"
              fontSize="1.5rem"
              minHeight="20vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="2vw"
            >
              You need to sign in before proceeding to the next step.
            </Box>
            <Box display="flex" justifyContent="center" mb="2vh">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setSignInRequestWindowOpenState(false);
                }}
                className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push("/forgot-password")}
                className={`${classes.button} ${classes.fixWidthButton}`}
              >
                Sign In
              </Button>
            </Box>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={isGuestCancelWindowOpen}
            onClose={() => setGuestCancelWindowOpen(false)}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem">Cancel Order</Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => setGuestCancelWindowOpen(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box display="flex" justifyContent="center" pt={2} pb={2}>
              <Box pr={2}>
                <p>Access Code</p>
              </Box>
              <TextField
                onChange={(e) => handleAccessCode(e)}
                value={accessCode}
                id="accessCode"
              />
            </Box>
            {guestCancelErrMsg && (
              <Box pt="1vh" width="100%">
                <Alert severity="error" classes={{ root: classes.alertRoot }}>
                  {guestCancelErrMsg}
                </Alert>
              </Box>
            )}
            <Box display="flex" justifyContent="center" pb={2}>
              <Button
                variant="outlined"
                color="primary"
                onClick={cancelGuestOrder}
                disabled={isCancelling}
                className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
              >
                {isCancelling ? <CircularProgress size={24} /> : "Yes"}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setGuestCancelWindowOpen(false)}
                className={`${classes.button} ${classes.fixWidthButton}`}
              >
                No
              </Button>
            </Box>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={isAllClaimedWindowOpen}
            onClose={() => setAllClaimedWindowOpenState(false)}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem"></Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => setAllClaimedWindowOpenState(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box
              color="seagreen"
              fontSize="1.5rem"
              minHeight="20vh"
              display="flex"
              justifyContent="center"
              alignItems="center"
              margin="2vw"
            >
              The order can't be cancelled because all the shipments are under
              processing.
            </Box>
          </Dialog>

          <Dialog
            maxWidth="sm"
            fullWidth
            open={addTips}
            onClose={() => setAddTipsStatus(false)}
          >
            <div className={classes.dialogTitle}>
              <Box fontSize="1.5rem"></Box>
              <IconButton
                // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                style={{ fill: "green" }}
                className={classes.closeBtn}
                onClick={() => setAddTipsStatus(false)}
              >
                <ClearIcon />
              </IconButton>
            </div>
            <Box fontSize="1.2rem" p={1}>
              The current tips:{" "}
              {fetchingPrice ? <CircularProgress size={24} /> : <b>C${tips}</b>}
            </Box>
            <Box
              display="flex"
              mt={3}
              mb={3}
              ml={1}
              mr={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Box mr={0.5}>Add tips</Box>
                <Box
                  minWidth="10rem"
                  flexGrow="1"
                  className={classes.inputBorder}
                >
                  <CustomInput
                    value={newTips}
                    onChange={handleTipsInput}
                    type="currency"
                    error={tipsNumberError}
                  />
                </Box>
              </Box>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                style={{
                  textTransform: "unset",
                  fontSize: "1rem",
                  width: "5rem",
                }}
                onClick={handleAddingTips}
              >
                {submittingTips ? (
                  <CircularProgress size={24} style={{ color: "black" }} />
                ) : (
                  "Add"
                )}
              </Button>
              {tipsMsgErr && (
                <Box pt={1} width="100%">
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {tipsMsgErr}
                  </Alert>
                </Box>
              )}
            </Box>
          </Dialog>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={openTipsSnackBar}
            onClose={handleCloseTipsSnackBar}
            message="Tips added"
            autoHideDuration={3000}
          />
        </>
      )}
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    margin: "0 auto",
    // width: '40vw',
    width: "600px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  button: {
    fontSize: "12pt",
    backgroundColor: "#ffa726",
    margin: "7px",
  },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    fontSize: "12pt",
    margin: "7px",
    marginRight: "24px",
  },
  textfield: {
    width: "100%",
  },
  calendar: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "70%",
  },
  backDrop: {
    timeout: 500,
    backdropFilter: "blur(3px)",
    backgroundColor: "rgba(0,0,30,0.4)",
  },
  getCodeButton: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  orderContainer: {
    backgroundColor: "rgba(255, 255, 255, 0.788)",
    borderRadius: "25px",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  inputBorder: {
    border: "1px solid black",
    borderRadius: "5px",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

OrderDetails.propTypes = {
  guest: PropTypes.bool,
};

export default OrderDetails;

import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  container:{
    border: 'solid 1px #86909c',
    borderRadius: '10px',
    padding: '10px 2px',
    width: '98%',
    maxWidth: '700px',
    margin: '3px auto'
  }
}))
const NoShipmentsLayout = ({message}) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant='subtitle1' align='center'>{message}</Typography>
    </div>
  )
}

export default NoShipmentsLayout
import React from "react";
import { useHistory } from "react-router-dom";
// import Slide from 'react-reveal';
import "../styles/styles.css";
// import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
// import ListAltIcon from "@material-ui/icons/ListAlt";
// import LocalShippingIcon from "@material-ui/icons/LocalShipping";
// import { Alert } from "@material-ui/lab";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

// import { getAccessToken } from "./Utils/doToken";

// import axios from 'axios';

// import {getAccessToken, getTokenFromCookie} from './Utils/doToken';
// import { useHistory } from 'react-router-dom'

import { Box, Container, useMediaQuery } from "@material-ui/core";

// const jumboStyle = {
//   color: "green",

//   maxheight: "-webkit-fill-available",
//   height: "90vh",
//   fontFamily: "Montserrat",
//   fontSize: "2rem",
//   letterSpacing: "2px",
//   display: "flex",
//   alignItems: "center",
//   flexDirection: "column",
//   justifyContent: "center",
//   textAlign: "center",
//   overflow: "hidden",
// };

const UserSettings = () => {
  // const [hide, setHide] = useState(true);
  // const dispatch = useDispatch();
  // const role = useSelector(state => state.user.role);

  const classes = useStyles();
  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));

  // const token = getAccessToken("access_token");

  // const userInfo = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const history = useHistory();

  // const [latestShippingLoading, setLastestShippingLoading] = useState(true);
  // const [latestOrderData, setLatestOrderData] = useState(null);
  // const [errorMsg, setErrorMsg] = useState("");

  const matches = useMediaQuery("(max-width:600px)");

  // useEffect(() => {
  //   setLastestShippingLoading(true);

  //   axios
  //     .get(
  //       getURLParams(BACKEND_GET_ORDERS_API, {
  //         page: 1,
  //         status: ["Active"],
  //       }),
  //       { headers: getHeaders() }
  //     )
  //     .then((response) => {
  //       // response.data.data && dispatch(setOrders({orders: response.data.data}));
  //       if (response.data.data && response.data.data[0])
  //         setLatestOrderData(response.data.data[0]);
  //       // setLoadingState(false);
  //       setLastestShippingLoading(false);
  //     })
  //     .catch((err) => {
  //       setErrorMsg(err.msg);
  //       setLastestShippingLoading(false);
  //     });
  // }, []);

  // let lastestItem = <LoadingContainer />;

  // if (!latestShippingLoading)
  //   lastestItem = (
  //     <Box fontSize="1.5rem" m={1} textAlign="center">
  //       No latest order found
  //     </Box>
  //   );

  // if (!latestShippingLoading && latestOrderData)
  //   lastestItem = (
  //     <>
  //       <Box
  //         className="order-list-text"
  //         display="flex"
  //         // justifyContent={matches ? 'space-between' : 'flex-start'}
  //         justifyContent="space-between"
  //         // width='100%'
  //         m={1}
  //       >
  //         {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
  //         <Box textAlign="center">
  //           Order Number: {matches ? <br /> : ""}
  //           <span className={classes.orderNum}>
  //             {latestOrderData.order_number}
  //           </span>
  //         </Box>
  //         <Box pl={1} textAlign="center" flexGrow={matches ? "0" : "1"}>
  //           Status: {matches ? <br /> : ""}
  //           <span style={{ fontStyle: "italic" }}>
  //             {latestOrderData.status}
  //           </span>
  //         </Box>
  //       </Box>
  //     </>
  //   );

  return (
    <>
      <Container>
        <h1 className={classes.title}>Settings</h1>
        <Box
          display="flex"
          justifyContent={matches ? "space-between" : "flex-start"}
          mt={2}
        >
          <Box
            className={classes.root}
            mr="4vw"
            role="button"
            onClick={() => {
              history.push("/address-book");
            }}
          >
            <Box
              display="flex"
              justifyContent="center"
              height="100%"
              alignItems="center"
            >
              {/* <Link to = "/shipping/start-a-quote">Start A Quote</Link> */}
              <LibraryBooksIcon />
              Address Book
            </Box>
          </Box>
        </Box>
      </Container>
    </>
  );
};

const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
  },
  root: {
    width: 150,
    height: 100,
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  lgRoot: {
    minHeight: 300,
    margin: 10,
    marginLeft: 0,
    marginTop: 20,
    // width: '90%',
    width: "100%",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {},
  orderNum: {
    fontSize: "1rem",
    color: "seagreen",
  },
});

export default UserSettings;

import {
  DRIVER_UPLOAD_IMAGES,
  DRIVER_REMOVE_IMAGES,
  DRIVER_CLEAR_IMAGES,
} from "./types";

export const driverUploadImages = (images, type) => {
  return {
    target: type,
    type: DRIVER_UPLOAD_IMAGES,
    payload: images,
  };
};

export const driverRemoveImages = (index, type) => {
  return {
    target: type,
    type: DRIVER_REMOVE_IMAGES,
    payload: index,
  };
};

export const driverClearImages = type => {
  return {
    target: type,
    type: DRIVER_CLEAR_IMAGES,
  };
};

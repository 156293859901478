import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { deleteToken, getAccessToken } from "../Utils/doToken";
import axios from "axios";

const ClearTokenRouter = ({ component: Component, ...rest }) => {
  useEffect(() => {
    const token = getAccessToken("access_token");
    if (Boolean(token)) {
      deleteToken("access_token");
      try {
        axios.get(`https://admin.uucargo.ca/api/logout`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        //setHide(true)
      } catch (error) {
        console.log(error);
      }
    }
  }, []);

  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
export default ClearTokenRouter;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import logo from "../Images/logo.png";
import {
  AppBar,
  IconButton,
  Toolbar,
  MenuItem,
  Menu,
  Divider,
  Typography,
  makeStyles,
  Box,
  useMediaQuery,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import WidgetsIcon from "@material-ui/icons/Widgets";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";

import MenuIcon from "@material-ui/icons/Menu";
import "../../styles/styles.css";
import axios from "axios";

import { getAccessToken, deleteToken } from "../../Utils/doToken";
import { useHistory } from "react-router-dom";
// import { userSignOut } from '../../actions/userSignInAction';
import { deleteUserInfo, updateUserInfo } from "../../slices";

import { redirectHome, redirectOrderList } from "../../Utils/redirect";
import Calculator from "../Images/icons/calculator";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    flexGrow: 1,
  },
  button: {
    borderRadius: 20,
    borderColor: "green",
    color: "green",
    fontSize: "14pt",
    flexGrow: 1,
  },
  userButton: {
    borderRadius: 20,
    borderColor: "green",
    color: "green",
    fontSize: "14pt",
    marginRight: "2vw",
  },
  appBarSpacer: {
    height: "70px",
  },
  hideGoBackArrow: {
    display: "none",
  },
  displayGoBackArrow: {
    display: "block",
  },
}));

const UserNavBar = ({ deferredPrompt, setDeferredPrompt }) => {
  const currentLocation = useLocation();
  // const [width, setWidth] = useState(getWidth());
  // const [selectedUser, setSelectedUser] = useState()
  // const [userName, setUserName] = useState('')
  const [showClassName, setShowClassName] = useState("hideGoBackArrow");
  const [anchorEl, setAnchorEl] = useState(null);
  // const [hide, setHide] = useState(true);

  const open = Boolean(anchorEl);
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.user.role);
  const isLoading = useSelector((state) => state.user.isLoading);

  const userRole = role || "End User";

  const matches = useMediaQuery("(max-width:800px)");

  //useEffect to listen to screen size change
  // useEffect(() => {
  //   const resizeListener = () => {
  //     // change width from the state object
  //     setWidth(getWidth());
  //   };
  //   // set resize listener
  //   window.addEventListener("resize", resizeListener);

  //   // clean up function
  //   return () => {
  //     // remove resize listener
  //     window.removeEventListener("resize", resizeListener);
  //   };
  // }, []);

  useEffect(() => {
    if (
      currentLocation.pathname === "/driver/available_shipments" ||
      currentLocation.pathname === "/driver/claimed_shipments" ||
      currentLocation.pathname === "/driver/load_shipments" ||
      currentLocation.pathname === "/driver/deliver_shipments" ||
      currentLocation.pathname === "/driver" ||
      currentLocation.pathname === "/sign-in" ||
      currentLocation.pathname === "/" ||
      currentLocation.pathname === "/guest-checkout" ||
      currentLocation.pathname === "/guest-order-details"
    ) {
      setShowClassName("hideGoBackArrow");
    } else {
      setShowClassName("displayGoBackArrow");
    }
  }, [currentLocation]);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const redirectToProfile = () => {
    handleClose();
    let path = "/profile";
    history.push(path);
  };

  const storedToken = getAccessToken("access_token");

  // let login = {
  //     email: null,
  //     password: null
  // }

  const signOut = (e) => {
    e.preventDefault();
    try {
      axios.get(`https://admin.uucargo.ca/api/logout`, {
        headers: { Authorization: `Bearer ${storedToken}` },
      });
      //setHide(true)
    } finally {
      deleteToken("access_token");
      dispatch(deleteUserInfo());
      handleClose();
      dispatch(updateUserInfo({ field: "login", value: false }));
      dispatch(updateUserInfo({ field: "signIn", value: false }));
      history.push("/");
    }
  };

  const redirectSupport = () => {
    window.open("https://www.uucargo.ca/support/", "_blank");
  };

  const handleGoBack = (event) => {
    history.goBack();
  };

  const installApp = () => {
    // Hide the app provided install promotion
    // hideInstallPromotion();
    // Show the install prompt
    if (deferredPrompt) {
      deferredPrompt.prompt();
      // Wait for the user to respond to the prompt
      // const { outcome } = await deferredPrompt.userChoice;
      // Optionally, send analytics event with outcome of user choice
      // console.log(`User response to the install prompt: ${outcome}`);
      // We've used the prompt, and can't use it again, throw it away
      setDeferredPrompt(null);
      handleClose();
    }
  };

  let menu = (
    <Menu
      id="menu-appbar"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={handleClose}
      style={{ width: "500px", textAlign: "center" }}
    >
      {matches && (
        <>
          <MenuItem
            onClick={() => {
              history.replace("/driver/available_shipments");
              handleClose();
            }}
          >
            Search Shipments
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.replace("/driver/claimed_shipments");
              handleClose();
            }}
          >
            My Shipments
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.replace("/driver/load_shipments");
              handleClose();
            }}
          >
            Go to Pickup
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.replace("/driver/deliver_shipments");
              handleClose();
            }}
          >
            Go to Deliver
          </MenuItem>
          <MenuItem
            onClick={() => {
              history.replace("/driver/bookkeeper");
              handleClose();
            }}
          >
            My Bookkeeper
          </MenuItem>
          <Divider />
        </>
      )}

      <MenuItem
        onClick={() => {
          redirectSupport();
          handleClose();
        }}
      >
        Support
      </MenuItem>
      <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
      <MenuItem onClick={signOut}>Sign Out</MenuItem>
    </Menu>
  );

  if (userRole === "End User")
    menu = (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        style={{ width: "500px", textAlign: "center" }}
      >
        {matches && (
          <MenuItem
            onClick={() => {
              history.push("/shipping/start-a-quote");
              handleClose();
            }}
          >
            Start A Quote
          </MenuItem>
        )}
        {matches && (
          <MenuItem
            onClick={() => {
              redirectOrderList(history);
              handleClose();
            }}
          >
            My Orders
          </MenuItem>
        )}
        {matches && <Divider />}
        <MenuItem
          onClick={() => {
            handleClose();
            history.push("/settings");
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            redirectSupport();
            handleClose();
          }}
        >
          {/* <Typography variant='subtitle2'>
                    <a href="mailto:support@uucargo.ca">Support</a> 
                    </Typography> */}
          Support
        </MenuItem>
        <Divider />
        <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
        <MenuItem onClick={signOut}>Sign Out</MenuItem>
      </Menu>
    );

  if (isLoading && storedToken) menu = "";

  if (!storedToken)
    menu = (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
        style={{ width: "500px", textAlign: "center" }}
      >
        {matches && (
          <MenuItem
            onClick={() => {
              history.push("/sign-up");
              handleClose();
            }}
          >
            Sign Up
          </MenuItem>
        )}
        {matches && (
          <MenuItem
            onClick={() => {
              history.push("/sign-in");
              handleClose();
            }}
          >
            Sign In
          </MenuItem>
        )}
        {matches && <Divider />}
        <MenuItem onClick={installApp}>Add To Home Screen</MenuItem>
        <MenuItem
          onClick={() => {
            redirectSupport();
            handleClose();
          }}
        >
          {/* <Typography variant='subtitle2'>
                    <a href="mailto:support@uucargo.ca">Support</a> 
                    </Typography> */}
          Support
        </MenuItem>
      </Menu>
    );

  let linkList = (
    <>
      <IconButton
        aria-label="Search Shipments"
        aria-haspopup="true"
        onClick={() => history.replace("/driver/available_shipments")}
        color="inherit"
        className={classes.button}
      >
        <SearchIcon />
        <Typography variant="subtitle2">Search Shipments</Typography>
      </IconButton>
      <IconButton
        aria-label="Shipments Overview"
        aria-haspopup="true"
        onClick={() => history.replace("/driver/claimed_shipments")}
        color="inherit"
        className={classes.button}
      >
        <WidgetsIcon />
        <Typography variant="subtitle2">My Shipments</Typography>
      </IconButton>
      <IconButton
        aria-label="Load Shipments"
        aria-haspopup="true"
        onClick={() => history.replace("/driver/load_shipments")}
        color="inherit"
        className={classes.button}
      >
        <LocalShippingIcon />
        <Typography variant="subtitle2">Go to Pickup</Typography>
      </IconButton>
      <IconButton
        aria-label="Deliver Shipments"
        aria-haspopup="true"
        onClick={() => history.replace("/driver/deliver_shipments")}
        color="inherit"
        className={classes.button}
      >
        <MarkunreadMailboxIcon />
        <Typography variant="subtitle2">Go to Deliver</Typography>
      </IconButton>
      <IconButton
        aria-label="Bookkeeper"
        aria-haspopup="true"
        onClick={() => history.replace("/driver/bookkeeper")}
        color="inherit"
        className={classes.button}
      >
        <Calculator width="26" fill="seagreen" />
        <Typography variant="subtitle2">My Bookkeeper</Typography>
      </IconButton>
    </>
  );

  if (userRole === "End User")
    linkList = (
      <Box>
        <IconButton
          aria-label="Deliver Shipments"
          aria-haspopup="true"
          onClick={() => history.push("/shipping/start-a-quote")}
          color="inherit"
          className={classes.userButton}
        >
          <LocalShippingIcon />
          <Typography variant="subtitle2">Start A Quote</Typography>
        </IconButton>

        <IconButton
          aria-label="Load Shipments"
          aria-haspopup="true"
          onClick={() => redirectOrderList(history)}
          color="inherit"
          className={classes.userButton}
        >
          <ListAltIcon />
          <Typography variant="subtitle2">My Orders</Typography>
        </IconButton>
        {/* {!hide && (<IconButton
            aria-label="Sign Out"
            aria-haspopup="true"
            onClick={signOut}
            color="inherit"
            className={classes.userButton}
            >
                
                <Typography variant='subtitle2'>Sign Out</Typography>
            </IconButton>)} */}
      </Box>
    );

  if (isLoading && storedToken) linkList = "";

  if (!storedToken)
    linkList = (
      <Box>
        <IconButton
          aria-label="Sign Up"
          aria-haspopup="true"
          onClick={() => history.push("/sign-up")}
          color="inherit"
          className={classes.userButton}
        >
          <Typography variant="subtitle2">Sign Up</Typography>
        </IconButton>
        <IconButton
          aria-label="Sign In"
          aria-haspopup="true"
          onClick={() => history.push("/sign-in")}
          color="inherit"
          className={classes.userButton}
        >
          <Typography variant="subtitle2">Sign In</Typography>
        </IconButton>
        {/* <IconButton
            aria-label="Support"
            aria-haspopup="true"
            color="inherit"
            className={classes.userButton}
            >
                <Typography variant='subtitle2'>
                   <a href="mailto:support@uucargo.ca">Support</a> 
                </Typography>
            </IconButton> */}
      </Box>
    );
  return (
    <>
      <AppBar
        position="fixed"
        className="AppBar"
        style={{ background: "white" }}
        sx={{ bottom: "auto", top: 0 }}
      >
        <Toolbar>
          <IconButton
            onClick={handleGoBack}
            className={classes[`${showClassName}`]}
          >
            <ArrowBackIosIcon viewBox="-6 0 24 24" />
          </IconButton>
          <a className={classes.logo}>
            <img
              style={{ height: "50px", padding: "10px 10px" }}
              src={logo}
              onClick={() => redirectHome(history, userRole)}
              alt="useAvatar"
            />
          </a>
          {userRole === "End User" && (
            <>
              {!matches && linkList}
              <IconButton
                aria-label="show more"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              {menu}
            </>
          )}
          {userRole !== "End User" &&
            (matches ? (
              <>
                <IconButton
                  aria-label="show more"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                {menu}
              </>
            ) : (
              <>
                {linkList}
                <IconButton
                  aria-label="show more"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                {menu}
              </>
            ))}
          {isLoading || !storedToken ? (
            ""
          ) : (
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={redirectToProfile}
            >
              <AccountCircle fontSize="large" />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <div className={classes.appBarSpacer}></div>
    </>
  );
};

export default UserNavBar;

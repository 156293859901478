import React, {useState, useEffect} from 'react';
// import Slide from 'react-reveal';
import {BrowserRouter as Route, Link} from 'react-router-dom';
import {
    Button,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {useSelector} from 'react-redux';
import PropTypes from 'prop-types'
import {format} from 'date-fns'

import '../../styles/shippingItem.css'

// const row = {
//     border: "2px",
// };




const QuoteSummary = ({toSeventh, toFirst, toFourth}) => {

    const shippingItems = useSelector(state => state.shipping.shippingItems);
    const initPickUpDetails = useSelector(state => state.shipping.pickUpDetails);

    // const [confirm, setConfirm] = useState();
    // const [selectedUser, setSelectedUser] = useState();
    const [pickUpDetails, setPicUpDetails] = useState(initPickUpDetails);
    const [palletItem, setPalletItem] = useState(shippingItems);
  
   
    const classes = useStyles();
   

    return (
        <div>
            <br />
            <br />
            <form className="summary-container">
                <h2 className="orange-title">Quote Summary:</h2>
                <div></div>
                <div id="summary-content1" className="subtitle">
                    <p className={classes.summaryHeader}>Pickup</p>
                    <p className={classes.summaryBodyText} >
                        {`${pickUpDetails.pickUp.address}`} <br/>
                        {`${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province} ${pickUpDetails.pickUp.zipCode}`} <br/>
                        Requested for {format(pickUpDetails.selectedDate, 'dd/MM/yyyy')}
                    </p>
                </div>
                <div id="summary-content2" className="bodytext">
                    <a onClick={toFirst}>Edit</a>
                </div>
                <div id="summary-content3" className="subtitle">
                <p className={classes.summaryHeader}>Delivery</p>
                <p className={classes.summaryBodyText} >
                    {`${pickUpDetails.deliver.address}`} <br/>
                        {`${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province} ${pickUpDetails.deliver.zipCode}`}
                </p>
                </div>
                <div id="summary-content4" className="bodytext">
                    <a onClick={toFirst}>Edit</a>
                </div>
                <div id="summary-content5" className="subtitle">
                <p className={classes.summaryHeader}>Delivery Services</p>
                <p className={classes.summaryBodyText}>Appointment fee</p>
                </div>
                <div id="summary-content6" className="bodytext"></div>
                <div id="summary-content7" className="subtitle">
                <p className={classes.summaryHeader}>Items</p>
                <div className={classes.summaryBodyText}>
                    {palletItem.map((item, key) => {
                        return(
                            <div key = {key}>
                                <p>{item.quantity} Pallet, {item.weight} lbs, Class 250</p>
                            </div>   
                        )
                    })}
                </div>
                </div>
                <div id="summary-content8" className="bodytext">
                    <a onClick={toFourth}>Edit</a>
                </div>
                <div id="summary-content9" className="subtitle">

                </div>
                <div></div>
                <div style={{textAlign:'center'}}>
                    <Button onClick ={toSeventh} variant="outlined" color="primary" className={classes.button}>
                        Continue
                    </Button>
                    <br/>
                    <br/>
                    <Link to ="/">
                    <Button className={classes.button} style={{fontSize:"20pt"}}>
                        Save Changes & Go to home
                    </Button>
                    </Link>
                </div>
                <div></div>
            </form>
            <br/>
            
             
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
    button: {
        borderRadius: 20,
        fontSize: "25pt",
        backgroundColor:'#ffa726',
        color: "white"
    },
    summaryHeader: {
        fontSize: '16pt',
        color: 'seagreen',
        fontFamily: "Montserrat",
        // borderBottom: '1px, solid rgb(141, 173, 155)',
    },
    summaryBodyText: {
        fontSize: '13pt',
        color: 'rgb(141, 173, 155)',
        fontFamily: "Montserrat",
    }
}));

QuoteSummary.propTypes ={
    toSeventh : PropTypes.func.isRequired,
    toFirst: PropTypes.func.isRequired,
    toFourth: PropTypes.func.isRequired,
}

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, { getShipmentItems, getPickUpDetails })(QuoteSummary);
export default QuoteSummary;
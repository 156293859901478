import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import Slide from 'react-reveal';
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Container,
  useMediaQuery,
  Button,
  IconButton,
  makeStyles,
  Snackbar,
} from "@material-ui/core";
// import produce from "immer";
// import ListAltIcon from "@material-ui/icons/ListAlt";
import ClearIcon from "@material-ui/icons/Clear";
import { Alert } from "@material-ui/lab";
// import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";

// import { AddressInputCombo } from "../shared/AddressInputCombo/addressInputCombo";

import axios from "axios";
import { BACKEND_UPDATE_USER_ADDRESS } from "../../Utils/apiUrl";
import { errHandling, getHeaders } from "../../Utils/fetchUtil";
import { updateUserInfo, getAddressBook } from "../../slices";
import LoadingContainer from "../shared/loadingContainer";
// import AddressInputPage from "./addressInputPage";

const AddressBook = () => {
  // const [hide, setHide] = useState(true);
  const dispatch = useDispatch();
  const { addressBook, addressBookLoading, addressBookLoadingErr } =
    useSelector((state) => state.user);
  const classes = useStyles();

  // const token = getAccessToken("access_token");

  // const userInfo = useSelector((state) => state.user);
  // const dispatch = useDispatch();
  const history = useHistory();

  const [deleteAdressErr, setDeleteErr] = useState("");

  useEffect(() => {
    dispatch(getAddressBook());
  }, [dispatch]);

  const handleDelete = (id) => {
    const newAddressList = addressBook.filter((item) => item.id !== id);
    dispatch(updateUserInfo({ field: "addressBook", value: newAddressList }));
    axios({
      method: "delete",
      url: BACKEND_UPDATE_USER_ADDRESS(id),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {})
      .catch((err) => {
        errHandling(err, setDeleteErr);
      });
  };

  const matches = useMediaQuery("(max-width:600px)");

  return (
    <Container>
      <h1 className={classes.title}>Address Book</h1>
      <Box>
        <Button
          color="primary"
          variant="contained"
          className={matches ? classes.smButton : classes.button}
          onClick={() => history.push("/address-book/add")}
          style={{
            textTransform: "unset",
          }}
        >
          Add Address
        </Button>
      </Box>
      {addressBookLoading ? (
        <LoadingContainer />
      ) : (
        <Box
          display="flex"
          // justifyContent={matches ? "space-between" : "flex-start"}
          alignItems="center"
          flexDirection="column"
          mt={2}
        >
          {addressBook.map((item) => (
            <Box className={classes.root} width="100%" mb={2}>
              <Box
                display="flex"
                //   justifyContent="center"
                flexDirection="column"
                height="100%"
                alignItems="center"
              >
                {/* <Link to = "/shipping/start-a-quote">Start A Quote</Link> */}
                {item.default ? (
                  <Box
                    borderBottom="1px solid rgba(0,0,0,0.5)"
                    width="100%"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box fontWeight="700" ml={1}>
                      default
                    </Box>
                    <IconButton
                      // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                      style={{ fill: "green" }}
                      className={classes.closeBtn}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Box
                    borderBottom="1px solid rgba(0,0,0,0.5)"
                    width="100%"
                    display="flex"
                    justifyContent="end"
                    alignItems="center"
                  >
                    <IconButton
                      // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                      style={{ fill: "green" }}
                      className={classes.closeBtn}
                      onClick={() => handleDelete(item.id)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Box>
                )}

                <Box m={1} alignSelf="start">
                  {item.phone && (
                    <>
                      {item.phone} <br />
                    </>
                  )}
                  {item.address} <br />
                  {`${item.city}, ${item.province} ${item.postal_code}`}
                </Box>
                <Box m={1} alignSelf="end">
                  <Button
                    variant="text"
                    style={{
                      textTransform: "unset",
                      color: "blue",
                      fontSize: "1rem",
                    }}
                    onClick={() =>
                      history.push(`/address-book/edit/${item.id}`)
                    }
                  >
                    Edit
                  </Button>
                </Box>
              </Box>
            </Box>
          ))}
          {addressBookLoadingErr && (
            <Box pt="1vh" width="100%">
              <Alert severity="error" classes={{ root: classes.alertRoot }}>
                {addressBookLoadingErr}
              </Alert>
            </Box>
          )}
        </Box>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={Boolean(deleteAdressErr)}
        onClose={() => setDeleteErr("")}
        message={deleteAdressErr}
        autoHideDuration={3000}
      />
    </Container>
  );
};

const useStyles = makeStyles({
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
  },
  root: {
    // width: 170,
    // height: 200,
    backgroundColor: "white",
    borderRadius: 5,
    // cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "orange",
    //   color: "white",
    // },
  },
  lgRoot: {
    minHeight: 300,
    margin: 10,
    marginLeft: 0,
    marginTop: 20,
    // width: '90%',
    width: "100%",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {},
  orderNum: {
    fontSize: "1rem",
    color: "seagreen",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
});

export default AddressBook;

import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addLoadedShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  Button,
  Backdrop,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
} from "@material-ui/core";
import {
  GET_SHIPMENTS_BY_ID_API,
  GET_PACKAGES_BY_ID_API,
  DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID,
  DRIVER_CONFIRM_UNCLAIM,
} from "../../Utils/apiUrl";
import LoadingCircle from "../LoadingCircle";
import { getTokenFromCookie } from "../../Utils/doToken";
import axios from "axios";
import ShipmentOptionsLayout from "../Layouts/ShipmentOptionsLayout";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px auto",
    width: "96%",
    maxWidth: "700px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  backDrop: {
    zIndex: 1,
    color: "#fff",
  },
  trackingNumbersWrapper: {
    width: "99%",
    margin: "5px auto",
    listStyleType: "none",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    backgroundColor: "#fbfbf8",
    padding: "5px",
  },
  trackingNumbersList: {},
}));

const ClaimedShipmentDetailPage = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openErrorDialog, setOpenConfirmDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [fineAmount, setFineAmount] = useState(null);
  const [unclaimSuccess, setUnclaimSuccess] = useState(false);
  const [claimedShipment, setClaimedShipment] = useState({});
  const [packageInfo, setPackageInfo] = useState([]);
  const cardActive = useSelector(state => {
    return state.user.activeCard === "active";
  });

  let { id } = useParams();
  const history = useHistory();

  const getShipmentInfo = async () => {
    setLoading(true);
    try {
      const header = {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      };
      const shipmentInfo = await axios.get(GET_SHIPMENTS_BY_ID_API(id), header);
      const packageId = shipmentInfo.data.package_number;
      const packageInformation = await axios.get(
        GET_PACKAGES_BY_ID_API(packageId),
        header
      );
      setClaimedShipment(shipmentInfo.data);
      setPackageInfo(packageInformation.data);
      setLoading(false);
    } catch (error) {
      console.log("Error when trying to get shipment/package data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getShipmentInfo();
  }, []);

  const handleLoadShipment = () => {
    const p = packageInfo;

    const trackingNumbers = [];
    const src = claimedShipment.tracking_numbers.data;
    if (src.length > 0) {
      src.forEach(t => {
        trackingNumbers.push(t.tracking_number);
      });
      console.log("tracking numbers", trackingNumbers);
    }
    const payload = {
      package_number: p.package_number,
      item_name: p.item_name,
      cover_image: p.cover_image,
      shipment_number: claimedShipment.shipment_number,
      tracking_number: trackingNumbers,
    };
    dispatch(addLoadedShipments(payload));
    history.push("/driver/load_shipments");
  };

  const handleCancelClaimClicked = () => {
    setOpenBackDrop(true);
    setOpenDialog(true);
  };

  const handleClickBackDrop = () => {
    setOpenBackDrop(false);
  };

  const handleDialogOnClose = () => {};

  const handleConfirmUnclaim = () => {
    let message = "";
    axios
      .put(
        DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID(id),
        {},
        {
          params: {
            confirmed: 0,
          },
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(res => {
        console.log(`unclaim shipment result`, res);
        if (res.data.status === 200) {
          //successfully unclaimed shipment
          if (res.fine_amount) {
            message = "You are able to unclaim this shipment with a fine.";
            setErrorMessage(message);
            setFineAmount(res.fine_amount);
          } else {
            message = "You are able to unclaim this shipment without a fine.";
            setErrorMessage(message);
            setFineAmount(null);
          }
        }
      })
      .catch(error => {
        if (error.request) {
          console.log(`error with request`, error.request);
          console.log(
            `error with request error code`,
            JSON.parse(error.request.response).code
          );
          const response = JSON.parse(error.request.response);

          if (response.code === "2004") {
            //driver unclaim with fine
            if (response.fine_amount) {
              setFineAmount(response.fine_amount);
            } else {
              setFineAmount(null);
            }
            setErrorMessage(response.message);
          } else if (response.code === "2000") {
            //unable to unclaim coz shipment not found or order of that shipment is paused
            message = "You are not able to unclaim this shipment. Due to ";
            message += response.message;
            setErrorMessage(message);
          }

          setOpenConfirmDialog(true);
          setOpenDialog(false);
        } else if (error.response) {
          console.log(`error with response ${error.response}`);
        } else {
          console.log(`Got error when trying to unclaim shipment ${error}`);
        }
      });
  };

  const handleCancel = () => {
    setFineAmount(null);
    setOpenDialog(false);
    setOpenBackDrop(false);
    setOpenConfirmDialog(false);
  };

  const handleErrorDialogOnClose = () => {};

  const handleConfirmClaim = () => {
    let message = "";
    const header = {
      headers: {
        Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      },
    };
    axios
      .put(
        DRIVER_CONFIRM_UNCLAIM(claimedShipment.shipment_number),
        {},
        {
          params: {
            confirmed: 1,
          },
          ...header,
        }
      )
      .then(res => {
        console.log("confirm unclaim info", res);
        //check status code, if it is 200, unclaim successfully
        if (res.status === 200) {
          message = "You have successfully unclaim this shipment.";
          setFineAmount(null);
          setUnclaimSuccess(true);
          setFeedbackMessage(message);
          setOpenSuccessDialog(true);
          setOpenConfirmDialog(false);
        }
      })
      .catch(error => {
        if (error.request) {
          console.log("confirm unclaim request error", error.request);
          setFeedbackMessage(JSON.parse(error.request.response).message);
          setFineAmount(null);
          setUnclaimSuccess(false);
          setOpenSuccessDialog(true);
          setOpenConfirmDialog(false);
        }
      });
  };

  const handleSuccessConfirm = () => {
    setOpenConfirmDialog(false);
    setOpenDialog(false);
    setOpenSuccessDialog(false);
    setOpenBackDrop(false);
    if (unclaimSuccess) {
      history.replace("/driver/claimed_shipments");
    } else {
      //got error when finishing last step of unclaim
      //should stay on current detail page
    }
  };

  const handleSuccessDialogOnClose = () => {};
  console.log("claimed shipment detail", claimedShipment);
  return (
    <>
      {!loading ? (
        <>
          <Typography variant="h6" align="center">
            Claimed Shipment
          </Typography>
          <Paper className={classes.paper}>
            {!claimedShipment ? (
              <LoadingCircle />
            ) : (
              <>
                <Grid container>
                  <Grid item xs={9}>
                    Shipment#: <i>{claimedShipment.shipment_number}</i>
                  </Grid>
                  <Grid item xs={12}>
                    Pick-up:{" "}
                    {cardActive
                      ? claimedShipment.pickup_address
                      : claimedShipment.pickup_city}
                  </Grid>
                  <Grid item xs={12}>
                    Delivery:{" "}
                    {cardActive
                      ? claimedShipment.deliver_address
                      : claimedShipment.deliver_city}
                  </Grid>
                  <Grid item xs={12}>
                    Phone:{" "}
                  </Grid>
                  <ShipmentOptionsLayout
                    optionList={claimedShipment.option_list}
                  />
                  <Grid container item xs={12} className={classes.blocks}>
                    <Grid item xs={12}>
                      <img
                        className={classes.img}
                        src={packageInfo.cover_image}
                        alt="package"
                      ></img>
                    </Grid>
                    <Grid item xs={6}>
                      {packageInfo.item_name}
                    </Grid>
                    <Grid item xs={6}>
                      ${packageInfo.unit_price}
                    </Grid>
                    <Grid item xs={12}>
                      length: {packageInfo.length}'' x width:{" "}
                      {packageInfo.width}''x height: {packageInfo.height}'',{" "}
                      {packageInfo.weight}lb
                    </Grid>
                    <Grid item xs={12}>
                      {packageInfo.shipment_type} x{" "}
                      {claimedShipment.tracking_number_count}
                    </Grid>
                  </Grid>
                  <div className={classes.trackingNumbersWrapper}>
                    <Typography variant="body1">
                      <b>Tracking Number(s):</b>
                    </Typography>
                    <div className={classes.trackingNumbersList}>
                      {claimedShipment?.tracking_numbers?.data &&
                        claimedShipment.tracking_numbers.data.map(
                          (t, index) => {
                            return <li key={index}>{t.tracking_number}</li>;
                          }
                        )}
                    </div>
                  </div>
                </Grid>
              </>
            )}
          </Paper>
          <Grid
            container
            item
            xs={12}
            style={{
              display: "flex",
              "justify-content": "center",
              marginTop: "8px",
            }}
          >
            <Grid item>
              <Box mr={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleLoadShipment}
                >
                  Pickup shipment
                </Button>
              </Box>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => handleCancelClaimClicked()}
              >
                Cancel Claim
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <LoadingCircle />
      )}
      {
        <>
          <Backdrop
            className={classes.backDrop}
            open={openBackDrop}
            onClick={() => handleClickBackDrop()}
          ></Backdrop>
          <Dialog
            open={openDialog}
            onClose={() => handleDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Unclaim shipment"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Unclaim shipment with shipment number below?
                <div>
                  <b>
                    <i>{`${id}`}</i>
                  </b>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmUnclaim} variant="contained">
                Yes
              </Button>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openErrorDialog}
            onClose={() => handleErrorDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Attention"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <b>
                    <i>{`${id}`}</i>
                  </b>
                </div>
                <div>
                  <Typography>{errorMessage}</Typography>
                </div>
                {fineAmount ? (
                  <>
                    <Typography>
                      <b>
                        To unclaim this shipment you will be change a fine of $
                        {fineAmount}.
                      </b>
                    </Typography>
                  </>
                ) : (
                  ""
                )}
                <Typography>
                  <b>Continue unclaim this shipment?</b>
                </Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmClaim} variant="contained">
                Confirm
              </Button>
              <Button
                onClick={handleCancel}
                variant="contained"
                color="primary"
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={openSuccessDialog}
            onClose={() => handleSuccessDialogOnClose()}
            fullWidth
          >
            <DialogTitle>{"Message"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <Typography>{`${feedbackMessage}`}</Typography>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={handleSuccessConfirm}>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
};

export default ClaimedShipmentDetailPage;

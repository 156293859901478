import {
  DRIVER_UPLOAD_IMAGES,
  DRIVER_REMOVE_IMAGES,
  DRIVER_CLEAR_IMAGES,
} from "../actions/types";
import produce from "immer";
const initial = {
  loadShipments: [],
  deliverShipments: [],
};

export const driverUploadImagesReducer = (state = initial, action) => {
  switch (action.type) {
    case DRIVER_UPLOAD_IMAGES:
      if (action.target === "load") {
        return produce(state, draft => {
          draft.loadShipments.push(...action.payload);
        });
      } else if (action.target === "deliver") {
        return produce(state, draft => {
          draft.deliverShipments.push(...action.payload);
        });
      }
      break;
    case DRIVER_REMOVE_IMAGES:
      if (action.target === "load") {
        return produce(state, draft => {
          draft.loadShipments.splice(action.payload, 1);
        });
      } else if (action.target === "deliver") {
        return produce(state, draft => {
          draft.deliverShipments.splice(action.payload, 1);
        });
      }
      break;
    case DRIVER_CLEAR_IMAGES:
      if (action.target === "load") {
        return produce(state, draft => {
          draft.loadShipments.splice(0, state.loadShipments.length);
        });
      } else if (action.target === "deliver") {
        return produce(state, draft => {
          draft.deliverShipments.splice(0, state.loadShipments.length);
        });
      }
      break;
    default:
      return state;
  }
};

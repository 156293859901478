import React, { useState } from "react";
import {
  TextField,
  Typography,
  Box,
  Dialog,
  Button,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import PropTypes from "prop-types";
// import { useStateValue } from "../../stateContext";
import { makeStyles } from "@material-ui/core/styles";
import ClearIcon from "@material-ui/icons/Clear";
import { ThumbUp } from "@material-ui/icons";
// import ImageUploader from "react-images-upload";
import axios from "axios";
import { useDispatch } from "react-redux";

import { getHeaders } from "../../../Utils/fetchUtil";
import { BACKEND_LICENSE_API } from "../../../Utils/apiUrl";
import { getUserProfile } from "../../../slices";

const LicenseInfoForm = ({ dialogOpen, setDialogOpen, email, update }) => {
  // const [{ formValues }, dispatch] = useStateValue();

  const classes = useStyles();
  const dispatch = useDispatch();

  const [success, setSuccessState] = useState(false);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [licenseNumber, setLicenseNumber] = useState("");
  const [expirationDate, setExpirationDate] = useState("");

  const [fileSrc, setFileSrc] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const handleUploadFile = (e) => {
    if (!(e.target.files && e.target.files[0])) return;
    setImgFile(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = function () {
      setFileSrc(reader.result);
    };
    reader?.readAsDataURL(e?.target?.files[0]);
    e.target.value = "";
  };

  const onSubmit = () => {
    setLoading(true);
    const bodyFormData = new FormData();
    bodyFormData.append("file", imgFile);
    bodyFormData.append("legal", name);
    bodyFormData.append("license", licenseNumber);
    if (expirationDate) bodyFormData.append("expiry", expirationDate);
    update && bodyFormData.append("_method", "PUT");
    axios({
      method: "post",
      url: BACKEND_LICENSE_API(email),
      data: bodyFormData,
      headers: {
        ...getHeaders(),
        "Content-Type": "multipart/form-data;",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        setSuccessState(true);
        dispatch(getUserProfile(true));
        setLoading(false);
      })
      .catch((err) => {
        // errHandling(error, setDeleteImageErrMsg);
        setLoading(false);
        if (err.response) {
          // Request made and server responded
          console.log(err.response.data.message);
        } else if (err.request) {
          // The request was made but no response was received
          console.log(err.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log(err.message);
        }
      });
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={dialogOpen}
      onClose={() => setDialogOpen(false)}
    >
      <Box m={2}>
        <Box className={classes.dialogTitle}>
          <Typography variant="h6">License Information</Typography>
          <IconButton
            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => {
              //   setReload(true);
              setDialogOpen(false);
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
        {success ? (
          <Box display="flex" justifyContent="center">
            <Box>
              <Box maxWidth="300px">
                <ThumbUp style={{ fontSize: "20pt" }} />
                {` Successfully updated the license information!`}
              </Box>
              <Box display="flex" justifyContent="center" mb={2} mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setDialogOpen(false)}
                  className={`${classes.button} ${classes.fixWidthButton}`}
                >
                  Close
                </Button>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box display="flex" justifyContent="center">
            <Box maxWidth="300px">
              <Box mb={3}>
                <TextField
                  label="Legal Name"
                  name="legal name"
                  variant="outlined"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  fullWidth
                />
              </Box>
              <Box mb={3}>
                <TextField
                  label="License Number"
                  name="license number"
                  variant="outlined"
                  type="number"
                  value={licenseNumber}
                  onChange={(e) => setLicenseNumber(e.target.value)}
                  required
                  fullWidth
                />
              </Box>
              <Box mb={3}>
                <TextField
                  label="Expiration Date"
                  name="expire date"
                  variant="outlined"
                  value={expirationDate}
                  onChange={(e) => setExpirationDate(e.target.value)}
                  fullWidth
                />
              </Box>
              <Box className={classes.uploaderWrapper}>
                <Box>
                  <input
                    accept="image/png, image/jpeg"
                    className={classes.input}
                    style={{ display: "none" }}
                    id="raised-button-file"
                    multiple
                    type="file"
                    onChange={handleUploadFile}
                  />
                  <label htmlFor="raised-button-file">
                    <Button
                      variant="contained"
                      component="span"
                      color="primary"
                      className={classes.button}
                    >
                      Upload Image *
                    </Button>
                  </label>
                  {fileSrc && (
                    <IconButton
                      // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                      style={{ fill: "green", marginLeft: "12px" }}
                      onClick={() => {
                        setFileSrc(null);
                        setImgFile(null);
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </Box>
                {fileSrc && (
                  <Box
                    height="120px"
                    width="120px"
                    position="relative"
                    ml={1}
                    mr={2}
                  >
                    <img
                      src={fileSrc}
                      alt="License"
                      className={classes.pkgImg}
                    />
                  </Box>
                )}
              </Box>
              <Box display="flex" justifyContent="center" mb={2} mt={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setDialogOpen(false);
                  }}
                  className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  onClick={onSubmit}
                  className={`${classes.button} ${classes.fixWidthButton}`}
                >
                  {loading ? (
                    <CircularProgress size={24} style={{ color: "black" }} />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    fontSize: "12pt",
    backgroundColor: "#ffa726",
    // color: "white",
    margin: "7px",
  },
  fixWidthButton: {
    width: "7rem",
  },
  outLinedButton: {
    fontSize: "12pt",
    margin: "7px",
    marginRight: "24px",
  },
  imgButton: {
    backgroundColor: "#fff",
    border: "solid 2px #e6e6e6",
    padding: "16px 25px",
    borderRadius: "30px",
    fontSize: "17px",
    lineHeight: "1.24",
    marginBottom: "10px",
  },
  imgInput: {
    opacity: "0",
    zIndex: "-1",
    position: "absolute",
  },
  uploaderWrapper: {
    border: "1px solid rgba(0,0,0,0.2)",
    borderRadius: 5,
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  dialogTitle: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
}));

LicenseInfoForm.propTypes = {
  dialogOpen: PropTypes.bool.isRequired,
  setDialogOpen: PropTypes.func.isRequired,
};

export default LicenseInfoForm;

import { DRIVER_ADD_SHIPMENTS_TO_SUMMARY, DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY, DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY,
 DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY } from "../actions/types";

const initialState = {
  driver_claimed_shipments:[]
}

const driver_claimShipmentsReducer = (state = initialState, action) => {
  switch(action.type){
    case DRIVER_ADD_SHIPMENTS_TO_SUMMARY:
      const {shipmentId, packageId, count, itemName, unitPrice, availableCount, shipmentType, weight} = action.payload
      if(state.driver_claimed_shipments.length > 0){
        const index = state.driver_claimed_shipments.findIndex(item => item.shipment_number === shipmentId && item.package_number === packageId)
        state.driver_claimed_shipments[index] = {
          item_name: itemName,
          shipment_number: shipmentId,
          package_number: packageId,
          count: count,
          unit_price: unitPrice,
          availableCount: availableCount,
          shipmentType: shipmentType,
          weight: weight
        }
        if(index !== -1){
          //found
          if(count === 0){
            return{
              ...state,
              driver_claimed_shipments: [...state.driver_claimed_shipments.slice(0,index), ...state.driver_claimed_shipments.slice(index+1)]
            }
          }else{
            return {
              ...state,
              driver_claimed_shipments: [
                ...state.driver_claimed_shipments.slice(0,index),
                {
                  ...state.driver_claimed_shipments[index],
                  count: count
                },
                ...state.driver_claimed_shipments.slice(index + 1)
              ]
            }
          }
          
        }else{
          //not found
          if(count === 0){
            return { ...state }
          }else{
            return {
              ...state,
              driver_claimed_shipments:[...state.driver_claimed_shipments, {shipment_number: shipmentId, package_number:packageId, 
                count:count, item_name: itemName, unit_price: unitPrice}]
            }
          }
          
        }
      }else if(count === 0){
        return {
          ...state
        }
      }else{
        return{
          ...state,
          driver_claimed_shipments:[{shipment_number: shipmentId, package_number: packageId, count: count, item_name: itemName,
          unit_price: unitPrice, availableCount: availableCount, shipmentType: shipmentType, weight: weight}]
        }
      }
    case DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY:
      return {

      }
    case DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY:
      return {

      }
    case DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY:
      return {
        driver_claimed_shipments:[]
      }
    default:
      return state
  }
}

export default driver_claimShipmentsReducer
import React, { useRef } from "react";
import { Redirect, Route } from "react-router-dom";
import { getAccessToken } from "../Utils/doToken";
import { useSelector } from "react-redux";

const SafeRouter = ({ component: Component, path: Path, ...rest }) => {
  const toDriverPages = Path.split("/")[1] === "driver";
  //check if credit card is actived before access to pages
  const toDetailPage = useRef(false);
  const checkedCreditCard = useRef(false);
  const cardActive = useSelector(state => {
    if (state.user.activeCard !== null) {
      checkedCreditCard.current = true;
      return state.user.activeCard === "active";
    }
  });

  const isDriver = useSelector(state => {
    return state.user.role === "Driver";
  });
  const isEndUser = useSelector(state => {
    return state.user.role === "End User";
  });

  //access to detail pages
  if (
    Path === "/driver/available_shipments/:id" ||
    Path === "/driver/claimed_shipments/:id" ||
    Path === "/driver/loaded_shipments/:id" ||
    Path === "/driver/delivered_shipments/:id"
  ) {
    toDetailPage.current = true;
  } else {
    //Not accessing to any of the detail pages
    toDetailPage.current = false;
  }
  // useEffect(() => {
  //   console.log('Path is ', Path)

  //check if user binded with credit card
  //not necseeary to call API every time trying to access to the detail pages
  // axios.get(BACKEND_ME_API,  { headers: { Authorization: `Bearer ${getAccessToken('access_token')}` } })
  // .then(res => {
  //   if(res.data.card === "inactive"){
  //   console.log('credit card inactive.')
  //     setBindedWithCreditCard(false)
  //   }else{
  //   console.log('credit card active.')
  //     setBindedWithCreditCard(true)
  //   }
  //   setCheckBind(true)
  // })
  //chech if credit card is active from the redux state

  // }, [Path])

  return (
    <>
      {toDriverPages ? (
        //to driver pages
        isDriver ? (
          // to driver pages
          // is driver
          toDetailPage.current ? (
            //to driver pages
            //is driver
            //to detail page
            checkedCreditCard.current && (
              <Route
                {...rest}
                path={Path}
                render={props =>
                  Boolean(getAccessToken("access_token")) ? (
                    //check if binded with credit card
                    cardActive ? (
                      <Component {...props} />
                    ) : (
                      <Redirect
                        to={{
                          pathname: "/profile/add-card",
                        }}
                      />
                    )
                  ) : (
                    //does not have access token
                    <Redirect
                      to={{
                        pathname: "/sign-in",
                        state: { from: props.location },
                      }}
                    />
                  )
                }
              />
            )
          ) : (
            //to driver pages
            //is driver
            //Not going to any detail pages
            <Route
              {...rest}
              path={Path}
              render={props =>
                Boolean(getAccessToken("access_token")) ? (
                  //Not accessing to detial page, act like normal routing
                  <Component {...props} />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/sign-in",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          )
        ) : (
          //to driver pages
          //not a driver
          //redirect to user loading page

          isEndUser && (
            <Route
              {...rest}
              path={Path}
              render={props =>
                Boolean(getAccessToken("access_token")) ? (
                  //Not accessing to detial page, act like normal routing
                  <Redirect
                    to={{
                      pathname: "/",
                      state: { from: props.location },
                    }}
                  />
                ) : (
                  <Redirect
                    to={{
                      pathname: "/sign-in",
                      state: { from: props.location },
                    }}
                  />
                )
              }
            />
          )
        )
      ) : (
        //not to driver pages
        <Route
          {...rest}
          path={Path}
          render={props =>
            Boolean(getAccessToken("access_token")) ? (
              //act like normal routing, but check if have access token
              <Component {...props} />
            ) : (
              //no access token, require sign-in
              <Redirect
                to={{
                  pathname: "/sign-in",
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )}
    </>
  );
};
export default SafeRouter;

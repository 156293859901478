import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
// import Slide from 'react-reveal';
// import "../styles/styles.css";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import BusinessIcon from "@material-ui/icons/Business";
import { Alert, Pagination } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";

import axios from "axios";

import { redirectOrderList } from "../../Utils/redirect";
import {
  BACKEND_GET_ORDERS_API,
  BACKEND_CLAIMED_SHIPMENTS_API,
} from "../../Utils/apiUrl";
import { getHeaders, getURLParams, errHandling } from "../../Utils/fetchUtil";
import LoadingContainer from "../shared/loadingContainer";
// import { addLatlng } from "../../slices";
import { getAccessToken } from "../../Utils/doToken";
import EstimateForm from "./Components/estimateForm";
import {
  USER_CURRENT_ADDRESS_COOKIE_NAME,
  USER_CURRENT_ADDRESS_COOKIE_TIME,
} from "../../Utils/config";
import { provinces } from "../shared";

import ConfirmationCodesInputLayout from "../Layouts/ConfirmationCodesInputLayout";

import {
  Box,
  Container,
  Card,
  useMediaQuery,
  Button,
  Divider,
  Dialog,
  IconButton,
} from "@material-ui/core";

const LandingPage = () => {
  // const token = getAccessToken("access_token");

  // const dispatch = useDispatch();
  const { firstname, signIn, email } = useSelector((state) => state.user);
  const history = useHistory();
  const classes = useStyles();

  const [lastPage, setLastPage] = useState(0);
  const [latestShippingLoading, setLastestShippingLoading] = useState(true);
  const [claimedShipmentsLoading, setClaimedShipmentsLoading] = useState(true);
  const [latestOrderData, setLatestOrderData] = useState(null);
  const [claimedShipments, setClaimedShipments] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [claimedErrorMsg, setClaimedErrorMsg] = useState("");
  const [page, setPage] = useState(1);
  const [initCity, setInitCity] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);

  const [estimateItem, setEstimateItem] = useState({
    pickupCity: "",
    pickupProvince: "British Columbia",
    deliverCity: "",
    deliverProvince: "British Columbia",
    quantity: null,
    selectedTypeID: "",
  });
  const [estimateErrMsg, setEstimateErrMsg] = useState("");

  const matches = useMediaQuery("(max-width:600px)");

  const matchesMid = useMediaQuery("(max-width:550px)");

  useEffect(() => {
    const setCookie = (name, value) => {
      let date = new Date();
      date.setTime(date.getTime() + USER_CURRENT_ADDRESS_COOKIE_TIME);
      const expires = "expires=" + date.toUTCString();
      document.cookie =
        name + "=" + JSON.stringify(value) + "; " + expires + "; path=/";
    };

    const current_address_cache = getAccessToken(
      USER_CURRENT_ADDRESS_COOKIE_NAME
    );

    const user_not_input =
      estimateItem.pickupCity === "" &&
      estimateItem.pickupProvince === "British Columbia";

    if (window.navigator.geolocation && !current_address_cache) {
      window.navigator.geolocation.getCurrentPosition(function (position) {
        // dispatch(
        //   addLatlng({
        //     lat: position.coords.latitude,
        //     lng: position.coords.longitude,
        //   })
        // );
        axios
          .get(
            `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ&result_type=street_address`
          )
          .then((res) => {
            if (res.data?.results.length > 0) {
              const fullAddress = res.data.results[0].formatted_address;
              const [street, city, provinceCombo] = fullAddress.split(", ");
              const province = provinceCombo.split(" ")[0];
              const postal = provinceCombo.substr(
                provinceCombo.indexOf(" ") + 1
              );
              setCookie(USER_CURRENT_ADDRESS_COOKIE_NAME, {
                street,
                city,
                province,
                postal,
              });
              setInitCity({ city });
              setEstimateItem({
                ...estimateItem,
                pickupCity: city,
                pickupProvince: provinces.find((ele) => ele.value === province)
                  .label,
              });
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      });
    }

    if (current_address_cache && user_not_input) {
      const { city, province } = JSON.parse(current_address_cache);
      setInitCity({ city });
      setEstimateItem({
        ...estimateItem,
        pickupCity: city,
        pickupProvince: provinces.find((ele) => ele.value === province).label,
      });
    }
  }, []);

  useEffect(() => {
    if (signIn) {
      setLastestShippingLoading(true);

      axios
        .get(
          getURLParams(BACKEND_GET_ORDERS_API, {
            page: 1,
            status: ["Active"],
          }),
          { headers: getHeaders() }
        )
        .then((response) => {
          // response.data.data && dispatch(setOrders({orders: response.data.data}));
          if (response.data.data && response.data.data[0])
            setLatestOrderData(response.data.data[0]);
          // setLoadingState(false);
          setLastestShippingLoading(false);
        })
        .catch((err) => {
          setLastestShippingLoading(false);
          errHandling(err, setErrorMsg);
        });
    }
  }, [signIn]);

  useEffect(() => {
    if (signIn) {
      setClaimedShipmentsLoading(true);

      axios
        .get(
          getURLParams(BACKEND_CLAIMED_SHIPMENTS_API, {
            sender_email: email,
            page: page,
            shipping_status: "claimed",
            per_page: 5,
          }),
          { headers: getHeaders() }
        )
        .then((response) => {
          // response.data.data && dispatch(setOrders({orders: response.data.data}));
          if (response.data.data) setClaimedShipments(response.data.data);
          // setLoadingState(false);
          setLastPage(response.data.last_page);
          setClaimedShipmentsLoading(false);
        })
        .catch((err) => {
          setClaimedShipmentsLoading(false);
          errHandling(err, setClaimedErrorMsg);
        });
    }
  }, [signIn, page, email]);

  const goOrderDetailPage = (orderNumber, pkgSection = false) => {
    let path = pkgSection
      ? `/order-details/${orderNumber}#packageSection`
      : `/order-details/${orderNumber}`;
    // dispatch(selectOrder({selected_order_number: orderNumber}))
    history.push(path);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  let lastestItem = <LoadingContainer />;

  if (!latestShippingLoading)
    lastestItem = (
      <Box fontSize="1.5rem" m={1} textAlign="center">
        No latest order found
      </Box>
    );

  if (!latestShippingLoading && latestOrderData)
    lastestItem = (
      <>
        <Box
          className="order-list-text"
          display="flex"
          // justifyContent={matches ? 'space-between' : 'flex-start'}
          justifyContent="space-between"
          m={1}
        >
          {/* <Box pr={1}><img alt={`order-${order.id}`} /></Box> */}
          <Box textAlign="left">
            <Box component="span" pl={1}>
              Order Number:{" "}
            </Box>
            {matches ? <br /> : ""}
            <Button
              variant="text"
              onClick={() => goOrderDetailPage(latestOrderData.order_number)}
              className={classes.linkText}
            >
              {latestOrderData.order_number}
            </Button>
          </Box>
          <Box pl={1} pr={1} textAlign="center" flexGrow={matches ? "0" : "1"}>
            Status: {matches ? <br /> : ""}
            <span style={{ fontStyle: "italic" }}>
              {latestOrderData.status}
            </span>
          </Box>
        </Box>
        <Box m={1}>
          {latestOrderData.packages.map((item) => {
            return (
              <Box className={classes.pkgWrapper} key={item.package_number}>
                <Box
                  className={classes.cardContent}
                  display="flex"
                  alignItems="center"
                  width="100%"
                  role="button"
                  onClick={() =>
                    goOrderDetailPage(latestOrderData.order_number, true)
                  }
                >
                  <Box
                    height="80px"
                    width="80px"
                    position="relative"
                    ml={1}
                    mr={2}
                  >
                    <img
                      src={item.cover_image}
                      alt={`order-${latestOrderData.id}`}
                      className={classes.pkgImg}
                    />
                  </Box>
                  <Box color="seagreen" textAlign="center" flexGrow="1">
                    {item.item_name}
                  </Box>
                  <Box textAlign="center" flexGrow="1">
                    Status: {matches ? <br /> : ""}
                    <span style={{ fontStyle: "italic" }}>{item.status}</span>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      </>
    );

  const pageSelection = lastPage > 1 && (
    <div className={classes.pagination}>
      <Pagination
        className={classes.pagination}
        count={lastPage}
        page={page}
        variant="outlined"
        shape="rounded"
        onChange={handlePageChange}
      />
    </div>
  );

  const comingPickup = (claimedShipments) => {
    let lastestItem = <LoadingContainer />;

    if (!claimedShipmentsLoading && claimedShipments.length === 0)
      lastestItem = (
        <Box fontSize="1.5rem" m={1} textAlign="center">
          No claimed shipment found
        </Box>
      );

    if (!claimedShipmentsLoading && claimedShipments.length !== 0)
      lastestItem = (
        <>
          {claimedShipments.map((item) => {
            return (
              // <Card
              //   classes={{ root: classes.claimRoot }}
              //   key={item.shipment_number}
              // >
              <Box
                className={`${classes.pkgWrapper} ${classes.claimRoot}`}
                key={item.shipment_number}
              >
                <Box
                  className={classes.cardContent}
                  display="flex"
                  alignItems="center"
                  pl={2}
                  width="100%"
                  role="button"
                  onClick={() => goOrderDetailPage(item.order_number, true)}
                >
                  <Box mr={2}>
                    <LocalShippingIcon />
                  </Box>
                  <Box display="flex" flexDirection="column" flexGrow="1">
                    <Box color="seagreen" textAlign="center" mb={1}>
                      {item.shipment_number}
                    </Box>
                    <Box textAlign="center" display="flex">
                      <Box component="span" flexBasis="60%" fontWeight="700">
                        {item.item_name}
                      </Box>
                      <span style={{ fontStyle: "italic" }}>
                        {getDate(item.schedule_pickup)}
                      </span>
                    </Box>
                  </Box>
                </Box>
              </Box>
              // </Card>
            );
          })}
        </>
      );
    return lastestItem;
  };

  return (
    <>
      {!signIn ? (
        <Box className={classes.signInWrapper}>
          <Box>
            <div className={classes.jumboStyle}>
              <h1>Welcome to UUCargo</h1>
            </div>
            <Box ml={1} mr={1}>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                mb={2}
              >
                <BusinessIcon />
                <Box component="span" pl={1}>
                  Free estimate shipping costs
                </Box>
              </Box>
              <EstimateForm
                item={estimateItem}
                changeItem={setEstimateItem}
                errorMsg={estimateErrMsg}
                setErrorMsg={setEstimateErrMsg}
                initPickupCity={initCity}
              />
            </Box>
            <Box m={1} mt={2} mb={4}>
              <Divider style={{ color: "black" }} />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/cargo-control-tracking/query");
                }}
              >
                <Box display="flex">
                  <Box ml={1}>
                    <FindInPageIcon />
                  </Box>
                  <Box ml={1} fontSize="0.9rem" textAlign="center">
                    Cargo Control Tracking
                  </Box>
                </Box>
              </Button>
            </Box>
            <Box m={1} mt={2} mb={4}>
              <Divider style={{ color: "black" }} />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  history.push("/sign-in");
                }}
                style={{ width: "14rem" }}
              >
                Sign in
              </Button>
            </Box>
            <Box textAlign="center" mt={3}>
              Don't have an Account? <Link to="/sign-up">Sign up</Link>
            </Box>
          </Box>
        </Box>
      ) : (
        <Container>
          <h1 className={classes.title}>Hi {firstname},</h1>
          <Box display="flex">
            <Box
              className={classes.root}
              mr={4}
              role="button"
              onClick={() => history.push("/shipping/start-a-quote")}
            >
              <Box
                display="flex"
                justifyContent="center"
                height="100%"
                alignItems="center"
              >
                {/* <Link to = "/shipping/start-a-quote">Start A Quote</Link> */}
                <LocalShippingIcon />
                Start A Quote
              </Box>
            </Box>
            <Box
              className={classes.root}
              role="button"
              onClick={() => redirectOrderList(history)}
            >
              <Box
                display="flex"
                justifyContent="center"
                height="100%"
                alignItems="center"
              >
                {/* <Link to = "/my-orders">My Orders</Link> */}
                <ListAltIcon />
                My Orders
              </Box>
            </Box>
            {!matchesMid && (
              <Box
                className={classes.root}
                ml={4}
                role="button"
                onClick={() => history.push("/cargo-control-tracking/query")}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  height="100%"
                  alignItems="center"
                >
                  <Box ml={1}>
                    <FindInPageIcon />
                  </Box>
                  <Box ml={1}>Cargo Control Tracking</Box>
                </Box>
              </Box>
            )}
          </Box>
          {matchesMid && (
            <Box
              className={classes.root}
              mt={2}
              role="button"
              onClick={() => history.push("/cargo-control-tracking/query")}
            >
              <Box
                display="flex"
                justifyContent="center"
                height="100%"
                alignItems="center"
              >
                <Box ml={1}>
                  <FindInPageIcon />
                </Box>
                <Box ml={1}>Cargo Control Tracking</Box>
              </Box>
            </Box>
          )}
          <hr />
          <Box>
            <Box fontSize="1.5rem" mt={1}>
              Latest shipping order:
            </Box>
            <Card classes={{ root: classes.lgRoot }}>
              {errorMsg ? (
                <Box pt={1} width="100%">
                  <Alert severity="error">{errorMsg}</Alert>
                </Box>
              ) : (
                ""
              )}
              {lastestItem}
            </Card>
          </Box>
          <hr />
          <Box>
            <Box fontSize="1.5rem" mt={1}>
              Coming to pick up:
            </Box>

            {claimedErrorMsg ? (
              <Card classes={{ root: classes.smRoot }}>
                <Box pt={1} width="100%">
                  <Alert severity="error">{claimedErrorMsg}</Alert>
                </Box>
              </Card>
            ) : (
              ""
            )}
            {!claimedErrorMsg && comingPickup(claimedShipments)}
            {pageSelection}
          </Box>
        </Container>
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem"></Box>
          <IconButton
            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => {
              setDialogOpen(false);
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <ConfirmationCodesInputLayout />
        <Box display="flex" alignItems="center" justifyContent="center" m={2}>
          <Box display="flex">
            <Box mr={4}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setDialogOpen(false);
                }}
                className={`${classes.button} ${classes.fixWidthButton}`}
                style={{
                  textTransform: "unset",
                }}
              >
                Cancel
              </Button>
            </Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(false);
              }}
              className={`${classes.button} ${classes.fixWidthButton}`}
              style={{
                textTransform: "unset",
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
  },
  root: {
    width: 150,
    height: "6rem",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  guestRoot: {
    width: 160,
    height: "4.5rem",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  lgRoot: {
    minHeight: 200,
    margin: 10,
    marginLeft: 0,
    marginTop: 10,
    // width: '90%',
    width: "100%",
  },
  smRoot: {
    minHeight: 100,
    margin: 10,
    marginLeft: 0,
    marginTop: 10,
    // width: '90%',
    width: "100%",
  },
  claimRoot: {
    // minHeight: 70,
    margin: 10,
    marginLeft: 0,
    marginTop: 10,
    // width: '90%',
    width: "100%",
    display: "flex",
    alignItems: "center",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      // border: '1px solid rgba(0,0,0,0.3)',
      backgroundColor: "rgba(0,0,0,0.1)",
      borderRadius: "5px",
    },
  },
  orderNum: {
    fontSize: "1rem",
    color: "seagreen",
  },
  jumboStyle: {
    color: "green",
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
  },
  signInWrapper: {
    height: "90vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    overflow: "hidden",
    // maxheight: "-webkit-fill-available",
  },
  linkText: {
    fontSize: "1rem !important",
    color: "blue",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  card: {
    width: "96%",
    maxWidth: "650px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    },
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
}));

export default LandingPage;

import React, {useState} from 'react'
import { Paper, Tabs, Tab, makeStyles } from '@material-ui/core'
const useStyles = makeStyles({
  root:{
    width:'50%'
  }
})
const ListAndMapTabsLayout = ({listComponent, mapComponent}) => {
  const classes = useStyles()
  const [tabValue, setTabValue] = useState(0)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <>
    <Paper className={classes.root}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="List" />
        <Tab label="Map" />
      </Tabs>
    </Paper>
    {
      tabValue === 0 ? listComponent : mapComponent
    }
    </>
  )
}

export default ListAndMapTabsLayout
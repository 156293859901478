import React, {useState} from 'react'
import { Typography } from '@material-ui/core'
import ClaimedShipmentsList from './ClaimedShipmentsList'
import SearchFilter from './SearchFilter'


const ClaimedShipmentsMainPage = () => {
  const [displayFilter, setDisplayFilter] = useState(false)

  const showHideSearchFilter = (display) => {
    if(!displayFilter && display){
      setDisplayFilter(true)
    }else{
      setDisplayFilter(false)
    }
  }



  return (
    <div className='shipment-list-main-page'>
    <Typography variant='h6' align='center'>Shipments Overview</Typography>
    <ClaimedShipmentsList />
    {/* <button className='filter-button'onClick={showHideSearchFilter}>Filter</button> */}
    {
      displayFilter ? <SearchFilter display={showHideSearchFilter}/> : ''
    }
    </div>
  )
}

export default ClaimedShipmentsMainPage
import React, { useState, useCallback } from "react";
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import {
  Grid,
  Chip,
  Typography,
  Button,
  makeStyles,
  Divider,
} from "@material-ui/core";
import { throttle } from "lodash";

const useStyles = makeStyles(theme => ({
  margins: {
    margin: "5px auto",
  },
  centeringContent: {
    display: "grid",
    placeItems: "center",
  },
  scannedNumbersWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    flexWrap: "wrap",
    background: "#ffffff",
    padding: "10px",
    border: "2px solid #D3D3D3",
    borderRadius: "5px",
    width: "98%",
    maxWidth: "750px",
    margin: "5px auto",
  },
  resultWrapper: {},
}));

const ScanBarcode = ({ onGetBarcode }) => {
  let nothingDetectedTimer = null;
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [feedback, setFeedback] = useState("");
  const [scannedNumbers, setScannedNumbers] = useState([]);
  const [openCamera, setOpenCamera] = useState(true);

  const handleDelete = num => {
    setScannedNumbers(preNums => preNums.filter(n => n !== num));
  };

  const scanResult = (error, result) => {
    if (typeof result !== "undefined") {
      setFeedback("");
      if (nothingDetectedTimer !== null) {
        clearInterval(nothingDetectedTimer);
        nothingDetectedTimer = null;
      }
      console.log("scanned result", result.text);
      throttle(
        setScannedNumbers(preState => {
          return [...new Set([...preState, result.text])];
        }),
        700
      );
    } else {
      if (nothingDetectedTimer === null) {
        nothingDetectedTimer = setInterval(() => {
          setFeedback(
            "No object is detected, please adjust camera angle to scan the barcode."
          );
        }, 1000 * 5);
      }
    }
  };

  return (
    <>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12}>
          {openCamera ? (
            <BarcodeScannerComponent
              width={350}
              height={350}
              onUpdate={(error, result) => scanResult(error, result)}
            />
          ) : (
            <Button variant="contained" onClick={() => setOpenCamera(true)}>
              Retry Scanning
            </Button>
          )}
        </Grid>
        <Grid item xs={12}>
          <p>{feedback}</p>
        </Grid>
        {scannedNumbers.length > 0 && (
          <Grid
            item
            container
            xs={12}
            direction="column"
            className={classes.resultWrapper}
          >
            <Typography align="left" color="black" variant="h6">
              Scanned Tracking Number(s):
            </Typography>
            <div className={classes.scannedNumbersWrapper}>
              {scannedNumbers.map(num => {
                return (
                  <Chip
                    variant="outlined"
                    size="medium"
                    color="primary"
                    label={num}
                    onDelete={() => handleDelete(num)}
                    key={num}
                  />
                );
              })}
            </div>
            <Grid item xs={12} className={classes.centeringContent}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  onGetBarcode(scannedNumbers);
                  setOpenCamera(false);
                }}
                className={classes.scanButton}
              >
                Check
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default ScanBarcode;

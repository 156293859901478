import React from "react";

import { Route, Switch, Redirect } from "react-router-dom";

import UserProfile from "../components/Profiles/userProfile";
import AddCreditCardPage from "../components/Profiles/addCreditCardPage";
import {
  // UserSignUpPage,
  UserSignInPage,
  ActiveResultPage,
  // JoinCompany,
  ReceiveEmailPage,
  ResetPasswordPage,
} from "../components/Auth";

// import ActiveResultPage from './components/Auth/ActiveResultPage/activeResultPage';
import ForgotPasswordPage from "../components/Auth/forgotPasswordPage";
import ChangePasswordPage from "../components/Auth/changePassword";
// import ReceiveEmailPage from './components/Auth/receiveEmail';
import LandingPage from "../components/LandingPage/landingPage";
import GuestCheckout from "../components/Shipping/guestCheckoutPage";
import UserSettings from "../components/userSettingsPage";
import AddressBook from "../components/AddressBook/addressBookPage";
import AddressInput from "../components/AddressBook/addressInputPage";
// import CompanyProfile from "../components/Profiles/companyProfile";
// import UserSignIn from '../components/Auth/SignIn/userSignIn';
import StartQuote from "../components/Shipping/startQuote";
// import AdditionalServices from "../components/Shipping/servicePickup";
import OrderConfirm from "../components/Shipping/orderConfirmed";
import OrderDetails from "../components/MyOrders/orderDetails";
import ControlNumber from "../components/ControlNumber/controlNumber";
import ShipmentDetails from "../components/ControlNumber/shipmentDetails";
import RequestCancel from "../components/MyOrders/requestCancel";
import OrderList from "../components/MyOrders/orderList";

import DriverLandingPage from "../components/DriverLandingPage";
import ShipmentMainPage from "../components/Shipments/ShipmentMainPage";
import SearchSettingPage from "../components/Shipments/SearchSettingPage";
import ShipmentDetailPage from "../components/Shipments/ShipmentDetailPage";
import ClaimedShipmentsMainPage from "../components/Shipments/ClaimedShipmentsMainPage";
import ClaimedShipmentDetailPage from "../components/Shipments/ClaimedShipmentDetailPage";
import LoadShipmentsPage from "../components/Shipments/LoadShipmentsPage";
import LoadedShipmentsDetailPage from "../components/Shipments/LoadedShipmentsDetailPage";
import DeliverShipmentsPage from "../components/Shipments/DeliverShipmentsPage";
import DeliveredShipmentsDetailPage from "../components/Shipments/DeliveredShipmentsDetailPage";
import useGaTracker from "../components/shared/Hooks/useGaTracker";
import SaftRouter from "../components/safeRouter";
import ClearTokenRouter from "../components/tokenClearRouter";
import UserRouter from "../components/userRouter";
import SettlementsList from "../components/Bookkeeper/settlementsList";
import SettlementDetails from "../components/Bookkeeper/settlementDetails";
import BookkeeperHome from "../components/Bookkeeper/bookkeeperHome";
import ConfirmShipments from "../components/MyOrders/confirmShipments";

const Routes = () => {
  useGaTracker();

  return (
    <Switch>
      <UserRouter exact path="/" landing component={LandingPage} />
      <ClearTokenRouter
        exact
        path={["/sign-in", "/sign-up"]}
        component={UserSignInPage}
      />
      <Route exact path="/email/activate-result" component={ActiveResultPage} />
      <Route exact path="/forgot-password" component={ForgotPasswordPage} />
      <Route exact path="/receive-email" component={ReceiveEmailPage} />
      <Route exact path="/reset_password" component={ResetPasswordPage} />
      <Route exact path="/guest-checkout" component={GuestCheckout} />
      <Route
        exact
        path="/guest-payment"
        render={() => <AddCreditCardPage addCard={false} />}
      />
      <Route
        exact
        path="/guest-order-details/"
        render={() => <OrderDetails guest={true} />}
      />

      <Route
        exact
        path="/driver/sign-in"
        render={() => <UserSignInPage driver={true} />}
      />
      <SaftRouter exact path="/profile" component={UserProfile} />
      <UserRouter exact path="/settings" component={UserSettings} />
      <UserRouter exact path="/address-book" component={AddressBook} />
      <UserRouter exact path="/address-book/add" component={AddressInput} />
      <Route
        exact
        path="/address-book/edit/:id"
        render={() => <AddressInput edit={true} />}
      />
      <Route exact path="/change-password" component={ChangePasswordPage} />
      <UserRouter
        exact
        path="/profile/add-card"
        component={AddCreditCardPage}
      />
      {/* <UserRouter exact path="/join-company" component={JoinCompany} /> */}
      {/* <SaftRouter exact path="/manage-company" component={CompanyProfile} /> */}
      <UserRouter exact path="/shipping/:tab" component={StartQuote} />
      <UserRouter
        exact
        path="/order-confirmed/:number"
        component={OrderConfirm}
      />
      {/* <SaftRouter exact path="/start-a-quote2" component={AdditionalServices} /> */}
      {/* <Route exact path = "/order-confirmed" component = {OrderConfirm}/> */}
      <UserRouter exact path="/cancel-order" component={RequestCancel} />
      <UserRouter exact path="/my-orders/:tab" component={OrderList} />
      <UserRouter exact path="/my-orders">
        <Redirect to="/my-orders/active" />
      </UserRouter>
      <UserRouter
        exact
        path="/confirm-shipments/:state"
        component={ConfirmShipments}
      />
      <UserRouter exact path="/order-details/:id" component={OrderDetails} />
      <Route
        exact
        path="/cargo-control-tracking/query"
        component={ControlNumber}
      />
      <Route
        exact
        path="/cargo-control-tracking/info/:ids"
        component={ShipmentDetails}
      />
      <Route
        exact
        path="/cargo-control-tracking/transactions/:id"
        render={() => <ShipmentDetails transaction={true} />}
      />
      <SaftRouter exact path="/driver" component={DriverLandingPage} />
      <SaftRouter
        exact
        path="/driver/available_shipments"
        component={ShipmentMainPage}
      />
      <SaftRouter
        exact
        path="/driver/search-setting"
        component={SearchSettingPage}
      />
      <SaftRouter
        exact
        path="/driver/available_shipments/:id"
        component={ShipmentDetailPage}
      />
      <SaftRouter
        exact
        path="/driver/claimed_shipments"
        component={ClaimedShipmentsMainPage}
      />
      <SaftRouter
        exact
        path="/driver/claimed_shipments/:id"
        component={ClaimedShipmentDetailPage}
      />
      <SaftRouter
        exact
        path="/driver/delivered_shipments/:id"
        component={DeliveredShipmentsDetailPage}
      />
      <SaftRouter
        exact
        path="/driver/load_shipments"
        component={LoadShipmentsPage}
      />
      <SaftRouter
        exact
        path="/driver/loaded_shipments/:id"
        component={LoadedShipmentsDetailPage}
      />
      <SaftRouter
        exact
        path="/driver/deliver_shipments"
        component={DeliverShipmentsPage}
      />
      <SaftRouter exact path="/driver/bookkeeper" component={BookkeeperHome} />
      <SaftRouter
        exact
        path="/driver/settlements-list"
        component={SettlementsList}
      />
      <SaftRouter
        exact
        path="/driver/settlement-details/:id"
        component={SettlementDetails}
      />
    </Switch>
  );
};

export default Routes;

import React from "react";

const InsuranceIcon = ({
  //   style = {},
  fill = "#000",
  width = "36",
  // className = "",
  //   ariaHidden = true,
  //   viewBox = "0 0 144 142",
}) => (
  //   <svg
  //     width={width}
  //     style={style}
  //     height={width}
  //     viewBox={viewBox}
  //     aria-hidden={ariaHidden}
  //     fill={fill}
  //     className={`svg-icon ${className || ""}`}
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  <svg
    version="1.2"
    baseProfile="tiny"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={width}
    viewBox="0 0 50 50"
    overflow="inherit"
    fill={fill}
  >
    <path d="M25.511 2.892l-24.243 20.108h5.732v24h36v-24h6.268l-23.757-20.108zm5.431 37.108h-11.349l2.38-10.711c-1.439-1-2.38-2.506-2.38-4.35 0-3.038 2.541-5.439 5.674-5.439 3.135 0 5.675 2.493 5.675 5.531 0 1.845-.941 3.245-2.379 4.242l2.379 10.727z" />
  </svg>
);

export default InsuranceIcon;

import { DRIVER_STACK_PACKAGES, DRIVER_FETCHING_PACKAGES, DRIVER_SELECT_PACKAGE } from './types'

export const driverStackPackages = (packages) => {
  return{
    type: DRIVER_STACK_PACKAGES,
    payload: packages
  }
}

export const driverFetchingPackages = (isFetching) => {
  return {
    type: DRIVER_FETCHING_PACKAGES,
    fetching: isFetching
  }
}

export const driverSelectPackage = (packageNumber) => {
  return{
    type: DRIVER_SELECT_PACKAGE,
    selected_package_number: packageNumber
  }
}
import React, { useEffect, useState } from "react";
// import {createMuiTheme } from '@material-ui/core/';
import { Box } from "@material-ui/core";
// import { Paper } from '@material-ui/core'
// import orange from '@material-ui/core/colors/orange';
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Alert } from "@material-ui/lab";

// import Fade from 'react-reveal';
import PropTypes from "prop-types";
import produce from "immer";
import { CustomInput } from "../../shared";
import { shipmentTypesDetails } from "../../shared/constInfo";

// import nextId from "react-id-generator";
// import '../../styles/shippingItem.css';

const shipmentTypes = [
  { label: 'Pallet 48" x 40"', value: "1" },
  { label: 'Pallet 48" x 48"', value: "2" },
  { label: "Oversize Pallet", value: "3" },
  { label: "Overweight Pallet", value: "4" },
  //   { label: "Oversize & Overweight Pallet", value: "5" },
  { label: "Full Truck (5 ton)", value: "5" },
  { label: "Full Trailer (FTL)", value: "6" },
];

const ShippingItemInputStateless = ({
  item,
  errorMsg,
  setErrorMsg,
  small = false,
  changeItem,
  userInputError,
  setUserInputError,
}) => {
  //   const [shippingItem, setShippingItem] = useState(item || defaultShippingItem);
  //   const [selectedType, setSelectedType] = useState(item.shipmentType || "");
  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();

  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  useEffect(() => {
    if (item.typeID) {
      changeItem(
        produce((draft) => {
          draft.length = shipmentTypesDetails[item.typeID].length || 48;
          draft.width = shipmentTypesDetails[item.typeID].width || 48;
          // draft.height = shipmentTypesDetails[item.typeID].height || 72;
          // draft.weight = shipmentTypesDetails[item.typeID].weight || 1500;
          draft.quantity = draft.quantity || null;
          if (item.typeID === "6" || item.typeID === "7") {
            draft.quantity = item.typeID === "6" ? 12 : 26;
          }
        })
      );
    }
  }, [item.typeID, changeItem]);

  useEffect(() => {
    const emptyField =
      item.description === null ||
      item.height === null ||
      item.length === null ||
      item.width === null ||
      item.weight === null ||
      item.quantity === null ||
      item.typeID === "";
    const invalidValue =
      item.description.length < 2 ||
      item.height < 1 ||
      item.weight < 1 ||
      item.quantity < 1 ||
      item.length < 1 ||
      item.width < 1;

    const notNum =
      isNaN(item.height) ||
      isNaN(item.weight) ||
      isNaN(item.length) ||
      isNaN(item.width) ||
      isNaN(item.quantity);

    if (!emptyField && !invalidValue && !notNum) {
      setErrorMsg("");
    }
  }, [item, setErrorMsg]);

  useEffect(() => {
    const weightLimit = item.typeID === "4" ? 1500 : 1200;
    const heightLimit = item.typeID === "3" ? 150 : 72;
    if (item.height > heightLimit) {
      setUserInputError((prev) => {
        return {
          ...prev,
          heightError: true,
        };
      });
    } else if (item.height <= heightLimit) {
      setUserInputError((prev) => {
        return {
          ...prev,
          heightError: false,
        };
      });
    }
    if (item.weight > weightLimit) {
      setUserInputError((prev) => {
        return {
          ...prev,
          weightError: true,
        };
      });
    } else if (item.weight <= weightLimit) {
      setUserInputError((prev) => {
        return {
          ...prev,
          weightError: false,
        };
      });
    }
  }, [item, setUserInputError]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    if (
      fieldName === "height" ||
      fieldName === "weight" ||
      fieldName === "length" ||
      fieldName === "width" ||
      fieldName === "quantity"
    )
      value = parseInt(value);
    e.preventDefault();
    changeItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  //setSampleData({...sampleData, address: editAddress})
  //   const handleListItemSelect = (e) => {
  //     setSelectedType(e.target.value);
  //   };

  // Disable the input area based on the type chosen
  const disableInput = (_selectedType, _shipmentTypesDetails, field) => {
    if (!_selectedType) return true;
    return Boolean(shipmentTypesDetails[_selectedType][field]);
  };

  return (
    <Box display="flex" justifyContent="center" heigh="100%" m="0">
      <Box display="flex" flexDirection="column">
        <Box display="flex" flexDirection="column">
          <Box
            display="flex"
            flexDirection="column"
            width={matches ? "60vw" : "30vw"}
            mr="1.5vw"
            mb="2vh"
          >
            <Box pb="1vh">
              <div className={classes.subTitle}>Item Description *</div>
              <CustomInput
                // className={classes.textfield}
                value={item.description}
                onChange={handleItemChange("description")}
              />
            </Box>
            <Box>
              <div className={classes.subTitle}>Packaging type *</div>
              <CustomInput
                onChange={handleItemChange("typeID")}
                options={shipmentTypes}
                value={item.typeID}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            <Box display="flex" mb="1vh">
              <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Length of pallet *
                </div>
                <CustomInput
                  // className={classes.textfield}
                  value={item.length}
                  onChange={handleItemChange("length")}
                  disabled={disableInput(
                    item.typeID,
                    shipmentTypesDetails,
                    "length"
                  )}
                  type="number"
                />
              </Box>
              <Box width={matches ? "" : "15vw"} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Width of pallet *
                </div>
                <CustomInput
                  value={item.width}
                  onChange={handleItemChange("width")}
                  disabled={disableInput(
                    item.typeID,
                    shipmentTypesDetails,
                    "width"
                  )}
                  type="number"
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box width={matches ? "" : "15vw"} mr={1} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Height of pallet *
                </div>
                <CustomInput
                  value={item.height}
                  onChange={handleItemChange("height")}
                  // disabled={disableInput(selectedType, shipmentTypesDetails, 'height')}
                  disabled={!item.typeID}
                  error={userInputError.heightError}
                  type="number"
                  placeholderValue={
                    item.typeID && parseInt(item.typeID) < 5
                      ? `Maximum: ${item.typeID === "3" ? "150" : "72"}`
                      : ""
                  }
                />
                <Box
                  pl={1}
                  color="red"
                  fontSize="1rem"
                  visibility={userInputError.heightError ? "visible" : "hidden"}
                >
                  Incorrect number
                </Box>
              </Box>
              <Box width={matches ? "" : "15vw"} flexGrow="1">
                <div
                  className={
                    small && matches ? classes.smSubTitle : classes.subTitle
                  }
                >
                  Weight of pallet *
                </div>
                <CustomInput
                  // className={classes.textfield}
                  value={item.weight}
                  onChange={handleItemChange("weight")}
                  // disabled={disableInput(selectedType, shipmentTypesDetails, 'weight')}
                  disabled={!item.typeID}
                  error={userInputError.weightError}
                  type="weight"
                  placeholderValue={
                    item.typeID && parseInt(item.typeID) < 5
                      ? `Maximum: ${item.typeID === "4" ? "3000" : "1200"}`
                      : ""
                  }
                />
                <Box
                  pl={1}
                  color="red"
                  fontSize="1rem"
                  visibility={userInputError.weightError ? "visible" : "hidden"}
                >
                  Incorrect number.
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box width={matches ? "60vw" : "21vw"} mb="2vh" mt={1}>
          <div className={classes.subTitle}>Number of pallets *</div>
          <CustomInput
            // className={classes.textfield}
            value={item.quantity}
            onChange={handleItemChange("quantity")}
            disabled={item.typeID === "6" || item.typeID === "7"}
            type="number"
          />
        </Box>

        <Box width="100%">
          {errorMsg ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              {errorMsg}
            </Alert>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1rem",
    fontFamily: "Montserrat",
  },
}));

ShippingItemInputStateless.propTypes = {
  item: PropTypes.object.isRequired,
  errorMsg: PropTypes.string.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  small: PropTypes.bool,
  changeItem: PropTypes.func,
  userInputError: PropTypes.object.isRequired,
  setUserInputError: PropTypes.func.isRequired,
};

export default ShippingItemInputStateless;

export const provinces = [
  { label: "", value: "" },
  { label: "Alberta", value: "AB" },
  { label: "British Columbia", value: "BC" },
  { label: "Manitoba", value: "MB" },
  { label: "New Brunswick", value: "NB" },
  { label: "Newfoundland and Labrador", value: "NF" },
  { label: "Northwest Territories", value: "NT" },
  { label: "Nova Scotia", value: "NS" },
  { label: "Nunavut", value: "NU" },
  { label: "Ontario", value: "ON" },
  { label: "Prince Edward Island", value: "PE" },
  { label: "Quebec", value: "QC" },
  { label: "Saskatchewan", value: "SK" },
  { label: "Yukon", value: "YT" },
];

export const shipmentTypesDetails = {
  1: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 40,
    height: 72,
    weight: 1500,
    //   "height": null,
    //   "weight": null,
    unit_price: 20,
    type_name: "Pallet(48''x40'')",
    id: 1,
  },
  2: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    height: 72,
    weight: 1500,
    unit_price: 20,
    type_name: "Pallet(48''x48'')",
    id: 2,
  },
  3: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    height: null,
    weight: null,
    unit_price: 25,
    type_name: "Oversize Pallet",
    id: 3,
  },
  4: {
    unit: "1 Pallet",
    unit_number: 1,
    length: 48,
    width: 48,
    height: null,
    weight: null,
    unit_price: 25,
    type_name: "Overweight Pallet",
    id: 4,
  },
  // 5: {
  //   unit: "1 Pallet",
  //   length: null,
  //   width: null,
  //   height: null,
  //   weight: null,
  //   unit_price: 30,
  //   type_name: "Oversize & Overweight Pallet",
  //   id: 5,
  // },
  5: {
    unit: "FTL(12 Pallets)",
    unit_number: 10,
    length: null,
    width: null,
    height: null,
    weight: null,
    unit_price: 180,
    type_name: "Full Truck(5 ton)",
    id: 6,
  },
  6: {
    unit: "FTL(26 Pallets)",
    unit_number: 25,
    length: null,
    width: null,
    height: null,
    weight: null,
    unit_price: 400,
    type_name: "Full Trailer (FTL)",
    id: 7,
  },
};

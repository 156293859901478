import {
  UPDATE_SEARCH_FILTER,
  FETCH_SEARCH_FILTER_STATIC,
} from "../actions/types";
import produce from "immer";
const initialState = {
  unitPrice: [null, null],
  totalPrice: [null, null],
  shipmentSize: [0],
  pickupCity: [],
  deliverCity: [],
  pickupDateFrom: "",
  pickupDateTo: "",
  static: null,
  pickupLoadingDock: null,
  deliverLoadingDock: null,
  deliverAppointment: null,
};

export const searchFilterSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_FILTER:
      return {
        ...state,
        unitPrice: action.payload.unitPrice,
        totalPrice: action.payload.totalPrice,
        shipmentSize: action.payload.shipmentSize,
        pickupCity: action.payload.pickupCity,
        deliverCity: action.payload.deliverCity,
        pickupDateFrom: action.payload.pickupDateFrom,
        pickupDateTo: action.payload.pickupDateTo,
        pickupLoadingDock: action.payload.pickupLoadingDock,
        deliverLoadingDock: action.payload.deliverLoadingDock,
        deliverAppointment: action.payload.deliverAppointment,
      };
    case FETCH_SEARCH_FILTER_STATIC:
      return produce(state, draft => {
        //initial unit price, total price values
        draft.unitPrice = [
          action.payload.min_unit_price,
          action.payload.max_unit_price,
        ];
        draft.totalPrice = [
          action.payload.min_total_price,
          action.payload.max_total_price,
        ];
        //set static
        draft.static = action.payload;
      });
    default:
      return state;
  }
};

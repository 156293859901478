import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Paper,
  Grid,
  Typography,
  ButtonBase,
  TextField,
  Button,
} from "@material-ui/core";

import {
  driverAddShipmentsToSummary,
  driverRemoveShipmentsFromSummary,
  driverClaimShipmentsFromSummary,
} from "../../actions/driverClaimShipmentAction";
import { useSelector } from "react-redux";
import store from "../../store/index";
import { getHeaders } from "../../Utils/fetchUtil";
import axios from "axios";
import { DRIVER_UPDATE_SHIPMENT_APPOINTMENT } from "../../Utils/apiUrl";
import { current } from "immer";

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  title: {
    color: "#111",
    fontFamily: "Helvetica Neue, sans-serif",
    "font-size": "30px",
    "font-weight": "bold",
    "letter-spacing": "-1px",
    "line-height": 1,
    margin: "5px",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "750px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    margin: "3px auto",
  },
  img: {
    margin: "auto",
    display: "block",
    maxHeight: "150px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignSelf: "center",
  },
  shipmentInfoWrapper: {
    display: "flex",
    flexDirection: "column",
  },
  deliverAppointmentInfoArea: {
    border: "1px solid grey",
    borderRadius: "2px",
    width: "90%",
    maxWidth: "370px",
    margin: "2px auto",
    padding: "4px",
    justifyContent: "space-around",
  },
  deliverDateAreaWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    margin: "5px auto",
  },
  flexAndWrap: {
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    gap: "5px",
  },
}));

const constructDateOrTime = date => {
  return date < 10 ? "0" + date : date;
};

export default function LoadedShipmentDetailLayout({
  sectionTitle,
  packageInfo,
  shipmentInfo,
  refetch,
}) {
  const classes = useStyles();

  const today = new Date();
  const currentDate =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1) +
    "-" +
    constructDateOrTime(today.getDate());

  const currentTime =
    today.getHours() + ":" + constructDateOrTime(today.getMinutes());
  const cardActive = useSelector(state => {
    return state.user.activeCard === "active";
  });

  const [disableTextField, setDisableTextField] = useState(false);
  const [quantity, setQuantity] = useState("");
  const [totalPrice, setTotalPrice] = useState(0);
  const [updateDeliverData, setUpdateDeliverData] = useState(false);

  const {
    available_count,
    height,
    cover_image,
    item_description,
    item_name,
    item_number,
    length,
    number,
    shipment_type,
    unit_price,
    weight,
    width,
    package_number,
  } = packageInfo;

  console.log("shipments detail page, package", packageInfo);
  const {
    shipment_number,
    sender,
    sender_name,
    schedule_pickup,
    deliver_address,
    deliver_area,
    deliver_city,
    deliver_postal_code,
    deliver_province,
    driver_name,
    order_number,
    pickup_address,
    pickup_area,
    pickup_city,
    pickup_postal_code,
    pickup_province,
    shipping_status,
    status,
    tracking_number_count,
    options,
    option_list,
  } = shipmentInfo;
  console.log("shipments detail page, shipment info", shipmentInfo);
  const pickupDate = schedule_pickup.split(" ");

  const initialDeliverDate = () => {
    //if no appointment date is set, use current date
    if (options === "n" || option_list.deliver_appointment_date === null) {
      return currentDate;
    } else if (options === "y" && option_list.deliver_appointment_date) {
      //get the appointment date
      return option_list.deliver_appointment_date.split(" ")[0];
    }
  };

  const initialDeliverTime = () => {
    //if no appointment date is set, use current date
    console.log("current time", currentTime);
    if (options === "n" || option_list.deliver_appointment_date === null) {
      return currentTime;
    } else if (options === "y" && option_list.deliver_appointment_date) {
      //get the appointment date
      return option_list.deliver_appointment_date.split(" ")[1];
    }
  };

  const [selectedDeliverDate, setDeliverDate] = useState(initialDeliverDate());
  const [selectedDeliverTime, setDeliverTime] = useState(initialDeliverTime());
  const prevDeliverDate = useRef(selectedDeliverDate);
  const prevDeliverTime = useRef(selectedDeliverTime);

  useEffect(() => {
    if (available_count === 0) {
      setDisableTextField(true);
    } else {
      setDisableTextField(false);
    }
  }, [available_count]);

  const updateDeliverDateTime = () => {
    //for updating appointment time, checking if date time changed
    if (
      selectedDeliverDate === prevDeliverDate.current &&
      selectedDeliverTime === prevDeliverTime.current
    ) {
      setUpdateDeliverData(false);
      return;
    }
    //call API
    axios
      .put(
        DRIVER_UPDATE_SHIPMENT_APPOINTMENT(shipment_number),
        {},
        {
          params: {
            appointment: selectedDeliverDate + " " + selectedDeliverTime,
          },
          headers: getHeaders(),
        }
      )
      .then(response => {
        //updated/set appointment date time
        //refetch shipment info/package info
        refetch(true);
      })
      .catch(error => {
        console.log(
          "error when updating apointment time",
          error.response.data.message
        );
      });
  };

  const handleDeliverDateChange = event => {
    console.log("set date: ", event.target.value);
    setDeliverDate(event.target.value);
  };

  const handleDeliverTimeChange = event => {
    console.log("set time: ", event.target.value);

    setDeliverTime(event.target.value);
  };

  const updateDeliverDateSwitch = status => {
    setUpdateDeliverData(status);
  };

  const handleChange = (e, shipmentId, packageId, itemName, unitPrice) => {
    if (e.target.value === "") {
      setQuantity();
    }
    let number = Number(e.target.value);
    if (number > available_count) {
      number = available_count;
    } else if (number < 0) {
      number = 0;
    }

    setQuantity(number.toString());

    //calculate total price
    setTotalPrice(number * unit_price);

    store.dispatch(
      driverAddShipmentsToSummary(
        shipmentId,
        packageId,
        number,
        itemName,
        unitPrice
      )
    );
  };

  return (
    <>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <Typography className={classes.title}>{sectionTitle}</Typography>
          </Grid>
          <Grid item container xs={12} className={classes.blocks}>
            <Grid item xs={12} className={classes.center}>
              <Typography variant="h6" style={{ textAlign: "center" }}>
                #{shipment_number}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="subtitle2"
                gutterBottom
                style={{ padding: "2px" }}
              >
                Pick up: {cardActive ? pickup_address : pickup_city}
              </Typography>

              <Typography variant="subtitle2" style={{ padding: "2px" }}>
                Delivery: {cardActive ? deliver_address : deliver_city}
              </Typography>
              <Typography variant="subtitle2" style={{ padding: "2px" }}>
                Pickup date: {pickupDate[0]}
              </Typography>

              {(() => {
                if (
                  options === "y" &&
                  option_list.option_deliver_appointment === "y"
                ) {
                  if (option_list.deliver_appointment_date === null) {
                    //No appointment yet
                    return (
                      <div className={classes.deliverAppointmentInfoArea}>
                        <Typography
                          variant="subtitle2"
                          style={{ padding: "2px", color: "#D0342C" }}
                        >
                          <b>
                            No deliver appointment is made, please create one.
                          </b>
                        </Typography>

                        <div className={classes.flexAndWrap}>
                          <form noValidate className={classes.flexAndWrap}>
                            <TextField
                              id="date"
                              label="Deliver Date:"
                              type="date"
                              defaultValue={selectedDeliverDate}
                              className={classes.textField}
                              onChange={handleDeliverDateChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <TextField
                              id="time"
                              label="Deliver Time:"
                              type="time"
                              defaultValue={selectedDeliverTime}
                              className={classes.textField}
                              onChange={handleDeliverTimeChange}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              inputProps={{
                                step: 300, // 5 min
                              }}
                            />
                          </form>

                          <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            onClick={() => updateDeliverDateTime()}
                          >
                            Submit
                          </Button>
                        </div>
                      </div>
                    );
                  } else {
                    //appointment created, able to display and update
                    if (!updateDeliverData) {
                      //display deliver date
                      return (
                        <div className={classes.deliverDateAreaWrapper}>
                          <Typography
                            variant="subtitle2"
                            style={{ padding: "2px" }}
                          >
                            Deliver date: {option_list.deliver_appointment_date}
                          </Typography>
                          <Button
                            color="primary"
                            variant="contained"
                            style={{ marginLeft: "5px" }}
                            onClick={() => updateDeliverDateSwitch(true)}
                          >
                            Update
                          </Button>
                        </div>
                      );
                    } else {
                      //update deliver date swidth is on
                      return (
                        <div className={classes.deliverAppointmentInfoArea}>
                          <div className={classes.flexAndWrap}>
                            <form noValidate>
                              <TextField
                                id="date"
                                label="Deliver Date"
                                type="date"
                                defaultValue={selectedDeliverDate}
                                className={classes.textField}
                                onChange={handleDeliverDateChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                              />
                              <TextField
                                id="time"
                                label="Deliver Time"
                                type="time"
                                defaultValue={selectedDeliverTime}
                                className={classes.textField}
                                onChange={handleDeliverTimeChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 300, // 5 min
                                }}
                              />
                            </form>

                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginLeft: "5px" }}
                              onClick={updateDeliverDateTime}
                            >
                              Update
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              style={{ marginLeft: "5px" }}
                              onClick={() => updateDeliverDateSwitch(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        </div>
                      );
                    }
                  }
                }
              })()}
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            className={classes.blocks}
            style={{ justifyContent: "space-around" }}
          >
            <Grid item xs={12} className={classes.center}>
              <div style={{}}>
                <ButtonBase className={classes.image}>
                  <img
                    className={classes.img}
                    alt="package"
                    src={cover_image}
                  />
                </ButtonBase>
                <Typography variant="h6" style={{ textAlign: "center" }}>
                  {item_name}
                </Typography>
              </div>
            </Grid>
            <Grid item className={classes.shipmentInfoWrapper}>
              <Typography variant="subtitle2" gutterBottom>
                {item_description}
              </Typography>
              <Typography variant="subtitle2" align="center">
                {shipment_type} x {tracking_number_count}
              </Typography>
              <Typography variant="subtitle2">
                length: {length}, width: {width}, height: {height}, Weight:{" "}
                {weight}lb
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}

import React from "react";
import {
  makeStyles,
  Button,
  Dialog,
  Backdrop,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress,
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function FeedbackDialogLayout({
  openDialog,
  openBackDrop,
  title,
  content,
  shipmentNumbers,
  handleConfirm,
  handleCancel,
  openFeedbackDialog,
  feedbackContent,
  feedbackTarget,
  handleFeedbackConfirm,
}) {
  const classes = useStyles();
  console.log("feedback: ", feedbackContent);

  return (
    <>
      {
        <>
          <Backdrop className={classes.backdrop} open={openBackDrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            open={openDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {content}
                {shipmentNumbers?.map(sNum => {
                  return (
                    <li key={sNum} style={{ listStyle: "none" }}>
                      <i>{sNum}</i>
                    </li>
                  );
                })}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirm} color="primary" autoFocus>
                Yes
              </Button>
              <Button onClick={handleCancel} color="primary">
                Cancle
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openFeedbackDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{"Awesome"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <div>
                  {feedbackContent?.loadShipmentRes?.status === 200 ? (
                    <>
                      <h5 style={{ color: "green" }}>
                        <bold>Successfully loaded shipment(s):</bold>
                      </h5>
                      {feedbackContent?.loadShipmentRes?.shipmentNumbers.map(
                        element => {
                          return (
                            <li style={{ listStyle: "none" }} key={element}>
                              <h5>{element}</h5>
                            </li>
                          );
                        }
                      )}
                    </>
                  ) : (
                    <>
                      <h5 style={{ color: "red" }}>
                        <bold>Fail to load shipment(s):</bold>
                      </h5>
                      {feedbackContent?.loadShipmentRes?.shipmentNumbers?.map(
                        element => {
                          return (
                            <li key={element} style={{ listStyle: "none" }}>
                              <h5>{element}</h5>
                            </li>
                          );
                        }
                      )}
                    </>
                  )}
                  {feedbackContent?.uploadImagesRes?.length !== 0 &&
                    feedbackContent?.uploadImagesRes?.map(element => {
                      return (
                        <li key={element.name} style={{ listStyle: "none" }}>
                          Upload image: <i>{element.name} - </i>
                          {element.status === "success" ? (
                            <i style={{ color: "green" }}>{element.status}</i>
                          ) : (
                            <i style={{ color: "red" }}>{element.status}</i>
                          )}
                        </li>
                      );
                    })}
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleFeedbackConfirm} color="primary" autoFocus>
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </>
  );
}

import React, { useState }from 'react';

const SearchSettingPage = () => {
  
  const [whereToPickUp, setWhereToPickUp] = useState('Richmond, Vancouver');
  const [whereToDeliver, setWhereToDeliver] = useState('');
  const [whenToPickUp, setWhenToPickUp] = useState('');

  const handleSubmit = (evt) => {
    evt.preventDefault();
    alert(`Submitting where to pick up:
            ${whereToPickUp}
          where to deliver:
           ${whereToDeliver}
          when to pick up: 
          ${whenToPickUp}`);
  }



  return (
    <div className='search-setting-page'>
      <h1>Search setting</h1>
      <form className='search-options' onSubmit={handleSubmit}>
        <label>Where to Pick-up:
          <input name='where_to_pickup' type='text' value={whereToPickUp} onChange={e => setWhereToPickUp(e.target.value)}/>
        </label>
        <label>Where to Deliver:
          <input name='where_to_deliver' type='text' value={whereToDeliver} onChange={e => setWhereToDeliver(e.target.value)}/>
        </label>
        <label >When to Pick-up:
          <input name='when_to_pick_up' type='date' value={whenToPickUp} onChange={e => setWhenToPickUp(e.target.value)}/>
        </label>
        <input type='submit' value='Search'/>
      </form>
    </div>
  )
}

export default SearchSettingPage;
import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Box,
  Dialog,
  IconButton,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import ClearIcon from "@material-ui/icons/Clear";
// import LoadingCircle from "../LoadingCircle";
import {
  // addLoadedShipments,
  cleanLoadedShipmentsData,
} from "../../actions/driverLoadAndDeliverShipmentsAction";
import { useDispatch } from "react-redux";

import { getAccessToken } from "../../Utils/doToken";
// import { getTokenFromCookie } from "../../Utils/doToken";
import {
  GUEST_CARGO_CONTROL_TRACKING_COOKIE_NAME,
  GUEST_CARGO_CONTROL_TRACKING_COOKIE_TIME,
} from "../../Utils/config";
import { forEach, rest } from "lodash";

const useStyles = makeStyles((theme) => ({
  scanInput: {
    justifyContent: "center",
    margin: "5px auto",
    gap: "5px",
  },
  scanButton: {
    marginLeft: "5px",
  },
  confirmButton: {
    color: "white",
  },
  centering: {
    display: "flex",
    justifyContent: "center",
  },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    marginRight: "24px",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
}));

const ControlNumber = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [textInput, setTextInput] = useState("");
  const [isSignInRequestWindowOpen, setSignInRequestWindowOpenState] =
    useState(false);

  const handleEnter = () => {
    const newText = textInput.replace(/\n/g, ",");

    let resText = "";

    newText.split(",").forEach((ele) => {
      if (resText === "") resText = ele;
      else if (ele !== "") resText += "," + ele;
    });

    history.push(`/cargo-control-tracking/info/${resText}`);
  };

  useEffect(() => {
    return () => {
      dispatch(cleanLoadedShipmentsData());
    };
  }, []);

  //input for tracking number
  const handleChange = (e) => {
    setTextInput(e.target.value.trim());
  };

  return (
    <>
      <Box fontWeight="600" textAlign="center">
        Cargo control number query
      </Box>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container className={classes.scanInput}>
          <form>
            <TextField
              placeholder="Control Numbers&#10;Note: numbers should separate by comma."
              variant="outlined"
              onChange={(e) => handleChange(e)}
              style={{ width: "300px" }}
              multiline
              rows={6}
            />
          </form>
        </Grid>
        <Grid item xs={12} container className={classes.scanInput}>
          <Box mt={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEnter()}
              className={classes.scanButton}
            >
              Check
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={isSignInRequestWindowOpen}
        onClose={() => setSignInRequestWindowOpenState(false)}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem"></Box>
          <IconButton
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => setSignInRequestWindowOpenState(false)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Box
          color="seagreen"
          fontSize="1.5rem"
          minHeight="20vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={2}
        >
          Please sign in to get more information.
        </Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSignInRequestWindowOpenState(false);
            }}
            className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/sign-in")}
            className={`${classes.button} ${classes.fixWidthButton}`}
          >
            Sign In
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default ControlNumber;

import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
  fetchingShipments,
  stackShipments,
} from "../../actions/fetchShipmentsAction";
import store from "../../store/index";
import {
  GET_AVAILABLE_SHIPMENTS,
  GET_DRIVER_CLAIMED_SHIPMENTS,
  GET_DRIVER_DELIVERED_SHIPMENTS,
} from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";

const useShipmentsFetch = (type, pageNumber, searchOptions) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const updateFilter = useRef(false);

  const header = {
    headers: {
      Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
    },
  };

  useEffect(() => {
    setShipments([]);
  }, []);

  useEffect(() => {
    updateFilter.current = true;
  }, [searchOptions]);

  useEffect(() => {
    setLoading(true);
    store.dispatch(fetchingShipments(true));
    setError(false);
    if (type === "available_shipments") {
      if (updateFilter.current && pageNumber === 1) {
        axios
          .post(
            GET_AVAILABLE_SHIPMENTS,
            {},
            {
              params: {
                page: pageNumber,
                // min_unit_price: searchOptions.unitPrice[0],
                // max_unit_price: searchOptions.unitPrice[1],
                // min_total_price: searchOptions.totalPrice[0],
                // max_total_price: searchOptions.totalPrice[1],
                shipment_size: searchOptions.shipmentSize,
                pickup_cities: searchOptions.pickupCity,
                deliver_cities: searchOptions.deliverCity,
                pickup_date_from: searchOptions.pickupDateFrom,
                pickup_date_to: searchOptions.pickupDateTo,
                option_pickup_loading_dock: searchOptions.pickupLoadingDock
                  ? "y"
                  : "",
                option_deliver_loading_dock: searchOptions.deliverLoadingDock
                  ? "y"
                  : "",
                option_deliver_appointment: searchOptions.deliverAppointment
                  ? "y"
                  : "",
              },
              ...header,
            }
          )
          .then(res => {
            store.dispatch(stackShipments(res.data.data));
            console.log("updated filter:", res.data);
            setShipments(res.data.data);
            setLastPage(res.data.last_page);
            setHasMore(res.data.data.length > 0);
            setLoading(false);
            store.dispatch(fetchingShipments(false));
          })
          .catch(err => {
            setError(true);
            console.log("error message", err);
          });
        updateFilter.current = false;
      } else if (!updateFilter.current) {
        //normal changing page
        axios
          .post(
            GET_AVAILABLE_SHIPMENTS,
            {},
            {
              params: {
                page: pageNumber,
                // min_unit_price: searchOptions.unitPrice[0],
                // max_unit_price: searchOptions.unitPrice[1],
                // min_total_price: searchOptions.totalPrice[0],
                // max_total_price: searchOptions.totalPrice[1],
                shipment_size: searchOptions.shipmentSize,
                pickup_cities: searchOptions.pickupCity,
                deliver_cities: searchOptions.deliverCity,
                pickup_date_from: searchOptions.pickupDateFrom,
                pickup_date_to: searchOptions.pickupDateTo,
                option_pickup_loading_dock: searchOptions.pickupLoadingDock
                  ? "y"
                  : "",
                option_deliver_loading_dock: searchOptions.deliverLoadingDock
                  ? "y"
                  : "",
                option_deliver_appointment: searchOptions.deliverAppointment
                  ? "y"
                  : "",
              },
              ...header,
            }
          )
          .then(res => {
            console.log("fetch new shipments", res.data.data);
            store.dispatch(stackShipments(res.data.data));
            setShipments(res.data.data);
            setLastPage(res.data.last_page);
            setHasMore(res.data.data.length > 0);
            setLoading(false);
            store.dispatch(fetchingShipments(false));
          })
          .catch(err => {
            setError(true);
            console.log("error message", err);
          });
      }
    } else if (type === "claimed_shipments") {
      axios
        .get(GET_DRIVER_CLAIMED_SHIPMENTS(), header)
        .then(res => {
          setShipments(res.data.data);
          setHasMore(res.data.data.length > 0);
          setLoading(false);
        })
        .catch(err => {
          setError(true);
          console.log("error message", err);
        });
    } else if (type === "delivered_shipments") {
      axios
        .get(GET_DRIVER_DELIVERED_SHIPMENTS(), header)
        .then(res => {
          setShipments(res.data.data);
          setHasMore(res.data.data.length > 0);
          setLoading(false);
        })
        .catch(err => {
          setError(true);
          console.log("error message", err);
        });
    }
  }, [pageNumber, searchOptions]);

  return { loading, error, shipments, hasMore, lastPage };
};

export default useShipmentsFetch;

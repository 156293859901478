import React, { useState, useEffect } from "react";
import GooglePlaceInput from "./googlePlacesAutoComplete";

// import DateFnsUtils from '@date-io/date-fns';
// import {format, setDate} from 'date-fns'
// import {dateFormat} from 'dateformat';
// import { MuiPickersUtilsProvider, Calendar, DatePicker } from "@material-ui/pickers";
// import enGBLocale from "date-fns/locale/en-GB";
// import { createMuiTheme } from '@material-ui/core/styles';
// import { Store, Home, Business, ArrowForwardIos } from '@material-ui/icons';
import { Box, TextField } from "@material-ui/core";
// import orange from '@material-ui/core/colors/orange';
// import { makeStyles } from "@material-ui/core/styles";
// import { propTypes } from 'react-bootstrap/esm/Image';
import CustomInput from "../CustomInput";

const provinces = [
  "",
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

export default function AddressInputCombo({
  handleChange,
  handleChangeFromSelection,
  addressObject,
  pickUp = false,
  userLocation,
  // freeSolo = false,
}) {
  // const classes = useStyles();

  const [cityObj, setCityObj] = useState({ city: "" });

  useEffect(() => {
    userLocation?.city && setCityObj({ city: userLocation.city });
  }, [userLocation?.city]);

  return (
    <Box>
      <Box mr={1} mb={1}>
        <CustomInput
          onChange={handleChange("phone")}
          // value={provinces.find(ele => ele.value === addressObject.province) || ''}
          value={addressObject.phone}
          type="line"
          label={pickUp ? "Phone *" : "Phone"}
        />
      </Box>
      <Box mr={1} mb={1}>
        <GooglePlaceInput
          type="street"
          handleChange={handleChangeFromSelection("address")}
          setProvinceValue={handleChangeFromSelection("province")}
          setCityValueUpperLevel={handleChangeFromSelection("city")}
          setCityValue={setCityObj}
          addressValue={userLocation?.street}
        />
      </Box>
      <Box mr={1} display="flex" mb={1}>
        <Box flexBasis="50%" mr={1}>
          <TextField
            onChange={handleChange("aptInfo")}
            value={addressObject.aptInfo}
            label="Apartment, suite, etc"
            fullWidth
          />
        </Box>
        <Box flexBasis="50%" ml={1}>
          <GooglePlaceInput
            type="postal"
            handleChange={handleChangeFromSelection("zipCode")}
            zipCodeValue={userLocation?.zipCode}
          />
        </Box>
      </Box>
      <Box mr={1} display="flex">
        <Box mr={1} flexBasis="50%">
          <GooglePlaceInput
            type="city"
            handleChange={handleChangeFromSelection("city")}
            // cityValue={userLocation?.city}
            cityValue={cityObj}
          />
        </Box>
        <Box flexBasis="50%">
          <CustomInput
            onChange={handleChangeFromSelection("province")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={addressObject.province}
            label="Province *"
            fullWidth
            inputWithSelect
            options={provinces}
          />
        </Box>
      </Box>
    </Box>
  );
}

import React, { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import {
  GET_AVAILABLE_SHIPMENTS,
  GET_DRIVER_LOADED_SHIPMENTS,
  GET_DRIVER_CLAIMED_SHIPMENTS,
  GET_DRIVER_DELIVERED_SHIPMENTS,
} from "../../Utils/apiUrl";
import axios from "axios";
import { connect } from "react-redux";
import { getTokenFromCookie } from "../../Utils/doToken";
import MapList from "./MapList";
import MapMarker from "./MapMarker";
import LoadingCircle from "../LoadingCircle";

export const ShipmentMapLayout = ({ type, searchOptions }) => {
  const [latitude, setLatitude] = useState(0);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [longtitude, setLongtitude] = useState(0);
  const [GoogleAPI, setGoogleAPI] = useState();
  const [page, setPage] = useState(1);
  const [shipments, setShipments] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [latLngs, setLatLngs] = useState([]);
  const preSelectedCard = useRef("");
  const preSelectedMarker = useRef("");
  const updateFilter = useRef(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [lastPage, setLastPage] = useState(0);
  const [pageNumber, setPagenumber] = useState(1);

  const header = {
    headers: {
      Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
    },
  };

  useEffect(() => {
    updateFilter.current = true;
  }, [searchOptions]);

  useEffect(() => {
    //fetch available shipments
    switch (type) {
      case "available_shipments":
        setLoading(true);
        if (updateFilter.current && pageNumber === 1) {
          axios
            .post(
              GET_AVAILABLE_SHIPMENTS,
              {},
              {
                params: {
                  page: pageNumber,
                  min_unit_price: searchOptions.unitPrice[0],
                  max_unit_price: searchOptions.unitPrice[1],
                  min_total_price: searchOptions.totalPrice[0],
                  max_total_price: searchOptions.totalPrice[1],
                  shipment_size: searchOptions.shipmentSize,
                  pickup_cities: searchOptions.pickupCity,
                  deliver_cities: searchOptions.deliverCity,
                  start_date: searchOptions.pickupDate,
                  end_date: searchOptions.deliverDate,
                },
                ...header,
              }
            )
            .then(res => {
              setShipments(res.data.data);
              setLastPage(res.data.last_page);
              setHasMore(res.data.data.length > 0);
              if (res.data.data.length > 0) {
                getAddresses(res.data.data);
              } else {
                //no shipments, clean the location for markers
                setLatLngs([]);
              }
              setLoading(false);
            })
            .catch(err => {
              setError(true);
            });
          updateFilter.current = false;
        } else if (!updateFilter.current) {
          //normal changing page
          axios
            .post(
              GET_AVAILABLE_SHIPMENTS,
              {},
              {
                params: {
                  page: pageNumber,
                  min_unit_price: searchOptions.unitPrice[0],
                  max_unit_price: searchOptions.unitPrice[1],
                  min_total_price: searchOptions.totalPrice[0],
                  max_total_price: searchOptions.totalPrice[1],
                  shipment_size: searchOptions.shipmentSize,
                  pickup_cities: searchOptions.pickupCity,
                  deliver_cities: searchOptions.deliverCity,
                  start_date: searchOptions.pickupDate,
                  end_date: searchOptions.deliverDate,
                },
                ...header,
              }
            )
            .then(res => {
              setShipments(res.data.data);
              setLastPage(res.data.last_page);
              setHasMore(res.data.data.length > 0);
              if (res.data.data.length > 0) {
                getAddresses(res.data.data);
              } else {
                //no shipments, clean the location for markers
                setLatLngs([]);
              }
              setLoading(false);
            })
            .catch(err => {
              setError(true);
            });
        }
        break;
      case "claimed_shipments":
        setLoading(true);
        axios
          .post(
            GET_DRIVER_CLAIMED_SHIPMENTS(),
            {},
            {
              params: {
                page: pageNumber,
              },
              ...header,
            }
          )
          .then(res => {
            setShipments(res.data.data);
            setLastPage(res.data.last_page);
            setHasMore(res.data.data.length > 0);
            if (res.data.data.length > 0) {
              getAddresses(res.data.data);
            } else {
              //no shipments, clean the location for markers
              setLatLngs([]);
            }
            setLoading(false);
          })
          .catch(err => {
            setError(true);
          });
        break;
      case "loaded_shipments":
        setLoading(true);
        axios
          .post(
            GET_DRIVER_LOADED_SHIPMENTS,
            {},
            {
              params: {
                page: pageNumber,
              },
              ...header,
            }
          )
          .then(res => {
            setShipments(res.data.data);
            setLastPage(res.data.last_page);
            setHasMore(res.data.data.length > 0);
            if (res.data.data.length > 0) {
              getAddresses(res.data.data);
            } else {
              //no shipments, clean the location for markers
              setLatLngs([]);
            }
            setLoading(false);
          })
          .catch(err => {
            setError(true);
          });
        break;
      case "delivered_shipments":
        setLoading(true);
        axios
          .post(
            GET_DRIVER_DELIVERED_SHIPMENTS(),
            {},
            {
              params: {
                page: pageNumber,
              },
              ...header,
            }
          )
          .then(res => {
            setShipments(res.data.data);
            setLastPage(res.data.last_page);
            setHasMore(res.data.data.length > 0);
            if (res.data.data.length > 0) {
              getAddresses(res.data.data);
            } else {
              //no shipments, clean the location for markers
              setLatLngs([]);
            }
            setLoading(false);
          })
          .catch(err => {
            setError(true);
          });
        break;
      default:
        return;
    }
  }, [page, searchOptions]);

  useEffect(() => {
    getGeocode();
  }, [addresses]);

  useEffect(() => {
    if (latLngs.length === 0) return;

    pushLatLngIntoShipment();
  }, [latLngs]);

  const getAddresses = shipments => {
    const tempAddress = [];
    shipments.forEach(s => {
      tempAddress.push({
        key: `address-${s.shipment_number}`,
        shipment_number: s.shipment_number,
        address: s.pickup_address,
      });
    });
    setAddresses(tempAddress);
  };

  const pushLatLngIntoShipment = () => {
    if (latLngs.length === 0) return;
    const tempAvailableShipments = shipments;
    for (let i = 0; i < tempAvailableShipments.length; i++) {
      tempAvailableShipments[i].latitude = latLngs[i].lat;
      tempAvailableShipments[i].longtitude = latLngs[i].lng;
    }
    setShipments(tempAvailableShipments);
  };

  const getGeocode = async () => {
    if (GoogleAPI === undefined) return;
    const tempLocation = [];
    const geocoder = new GoogleAPI.maps.Geocoder();
    for (const a of addresses) {
      let res = await geocoder.geocode({ address: a.address });

      //make markers
      let location = {
        shipment_number: a.shipment_number,
        lat: res.results[0].geometry.location.lat(),
        lng: res.results[0].geometry.location.lng(),
      };
      tempLocation.push(location);
    }
    setLatLngs(tempLocation);

    changeCenter(tempLocation[0].lat, tempLocation[0].lng);
  };

  const changeCenter = (lat, lng) => {
    setLatitude(lat);
    setLongtitude(lng);
    GoogleAPI.map.panTo({ lat: lat, lng: lng });
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(position => {
      setLatitude(position.coords.latitude);
      setLongtitude(position.coords.longitude);
    });

    //setMapList ref
    const observer = new MutationObserver((mutations, obs) => {
      const mapList = document.getElementById("mapList");
      if (mapList) {
        mapList.addEventListener("wheel", event => {
          event.preventDefault();
          mapList.scrollLeft += event.deltaY;
        });
        obs.disconnect();
        return;
      }
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });
  }, []);

  const handleInput = e => {
    const number = Number(e.target.value);
    setLatitude(number);
  };

  const handleCardClick = (lat, lng, sNum) => {
    changeCenter(lat, lng);

    selectCardItem(sNum);
    selectMarker(sNum);
  };

  const handleMarkerClick = sNum => {
    selectMarker(sNum);
    selectCardItem(sNum);
    const element = document.getElementById(`cardItem-${sNum}`);
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "center",
    });
  };

  const selectCardItem = sNum => {
    document.querySelector(`#cardItem-${sNum}`).style.border =
      "2px solid lightsalmon";
    if (preSelectedCard.current === "") {
      preSelectedCard.current = `#cardItem-${sNum}`;
    } else if (preSelectedCard.current !== `#cardItem-${sNum}`) {
      document.querySelector(preSelectedCard.current).style.border = "";
      preSelectedCard.current = `#cardItem-${sNum}`;
    }
  };

  const selectMarker = sNum => {
    const marker = document.querySelector(`#markerIcon-${sNum}`);
    marker.style.color = "orangered";
    marker.style.transform = "scale(2)";
    if (preSelectedMarker.current === "") {
      preSelectedMarker.current = `#markerIcon-${sNum}`;
    } else if (preSelectedMarker.current !== `#markerIcon-${sNum}`) {
      document.querySelector(preSelectedMarker.current).style.transform =
        "scale(1.5)";
      document.querySelector(preSelectedMarker.current).style.color = "orange";
      preSelectedMarker.current = `#markerIcon-${sNum}`;
    }
  };

  return (
    <>
      {/* <input value={latitude} onChange={handleInput} style={{height:'20px'}}></input> */}
      {
        <div style={{ height: "50vh", width: "100%" }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ",
            }}
            center={{
              lat: latitude,
              lng: longtitude,
            }}
            defaultZoom={11}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              setGoogleAPI({ map, maps });
              setMapLoaded(true);
            }}
          >
            {latLngs.map(location => {
              return (
                <MapMarker
                  key={location.shipment_number}
                  shipmentNumber={location.shipment_number}
                  title={""}
                  lat={location.lat}
                  lng={location.lng}
                  handleMarkerClick={handleMarkerClick}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      }
      {loading ? (
        <LoadingCircle />
      ) : (
        <MapList shipments={shipments} handleCardClick={handleCardClick} />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    searchOptions: state.searchFilter,
  };
};

export default connect(mapStateToProps)(ShipmentMapLayout);

import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import store from "../../store/index";
import { driverSelectPackage } from "../../actions/driverFetchPackageAction";
import { selectShipment } from "../../actions/fetchShipmentsAction";
import ShipmentList from "./ShipmentList";
import useShipmentsFetch from "./useShipmentsFetch";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import LoadingCircle from "../LoadingCircle";
import Pagination from "@material-ui/lab/Pagination";
import NoShipmentsLayout from "../Layouts/NoShipmentsLayout";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
  scrollComponent: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const ScrollComponent = ({ type, searchOptions }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const history = useHistory();
  //const observer = useRef()
  const cardActive = useSelector(state => {
    return state.user.activeCard === "active";
  });

  useEffect(() => {
    setPage(1);
  }, [searchOptions]);

  const { loading, error, shipments, hasMore, lastPage } = useShipmentsFetch(
    type,
    page,
    searchOptions
  );
  const handleChange = (event, value) => {
    setPage(value);
  };

  const goToBindCreditCard = () => {
    history.push("/profile/add-card");
  };

  const handleClose = () => {
    setOpen(false);
  };

  const goPackageDetailPage = (shipmentNumber, packageNumber) => {
    //check if current user bind with a credit card
    if (cardActive) {
      let path = `/driver/${type}/${shipmentNumber}`;
      store.dispatch(selectShipment(shipmentNumber));
      store.dispatch(driverSelectPackage(packageNumber));
      history.push(path);
    } else {
      //show alert to bind credit card
      setOpen(true);
    }
  };

  // useEffect(()=>{
  //   if(shipments.length > 0){
  //   }
  // },[shipments])

  //implementing infinite scrolling with intersection observer
  // const lastShipment = useCallback(node => {
  //   console.log('node',node)
  //   if(loading) return
  //   if (observer.current) { observer.current.disconnect() }
  //   observer.current = new IntersectionObserver(entries => {
  //     if(entries[0].isIntersecting && hasMore){
  //       console.log('is intersecting')
  //       setPage(prePage => prePage + 1)
  //     }
  //   })
  //   if(node) observer.current.observe(node)
  // },[loading, hasMore])
  return (
    <div className={classes.scrollComponent}>
      {!loading ? (
        shipments.length > 0 ? (
          shipments.map((s, index) => {
            const {
              shipment_number,
              package_number,
              item_name,
              item_description,
              shipment_type,
              length,
              width,
              height,
              weight,
              number,
              image_location,
              unit_price,
              available_count,
              pickup_city,
              deliver_city,
              schedule_pickup,
              cover_image,
              tracking_number_count,
              option_list,
            } = s;

            return (
              <ShipmentList
                key={shipment_number}
                imgSrc={cover_image}
                itemName={item_name}
                trackingNumberCount={tracking_number_count}
                width={width}
                length={length}
                height={height}
                weight={weight}
                unitPrice={unit_price}
                shipmentType={shipment_type}
                description={item_description}
                number={number}
                available_count={available_count}
                index={shipment_number}
                pickupCity={pickup_city}
                deliverCity={deliver_city}
                pickupDate={schedule_pickup}
                optionList={option_list}
                handleClick={() => {
                  goPackageDetailPage(shipment_number, package_number);
                }}
              />
            );
          })
        ) : (
          <NoShipmentsLayout message="No shipments found." />
        )
      ) : (
        <LoadingCircle />
      )}
      {!loading && lastPage > 1 && (
        <div className={classes.pagination}>
          <Pagination
            className={classes.pagination}
            count={lastPage}
            page={page}
            variant="outlined"
            shape="rounded"
            onChange={handleChange}
          />
        </div>
      )}
      {
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth
        >
          <DialogTitle id="alert-dialog-title">
            {"Please bind your credit card."}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Binding a credit card allow you to access more information of
              shipments.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={goToBindCreditCard}
              variant="contained"
            >
              Bind my credit card
            </Button>
            <Button onClick={handleClose} variant="contained">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchOptions: state.searchFilter,
  };
};
export default connect(mapStateToProps)(ScrollComponent);

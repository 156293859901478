import {
  DRIVER_ADD_DELIVER_SHIPMENTS,
  DRIVER_SELECT_DELIVER_SHIPMENTS,
  DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS,
  DRIVER_ADD_LOADED_SHIPMENTS,
  DRIVER_REMOVE_LOADED_SHIPMENTS,
  DRIVER_CLEAN_LOADED_SHIPMENTS_DATA,
  DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA,
  DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS,
} from "./types";

export const addDeliverShipments = data => {
  return {
    type: DRIVER_ADD_DELIVER_SHIPMENTS,
    payload: data,
  };
};

export const selectDeliverShipments = data => {
  return {
    type: DRIVER_SELECT_DELIVER_SHIPMENTS,
    payload: data,
  };
};

export const removeSelectedDeliverShipment = data => {
  return {
    type: DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS,
    payload: data,
  };
};

export const removeAllSelectedDeliverShipments = () => {
  return {
    type: DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS,
  };
};

export const cleanDeliverShipmentsData = () => {
  return {
    type: DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA,
  };
};

export const addLoadedShipments = data => {
  return {
    type: DRIVER_ADD_LOADED_SHIPMENTS,
    payload: data,
  };
};

export const removeLoadedShipments = data => {
  return {
    type: DRIVER_REMOVE_LOADED_SHIPMENTS,
    payload: data,
  };
};

export const cleanLoadedShipmentsData = () => {
  return {
    type: DRIVER_CLEAN_LOADED_SHIPMENTS_DATA,
  };
};

// import {newUserReducer} from './reducers';
import shippingItemReducer from "./shippingItemReducer";
import locationReducer from "./locationReducer";
import shipmentReducer from "./shipmentReducer";
import { searchFilterSettingReducer } from "./searchFilterReducer";
import { combineReducers } from "redux";
// import {showUsersReducer} from './reducers';

// import orderReducer from './orderReducer';
// import packageReducer from './packageReducer';
import driver_packageReducer from "./driver_packageReducer";
import shipmentsReducer from "./shipmentsReducer";
import driver_claimShipmentsReducer from "./driver_claimShipmentsReducer";
import { driverDeliverShipmentsReducer } from "./driver_DeliverShipmentsReducer";
import { driverUploadImagesReducer } from "./driverUploadImagesReducer";
import { driverLoadedShipmentsReducer } from "./driver_LoadShipmentsReducer";
import {
  ordersReducer,
  shippingReducer,
  userReducer,
  packagesReducer,
} from "../slices";

export default combineReducers({
  shippingItems: shippingItemReducer,
  location: locationReducer,
  shipments: shipmentReducer,
  searchFilter: searchFilterSettingReducer,
  // orders : orderReducer,
  // packages: packageReducer,
  driver_packages: driver_packageReducer,
  orderShipments: shipmentsReducer,
  driver_claim_summary: driver_claimShipmentsReducer,
  driver_deliver_packages: driverDeliverShipmentsReducer,
  driver_uploaded_images: driverUploadImagesReducer,
  driver_loaded_packages: driverLoadedShipmentsReducer,
  shipping: shippingReducer,
  user: userReducer,
  orders: ordersReducer,
  packages: packagesReducer,
});

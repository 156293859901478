//To-do move all the API calls in this file
export const BACKEND_ROUTE_URI = "https://api.uucargo.ca/api";
export const NODE_ROUTE_URI = "https://api.uucargo.ca/v2";

// Authentication
export const MIDDLEWARE_LOGIN_API = function (email, password) {
  return `${BACKEND_ROUTE_URI}/userprofile/signin/?email=${email}&source=Email&password=${password}`;
};

export const BACKEND_LOGIN_API = `${BACKEND_ROUTE_URI}/userprofile/signin/`;
export const BACKEND_DRIVER_LOGIN_API = `${BACKEND_ROUTE_URI}/driver/signin/`;
export const BACKEND_CHANGE_PASSWORD_API = `${BACKEND_ROUTE_URI}/changepassword/`;

// Profile

export const BACKEND_ME_API = `${BACKEND_ROUTE_URI}/me`;
export const BACKEND_PROFILE_API = `${BACKEND_ROUTE_URI}/userprofile/`;

export const BACKEND_CHANGE_ROLE_API = (email) =>
  `${BACKEND_ROUTE_URI}/role/${email}`;

export const BACKEND_LICENSE_API = (email) =>
  `${BACKEND_ROUTE_URI}/license/${email}`;

// Payment
export const CREDIT_CARD_API = `${BACKEND_ROUTE_URI}/creditcard/`;
export const LIST_CREDIT_CARD_API = `${BACKEND_ROUTE_URI}/creditcards/`;

export const BACKEND_CREATE_PAYMENT_INTENT = `${BACKEND_ROUTE_URI}/guest/checkout`;

export const BACKEND_PAYMENT_CONFIRM = `${BACKEND_ROUTE_URI}/guest/checkout/succeed`;

// export const BACKEND_ORDERS_API = `${BACKEND_ROUTE_URI}/companyorders/info@jlinkcargo.ca`

// Address book
export const BACKEND_GET_ADDRESSBOOK = `${BACKEND_ROUTE_URI}/addressbooks`;

export const BACKEND_ADD_TO_ADDRESSBOOK = `${BACKEND_ROUTE_URI}/addressbook`;

export const BACKEND_UPDATE_USER_ADDRESS = (id) => {
  return `${BACKEND_ROUTE_URI}/addressbook/${id}`;
};

// Orders
export const BACKEND_ORDERS_API = `${BACKEND_ROUTE_URI}/orders/`;
export const BACKEND_GUEST_ORDERS_API = `${BACKEND_ROUTE_URI}/guestorders/`;
export const BACKEND_GUEST_ORDER_BY_ID_API = `${BACKEND_ROUTE_URI}/guestorder/`;

export const BACKEND_GET_ORDERS_API = `${BACKEND_ROUTE_URI}/userorders/`;

export const BACKEND_PACKAGES_API = `${BACKEND_ROUTE_URI}/packages/`;
export const BACKEND_GUEST_PACKAGES_API = `${BACKEND_ROUTE_URI}/guestpackages/`;

export const BACKEND_PRICE_ESTIMATE_API = `${BACKEND_ROUTE_URI}/estimate/`;

export const BACKEND_GUEST_PRICE_ESTIMATE_API = `${BACKEND_ROUTE_URI}/guestestimate/`;

export const BACKEND_ORDER_PRICE_API = `${BACKEND_ROUTE_URI}/orderprice/`;

export const BACKEND_GUEST_ORDER_PRICE_API = `${BACKEND_ROUTE_URI}/guestorderprice/`;

export const BACKEND_ORDER_CANCEL_API = `${BACKEND_ROUTE_URI}/cancelorder/`;

export const BACKEND_GUEST_ORDER_CANCEL_API = `${BACKEND_ROUTE_URI}/cancelguestorder/`;

export const BACKEND_ORDER_LABELS_API = `${BACKEND_ROUTE_URI}/shippinglabels/`;

export const BACKEND_REMOVE_PACKAGE_IMAGE_API = `${BACKEND_ROUTE_URI}/undoimage/`;

export const BACKEND_CLAIMED_SHIPMENTS_API = `${BACKEND_ROUTE_URI}/shipments/`;

export const BACKEND_ORDERS_BY_ORDERNUMBER_API = (orderNumber) => {
  return `${BACKEND_ROUTE_URI}/orders/${orderNumber}`;
};

export const BACKEND_ADDON_INFO_API = (id) => {
  return `${BACKEND_ROUTE_URI}/extraoptions/${id}`;
};

// Shipments
export const GET_AVAILABLE_SHIPMENTS = `${BACKEND_ROUTE_URI}/availableshipments/`;

export const GET_UNCONFIRMED_DELIVERED_SHIPMENTS = `${BACKEND_ROUTE_URI}/unconfirm_delivered_shipments/`;
export const GET_UNCONFIRMED_PICKEDUP_SHIPMENTS = `${BACKEND_ROUTE_URI}/unconfirm_pickedup_shipments/`;

export const CONFIRM_SHIPMENTS_DELIVERY = shipmentNumber => {
  return `${BACKEND_ROUTE_URI}/confirm_delivered_shipments/${shipmentNumber}`;
};

export const GET_SHIPMENTS_BY_ID_API = (shipmentID) => {
  return `${BACKEND_ROUTE_URI}/shipments/${shipmentID}`;
};

export const GET_PACKAGES_BY_ID_API = (packageID) => {
  return `${BACKEND_ROUTE_URI}/packages/${packageID}`;
};

export const GET_DRIVER_CLAIMED_SHIPMENTS = () => {
  return `${BACKEND_ROUTE_URI}/claimedshipments`;
};

export const GET_DRIVER_LOADED_SHIPMENTS = `${BACKEND_ROUTE_URI}/loadedshipments`;

export const DRIVER_LOAD_SHIPMENTS = `${BACKEND_ROUTE_URI}/loadshipments`;

export const GET_DRIVER_DELIVERED_SHIPMENTS = () => {
  return `${BACKEND_ROUTE_URI}/deliveredshipments`;
};

export const CREATE_NEW_MESSAGE_API = `https://admin.uucargo.ca/api/messages/O-P8X60Isj-0000000002?message=$`;

export const DRIVER_GET_ALL_TRACKING_NUMBERS = `${BACKEND_ROUTE_URI}/trackingnumbers`;

export const GET_TRACKING_INFO_BY_TRACKING_NUMBER = (trackingNumber) => {
  return `${BACKEND_ROUTE_URI}/trackingnumber/${trackingNumber}`;
};

export const DRIVER_CLAIM_SHIPMENTS_BY_SHIPMENT_ID = (id, quantity) => {
  return `${BACKEND_ROUTE_URI}/claim/${id}?quantity=${quantity}`;
};

export const DRIVER_CONFIRM_UNCLAIM = (id) => {
  return `${BACKEND_ROUTE_URI}/unclaim/${id}`;
};

export const DRIVER_UNCLAIM_SHIPMENT_BY_SHIPMENT_ID = (id) => {
  return `${BACKEND_ROUTE_URI}/unclaim/${id}`;
};

export const DRIVER_LOAD_SHIPMENTS_BY_SHIPMENT_ID = (id) => {
  return `${BACKEND_ROUTE_URI}/loadshipment/${id}`;
};

export const ADD_SHIPMENTS_TIPS = (id) => {
  return `${BACKEND_ROUTE_URI}/order/tips/${id}`;
};

export const DRIVER_DELIVER_SHIPMENT_BY_TRACKING_NUMBER = `${BACKEND_ROUTE_URI}/delivertrackingnumbers`;

//shipment static
export const SHIPMENT_STATIC = `${BACKEND_ROUTE_URI}/shipment/statistics`;

//shipment get bonus
export const CHECK_BONUS = (shipmentNumber) => {
  return `${BACKEND_ROUTE_URI}/shipmentbonus/${shipmentNumber}`;
};

// Settlements && Bookkeeper
export const GET_SETTLEMENTS = (userEmail) => {
  return `${BACKEND_ROUTE_URI}/driver/settlements/${userEmail}`;
};

export const GET_UNSETTLED_TRANSACTION = (userEmail) => {
  return `${BACKEND_ROUTE_URI}/driver/transactions/unsettled/${userEmail}`;
};

export const GET_SETTLEMENT_DETAIL = (userEmail, settlementNumber) => {
  return `${BACKEND_ROUTE_URI}/driver/settlement/${userEmail}/${settlementNumber}`;
};

export const SETTLEMENT_DISPUTE = (userEmail, settlementNumber) => {
  return `${BACKEND_ROUTE_URI}/driver/disputesettlement/${userEmail}/`;
};

export const DRIVER_SUMMARY = `${BACKEND_ROUTE_URI}/driver/settlement/summary/`;

// General email API

export const GENERAL_EMAIL = `${BACKEND_ROUTE_URI}/notify/routeprice`;

// Package images
export const GET_PACKAGE_IMAGE = `${BACKEND_ROUTE_URI}/images`;

export const UPLOAD_PACKAGE_IMAGE = `${BACKEND_ROUTE_URI}/uploadimage`;

//deliver shipments with upload images
export const DRIVER_UPLOAD_SHIPMENTS_IMAGES = `${BACKEND_ROUTE_URI}/uploadshipmentsimage`;

//create or update shipment appointment
export const DRIVER_UPDATE_SHIPMENT_APPOINTMENT = (shipmentID) => {
  return `${BACKEND_ROUTE_URI}/shipmentappointment/${shipmentID}`;
};

// Cargo tracking
export const GET_CARGO_CONTROL_TRACKING = `${NODE_ROUTE_URI}/cargo_control_tracking/list`;
export const GET_CARGO_CONTROL_TRANSACTION = `${NODE_ROUTE_URI}/cargo_control_transaction/list`;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { getShipmentItems, getPickUpDetails } from '../../actions/shipmentAction';
import { useHistory } from "react-router-dom";
// import Slide from 'react-reveal';
import {
  TextField,
  Button,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  Switch,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  Snackbar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ClearIcon from "@material-ui/icons/Clear";
import HelpIcon from "@material-ui/icons/Help";
import EventIcon from "@material-ui/icons/Event";

import PropTypes from "prop-types";
import { format } from "date-fns";
import axios from "axios";
import produce from "immer";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
// import NewCredit from '../CreditCard/newCredit'
import { getHeaders } from "../../Utils/fetchUtil";
import {
  BACKEND_ORDERS_API,
  BACKEND_PACKAGES_API,
  BACKEND_PRICE_ESTIMATE_API,
  LIST_CREDIT_CARD_API,
  BACKEND_ADDON_INFO_API,
  GENERAL_EMAIL,
  BACKEND_ADD_TO_ADDRESSBOOK,
} from "../../Utils/apiUrl";
import { CustomInput } from "../shared";
import LoadingContainer from "../shared/loadingContainer";
import { redirectHome } from "../../Utils/redirect";
import { errHandling } from "../../Utils/fetchUtil";
import { CardImages } from "../Images/cards/";
import { clearShippingInfo } from "../../slices/shippingSlice";
import AddCreditCardPage from "../Profiles/addCreditCardPage";
import PriceModule from "../shared/OrderDetail/priceModule";
import InsuranceIcon from "../Images/icons/insuranceIcon";
import ReviewOrderHeader from "./components/reviewOrderHeader";
import LoadingDock from "../Images/icons/LoadingDock.png";
import { getAddressBook } from "../../slices";

// Compare number with limit
const numberExceed = (num, limit) => {
  return parseFloat(num) > limit;
};

const ReviewAndConfirm = ({
  toFirst,
  toFourth,
  toConfirm,
  pallets,
  pickUpDetails,
}) => {
  const dispatch = useDispatch();
  // const pallets = useSelector((state) => state.shipping.shippingItems);
  // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
  const userEmail = useSelector((state) => state.user.email);
  const firstname = useSelector((state) => state.user.firstname);
  const lastname = useSelector((state) => state.user.lastname);
  const cardStatus = useSelector((state) => state.user.activeCard);
  const [extraMsg, setExtraMsg] = useState("");
  const [routeError, setRouteError] = useState(true);

  const [fetchingPrice, setFetchingPriceState] = useState(true);
  const [creatingOrder, setCreatingStatus] = useState(false);
  const [shippingPrice, setShippingPrice] = useState({});

  const [priceErrMsg, setPriceErrMsg] = useState("");
  const [cardErrMsg, setCardErrMsg] = useState("");

  const [cardLoading, setCardLoading] = useState(true);
  const [cardInfo, setCardInfo] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [sendingEmail, setSendingStatus] = useState(false);

  const [emailSentSuccess, setEmailSentSuccessState] = useState(false);
  const [emailSentFail, setEmailSentFailState] = useState(false);

  // Error indication to check the fee numbers when there are more than two packages
  const [extraFeeError, setExtraFeeError] = useState({
    pickupDock: false,
    deliverDock: false,
    appointment: false,
  });

  const [orderAddons, setOrderAddons] = useState({
    insurance: false,
    insuranceFee: null,
    pickupDock: null,
    pickupDockFee: "0",
    fixPickupDockFee: null,
    pickupTime: false,
    pickupTimeStart: "09:00",
    pickupTimeEnd: "12:00",
    deliverDock: null,
    deliverDockFee: "0",
    fixDeliverDockFee: null,
    deliverTime: false,
    deliverTimeStart: "09:00",
    deliverTimeEnd: "12:00",
    deliveryAppointment: false,
    appointmentFee: "0",
    appoinmentPhone: pickUpDetails.deliver.phone,
    invoice: false,
  });

  // useEffect(() => {
  //   console.log(orderAddons);
  // }, [orderAddons]);

  const history = useHistory();
  // const location = useLocation();

  useEffect(() => {
    const moreThanOne = moreThanOnePallet(pallets);
    if (numberExceed(orderAddons.pickupDockFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          pickupDock: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          pickupDock: false,
        };
      });

    if (numberExceed(orderAddons.deliverDockFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          deliverDock: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          deliverDock: false,
        };
      });

    if (numberExceed(orderAddons.appointmentFee, 0) && moreThanOne) {
      setExtraFeeError((prev) => {
        return {
          ...prev,
          appointment: true,
        };
      });
    } else
      setExtraFeeError((prev) => {
        return {
          ...prev,
          appointment: false,
        };
      });
  }, [orderAddons, pallets, setExtraFeeError]);

  useEffect(() => {
    const postData = {
      pickup_area: `${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province}`,
      deliver_area: `${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}`,
      goods: pallets.map((pallet) => {
        return {
          shipment_type_id: pallet.shipmentType,
          quantity:
            pallet.shipmentType === "ftruck" ||
            pallet.shipmentType === "ftrailer"
              ? "1"
              : pallet.quantity.toString(),
        };
      }),
    };
    console.log(postData);
    axios({
      method: "post",
      url: BACKEND_PRICE_ESTIMATE_API,
      data: JSON.stringify(postData),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => {
        setRouteError(false);
        setFetchingPriceState(false);
        setShippingPrice(response.data || {});
      })
      .catch((err) => {
        if (err.response?.data?.code !== "2005")
          errHandling(err, setPriceErrMsg);
        // setPriceErrMsg(err.response.data.message);
        setFetchingPriceState(false);
      });
  }, []);

  // Fetches data from backend/api/me to display userprofile data
  useEffect(() => {
    if (cardStatus === "active") {
      axios
        .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
        .then((response) => {
          setCardLoading(false);
          setCardInfo(response.data[0]);
          setReload(false);
        })
        .catch((err) => {
          // console.log(err);
          errHandling(err, setCardErrMsg);
          setCardLoading(false);
          setReload(false);
        });
    }
  }, [cardStatus]);

  // Fetch the fix dock usage fee
  useEffect(() => {
    if (
      orderAddons.pickupDock === "No" &&
      orderAddons.fixPickupDockFee === null
    ) {
      axios
        .get(BACKEND_ADDON_INFO_API("1"), { headers: getHeaders() })
        .then((response) => {
          // setCardLoading(false);
          // setCardInfo(response.data[0]);
          setOrderAddons(
            produce((draft) => {
              draft.fixPickupDockFee = response.data.value;
            })
          );
        })
        .catch((err) => {
          console.log(err.message);
          // errHandling(err, setCardErrMsg);
          // setCardLoading(false);
        });
    }
  }, [orderAddons.pickupDock, orderAddons.fixPickupDockFee]);

  // Fetch the fix dock usage fee
  useEffect(() => {
    if (
      orderAddons.deliverDock === "No" &&
      orderAddons.fixDeliverDockFee === null
    ) {
      axios
        .get(BACKEND_ADDON_INFO_API("2"), { headers: getHeaders() })
        .then((response) => {
          // setCardLoading(false);
          // setCardInfo(response.data[0]);
          setOrderAddons(
            produce((draft) => {
              draft.fixDeliverDockFee = response.data.value;
            })
          );
        })
        .catch((err) => {
          console.log(err.message);
          // errHandling(err, setCardErrMsg);
          // setCardLoading(false);
        });
    }
  }, [orderAddons.deliverDock, orderAddons.fixDeliverDockFee]);

  useEffect(() => {
    if (cardStatus === "active" && reload) {
      axios
        .get(LIST_CREDIT_CARD_API, { headers: getHeaders() })
        .then((response) => {
          setCardLoading(false);
          setCardInfo(response.data[0]);
        })
        .catch((err) => {
          // console.log(err);
          errHandling(err, setCardErrMsg);
          setCardLoading(false);
        });
    }
  }, [cardStatus, reload]);

  const classes = useStyles();
  const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // const [pallets, setPallets] = useState(shippingItems)
  // const [pickUpDetails, setPickUpDetails] = useState(initPickUpDetails)

  // Change the toggle bottom
  const handleItemChange = (fieldName) => (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (
      fieldName === "insurance" ||
      fieldName === "pickupTime" ||
      fieldName === "deliverTime" ||
      fieldName === "deliveryAppointment" ||
      fieldName === "invoice"
    ) {
      value = e.target.checked;
    }

    setOrderAddons(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  // Call send email API when there is no route info
  const handleEmail = () => {
    const postObj = {
      from: userEmail,
      name: `${firstname} ${lastname}`,
      subject: "Attention: route price is missing",
      text: `No Route from ${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province} to ${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}`,
    };
    setSendingStatus(true);
    console.log(postObj);
    axios({
      method: "post",
      url: GENERAL_EMAIL,
      data: JSON.stringify(postObj),
      headers: {
        ...getHeaders(),
        "Content-Type": "application/json",
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((res) => {
        setSendingStatus(false);
        setEmailSentSuccessState(true);
      })
      .catch((error) => {
        setSendingStatus(false);
        setEmailSentFailState(true);
      });
  };

  const handleAddInsurance = (e) => {
    handleItemChange("insurance")(e);
    if (e.target.checked)
      axios
        .get(BACKEND_ADDON_INFO_API("10"), { headers: getHeaders() })
        .then((response) => {
          // setCardLoading(false);
          // setCardInfo(response.data[0]);
          setOrderAddons(
            produce((draft) => {
              draft.insuranceFee = response.data.value;
            })
          );
        })
        .catch((err) => {
          console.log(err.message);
          // errHandling(err, setCardErrMsg);
          // setCardLoading(false);
        });
  };

  // Checker for the input address
  const getEmptyStatus = (_formatData) => {
    const { pickUp, deliver } = _formatData;
    const pickUpEmpty =
      // pickUp.zipCode.trim() === "" ||
      pickUp.address.trim() === "" ||
      pickUp.city.trim() === "" ||
      pickUp.province.trim() === "";
    const deliverEmpty =
      // deliver.zipCode.trim() === "" ||
      deliver.address.trim() === "" ||
      deliver.city.trim() === "" ||
      deliver.province.trim() === "";
    if (pickUpEmpty || deliverEmpty) return true;
  };

  const getPickUpEmptyStatus = (_formatData) => {
    const { pickUp } = _formatData;
    const pickUpEmpty =
      // pickUp.zipCode.trim() === "" ||
      pickUp.address.trim() === "" ||
      pickUp.city.trim() === "" ||
      pickUp.province.trim() === "";
    return pickUpEmpty;
  };

  const getDeliverEmptyStatus = (_formatData) => {
    const { deliver } = _formatData;
    const deliverEmpty =
      // deliver.zipCode.trim() === "" ||
      deliver.address.trim() === "" ||
      deliver.city.trim() === "" ||
      deliver.province.trim() === "";
    return deliverEmpty;
  };

  const saveAndReturn = () => {
    localStorage.setItem(
      "shippingData",
      JSON.stringify({ pickUpDetails: pickUpDetails, shippingItems: pallets })
    );
    dispatch(clearShippingInfo());
    redirectHome(history);
  };

  const firstLetterUpper = (_string) => {
    return _string.replace(/^\S/, (s) => s.toUpperCase());
  };

  const onRedicrect = () => {
    // history.push({
    //   pathname: "/profile/add-card",
    //   state: { from: location.pathname },
    // });
    setDialogOpen(true);
  };

  const handleSubmitOrder = async () => {
    setCreatingStatus(true);

    const postData = {
      sender: "Individual",
      user_email: userEmail,
      message: extraMsg,
      pickup_address: pickUpDetails.pickUp.aptInfo
        ? `${pickUpDetails.pickUp.address}, ${pickUpDetails.pickUp.aptInfo}, ${
            pickUpDetails.pickUp.city
          }, ${pickUpDetails.pickUp.province}${
            pickUpDetails.pickUp.zipCode
              ? " " + pickUpDetails.pickUp.zipCode
              : ""
          }`
        : `${pickUpDetails.pickUp.address}, ${pickUpDetails.pickUp.city}, ${
            pickUpDetails.pickUp.province
          }${
            pickUpDetails.pickUp.zipCode
              ? " " + pickUpDetails.pickUp.zipCode
              : ""
          }`,
      pickup_area: `${pickUpDetails.pickUp.city}, ${pickUpDetails.pickUp.province}`,
      pickup_phone: pickUpDetails.pickUp.phone,
      deliver_address: pickUpDetails.deliver.aptInfo
        ? `${pickUpDetails.deliver.address}, ${
            pickUpDetails.deliver.aptInfo
          }, ${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}${
            pickUpDetails.deliver.zipCode
              ? " " + pickUpDetails.deliver.zipCode
              : ""
          }`
        : `${pickUpDetails.deliver.address}, ${pickUpDetails.deliver.city}, ${
            pickUpDetails.deliver.province
          }${
            pickUpDetails.deliver.zipCode
              ? " " + pickUpDetails.deliver.zipCode
              : ""
          }`,
      deliver_area: `${pickUpDetails.deliver.city}, ${pickUpDetails.deliver.province}`,
      delivery_phone: pickUpDetails.deliver.phone,
      schedule_pickup: format(pickUpDetails.selectedDate, "yyyy-MM-dd HH:mm"),
      status: "active",
      shipping_status: "created",
    };
    postData.option_pickup_loading_dock =
      orderAddons.pickupDock === "Yes" ? "y" : "n";
    postData.option_deliver_loading_dock =
      orderAddons.deliverDock === "Yes" ? "y" : "n";
    postData.option_insurance = orderAddons.insurance ? "y" : "n";
    if (orderAddons.pickupDock === "Yes") {
      postData.fee_pickup_loading_dock = orderAddons.pickupDockFee || "0";
    }
    if (orderAddons.deliverDock === "Yes") {
      postData.fee_deliver_loading_dock = orderAddons.deliverDockFee || "0";
    }
    if (orderAddons.pickupTime) {
      postData.option_pickup_time_range = `${orderAddons.pickupTimeStart} - ${orderAddons.pickupTimeEnd}`;
    }
    if (orderAddons.deliverTime) {
      postData.option_deliver_time_range = `${orderAddons.deliverTimeStart} - ${orderAddons.deliverTimeEnd}`;
    }
    if (orderAddons.deliveryAppointment) {
      postData.option_deliver_appointment = "y";
      postData.fee_deliver_appointment = orderAddons.appointmentFee || "0";
      postData.phone_deliver_appointment = orderAddons.appoinmentPhone;
    }

    console.log(postData);

    try {
      const orderResponse = await axios({
        method: "post",
        url: BACKEND_ORDERS_API,
        data: JSON.stringify(postData),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });
      const orderNum = orderResponse.data.success.order_number;

      const pickupAddressData = {
        address: pickUpDetails.pickUp.address,
        city: pickUpDetails.pickUp.city,
        province: pickUpDetails.pickUp.province,
        postalcode: pickUpDetails.pickUp.zipCode || "",
        phone: pickUpDetails.pickUp.phone,
      };

      await axios({
        method: "post",
        url: BACKEND_ADD_TO_ADDRESSBOOK,
        data: JSON.stringify(pickupAddressData),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      const deliverAddressData = {
        address: pickUpDetails.deliver.address,
        city: pickUpDetails.deliver.city,
        province: pickUpDetails.deliver.province,
        postalcode: pickUpDetails.deliver.zipCode || "",
        phone: pickUpDetails.deliver.phone || "",
      };

      await axios({
        method: "post",
        url: BACKEND_ADD_TO_ADDRESSBOOK,
        data: JSON.stringify(deliverAddressData),
        headers: {
          ...getHeaders(),
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
      });

      dispatch(getAddressBook(true));

      const packageData = pallets.map((pallet, index) => {
        const palletInfo = {
          order_number: orderNum,
          item_number: index + 1,
          item_name: pallet.description,
          shipment_type_id: pallet.shipmentType,
          length: pallet.length,
          width: pallet.width,
          weight: pallet.weight,
          height: pallet.height,
          number: pallet.quantity,
          available_count: pallet.quantity,
          status: "created",
          // unit_price: shipmentTypesDetails[pallet.shippmentType].unit_price,
        };
        console.log(palletInfo);
        return axios({
          method: "post",
          url: BACKEND_PACKAGES_API,
          data: JSON.stringify(palletInfo),
          headers: {
            ...getHeaders(),
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        });
      });
      await axios.all(packageData);
      setCreatingStatus(false);
      dispatch(clearShippingInfo());
      localStorage.removeItem("shippingData");
      toConfirm(orderNum);
    } catch (error) {
      console.log(error);
      setCreatingStatus(false);
    }
  };

  const handleChange = (event) => {
    setExtraMsg(event.target.value);
  };

  const getDockFee = (dock, dockFee, fixDockFee) => {
    if (dock === null) return null;
    if (dock === "Yes") return dockFee || "0";
    return fixDockFee;
  };

  const moreThanOnePallet = (_pallets) => {
    return _pallets.length > 1;
  };

  let priceComponent = (
    <>
      <h4 className="orange-title">Price:</h4>
      <PriceModule
        priceErrMsg={priceErrMsg}
        fetchingPrice={fetchingPrice}
        shippingPrice={shippingPrice}
        addon={{
          pickupDockFee: getDockFee(
            orderAddons.pickupDock,
            orderAddons.pickupDockFee,
            orderAddons.fixPickupDockFee
          ),
          deliverDockFee: getDockFee(
            orderAddons.deliverDock,
            orderAddons.deliverDockFee,
            orderAddons.fixDeliverDockFee
          ),
          insuranceFee: orderAddons.insurance ? orderAddons.insuranceFee : null,
          appointmentFee: orderAddons.deliveryAppointment
            ? orderAddons.appointmentFee || "0"
            : null,
        }}
      />
    </>
  );

  if (!fetchingPrice && routeError) {
    priceComponent = (
      <Box>
        <Box fontSize="1.2rem" fontWeight="700">
          Your route is not available.{" "}
        </Box>
        <Box fontSize="1.2rem">- You can contact us:</Box>
        <Box>- Phone:</Box>
        <Box>
          - Email:{" "}
          <Box component="span" fontWeight="600">
            support@uucargo.ca
          </Box>
        </Box>
        <Box mt={1}>
          Click{" "}
          <Button
            variant="contained"
            color="primary"
            onClick={handleEmail}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
              width: "4rem",
            }}
          >
            {sendingEmail ? (
              <CircularProgress size={28} style={{ color: "black" }} />
            ) : (
              "Here"
            )}
          </Button>{" "}
          to suggest the route to us
        </Box>
      </Box>
    );
  }

  const cardContainer = (_loading, _cardInfo, _cardStatus, _errMsg) => {
    if (!_loading && _errMsg)
      return (
        <Box pt="1vh" width="100%">
          <Alert severity="error" classes={{ root: classes.alertRoot }}>
            {_errMsg}
          </Alert>
        </Box>
      );

    if (!_loading && !_cardInfo)
      return (
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={onRedicrect}
        >
          Add a Card
        </Button>
      );

    if (!_loading && _cardInfo)
      return (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box className={classes.bodyText}>
            {_cardStatus === "active" ? (
              <>
                <img
                  src={CardImages[firstLetterUpper(cardInfo.card_type)]}
                  alt={firstLetterUpper(cardInfo.card_type)}
                  width="50px"
                  align="bottom"
                  style={{ padding: "0 5px" }}
                />
                {`${firstLetterUpper(cardInfo.card_type)} ending in ${
                  cardInfo.card_last_four_digits
                }`}
              </>
            ) : (
              "You don't have any credit card registerd"
            )}
          </Box>
          <Button
            // className={classes.button}
            variant="contained"
            color="primary"
            onClick={onRedicrect}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            {_cardStatus === "active" ? "Replace" : "Add"}
          </Button>
        </Box>
      );

    return <LoadingContainer />;
  };

  return (
    <Box width="100%">
      <div
        className={matches ? classes.smRootContainer : classes.rootContainer}
      >
        <h2 className="orange-title">Quote Summary:</h2>
        <ReviewOrderHeader
          pickupEmpty={getPickUpEmptyStatus(pickUpDetails)}
          deliverEmpty={getDeliverEmptyStatus(pickUpDetails)}
          pickUpDetails={pickUpDetails}
          toFirst={toFirst}
          toFourth={toFourth}
          pallets={pallets}
        />
        <hr />

        <Box>
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className="bodytext">
                <InsuranceIcon width="23" fill="seagreen" />
                <Box component="span"> Add insurance?</Box>
              </Box>
              <Switch
                color="primary"
                checked={orderAddons.insurance}
                onChange={handleAddInsurance}
              />
            </Box>
            <Collapse in={orderAddons.insurance}>
              <Box
                display="flex"
                ml={2}
                mr={2}
                mb={2}
                mt={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Insurance fee</Box>
                <Box component="span" fontSize="1.2rem">{`C$ ${
                  orderAddons.insuranceFee ? orderAddons.insuranceFee : ""
                }`}</Box>
              </Box>
            </Collapse>
          </Box>
          {/* <Box display="flex" justifyContent="space-between">
            <span className="bodytext">Need invoice?</span>
            <Switch
              color="primary"
              checked={orderAddons.invoice}
              onChange={handleItemChange("invoice")}
            />
          </Box> */}
          <Box className={classes.summaryHeader} mt={2} fontWeight="700">
            Pickup Services
          </Box>
          <Box
            pt={1}
            className={
              orderAddons.pickupDock === "Yes" ||
              orderAddons.pickupDock === "No"
                ? classes.feeWrapper
                : ""
            }
          >
            <Box component="span" className="bodytext" flexBasis="200px">
              <img
                src={LoadingDock}
                alt="Loading Dock"
                width="40px"
                align="bottom"
                style={{
                  backgroundColor: "seagreen",
                }}
              />{" "}
              Is there a loading dock at the pick up place *?{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.uucargo.ca/support/"
              >
                <HelpIcon style={{ color: "grey" }} />
              </a>
            </Box>
            <Box display="flex" justifyContent="end" mt={1}>
              <RadioGroup
                row
                aria-label="dock"
                name="dock"
                value={orderAddons.pickupDock}
                onChange={handleItemChange("pickupDock")}
                className={orderAddons.pickupDock ? "" : classes.radioWrapper}
              >
                <FormControlLabel
                  value="No"
                  control={<Radio color="primary" />}
                  label="No"
                />
                <FormControlLabel
                  value="Yes"
                  control={<Radio color="primary" />}
                  label="Yes"
                />
              </RadioGroup>
            </Box>
            <Collapse
              in={
                orderAddons.pickupDock === "Yes" ||
                orderAddons.pickupDock === "No"
              }
            >
              {orderAddons.pickupDock === "Yes" ? (
                <>
                  <Box
                    display="flex"
                    ml={2}
                    mr={2}
                    pb={2}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box mb={0.5}>Usage fee</Box>
                    <Box width="10rem">
                      <CustomInput
                        value={orderAddons.pickupDockFee}
                        onChange={handleItemChange("pickupDockFee")}
                        type="currency"
                        error={extraFeeError.pickupDock}
                      />
                    </Box>
                  </Box>
                  <Box
                    pl={2}
                    pr={1}
                    color="red"
                    display={extraFeeError.pickupDock ? "block" : "none"}
                  >
                    Usage fee cannot be none-zero if the order has more than one
                    package.
                  </Box>
                </>
              ) : (
                <Box
                  display="flex"
                  ml={2}
                  mr={2}
                  mb={2}
                  mt={1}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box>Loading dock usage fee</Box>
                  <Box component="span" fontSize="1.2rem">{`C$ ${
                    orderAddons.fixPickupDockFee
                      ? orderAddons.fixPickupDockFee
                      : ""
                  }`}</Box>
                </Box>
              )}
            </Collapse>
          </Box>

          <Box
            pt={1}
            className={orderAddons.pickupTime ? classes.feeWrapper : ""}
          >
            <Box display="flex" justifyContent="space-between">
              <Box component="span" className="bodytext">
                <AccessAlarmsIcon /> Specify pickup time?
              </Box>
              <Switch
                color="primary"
                checked={orderAddons.pickupTime}
                onChange={handleItemChange("pickupTime")}
              />
            </Box>
            <Collapse in={orderAddons.pickupTime}>
              <Box display="flex" justifyContent="space-around" pb={2}>
                <TextField
                  id="start time"
                  label="From"
                  type="time"
                  value={orderAddons.pickupTimeStart}
                  onChange={handleItemChange("pickupTimeStart")}
                  className={classes.timePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <TextField
                  id="End time"
                  label="To"
                  type="time"
                  value={orderAddons.pickupTimeEnd}
                  onChange={handleItemChange("pickupTimeEnd")}
                  className={classes.timePicker}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </Box>
            </Collapse>
          </Box>
        </Box>

        <Box className={classes.summaryHeader} mt={2} fontWeight="700">
          Delivery Services
        </Box>
        <Box
          pt={1}
          className={
            orderAddons.deliverDock === "Yes" ||
            orderAddons.deliverDock === "No"
              ? classes.feeWrapper
              : ""
          }
        >
          <Box component="span" className="bodytext" flexBasis="200px">
            <img
              src={LoadingDock}
              alt="Loading Dock"
              width="40px"
              align="bottom"
              style={{
                backgroundColor: "seagreen",
              }}
            />{" "}
            Is there a loading dock at the deliver place *?{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.uucargo.ca/support/"
            >
              <HelpIcon style={{ color: "grey" }} />
            </a>
          </Box>
          <Box display="flex" justifyContent="end" mt={1}>
            <RadioGroup
              row
              aria-label="dock"
              name="dock"
              value={orderAddons.deliverDock}
              onChange={handleItemChange("deliverDock")}
              className={orderAddons.deliverDock ? "" : classes.radioWrapper}
            >
              <FormControlLabel
                value="No"
                control={<Radio color="primary" />}
                label="No"
              />
              <FormControlLabel
                value="Yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
            </RadioGroup>
          </Box>
          <Collapse
            in={
              orderAddons.deliverDock === "Yes" ||
              orderAddons.deliverDock === "No"
            }
          >
            {orderAddons.deliverDock === "Yes" ? (
              <>
                <Box
                  display="flex"
                  ml={2}
                  mr={2}
                  mb={2}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box mb={0.5}>Usage fee</Box>
                  <Box width="10rem">
                    <CustomInput
                      value={orderAddons.deliverDockFee}
                      onChange={handleItemChange("deliverDockFee")}
                      type="currency"
                      error={extraFeeError.deliverDock}
                    />
                  </Box>
                </Box>
                <Box
                  pl={2}
                  pr={1}
                  color="red"
                  display={extraFeeError.deliverDock ? "block" : "none"}
                >
                  Usage fee cannot be none-zero if the order has more than one
                  package.
                </Box>
              </>
            ) : (
              <Box
                display="flex"
                ml={2}
                mr={2}
                mb={2}
                mt={1}
                justifyContent="space-between"
                alignItems="center"
              >
                <Box>Loading dock usage fee</Box>
                <Box component="span" fontSize="1.2rem">{`C$ ${
                  orderAddons.fixDeliverDockFee
                    ? orderAddons.fixDeliverDockFee
                    : ""
                }`}</Box>
              </Box>
            )}
          </Collapse>
        </Box>

        <Box
          pt={1}
          className={orderAddons.deliverTime ? classes.feeWrapper : ""}
        >
          <Box display="flex" justifyContent="space-between">
            <Box component="span" className="bodytext">
              <AccessAlarmsIcon /> Specify deliver time?
            </Box>
            <Switch
              color="primary"
              checked={orderAddons.deliverTime}
              onChange={handleItemChange("deliverTime")}
            />
          </Box>
          <Collapse in={orderAddons.deliverTime}>
            <Box display="flex" justifyContent="space-around" pb={2}>
              <TextField
                id="start time"
                label="From"
                type="time"
                value={orderAddons.deliverTimeStart}
                onChange={handleItemChange("deliverTimeStart")}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
              <TextField
                id="End time"
                label="To"
                type="time"
                value={orderAddons.deliverTimeEnd}
                onChange={handleItemChange("deliverTimeEnd")}
                className={classes.timePicker}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  step: 300, // 5 min
                }}
              />
            </Box>
          </Collapse>
        </Box>

        <Box
          pt={1}
          pb={1}
          className={orderAddons.deliveryAppointment ? classes.feeWrapper : ""}
        >
          <Box display="flex" justifyContent="space-between">
            <Box component="span" className="bodytext">
              <EventIcon /> Need a delivery appointment?
            </Box>
            <Switch
              color="primary"
              checked={orderAddons.deliveryAppointment}
              onChange={handleItemChange("deliveryAppointment")}
            />
          </Box>
          <Collapse in={orderAddons.deliveryAppointment}>
            <Box m={2}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mb={0.5}>Phone No.</Box>
                <Box width="10rem">
                  <CustomInput
                    value={orderAddons.appoinmentPhone}
                    onChange={handleItemChange("appoinmentPhone")}
                    type="number"
                  />
                </Box>
              </Box>
              <Box
                mt={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box mb={0.5}>Appoinment fee</Box>
                <Box width="10rem">
                  <CustomInput
                    value={orderAddons.appointmentFee}
                    onChange={handleItemChange("appointmentFee")}
                    type="currency"
                    error={extraFeeError.appointment}
                  />
                </Box>
              </Box>
              <Box
                pt={1}
                color="red"
                display={extraFeeError.appointment ? "block" : "none"}
              >
                Usage fee cannot be none-zero if the order has more than one
                package.
              </Box>
            </Box>
          </Collapse>
        </Box>

        <div className={classes.divider} />

        {priceErrMsg && !fetchingPrice ? (
          <Box pt="1vh" width="100%">
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              {priceErrMsg}
            </Alert>
          </Box>
        ) : (
          priceComponent
        )}

        <div className={classes.divider} />

        {cardContainer(cardLoading, cardInfo, cardStatus, cardErrMsg)}

        <Box pt="1vh" width="100%">
          {cardStatus !== "active" ? (
            <Alert severity="error">
              Please add one credit card before you place the order.
            </Alert>
          ) : (
            ""
          )}
        </Box>

        <hr />
        <Box className="subtitle" mb={2}>
          <p>Additional Message</p>
          <TextField
            className={classes.textfield}
            InputProps={{ disableUnderline: true }}
            onChange={handleChange}
            variant="filled"
            rows={2}
            label="Text here"
            multiline
          />
        </Box>

        <Box pt="1vh" width="100%">
          {getEmptyStatus(pickUpDetails) ? (
            <Alert severity="error" classes={{ root: classes.alertRoot }}>
              Please enter all the address information.
            </Alert>
          ) : (
            ""
          )}
        </Box>

        <Box display="flex" justifyContent="space-between" mt="2vh">
          <Box fontSize="1rem" mr={5}>
            <Button onClick={saveAndReturn} className={classes.textButton}>
              Save & <br />
              Go to home
            </Button>
          </Box>
          <Button
            disabled={
              getEmptyStatus(pickUpDetails) ||
              creatingOrder ||
              cardStatus !== "active" ||
              extraFeeError.pickupDock ||
              extraFeeError.deliverDock ||
              extraFeeError.appointment ||
              routeError
            }
            color="primary"
            variant="contained"
            className={matches ? classes.smButton : classes.button}
            onClick={handleSubmitOrder}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
            }}
          >
            {creatingOrder ? <CircularProgress size={24} /> : "Confirm Order"}
          </Button>
        </Box>
        <Dialog
          maxWidth="sm"
          fullWidth
          open={dialogOpen}
          onClose={() => {
            setReload(true);
            setDialogOpen(false);
          }}
        >
          <div className={classes.dialogTitle}>
            <Box fontSize="1.5rem"></Box>
            <IconButton
              // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
              style={{ fill: "green" }}
              className={classes.closeBtn}
              onClick={() => {
                setReload(true);
                setDialogOpen(false);
              }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          <AddCreditCardPage addCard={true} popUp={true} />
        </Dialog>
      </div>

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={emailSentSuccess}
        onClose={() => setEmailSentSuccessState(false)}
        message="Send email successfully"
        autoHideDuration={3000}
      />

      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={emailSentFail}
        onClose={() => setEmailSentFailState(false)}
        message="Failed to send email"
        autoHideDuration={3000}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  rootContainer: {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // flexDirection: 'column',
    margin: "0 auto",
    width: "40vw",
    minWidth: "500px",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  smRootContainer: {
    width: "100%",
    paddingLeft: "3vw",
    paddingRight: "3vw",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  button: {
    borderRadius: 5,
    fontSize: "1.2rem",
    backgroundColor: "#ffa726",
    width: "14rem",
  },
  smButton: {
    borderRadius: 5,
    fontSize: "1rem",
    backgroundColor: "#ffa726",
    width: "12rem",
  },
  textfield: {
    width: "100%",
  },
  dockerFee: {
    width: "4rem",
  },
  feeWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    marginBottom: theme.spacing(2),
    backgroundColor: "#FEEAAE",
  },
  summaryHeader: {
    fontSize: "16pt",
    color: "rgb(141, 173, 155)",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  summaryBodyText: {
    fontSize: "13pt",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  textButton: {
    color: "blue",
  },
  bodyText: {
    fontSize: "1rem",
    color: "seagreen",
    fontFamily: "Montserrat",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  timePicker: {
    width: 160,
  },
  divider: {
    borderBottom: "2px solid rgba(0,0,0,0.3)",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  radioWrapper: {
    border: "1px solid red",
    paddingLeft: theme.spacing(1),
    borderRadius: "5px",
  },
}));

ReviewAndConfirm.propTypes = {
  toFirst: PropTypes.func.isRequired,
  toFourth: PropTypes.func.isRequired,
  toConfirm: PropTypes.func.isRequired,
  pickUpDetails: PropTypes.object,
  pallets: PropTypes.array,
};

// const mapStateToProps = state => ({
//     shippingItems: state.shippingItems
// })

// export default connect(mapStateToProps, {getShipmentItems, getPickUpDetails})(ReviewAndConfirm);
export default ReviewAndConfirm;

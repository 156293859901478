import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  // CardMedia,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import NoShipmentsLayout from "../Layouts/NoShipmentsLayout";

const useStyles = makeStyles((theme) => ({
  mapList: {
    display: "flex",
    flexDirection: "row",
    overflowX: "scroll",
    overflowY: "hidden",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    marginBottom: "10px",
  },
  listItem: {
    listStyle: "none",
    marginLeft: "3px",
    marginRight: "3px",
  },
  img: {
    height: "130px",
  },
  cardButtonContainer: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  "::-webkit-scrollbar": {
    width: "0" /* Remove scrollbar space */,
    background: "transparent" /* Optional: just make scrollbar invisible */,
  },
  card: {
    minHeight: "330px",
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
  },
  priceAndWeight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  pkgImg: {
    margin: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
}));

const MapList = ({ shipments, handleCardClick }) => {
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);

  const goToShipmentDetailPage = (sNum) => {
    let path = `/driver/available_shipments/${sNum}`;
    history.push(path);
  };

  useEffect(() => {
    console.log("shipments status in maplist", shipments);
    if (shipments.length > 0) {
      setLoading(false);
    }
  }, [shipments]);

  return (
    <>
      {shipments.length !== 0 ? (
        <div className={classes.mapList} id="mapList">
          {shipments.map((s, index) => {
            return (
              <li
                key={s.shipment_number}
                className={classes.listItem}
                onClick={() =>
                  handleCardClick(s.latitude, s.longtitude, s.shipment_number)
                }
              >
                <Card
                  id={`cardItem-${s.shipment_number}`}
                  className={classes.card}
                >
                  <CardActionArea>
                    {/* <CardMedia
                      className={classes.img}
                      image={s.image_location}
                      title="package"
                    /> */}
                    <Box
                      height="80px"
                      width="80px"
                      position="relative"
                      style={{ margin: "0 auto" }}
                    >
                      <img
                        src={s.image_location || s.cover_image}
                        alt={`order-${s.shipment_number}`}
                        className={classes.pkgImg}
                      />
                    </Box>
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h6">
                        {s.item_name}
                      </Typography>
                      <Typography variant="subtitle2" align="left">
                        {s.shipment_number}
                      </Typography>
                      <Typography variant="caption" align="left">
                        Pickup from: {s.pickup_city}
                      </Typography>
                      <Typography variant="caption" align="left">
                        Deliver to: {s.deliver_city}
                      </Typography>
                      <Typography variant="caption" align="left">
                        Pickup Date: {s.schedule_pickup.split(" ")[0]}
                      </Typography>
                      <div className={classes.priceAndWeight}>
                        <Typography variant="caption" align="left">
                          {s.weight}lb
                        </Typography>
                        <Typography variant="caption" align="left">
                          ${s.unit_price}/Unit
                        </Typography>
                        <Typography variant="caption" align="left">
                          Total: ${s.total_price}
                        </Typography>
                      </div>
                    </CardContent>
                  </CardActionArea>
                  <CardActions className={classes.cardButtonContainer}>
                    <Button
                      size="small"
                      color="primary"
                      onClick={() => goToShipmentDetailPage(s.shipment_number)}
                    >
                      Detail
                    </Button>
                  </CardActions>
                </Card>
              </li>
            );
          })}
        </div>
      ) : (
        <NoShipmentsLayout message={"No shipments found."} />
      )}
    </>
  );
};

export default MapList;

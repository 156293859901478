import React, { useState, useEffect } from "react";
// import QuoteSummary from "../Shipping/quoteSummary";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  // TextField,
  // Snackbar,
  Dialog,
  // DialogTitle,
  // CircularProgress,
  // Container,
  Box,
  IconButton,
  // Grid,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
// import orange from '@material-ui/core/colors/orange';
// import { Alert } from "@material-ui/lab";
import axios from "axios";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
// import EventIcon from "@material-ui/icons/Event";

import { GET_CARGO_CONTROL_TRACKING } from "../../../Utils/apiUrl";
import {
  // getHeaders,
  getURLParams,
  // errHandling,
} from "../../../Utils/fetchUtil";
import LoadingContainer from "../../shared/loadingContainer";
import { getAccessToken } from "../../../Utils/doToken";

const courier = "J-LINK";

const ShipmentDetails = () => {
  // const matches = useMediaQuery(theme => theme.breakpoints.down('sm'));
  const matches = useMediaQuery("(max-width:800px)");

  const classes = useStyles();

  const { ids } = useParams();
  const history = useHistory();

  const [shipmentData, setShipmentData] = useState([]);
  const [loading, setDataLoading] = useState(true);
  const [isSignInRequestWindowOpen, setSignInRequestWindowOpenState] =
    useState(false);

  useEffect(() => {
    axios
      .get(
        getURLParams(GET_CARGO_CONTROL_TRACKING, {
          ccn: ids,
        })
      )
      .then((response) => {
        // response.data.data && dispatch(setOrders({orders: response.data.data}));
        // if (response.data.data && response.data.data[0])
        //   setLatestOrderData(response.data.data[0]);
        setShipmentData(response.data);
        setDataLoading(false);
        // setLastestShippingLoading(false);
      })
      .catch((err) => {
        // setLastestShippingLoading(false);
        // errHandling(err, setErrorMsg);
        console.log(err.message);
        setDataLoading(false);
      });
  }, [ids]);

  const handleLinkClick = (_id) => {
    const token = getAccessToken("access_token");
    if (Boolean(token)) {
      history.push(`/cargo-control-tracking/transactions/${_id}`);
    } else {
      setSignInRequestWindowOpenState(true);
    }
  };

  return loading ? (
    <LoadingContainer />
  ) : (
    <>
      {shipmentData.map((shipment, i, list) => (
        <Box
          className={matches ? classes.smLayout : classes.wideLayout}
          mb={i + 1 !== list.length ? 6 : 0}
          key={shipment.cargo_control_no}
        >
          <Box
            className={
              matches
                ? classes.smLeftColumn
                : `${classes.leftColumn} ${classes.firstColumn}`
            }
          >
            <Box mb={2}>
              <Box className={classes.sessionHeader}>Cargo</Box>
              <div className={classes.dividerWithoutMargin} />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box className={classes.priceBodyText}>Primary CC #</Box>
                <Box className={classes.priceBodyText}>
                  {/* <a
                    href={`/shipment-details-transaction/${shipment.cargo_control_no}`}
                  > */}
                  <Button
                    style={{ color: "blue", textDecoration: "underline" }}
                    onClick={() => handleLinkClick(shipment.cargo_control_no)}
                  >
                    {shipment.cargo_control_no}
                  </Button>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Container #</Box>
                <Box className={classes.priceBodyText}>
                  {shipment.container_no}
                </Box>
              </Box>
            </Box>

            <Box mb={2}>
              <Box className={classes.sessionHeader}>Status</Box>
              <div className={classes.divider} />
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Status</Box>
                <div>
                  <Box className={classes.priceBodyText}>
                    {shipment.current_status}
                  </Box>
                </div>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box className={classes.priceBodyText}>Released Date</Box>
                <Box className={classes.priceBodyText}>
                  {shipment.released_date?.split("T")[0] || ""}
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Box
                  className={classes.priceBodyText}
                  flexBasis="125px"
                  flexShrink="0"
                >
                  Message Code
                </Box>
                <Box className={classes.priceBodyText}>
                  {shipment.code_text}
                </Box>
              </Box>
            </Box>
          </Box>

          <Box flexGrow={1}>
            <Box mb={2}>
              <Box className={classes.sessionHeader}>Customs</Box>
              <div className={classes.divider} />
              <Box>
                <Box className={classes.priceBodyText}>{shipment.customs}</Box>
              </Box>
              <Box>
                <Box className={classes.priceBodyText}>
                  {shipment.customs_release_date?.split("T")[0] || ""}
                </Box>
              </Box>
            </Box>

            <Box>
              <Box className={classes.sessionHeader}>Courier</Box>
              <div className={classes.divider} />
              <Box>
                <Box className={classes.priceBodyText}>{courier}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ))}

      <Box className={classes.getCodeButton} mt={4}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={() => {
            window.print();
            return false;
          }}
          style={{
            textTransform: "unset",
            fontSize: "1rem",
          }}
        >
          Print this page
        </Button>
      </Box>
      <br />

      <Dialog
        maxWidth="sm"
        fullWidth
        open={isSignInRequestWindowOpen}
        onClose={() => setSignInRequestWindowOpenState(false)}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem"></Box>
          <IconButton
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => setSignInRequestWindowOpenState(false)}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <Box
          color="seagreen"
          fontSize="1.5rem"
          minHeight="20vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
          margin={2}
        >
          You need to sign in before checking the cargo control transactions.
        </Box>
        <Box display="flex" justifyContent="center" mb={4}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setSignInRequestWindowOpenState(false);
            }}
            className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push("/sign-in")}
            className={`${classes.button} ${classes.fixWidthButton}`}
          >
            Sign In
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  smLayout: {
    display: "block",
    "@media print": {
      display: "flex",
    },
  },
  wideLayout: {
    display: "flex",
  },
  smLeftColumn: {
    "@media print": {
      marginRight: theme.spacing(4),
      flexBasis: "70%",
    },
  },
  leftColumn: {
    marginRight: theme.spacing(4),
  },
  firstColumn: {
    flexBasis: "70%",
  },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    marginRight: "24px",
  },
  getCodeButton: {
    display: "flex",
    justifyContent: "center",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  sessionHeader: {
    fontSize: "1rem",
    fontWeight: "700",
    fontFamily: "Montserrat",
    // borderBottom: '1px, solid rgb(141, 173, 155)',
  },
  priceBodyText: {
    fontSize: "1rem",
    // color: "seagreen",
    fontFamily: "Montserrat",
    "& a": {
      textDecoration: "underline",
    },
  },
  divider: {
    borderBottom: "1px solid rgba(0,0,0,0.3)",
    marginBottom: theme.spacing(1),
  },
  dividerWithoutMargin: {
    borderBottom: "1px solid rgba(0,0,0,0.3)",
  },
}));

ShipmentDetails.propTypes = {
  guest: PropTypes.bool,
};

export default ShipmentDetails;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import styled from 'styled-components';
import PropTypes from "prop-types";

import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
// import { makeStyles } from '@material-ui/core/styles';
// import grey from '@material-ui/core/colors/grey';
// import useMediaQuery from '@material-ui/core/useMediaQuery';

import { LoadingContainer } from "./shared";
import { getUserProfile, updateUserInfo } from "../slices";
import NavBar from "./NavBars/userBar";
import { getAccessToken } from "../Utils/doToken";
import SafariShareIcon from "./Images/icons/safariShare";

const MainContainer = ({ children }) => {
  //   const classes = useStyles();
  const dispatch = useDispatch();
  //   const allResidentsIsLoaded = useSelector(state => state.residentsInfo.allResidentsIsLoaded);
  //   const allGroupsIsLoaded = useSelector(state => state.residentsInfo.allGroupsIsLoaded);
  //   const entityInfoIsLoaded = useSelector(entityIsLoaded);
  //   const featureSwitches = useSelector(state => state.entityInfo.featureSwitches);

  const { signIn, isLoading, dataLoaded } = useSelector((state) => state.user);

  const [open, setOpen] = useState(false);
  const [iosOpen, setIosOpen] = useState(false);

  const [deferredPrompt, setDeferredPrompt] = useState(null);

  const curToken = getAccessToken("access_token");

  useEffect(() => {
    console.log(Intl.DateTimeFormat().resolvedOptions().timeZone);
    const cachesClear = async () => {
      if (caches) {
        const names = await caches.keys();
        await Promise.all(names.map((name) => caches.delete(name)));
      }
    };
    cachesClear();
  }, []);

  useEffect(() => {
    if (curToken) dispatch(updateUserInfo({ field: "signIn", value: true }));
    else dispatch(updateUserInfo({ field: "signIn", value: false }));
  }, [curToken, dispatch]);

  useEffect(() => {
    const loadUsingPrevToken = !dataLoaded && signIn;
    // const loadAfterSignIn = !dataLoaded && login;

    if (loadUsingPrevToken) {
      const token = getAccessToken("access_token");
      token && dispatch(getUserProfile());
    }
  }, [dataLoaded, dispatch, signIn]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleIosClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setIosOpen(false);
  };

  useEffect(() => {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setIosOpen(true);
    }
    // Initialize deferredPrompt for use later to show browser install prompt.
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Stash the event so it can be triggered later.
      setDeferredPrompt(e);
      // Update UI notify the user they can install the PWA
      // showInstallPromotion();
      // Optionally, send analytics event that PWA install promo was shown.
      setOpen(true);
    });
  }, []);

  console.log(isLoading, signIn);

  //   if (isLoading && token) return <LoadingContainer />;
  return (
    <>
      <NavBar
        deferredPrompt={deferredPrompt}
        setDeferredPrompt={setDeferredPrompt}
      />
      {isLoading && signIn ? <LoadingContainer /> : children}
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        onClose={handleClose}
        message="Add to home screen"
        action={
          <React.Fragment>
            <Button
              color="secondary"
              size="small"
              onClick={() => {
                deferredPrompt.prompt();
                setDeferredPrompt(null);
                handleClose();
              }}
            >
              Add
            </Button>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={iosOpen}
        onClose={handleIosClose}
        message={
          <span>
            Install this webapp: tab <SafariShareIcon /> and then click add to
            home screen
          </span>
        }
        // message="Add to home screen: tab <SafariShareIcon/> below arrow"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleIosClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </>
  );
};

// const useStyles = makeStyles({
//   default: {
//     // background: '#303030
//   },
//   purple: {
//     background: '#774B97',
//   },
//   warn: {
//     background: '#DDA647',
//   },
//   message: {
//     textAlign: 'center',
//   },
// });

MainContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
    .isRequired,
};

export default MainContainer;

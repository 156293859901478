import React, { useState, useEffect } from "react";
import LoadedShipmentListItem from "./LoadedShipmentListItem";
import { GET_DRIVER_LOADED_SHIPMENTS } from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";
import axios from "axios";
import LoadingCircle from "../LoadingCircle";
import Pagination from "@material-ui/lab/Pagination";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NoShipmentsLayout from "./NoShipmentsLayout";
import { getHeaders } from "../../Utils/fetchUtil";

const useStyles = makeStyles(theme => ({
  root: {
    height: "90vh",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  pagination: {
    "& > *": {
      marginTop: theme.spacing(2),
    },
    display: "flex",
    justifyContent: "center",
  },
}));

const LoadedShipmentListItemLayout = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [shipments, setShipments] = useState([]);
  const [lastPage, setLastPage] = useState(0);
  const [page, setPage] = useState();

  const handleChange = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(GET_DRIVER_LOADED_SHIPMENTS, { headers: getHeaders() })
      .then(res => {
        setShipments(res.data.data);
        setLastPage(res.data.lastPage);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <div className={classes.root}>
        {!loading ? (
          shipments.length > 0 ? (
            shipments.map(s => {
              return <LoadedShipmentListItem shipment={s} />;
            })
          ) : (
            <NoShipmentsLayout message="No shipments have been loaded." />
          )
        ) : (
          <LoadingCircle />
        )}
        {!loading && lastPage > 1 && (
          <div className={classes.pagination}>
            <Pagination
              className={classes.pagination}
              count={lastPage}
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default LoadedShipmentListItemLayout;

import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import "../../../styles/styles.css"

const useStyles = makeStyles((theme) => ({
    input: {
        textAlign:"center",
        justifyContent:"center",
    },
}));

const JoinCompany = () => {

    const classes = useStyles();

    return(
        <div>
            <form className="container">
                <h1>Company User</h1>
                <div></div>
                <div  className="subtitle">Company ID</div>
                <div  className="bodytext"></div>
                <div  className="subtitle">Message</div>
                <div  className="bodytext"></div>
                <div></div>
            </form>
        </div>
    )
}

export default JoinCompany;
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Checkbox,
  Box,
  Container,
  CircularProgress,
} from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
// import UserBar from '../../NavBars/userBar';
// import Slide from 'react-reveal';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import axios from "axios";

import "../../../styles/styles.css";
import { redirectSignUpSuccessful } from "../../../Utils/redirect";
import { errHandling } from "../../../Utils/fetchUtil";
// import { CheckBoxOutlineBlank } from "@material-ui/icons";

const submitStyle = {
  color: "green",
  maxheight: "-webkit-fill-available",
  fontFamily: "Montserrat",
  letterSpacing: "2px",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
  flexDirection: "column",
  textAlign: "center",
};

const formStyle = {
  color: "green",
  maxheight: "-webkit-fill-available",
  fontFamily: "Montserrat",
  letterSpacing: "2px",
  display: "flex",
  justifyContent: "center",
  overflow: "hidden",
};

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  button: {
    color: "white",
  },
  paper: {
    backgroundColor: "white",
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2, 2, 3),
    justifyContent: "center",
    width: "100%",
  },
  paper2: {
    backgroundColor: "white",
    padding: theme.spacing(2, 4, 3),
    boxShadow: theme.shadows[1],
    width: "100%",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  successWrapper: {
    backgroundColor: "white",
    padding: theme.spacing(1),
    borderRadius: "5px",
  },
}));

const UserSignUp = (props) => {
  // User Data that get saved as object
  // const [userData, setUserData] = useState();
  const [confirmUser, setConfirmUser] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [validLength, setValidLength] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [signUpResponse, setSignUpResponse] = useState(0);
  const [signUpSuccess, setSignUpSuccess] = useState(false);
  const [signUpFail, setSignUpFail] = useState(false);
  const [shortPassword, setShortPassword] = useState(false);
  const [showSignUp, setShowSignUp] = useState(true);
  const [signUpErrorMessage, setSignUpErrorMessage] = useState("");
  const [isChecked, setCheckedState] = useState(false);
  //   const [isPrivacyChecked, setPrivacyCheckedState] = useState(false);

  const [loading, setLoading] = useState(false);

  //   const [value, setValue] = React.useState("end_user");

  //   const handleChange = (event) => {
  //     setValue(event.target.value);
  //   };

  //useEffect handler which makes sure the userData state is first updated then sends the data to the MongoDB :D! FIXED
  useEffect(() => {
    // if (confirmUser === true) {
    //     console.log(userData)
    //     props.dispatch(createUser(userData))
    //     setConfirmUser(false)
    // }
    if (signUpResponse === 200) {
      setSignUpSuccess(true);
      setShowSignUp(false);
      setSignUpFail(false);
      setShortPassword(false);
      return;
    }
  }, [signUpResponse]);

  // const appendData = () => {
  //     setUserData({
  //         email: email,
  //         lastname: lastName,
  //         firstname: firstName,
  //         password: password
  //     })
  // }

  const checkPasswordLength = (password) => {
    if (password.length >= 6) {
      setValidLength(true);
      setSignUpFail(false);
    } else {
      setValidLength(false);
    }
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstName(e.target.value);
  };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    checkPasswordLength(e.target.value);
  };

  const handleCheck = () => {
    setCheckedState((prev) => !prev);
  };

  //Function which sends the user sign-up input to the backend sign-up API and returns a response
  const handleSubmit = (e) => {
    setSignUpErrorMessage("");
    e.preventDefault();
    if (password.length < 6) {
      setShortPassword(true);
      setSignUpFail(false);
      return;
    }
    if (password.length > 6) {
      setShortPassword(false);
    }
    setLoading(true);

    const singUpInfo = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      password: password,
    };
    axios
      .post(
        `https://admin.uucargo.ca/api/${
          !isChecked ? "userprofile" : "driver"
        }/signup/?source=Email&social&status=under%20review&type=Individual&company&bank`,
        JSON.stringify(singUpInfo),
        {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        }
      )
      .then((response) => {
        setSignUpResponse(response.status);
        setLoading(false);
      })
      .catch((error) => {
        // if (
        //   error.response.status === 400 ||
        //   error.response.status === 401 ||
        //   error.response.status === 403 ||
        //   error.response.status === 404 ||
        //   error.response.status === 422 ||
        //   error.response.status === 500
        // ) {
        setSignUpFail(true);
        setShortPassword(false);
        errHandling(error, setSignUpErrorMessage);
        // setSignUpErrorMessage(error.response.data.message);
        setLoading(false);
        // }
      });
    setConfirmUser(true);
  };

  return (
    <>
      {showSignUp ? (
        <div className={classes.paper}>
          <div>
            <div>
              <p className="subtitle">E-mail</p>
            </div>
            <div>
              <TextField
                onChange={(e) => handleEmail(e)}
                placeholder="E-mail"
                value={email}
                id="email"
                type="email"
                className={classes.input}
              />
            </div>
          </div>
          <div>
            <div>
              <p className="subtitle">First Name</p>
            </div>
            <div>
              <TextField
                onChange={(e) => handleFirstName(e)}
                className={classes.input}
                fullWidth={true}
                placeholder="Firstname"
                value={firstName}
                id="firstName"
                type="text"
              />
            </div>
          </div>
          <div>
            <div>
              <p className="subtitle">Last Name</p>
            </div>
            <div>
              <TextField
                onChange={(e) => handleLastName(e)}
                className={classes.input}
                fullWidth={true}
                placeholder="Lastname"
                value={lastName}
                id="lastName"
                type="text"
              />
            </div>
          </div>
          <div>
            <div>
              <p className="subtitle">Password</p>
            </div>
            <div>
              <TextField
                onChange={(e) => handlePasswordChange(e)}
                placeholder="Enter Password"
                value={password}
                type="password"
                className={classes.input}
              ></TextField>
            </div>
            {validLength
              ? "Within 6-20 Characters"
              : "Not Within 6-20 Character"}
          </div>
          <Box mt={1} mb={1} diplay="flex">
            {/* <RadioGroup row aria-label="user role" name="user_role" value={value} onChange={handleChange}>
                            <FormControlLabel value="end_user" control={<Radio color="primary"/>} label="End User" />
                            <FormControlLabel value="driver" control={<Radio color="primary"/>} label="Driver" />
                        </RadioGroup> */}
            <Checkbox
              color="primary"
              checked={isChecked}
              onChange={() => handleCheck()}
              name="userOrDriver"
            />
            <span>Sign up as driver</span>
          </Box>

          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ width: "15rem" }}
          >
            {loading ? (
              <CircularProgress style={{ color: "white" }} size={24} />
            ) : (
              "Create Account"
            )}
          </Button>
          {/* <RadioGroup row aria-label="user role" name="user_role" value={value} onChange={handleChange}>
                            <FormControlLabel value="end_user" control={<Radio color="primary"/>} label="End User" />
                            <FormControlLabel value="driver" control={<Radio color="primary"/>} label="Driver" />
                        </RadioGroup> */}
          <Box mt={2}>
            <Box component="span" color="rgba(0,0,0,0.6)">
              By signing up, you agree to our
            </Box>{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.uucargo.ca/privacy-policy/"
            >
              Privacy Statement
            </a>
          </Box>
          <div>
            {signUpFail ? (
              <Alert severity="error" classes={{ root: classes.alertRoot }}>
                <AlertTitle>Error</AlertTitle>
                {signUpErrorMessage}
              </Alert>
            ) : (
              ""
            )}
            {shortPassword ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Invalid password.
              </Alert>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}
      <div style={submitStyle}>
        <br />
        <br />
        <br />
        <div>
          {signUpSuccess ? (
            <Box className={classes.successWrapper} mb={2}>
              <Box component="strong" fontSize="1.5rem" pb={1}>
                <CheckCircleOutlineIcon /> Thank you for registering on UUCargo!{" "}
              </Box>
              <Box component="p" fontSize="1.2rem">
                We've sent you a activation link to your e-mail, you may sign in
                your account within 24 hours before activation.{" "}
              </Box>
              <Box component="p" fontSize="1.2rem">
                However, the link will expire after 24 hours.
              </Box>
            </Box>
          ) : (
            ""
          )}
        </div>
        <div>
          {signUpSuccess ? (
            <div>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                onClick={() => redirectSignUpSuccessful(history)}
              >
                Sign In
              </Button>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
};
//

export default UserSignUp;

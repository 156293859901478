import React, { useState, useEffect } from "react";

import { string, bool, func } from "prop-types";

import { makeStyles, Typography } from "@material-ui/core";

const PasswordChecker = ({ inputValue, show, setTotalValid }) => {
  const [isValid, setValid] = useState({
    length: false,
    case: false,
    digit: false,
    symbol: false,
    lengthMax: false,
  });

  const classes = useStyles();

  useEffect(() => {
    const checkValid = (inputPassword) => {
      // Whether length larger or euqal to 8
      const lengthValid = /.{6,}/.test(inputPassword);

      //   // Whether contains lower and uppper case letters
      //   const caseValid =
      //     /(?=.*?[A-Z])/.test(inputPassword) &&
      //     /(?=.*?[a-z])/.test(inputPassword);

      //   // Whether contains digit
      //   const digitValid = /(?=.*?[0-9])/.test(inputPassword);

      //   // Whether contains symbol
      //   const symbolValid = /(?=.*?[#?!@$%^&*-])/.test(inputPassword);

      // Whether length larger than 32
      const lengthMaxValid = /.{20,}/.test(inputPassword);

      setTotalValid(
        // lengthValid && caseValid && digitValid && symbolValid && !lengthMaxValid
        lengthValid && !lengthMaxValid
      );

      setValid({
        length: lengthValid,
        // case: caseValid,
        // digit: digitValid,
        // symbol: symbolValid,
        lengthMax: lengthMaxValid,
      });
    };
    checkValid(inputValue);
  }, [inputValue, setValid, setTotalValid]);

  const passwordRequirement = {
    length: "At least 6 characters",
    // case: intl.get("@@systemTable/password_case"),
    // digit: intl.get("@@systemTable/password_digit"),
    // symbol: intl.get("@@systemTable/password_symbol"),
    lengthMax: "No more than 20 characters",
  };

  return (
    <>
      {show ? (
        <>
          <Typography
            align="left"
            className={isValid.length ? classes.success : classes.error}
          >
            {passwordRequirement.length}
          </Typography>
          {/* <Typography
            align="left"
            className={isValid.case ? classes.success : classes.error}
          >
            {passwordRequirement.case}
          </Typography>
          <Typography
            align="left"
            className={isValid.digit ? classes.success : classes.error}
          >
            {passwordRequirement.digit}
          </Typography>
          <Typography
            align="left"
            className={isValid.symbol ? classes.success : classes.error}
          >
            {passwordRequirement.symbol}
          </Typography> */}
          <Typography align="left" className={classes.error}>
            {isValid.lengthMax ? passwordRequirement.lengthMax : ""}
          </Typography>
        </>
      ) : (
        ""
      )}
    </>
  );
};

PasswordChecker.propTypes = {
  inputValue: string.isRequired,
  show: bool.isRequired,
  setTotalValid: func.isRequired,
};

const useStyles = makeStyles((theme) => ({
  success: {
    color: theme.palette.success.main,
  },
  error: {
    color: theme.palette.error.main,
  },
}));

export default PasswordChecker;

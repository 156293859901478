export const USER_CURRENT_ADDRESS_COOKIE_NAME = "user_current_address";
export const USER_CURRENT_CITY_COOKIE_NAME = "user_current_city";

export const USER_CURRENT_ADDRESS_COOKIE_TIME = 3600 * 1000;

export const GUEST_ORDER_PRICE_CHECK_COOKIE_NAME = "price_check";

export const GUEST_ORDER_PRICE_CHECK_COOKIE_TIME = 3600 * 1000;

export const GUEST_CARGO_CONTROL_TRACKING_COOKIE_NAME =
  "cargo_control_tracking";

export const GUEST_CARGO_CONTROL_TRACKING_COOKIE_TIME = 3600 * 1000;

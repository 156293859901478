import React, { useState, useEffect } from "react";
// import {createMuiTheme } from '@material-ui/core/';
// import { useDispatch, useSelector } from "react-redux";
import { Button, Box, Dialog, IconButton, Divider } from "@material-ui/core";
// import orange from '@material-ui/core/colors/orange';
import { makeStyles } from "@material-ui/core/styles";
import GooglePlaceInput from "../../shared/AddressInputCombo/googlePlacesAutoComplete";
import { Alert } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";

// import Fade from 'react-reveal';
import PropTypes from "prop-types";
import produce from "immer";
// import { shipmentTypesDetails } from "../../shared/constInfo";
import axios from "axios";

import { CustomInput } from "../../shared";
// import LoadingContainer from "../../shared/loadingContainer";
import { BACKEND_GUEST_PRICE_ESTIMATE_API } from "../../../Utils/apiUrl";
import { errHandling } from "../../../Utils/fetchUtil";
import PriceModule from "../../shared/OrderDetail/priceModule";
// import { redirectSignUpSuccessful } from "../../Utils/redirect";
import { useHistory, Link } from "react-router-dom";

// import nextId from "react-id-generator";
// import '../../styles/shippingItem.css';

const shipmentTypes = [
  { label: 'Pallet 48" x 40"', value: "1" },
  { label: 'Pallet 48" x 48"', value: "2" },
  { label: "Oversize Pallet", value: "3" },
  { label: "Overweight Pallet", value: "4" },
  // { label: "Oversize & Overweight Pallet", value: "5" },
  { label: "Full Truck (5 ton)", value: "6" },
  { label: "Full Trailer (FTL)", value: "7" },
];

const provinces = [
  "",
  "Alberta",
  "British Columbia",
  "Manitoba",
  "New Brunswick",
  "Newfoundland and Labrador",
  "Northwest Territories",
  "Nova Scotia",
  "Nunavut",
  "Ontario",
  "Prince Edward Island",
  "Quebec",
  "Saskatchewan",
  "Yukon",
];

const EstimateForm = ({
  item,
  errorMsg,
  setErrorMsg,
  changeItem,
  initPickupCity,
}) => {
  // const [showError, setShowErrorState] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  const [priceErrMsg, setPriceErrMsg] = useState("");
  const [fetchingPrice, setFetchingPriceState] = useState(false);
  const [shippingPrice, setShippingPrice] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const emtpyAddress =
      item.pickupCity?.trim() === "" ||
      item.pickupProvince?.trim() === "" ||
      item.deliverCity?.trim() === "" ||
      item.deliverProvince?.trim() === "";
    const emptyField = item.quantity === null || item.selectedTypeID === "";
    const invalidValue = item.quantity < 1;

    const notNum = isNaN(item.quantity);

    if (!emtpyAddress || (!emptyField && !invalidValue && !notNum)) {
      setErrorMsg("");
    }
  }, [item, setErrorMsg]);

  const handleItemChange = (fieldName) => (e) => {
    let value = e.target.value;
    if (fieldName === "quantity") value = parseInt(value);
    e.preventDefault();
    changeItem(
      produce((draft) => {
        draft[fieldName] = value;
      })
    );
  };

  const handleChangeFromSelection = (fieldName) => (newValue) => {
    changeItem(
      produce((draft) => {
        draft[fieldName] = newValue;
      })
    );
  };

  //setSampleData({...sampleData, address: editAddress})
  const handleListItemSelect = (e) => {
    const typeValue = e.target.value;
    changeItem(
      produce((draft) => {
        draft.selectedTypeID = typeValue;
        draft.quantity = draft.quantity || null;
        if (typeValue === "6" || typeValue === "7") {
          draft.quantity = typeValue === "6" ? 12 : 26;
        }
      })
    );
  };

  //Checks if pallet weight,height and num pallets is not a number and if they aren't, return some error
  //Sets the Redux dispatch pallet state
  const handleEstimateData = (item) => {
    const emtpyAddress =
      item.pickupCity.trim() === "" ||
      item.pickupProvince.trim() === "" ||
      item.deliverCity.trim() === "" ||
      item.deliverProvince.trim() === "";
    const emptyField = item.quantity === null || item.selectedTypeID === "";
    if (emtpyAddress) {
      setErrorMsg("Incomplete address information.");
    } else if (emptyField || item.quantity < 1) {
      setErrorMsg("Invalid shipping item values.");
    } else if (isNaN(item.quantity)) {
      setErrorMsg("Not a valid number");
    } else {
      setDialogOpen(true);
      setErrorMsg("");
      if (!checked) {
        setFetchingPriceState(true);
        const postData = {
          pickup_area: `${item.pickupCity}, ${item.pickupProvince}`,
          deliver_area: `${item.deliverCity}, ${item.deliverProvince}`,
          goods: [
            {
              shipment_type_id: item.selectedTypeID,
              quantity: item.quantity,
            },
          ],
        };
        axios({
          method: "post",
          url: BACKEND_GUEST_PRICE_ESTIMATE_API,
          data: JSON.stringify(postData),
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
          },
        })
          .then((response) => {
            setFetchingPriceState(false);
            setShippingPrice(response.data || {});
          })
          .catch((err) => {
            errHandling(err, setPriceErrMsg);
            // setPriceErrMsg(err.message);
            setFetchingPriceState(false);
          });
      }
    }
  };

  const priceComponent = (
    <>
      <h4 className="orange-title">Price:</h4>
      <PriceModule
        priceErrMsg={priceErrMsg}
        fetchingPrice={fetchingPrice}
        shippingPrice={shippingPrice}
      />
    </>
  );

  const handleRedirection = () => {
    localStorage.setItem(
      "shippingData",
      JSON.stringify({ estimateDetails: item, itemInfo: item })
    );
    history.push({
      pathname: "/sign-in",
      state: { from: { pathname: "/shipping/start-a-quote" } },
    });
  };

  return (
    <Box>
      <Box display="flex">
        <Box mr={1} flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Pickup City
          </Box>
          <GooglePlaceInput
            type="city"
            cityValue={initPickupCity}
            handleChange={handleChangeFromSelection("pickupCity")}
            estimate
          />
        </Box>
        <Box flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Pickup Province
          </Box>
          <CustomInput
            onChange={handleChangeFromSelection("pickupProvince")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={item.pickupProvince}
            fullWidth
            estimate
            inputWithSelect
            options={provinces}
          />
        </Box>
      </Box>
      <Box display="flex" mt={2}>
        <Box mr={1} flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Deliver City
          </Box>
          <GooglePlaceInput
            type="city"
            handleChange={handleChangeFromSelection("deliverCity")}
            // cityValue={userLocation?.city}
            estimate
          />
        </Box>
        <Box flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Deliver Province
          </Box>
          <CustomInput
            onChange={handleChangeFromSelection("deliverProvince")}
            // value={provinces.find(ele => ele.value === addressObject.province) || ''}
            value={item.deliverProvince}
            fullWidth
            estimate
            inputWithSelect
            options={provinces}
          />
        </Box>
      </Box>
      <Box display="flex" mb={2} mt={2}>
        <Box mr={1} flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Packaging type *
          </Box>
          <CustomInput
            onChange={handleListItemSelect}
            options={shipmentTypes}
            value={item.selectedTypeID}
          />
        </Box>
        <Box flexBasis="50%">
          <Box className={classes.smSubTitle} mb={0.5}>
            Number of pallets *
          </Box>
          <CustomInput
            // className={classes.textfield}
            value={item.quantity}
            onChange={handleItemChange("quantity")}
            disabled={
              item.selectedTypeID === "6" || item.selectedTypeID === "7"
            }
            type="number"
          />
        </Box>
      </Box>

      <Box width="100%">
        {errorMsg ? (
          <Alert severity="error" classes={{ root: classes.alertRoot }}>
            {errorMsg}
          </Alert>
        ) : (
          ""
        )}
      </Box>

      <Box mb={2} mt={3} display="flex" justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleEstimateData(item);
          }}
          style={{ width: "10rem" }}
        >
          Start estimate
        </Button>
      </Box>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={dialogOpen}
        onClose={() => {
          setChecked(true);
          setDialogOpen(false);
        }}
      >
        <div className={classes.dialogTitle}>
          <Box fontSize="1.5rem"></Box>
          <IconButton
            // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
            style={{ fill: "green" }}
            className={classes.closeBtn}
            onClick={() => {
              setChecked(true);
              setDialogOpen(false);
            }}
          >
            <ClearIcon />
          </IconButton>
        </div>
        {checked ? (
          <Box m={2} fontSize="1.5rem">
            Please sign in to get more information.
          </Box>
        ) : (
          <Box m={1}>
            {priceErrMsg && !fetchingPrice ? (
              <Box pt="1vh" width="100%">
                <Alert severity="error" classes={{ root: classes.alertRoot }}>
                  {priceErrMsg}
                </Alert>
              </Box>
            ) : (
              priceComponent
            )}
          </Box>
        )}
        <Box display="flex" justifyContent="center" mb="2vh">
          <Box mr={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setChecked(true);
                setDialogOpen(false);
              }}
            >
              Cancel
            </Button>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRedirection}
          >
            Sign In
          </Button>
        </Box>
        <Box ml={3} mr={3} mt={1}>
          <Divider />
        </Box>
        <Box textAlign="center" pt={2} pb={3}>
          <Box component="span" color="rgba(0,0,0,0.6)">
            Don't have an Account?{" "}
          </Box>
          <Link to="/sign-up">Sign up</Link>
        </Box>
      </Dialog>
    </Box>
  );
};

// const theme = createMuiTheme({
//     palette: {
//         primary: { light: orange[300], main: orange[500], dark: orange[700] }
//     }
// })

const useStyles = makeStyles((theme) => ({
  input: {
    color: "grey",
    width: "90%",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    fontWeight: 600,
    fontSize: "1.2rem",
    fontFamily: "Montserrat",
  },
  smSubTitle: {
    fontWeight: 600,
    fontSize: "1rem",
    // fontFamily: "Montserrat",
    textAlign: "left",
  },
  dialogTitle: {
    padding: theme.spacing(1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
}));

EstimateForm.propTypes = {
  item: PropTypes.object,
  edit: PropTypes.bool,
  onCancel: PropTypes.func,
  changeItem: PropTypes.func,
  setShippingItems: PropTypes.func,
};

export default EstimateForm;

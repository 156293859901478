import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import UserNavBar from '../NavBars/userBar';
// import TabComponent from "../shared/Tabs/IconTabComponent";
// import ServicePickUp from './servicePickup';
// import ServiceDelivery from './serviceDelivery';
import ShippingItem from "./shippingItem";
import FreightClass from "./freightClass";
import QuoteSummary from "./quoteSummary";
import ReviewAndConfirm from "./reviewAndConfirm";
// import {addPickUpDetails} from '../../actions/shipmentAction';
import {
  // addPickupDetails,
  // loadShippingItems,
  defaultPickUpDetails,
} from "../../slices";

import DateFnsUtils from "@date-io/date-fns";
import { format } from "date-fns";
import { useHistory, useParams } from "react-router-dom";
// import {dateFormat} from 'dateformat';
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import enGBLocale from "date-fns/locale/en-GB";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import {
  IconButton,
  TextField,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  Button,
  useMediaQuery,
} from "@material-ui/core";
import { ArrowForwardIos } from "@material-ui/icons";
import { Paper, Box } from "@material-ui/core";
import orange from "@material-ui/core/colors/orange";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import ClearIcon from "@material-ui/icons/Clear";
import "../../styles/shipping.css";
// import PropTypes from 'prop-types';
import produce from "immer";
import { AddressInputCombo, provinces, amzFulfillCenterList } from "../shared";
import { USER_CURRENT_ADDRESS_COOKIE_NAME } from "../../Utils/config";
import { getAccessToken } from "../../Utils/doToken";
import { shipmentTypesDetails } from "../shared/constInfo";

const initUserLocation = {
  street: "",
  city: "",
  zipCode: "",
};

const matchAmz = (lists, addressObj) => {
  const provinceAbre = provinces.find(
    (ele) => ele.label === addressObj.province
  ).value;
  return lists.some((addres) => {
    const [street, city, province] = addres.split(", ");
    return (
      addressObj.city === city &&
      provinceAbre === province &&
      addressObj.address === street
    );
  });
};

const StartQuote = () => {
  const { tab } = useParams();
  const history = useHistory();
  // const dispatch = useDispatch();
  // const pickUpDetails = useSelector((state) => state.shipping.pickUpDetails);
  // const { lat, lng } = useSelector((state) => state.user.latlng);
  const { addressBook } = useSelector((state) => state.user);

  // const matches = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const matches = useMediaQuery("(max-width:1080px)");

  // const [selectedDate, setSelectedDate] = useState(new Date());

  // Form data to store all the information
  const [formatData, setFormatData] = useState(defaultPickUpDetails);
  const [shippingItems, setShippingItems] = useState([]);
  const [item, setItemInfo] = useState(null);

  const [userLocation, setUserLocation] = useState(initUserLocation);
  const [deliverLocation, setDeliverLocation] = useState(initUserLocation);
  const [isWindowOpen, setWindowOpenState] = useState(false);
  const [currentField, setCurrentField] = useState("pickUp");

  const [value, setValue] = useState(null);

  // const [destination, setDestination] = useState('');
  // const [details, setDetails] = useState(shippingItems.pickUpDetail);
  // const [details, setDetails] = useState('');
  // const [orderNum, setOrderNum] = useState('');
  const classes = useStyles();

  const [showErroMsg, setMsgStatus] = useState(false);
  const [amazonFba, setAmazonFba] = useState(false);

  const getEmptyStatus = (_formatData) => {
    const { pickUp, deliver } = _formatData;
    const pickUpEmpty =
      pickUp.phone.trim() === "" ||
      pickUp.address.trim() === "" ||
      pickUp.city.trim() === "" ||
      pickUp.province.trim() === "";
    const deliverEmpty =
      deliver.address.trim() === "" ||
      deliver.city.trim() === "" ||
      deliver.province.trim() === "";
    return [pickUpEmpty, deliverEmpty];
  };

  useEffect(() => {
    const prevItems = localStorage.getItem("shippingData");
    if (prevItems) {
      const { pickUpDetails, shippingItems } = JSON.parse(prevItems);
      // setFormatData({...pickUpDetails, selectedDate: new Date(pickUpDetails.selectedDate)});
      if (pickUpDetails && shippingItems) {
        setUserLocation(
          produce((draft) => {
            draft.street = pickUpDetails.pickUp.address;
            draft.city = pickUpDetails.pickUp.city;
            draft.zipCode = pickUpDetails.pickUp.zipCode || "";
          })
        );
        setDeliverLocation(
          produce((draft) => {
            draft.street = pickUpDetails.deliver.address;
            draft.city = pickUpDetails.deliver.city;
            draft.zipCode = pickUpDetails.deliver.zipCode || "";
          })
        );
        setFormatData(
          produce((draft) => {
            // draft = { ...pickUpDetails };
            draft.pickUp.address = pickUpDetails.pickUp.address;
            draft.pickUp.city = pickUpDetails.pickUp.city;
            if (pickUpDetails.pickUp.zipCode)
              draft.pickUp.zipCode = pickUpDetails.pickUp.zipCode;
            draft.deliver.address = pickUpDetails.deliver.address;
            draft.deliver.city = pickUpDetails.deliver.city;
            if (pickUpDetails.deliver.zipCode)
              draft.deliver.zipCode = pickUpDetails.deliver.zipCode;
            draft.selectedDate = new Date(pickUpDetails.selectedDate);
            // draft.pickUp.province = provinces.find(
            //   (ele) => ele.value === pickUpDetails.pickUp.province
            // ).label;
            // draft.deliver.province = provinces.find(
            //   (ele) => ele.value === pickUpDetails.deliver.province
            // ).label;
            draft.pickUp.province = pickUpDetails.pickUp.province;
            draft.deliver.province = pickUpDetails.deliver.province;
            draft.pickUp.phone = pickUpDetails.pickUp.phone;
            draft.deliver.phone = pickUpDetails.deliver.phone;
          })
        );
        setShippingItems(shippingItems);
      } else {
        const { estimateDetails, itemInfo } = JSON.parse(prevItems);
        setUserLocation(
          produce((draft) => {
            draft.city = estimateDetails.pickupCity;
          })
        );
        setDeliverLocation(
          produce((draft) => {
            draft.city = estimateDetails.deliverCity;
          })
        );
        setFormatData(
          produce((draft) => {
            // draft = { ...pickUpDetails };
            draft.pickUp.city = estimateDetails.pickupCity;
            draft.deliver.city = estimateDetails.deliverCity;
            draft.pickUp.province = estimateDetails.pickupProvince;
            draft.deliver.province = estimateDetails.deliverProvince;
          })
        );
        setItemInfo({
          description: "",
          height: null,
          weight: null,
          quantity: itemInfo.quantity,
          length:
            itemInfo.selectedTypeID &&
            shipmentTypesDetails[itemInfo.selectedTypeID].length
              ? shipmentTypesDetails[itemInfo.selectedTypeID].length
              : null,
          width:
            itemInfo.selectedTypeID &&
            shipmentTypesDetails[itemInfo.selectedTypeID].width
              ? shipmentTypesDetails[itemInfo.selectedTypeID].width
              : null,
          selectedTypeID: itemInfo.selectedTypeID,
          color: "",
          hazardous: false,
          tempSensitive: false,
        });
      }
      // dispatch(loadShippingItems({ items: shippingItems }));
    }
  }, []);

  useEffect(() => {
    const prevItems = localStorage.getItem("shippingData");

    const current_address_cache = getAccessToken(
      USER_CURRENT_ADDRESS_COOKIE_NAME
    );
    if (current_address_cache && !prevItems) {
      const { city, province, street, postal } = JSON.parse(
        current_address_cache
      );
      setUserLocation(
        produce((draft) => {
          draft.street = street;
          draft.city = city;
          if (postal) draft.zipCode = postal;
        })
      );
      setFormatData(
        produce((draft) => {
          draft.pickUp.address = street;
          draft.pickUp.city = city;
          if (postal) draft.pickUp.zipCode = postal;
          draft.pickUp.province = provinces.find(
            (ele) => ele.value === province
          ).label;
        })
      );
    }
  }, []);

  useEffect(() => {
    const [pickUpEmpty, deliverEmpty] = getEmptyStatus(formatData);
    if (!pickUpEmpty && !deliverEmpty) setMsgStatus(false);
  }, [formatData]);

  /**
   * @param {boolean} address Belongs to the address submodule if True
   * @param {string} fieldName The field name of the form data object
   * @param {string} addressType 'pickUp' or 'Deliver'
   */
  const handleChange =
    (address, addressType = "pickUp") =>
    (fieldName) =>
    (e) => {
      setFormatData(
        produce((draft) => {
          if (address) draft[addressType][fieldName] = e.target.value;
          else draft[fieldName] = e.target.value;
        })
      );
    };

  /**
   * @param {boolean} address Belongs to the address submodule if True
   * @param {string} fieldName The field name of the form data object
   * @param {string} addressType 'pickUp' or 'Deliver'
   */
  const handleChangeFromSelection =
    (address, addressType = "pickUp") =>
    (fieldName) =>
    (newValue) => {
      setFormatData(
        produce((draft) => {
          if (address && newValue) draft[addressType][fieldName] = newValue;
          else if (address && !newValue) draft[addressType][fieldName] = "";
          else draft[fieldName] = newValue;
        })
      );
    };

  // const [value, setValue] = React.useState("female");

  const handleAddressSelection = (field, event) => {
    const index = parseInt(event.target.value);
    setValue(index);
    const item = addressBook[index];
    if (field === "pickUp")
      setUserLocation(
        produce((draft) => {
          draft.street = item.address;
          draft.city = item.city;
          draft.zipCode = item.zipCode;
        })
      );
    if (field === "deliver")
      setDeliverLocation(
        produce((draft) => {
          draft.street = item.address;
          draft.city = item.city;
          draft.zipCode = item.zipCode;
        })
      );
    setFormatData(
      produce((draft) => {
        draft[field].city = item.city;
        draft[field].zipCode = item.zipCode;
        draft[field].province = provinces.find(
          (ele) => ele.value === item.province
        ).label;
      })
    );
    // setFormatData(
    //   produce((draft) => {
    //     draft[field].address = item.address;
    //     draft[field].city = item.city;
    //     draft[field].zipCode = item.zipCode;
    //     draft[field].province = provinces.find(
    //       (ele) => ele.value === item.province
    //     ).label;
    //   })
    // );
    setTimeout(() => {
      if (setWindowOpenState && setValue) {
        setWindowOpenState(false);
        setValue(null);
      }
    }, 200);
  };

  /*Function that handles the rendering of different components, Can be implmented as tabbed design for later*/
  const handleSelection = () => {
    if (tab === "start-a-quote") {
      return (
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            heigh="100%"
            flexDirection={matches ? "column" : "row"}
          >
            {/* <Fade duration={2000}> */}
            <Box
              display="flex"
              flexDirection="column"
              width={matches ? "100%" : "60%"}
              mr={2}
            >
              <Box color="orange">
                <h1>When and Where are you shipping?</h1>
              </Box>
              <Box
                mb={1}
                className={classes.subTitle}
                display="flex"
                alignItems="center"
              >
                <span>Pickup</span>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCurrentField("pickUp");
                      setWindowOpenState(true);
                    }}
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                    }}
                  >
                    Address book
                  </Button>
                </Box>
              </Box>
              <AddressInputCombo
                handleChange={handleChange(true)}
                handleChangeFromSelection={handleChangeFromSelection(true)}
                addressObject={formatData.pickUp}
                userLocation={userLocation}
                pickUp
              />
              <Box
                mb={1}
                mt={2}
                className={classes.subTitle}
                display="flex"
                alignItems="center"
              >
                <span>Deliver</span>
                <Box ml={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setCurrentField("deliver");
                      setWindowOpenState(true);
                    }}
                    style={{
                      textTransform: "unset",
                      fontSize: "1rem",
                    }}
                  >
                    Address book
                  </Button>
                </Box>
              </Box>
              <AddressInputCombo
                handleChange={handleChange(true, "deliver")}
                handleChangeFromSelection={handleChangeFromSelection(
                  true,
                  "deliver"
                )}
                addressObject={formatData.deliver}
                userLocation={deliverLocation}
              />
              {/* <Box mt="5vh" display="none">
                <div className={classes.subTitle}>Type of location</div>
              </Box>
              <Box display="none">
                <TabComponent
                  initTab={formatData.locationType}
                  tabs={tabs}
                  onChange={handleChangeFromSelection(false)("locationType")}
                  noShadow
                />
              </Box> */}
              <Box className={classes.noteWrapper} p={1} mt={2}>
                Please note: The delivery destination cannot be the amazon
                fulfillment center. (FBA)
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              width={matches ? "100%" : "40%"}
              mt={matches ? 2 : ""}
            >
              <div id="quote-content2">
                <MuiThemeProvider theme={theme}>
                  <MuiPickersUtilsProvider
                    utils={DateFnsUtils}
                    locale={enGBLocale}
                  >
                    <Paper className={classes.paper}>
                      <DatePicker
                        autoOk
                        variant="static"
                        value={formatData.selectedDate}
                        onChange={handleChangeFromSelection(false)(
                          "selectedDate"
                        )}
                      />
                    </Paper>
                  </MuiPickersUtilsProvider>
                </MuiThemeProvider>
              </div>
              <Box mt="2vh">
                <div className={classes.subTitle}>Requested loading date</div>
                <TextField
                  value={format(formatData.selectedDate, "dd/MM/yyyy")}
                  className={classes.input}
                />
              </Box>
              <Box pt="1vh" width="100%">
                {showErroMsg ? (
                  <Alert severity="error" classes={{ root: classes.alertRoot }}>
                    {amazonFba ? (
                      <span>
                        The deliver destination cannot be the amazon fulfillment
                        center
                      </span>
                    ) : (
                      <span>
                        Please enter information. <br /> * Mandatory
                      </span>
                    )}
                  </Alert>
                ) : (
                  ""
                )}
              </Box>
              <Box mt="3vh" textAlign="center">
                <IconButton id="iconbuttonarrow" onClick={() => next(false)}>
                  <ArrowForwardIos
                    style={{ fontSize: "200%", fill: "white" }}
                  />
                </IconButton>
              </Box>
            </Box>
            {/* </Fade> */}
            <Dialog
              maxWidth="sm"
              fullWidth
              open={isWindowOpen}
              onClose={() => setWindowOpenState(false)}
            >
              <Box ml={1} className={classes.dialogTitle}>
                <Box fontSize="1.5rem">Address book</Box>
                <IconButton
                  // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                  style={{ fill: "green" }}
                  className={classes.closeBtn}
                  onClick={() => setWindowOpenState(false)}
                >
                  <ClearIcon />
                </IconButton>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                m={1}
              >
                <RadioGroup
                  aria-label="address"
                  name="addressBook"
                  // value={value}
                  onChange={(e) => handleAddressSelection(currentField, e)}
                >
                  {addressBook.map((item, index) => (
                    <FormControlLabel
                      value={index}
                      control={
                        <Radio checked={value === index} color="primary" />
                      }
                      key={item.zipCode}
                      label={`${item.address}, ${item.city}, ${item.province} ${item.zipCode}`}
                    />
                  ))}
                </RadioGroup>
              </Box>
            </Dialog>
            <Dialog
              maxWidth="sm"
              fullWidth
              open={amazonFba}
              onClose={() => {
                setAmazonFba(false);
              }}
            >
              <div className={classes.dialogTitle}>
                <Box fontSize="1.5rem"></Box>
                <IconButton
                  // styles={{icon: {color: 'rgb(141, 173, 155)', fontSize: 36}}}
                  style={{ fill: "green" }}
                  className={classes.closeBtn}
                  onClick={() => {
                    setAmazonFba(false);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </div>
              <Box
                color="seagreen"
                fontSize="1.5rem"
                minHeight="20vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
                margin="2vw"
              >
                The delivery destination cannot be the amazon fulfillment
                center. (FBA)
              </Box>
              <Box display="flex" justifyContent="center" pb={4}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setAmazonFba(false);
                  }}
                  className={`${classes.outLinedButton} ${classes.fixWidthButton}`}
                >
                  Go back
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => next(true)}
                  className={`${classes.button} ${classes.fixWidthButton}`}
                >
                  Continue
                </Button>
              </Box>
            </Dialog>
          </Box>
        </Container>
      );
    }
    // if (section === 2) {
    //     return (
    //         <Fade duration={2000}>
    //             <ServicePickUp thirdPage={handleThirdPage} />
    //         </Fade>
    //     )
    // }
    // if (section === 3) {
    //     return (
    //         <Fade duration={2000}>
    //             <ServiceDelivery secondPage={handleSecondPage} fourthPage={handleFourthPage} />
    //         </Fade>
    //     )
    // }
    if (tab === "shipping-item") {
      return (
        <ShippingItem
          backToThird={handleFirstPage}
          toSeventh={handleSeventhPage}
          shippingItems={shippingItems}
          setShippingItems={setShippingItems}
          itemInfo={item}
        />
      );
    }
    if (tab === "freight-class") {
      return (
        // <Fade duration={2000}>
        <FreightClass
          backToFourth={handleFourthPage}
          toSixth={handleSeventhPage}
          shippingItems={shippingItems}
        />
        // </Fade>
      );
    }
    if (tab === "quote-summary") {
      return (
        <QuoteSummary
          toFourth={handleFourthPage}
          toFirst={handleFirstPage}
          toSeventh={handleSeventhPage}
        />
      );
    }
    if (tab === "review-confirm") {
      return (
        <ReviewAndConfirm
          toFirst={handleFirstPage}
          toFourth={handleFourthPage}
          toConfirm={handleConfirmPage}
          pallets={shippingItems}
          pickUpDetails={formatData}
        />
      );
    }
    // if (tab === 'order-confirmed') {
    //   return(
    //       <OrderConfirm />
    //   )
    // }
  };

  //when the arrow button to the next page is pressed, replace the Redux pickUpDetails with the current textfiel values
  const next = (skipCheckAmz = false) => {
    const [pickUpEmpty, deliverEmpty] = getEmptyStatus(formatData);
    if (pickUpEmpty || deliverEmpty) {
      setMsgStatus(true);
    } else if (
      !skipCheckAmz &&
      matchAmz(amzFulfillCenterList, formatData.deliver)
    ) {
      setAmazonFba(true);
    } else {
      // const newFormatData = {
      //   ...formatData,
      //   pickUp: {
      //     ...formatData.pickUp,
      //     province: provinces.find(
      //       (ele) => ele.label === formatData.pickUp.province
      //     ).value,
      //   },
      //   deliver: {
      //     ...formatData.deliver,
      //     province: provinces.find(
      //       (ele) => ele.label === formatData.deliver.province
      //     ).value,
      //   },
      // };
      // dispatch(addPickupDetails({ newPickUpInfo: newFormatData }));
      // setSection(4)
      setUserLocation({
        street: formatData.pickUp.address,
        city: formatData.pickUp.city,
        zipCode: formatData.pickUp.zipCode,
      });
      setDeliverLocation({
        street: formatData.deliver.address,
        city: formatData.deliver.city,
        zipCode: formatData.deliver.zipCode,
      });
      history.push("/shipping/shipping-item");
    }
  };

  // }

  const handleFirstPage = () => {
    history.push("/shipping/start-a-quote");
  };

  const handleFourthPage = () => {
    history.push("/shipping/shipping-item");
  };

  // const handleFifthPage = () => {
  //   history.push("/shipping/freight-class");
  // };

  // const handleSixthPage= () => {
  //   history.push('/shipping/quote-summary');
  // }
  const handleSeventhPage = () => {
    history.push("/shipping/review-confirm");
  };

  const handleConfirmPage = (number) => {
    history.push(`/order-confirmed/${number}`);
  };

  return (
    <div>
      <br />
      {handleSelection()}
    </div>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: { light: orange[300], main: orange[500], dark: orange[700] },
  },
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "20px",
    // padding: theme.spacing(2, 4, 3),
    overflow: "hidden",
    width: "100%",
    margin: "0 auto",
  },
  button: {
    borderRadius: 5,
    fontSize: "12pt",
  },
  input: {
    color: "grey",
    width: "90%",
  },
  largeIcon: {
    fontSize: "200%",
  },
  label: {
    flexDirection: "column",
  },
  icon: {
    fontSize: "32px !important",
    marginBottom: theme.spacing(1),
  },
  subTitle: {
    color: "darkgreen",
    fontWeight: 600,
    fontSize: "1.5rem",
    fontFamily: "Montserrat",
  },
  alertRoot: {
    width: "80%",
    minWidth: "200px",
    margin: "10px auto",
  },
  dialogTitle: {
    padding: "1vh 1vw 0.5vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "rgb(141, 173, 155)",
  },
  // button: {
  //   fontSize: "12pt",
  //   backgroundColor: "#ffa726",
  //   color: "white",
  //   margin: "7px",
  // },
  fixWidthButton: {
    width: "8rem",
  },
  outLinedButton: {
    fontSize: "12pt",
    // margin: "7px",
    marginRight: "24px",
  },
  noteWrapper: {
    // border: "1px solid rgba(0,0,0,0.3)",
    borderRadius: 5,
    // padding: theme.spacing(1),
    backgroundColor: "#FEEAAE",
  },
}));

// export default connect(mapStateToProps, {addPickUpDetails})(StartQuote);
export default StartQuote;

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  makeStyles,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Paper,
  Typography,
  Dialog,
  Backdrop,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import LoadAndDeliverShipmentsLayout from "./LoadAndDeliverShipmentsLayout";
import ConfirmationCodesInputLayout from "./ConfirmationCodesInputLayout";
import DriverUploadImages from "./DriverUploadImages";
import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import {
  DRIVER_DELIVER_SHIPMENT_BY_TRACKING_NUMBER,
  DRIVER_UPLOAD_SHIPMENTS_IMAGES,
} from "../../Utils/apiUrl";
import { getTokenFromCookie } from "../../Utils/doToken";
import { connect } from "react-redux";
import { removeAllSelectedDeliverShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import { useDispatch } from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepCotent: {
    paddingLeft: "10px",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  missingTrackingNumberWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "10px",
    listStyleType: "none",
  },
}));

const getSteps = () => {
  return [
    "Review and select your loaded shipments",
    "Confirm selected shipments to deliver",
    "Take pictures for the delivered shipments (Recommended)",
    "Let receiver input confirmation codes",
  ];
};

const DeliverShipmentsLayout = ({ driverDeliverPackages }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [activeNext, setActiveNext] = useState([true, true]);
  const [openBackDrop, setOpenBackDrop] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMs, setDialogMs] = useState([]);
  const [missingTrackingNumbers, setMissingTrackingNumbers] = useState([]);
  const steps = getSteps();
  const [confirmationCodes, setConfirmationCodes] = useState([]);
  const selectedTrackingNumbers = useSelector(
    state => state.driver_deliver_packages.selectedTrackingNumber
  );

  const selectedShipmentNumbers = useSelector(
    state => state.driver_deliver_packages.selectedShipmentNumber
  );

  const driverUploadedImages = useSelector(
    state => state.driver_uploaded_images.deliverShipments
  );

  const getStepContent = step => {
    switch (step) {
      case 0:
        return <LoadAndDeliverShipmentsLayout />;
      case 1:
        return <LoadAndDeliverShipmentsLayout deletable />;
      case 2:
        return <DriverUploadImages type="deliver" />;
      case 3:
        return (
          <ConfirmationCodesInputLayout
            codesCallback={handleConfimationCodes}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleConfimationCodes = codes => {
    setConfirmationCodes(codes);
  };

  useEffect(() => {
    setActiveNext([
      driverDeliverPackages.selectedTrackingNumber.length === 0,
      Object.keys(driverDeliverPackages.selectedPackages).length === 0,
    ]);
  }, [
    driverDeliverPackages.selectedTrackingNumber,
    driverDeliverPackages.selectedPackages,
  ]);

  const handleCloseBackDrop = () => {};

  const handleRetry = () => {
    dispatch(removeAllSelectedDeliverShipments());
    setOpenBackDrop(false);
    setOpenDialog(false);
    setActiveStep(0);
    setMissingTrackingNumbers([]);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setOpenBackDrop(false);
    setActiveStep(0);
    setMissingTrackingNumbers([]);
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      const header = {
        Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
      };

      //make API calls here
      if (selectedTrackingNumbers.length > 0) {
        setOpenBackDrop(true);
        const formData = new FormData();
        for (let i = 0; i < selectedTrackingNumbers.length; i++) {
          formData.append("tracking_numbers[]", selectedTrackingNumbers[i]);
        }
        axios
          .post(DRIVER_DELIVER_SHIPMENT_BY_TRACKING_NUMBER, formData, {
            headers: header,
          })
          .then(res => {
            console.log("respond object", res);
            if (res.data.missing.length > 0) {
              setDialogTitle("Missing Some Tracking Numbers");
              setDialogMs(
                "Please check tracking numbers below before confirming deliver shipments."
              );
              setMissingTrackingNumbers(res.data.missing);
            }
            setOpenDialog(true);
          })
          .catch(error => {
            setDialogTitle("Fail to Deliver Shipments");
            if (error.response.data.message) {
              setDialogMs(
                `Fail to deliver shipments with error message: ${error.response.data.message}.`
              );
            } else {
              setDialogMs("Unknown error, please contact our support .");
            }
            setOpenDialog(true);
          });
      }

      if (driverUploadedImages.length > 0) {
        for (let i = 0; i < driverUploadedImages.length; i++) {
          let formData = new FormData();
          formData.append("file", driverUploadedImages[i].raw);

          for (let i = 0; i < selectedShipmentNumbers.length; i++) {
            formData.append("shipments[]", selectedShipmentNumbers[i]);
          }

          axios({
            method: "post",
            url: DRIVER_UPLOAD_SHIPMENTS_IMAGES,
            data: formData,
            headers: {
              Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
              "Content-Type": "multipart/form-data;",
            },
          })
            .then(res => {
              console.log(`response of request ${i}`, res);
              setOpenBackDrop(false);
            })
            .catch(error => {
              console.log(error);
              setOpenBackDrop(false);
            });
        }
        //setActiveStep(0);
      }
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={classes.root}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        style={{ width: "94%", maxWidth: "700px" }}
      >
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
            <StepContent className={classes.stepCotent}>
              <Typography>{getStepContent(index)}</Typography>
              <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                    className={classes.button}
                    disabled={activeNext[activeStep]}
                  >
                    {activeStep === steps.length - 1 ? "Finish" : "Next"}
                  </Button>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {
        <>
          <Backdrop
            className={classes.backdrop}
            open={openBackDrop}
            onClick={handleCloseBackDrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
          >
            <DialogTitle id="alert-dialog-title">{dialogTitle}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {dialogMs}
              </DialogContentText>
              <div className={classes.missingTrackingNumberWrapper}>
                {missingTrackingNumbers &&
                  missingTrackingNumbers.map((num, index) => {
                    return <li key={index}>{num.tracking_number}</li>;
                  })}
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleRetry} color="primary">
                Retry
              </Button>
              <Button onClick={handleCloseDialog} color="primary">
                Okay
              </Button>
            </DialogActions>
          </Dialog>
        </>
      }
    </div>
  );
};

const mapStateToProps = state => {
  return {
    driverDeliverPackages: state.driver_deliver_packages,
  };
};

export default connect(mapStateToProps)(DeliverShipmentsLayout);

import React from "react";
import LoadingCircle from "../LoadingCircle";
import {
  makeStyles,
  // Divider,
  Typography,
  Card,
  CardActionArea,
  Box,
} from "@material-ui/core";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import { Alert } from "@material-ui/lab";
import { useHistory } from "react-router";

const useStyle = makeStyles((theme) => ({
  listItem: {
    listStyleType: "none",
  },
  listContainer: {
    width: "100%",
    maxWidth: "700px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    margin: "0px auto",
  },
  card: {
    width: "96%",
    maxWidth: "650px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    },
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
}));

export default function ShipmentsLayout({
  shipments,
  loading,
  title,
  noResult,
  reminder = false,
  errMsg = "",
}) {
  const classes = useStyle();
  const history = useHistory();

  //   useEffect(() => {
  //     if (shipments.length > 0) {
  //       setLoading(false);
  //     }
  //   }, [shipments]);

  const handleGoToShipmentDetail = (sNum) => {
    history.push(
      reminder
        ? `/driver/loaded_shipments/${sNum}`
        : `/driver/available_shipments/${sNum}`
    );
  };

  let noDataComponent = (
    <Box pt={1} width="100%">
      <Alert severity="error">{errMsg}</Alert>
    </Box>
  );

  if (loading) noDataComponent = <LoadingCircle />;

  return (
    <>
      {loading || errMsg ? (
        noDataComponent
      ) : (
        <>
          <Box fontWeight="600" textAlign="center" fontSize="1.2rem">
            {title}
          </Box>
          <div className={classes.listContainer}>
            {shipments === null || shipments.length === 0 ? (
              <Box pt={1}>
                <Typography variant="body2" align="center">
                  {noResult}
                </Typography>
              </Box>
            ) : (
              shipments.map((s, index) => {
                return (
                  <li key={index} className={classes.listItem}>
                    <Card
                      className={classes.card}
                      onClick={() =>
                        handleGoToShipmentDetail(s.shipment_number)
                      }
                    >
                      <CardActionArea className={classes.cardContent}>
                        <LocalShippingIcon />
                        <Box component="span" pl={1} fontWeight="600">
                          {s.item_name}
                        </Box>
                        <Box pt={0.5}>{s.shipment_number}</Box>
                      </CardActionArea>
                    </Card>
                  </li>
                );
              })
            )}
          </div>
        </>
      )}
    </>
  );
}

export const NEW_USER = "NEW_USER";
export const FETCH_USER = "FETCH_USER";
export const NEW_SHIPPING_ITEM = "NEW_SHIPPING_ITEM";
export const GET_SHIPPING_ITEM = "GET_SHIPPING_ITEM";
export const NEW_PICKUP_DETAILS = "NEW_PICKUP_DETAILS";
export const GET_PICKUP_DETAILS = "GET_PICKUP_DETAILS";
export const DELETE_SHIPPING_ITEM = "DELETE_SHIPPING_ITEM";

export const GET_ORDERS = "GET_ORDERS";
export const FETCHING_ORDERS = "FETCHING_ORDERS";
export const SELECT_ORDER = "SELECT_ORDER";

export const GET_PACKAGES = "GET_PACKAGES";
export const SELECT_PACKAGE = "SELECT_PACKAGE";

export const GET_SHIPMENTS = "GET_SHIPMENTS";

export const SET_NEW_LOCATION = "SET_NEW_LOCATION";
export const UPDATE_LOCATION = "UPDATE_LOCATION";
export const CLEAN_LOCATION = "CLEAN_LOCATION";

export const STACK_SHIPMENTS = "STACK_SHIPMENTS";
export const FETCHING_SHIPMENTS = "FETCHING_SHIPMENTS";
export const SELECT_SHIPMENT = "SELECT_SHIPMENT";

export const STACK_PACKAGES = "STACK_PACKAGES";
export const FETCHING_PACKAGES = "FETCHING_PACKAGES";

export const UPDATE_SEARCH_FILTER = "UPDATE_SEARCH_FILTER";
export const CLEAN_SEARCH_FILTER = "CLEAN_SEARCH_FILTER";
export const FETCH_SEARCH_FILTER_STATIC = "FETCH_SEARCH_FILTER_STATIC";

export const DRIVER_STACK_PACKAGES = "DRIVER_STACK_PACKAGES";
export const DRIVER_FETCHING_PACKAGES = "DRIVER_FETCHING_PACKAGES";
export const DRIVER_SELECT_PACKAGE = "DRIVER_SELECT_PACKAGE";

export const DRIVER_ADD_SHIPMENTS_TO_SUMMARY =
  "DRIVER_ADD_SHIPMENTS_TO_SUMMARY";
export const DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY =
  "DRIVER_REMOVE_SHIPMENTS_FROM_SUMMARY";
export const DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY =
  "DRIVER_CLAIM_SHIPMENTS_FROM_SUMMARY";
export const DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY =
  "DRIVER_CLEAN_ALL_SHIPMENTS_FROM_SUMMARY";

export const DRIVER_ADD_DELIVER_SHIPMENTS = "DRIVER_ADD_DELIVER_SHIPMENTS";
export const DRIVER_SELECT_DELIVER_SHIPMENTS =
  "DRIVER_SELECT_DELIVER_SHIPMENTS";
export const DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS =
  "DRIVER_REMOVE_SELECTED_DELIVER_SHIPMENTS";
export const DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS =
  "DRIVER_REMOVE_ALL_SELECTED_DELIVER_SHIPMENTS";
export const DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA =
  "DRIVER_CLEAN_DELIVER_SHIPMENTS_DATA";

export const DRIVER_ADD_LOADED_SHIPMENTS = "DRIVER_ADD_LOADED_SHIPMENTS";
export const DRIVER_SELECT_LOADED_SHIPMENTS = "DRIVER_SELECT_LOADED_SHIPMENTS";
export const DRIVER_REMOVE_LOADED_SHIPMENTS = "DRIVER_REMOVE_LOADED_SHIPMENTS";
export const DRIVER_CLEAN_LOADED_SHIPMENTS_DATA =
  "DRIVER_CLEAN_LOADED_SHIPMENTS_DATA";

export const DRIVER_UPLOAD_IMAGES = "DRIVER_UPLOAD_IMAGES";
export const DRIVER_REMOVE_IMAGES = "DRIVER_REMOVE_IMAGES";
export const DRIVER_CLEAR_IMAGES = "DRIVER_CLEAR_IMAGES";

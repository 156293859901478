import React, { useState, useEffect } from 'react'
import { BrowserRouter as Route, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import ScrollComponent from './ScrollComponent'
import LoadedShipmentList from '../Layouts/LoadedShipmentList'
import ListAndMapTabsLayout from '../Layouts/ListAndMapTabsLayout'
import ShipmentMapLayout from '../Map/ShipmentMapLayout'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    maxWidth:'700px',
    height: '100%',
    textAlign:'center',
    margin:'0 auto'
  },
  tabPanel:{
    overflowY:'hidden',
    '&::-webkit-scrollbar':{
      'display': 'none'
    },
    height:'fitContent',
    backgroundColor:'#FFFAF2',
  },
  container:{
    padding: '0px'
  }
}))

const TabPanel = (props) => {
  const { children, value, index, ...other } = props
  const classes = useStyles()
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} classes={{root:classes.container}}>
            <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const a11yProps = (index) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const ClaimedShipmentsList = () => {
  const history = useHistory()
  const [loadedShipments, setLoadedShipments] = useState([])

  const classes = useStyles()
  const theme = useTheme()
  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const goOrderDetailPage = (orderNumber) => {
    console.log(orderNumber)
    let path = `/order-details/${orderNumber}`
    history.push(path)
  }

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" color="default" >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            fullWidth={true}
            centered
            scrollButtons="auto"
            variant='fullWidth'
          >
            <Tab label="Claimed" {...a11yProps(0)} />
            <Tab label="Loaded" {...a11yProps(1)} />
            <Tab label="Delivered" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        
          <TabPanel value={value} index={0} dir={theme.direction} className={classes.tabPanel}>
            {
              <ListAndMapTabsLayout listComponent={<ScrollComponent type='claimed_shipments'/>} 
              mapComponent={<ShipmentMapLayout type='claimed_shipments'/>}/>
              
            }
          </TabPanel>
          <TabPanel value={value} index={1} dir={theme.direction} className={classes.tabPanel}>
            <>
              {
                <ListAndMapTabsLayout listComponent={<LoadedShipmentList/>} 
                mapComponent={<ShipmentMapLayout type='loaded_shipments'/>}/>
                
              }
            </>
          </TabPanel>
          <TabPanel value={value} index={2} dir={theme.direction} className={classes.tabPanel}>
            <div>
              {
                <ListAndMapTabsLayout listComponent={<ScrollComponent type={'delivered_shipments'}/>} 
                mapComponent={<ShipmentMapLayout type='delivered_shipments'/>}/>
                
              }
            </div>
          </TabPanel>
      </div>
    </>
  )
}

export default ClaimedShipmentsList
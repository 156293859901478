import React, { useState, useEffect } from "react";
import ScanBarcode from "../ScanComponents/ScanBarcode";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
  IconButton,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Paper,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import axios from "axios";
import {
  GET_TRACKING_INFO_BY_TRACKING_NUMBER,
  GET_PACKAGES_BY_ID_API,
  DRIVER_LOAD_SHIPMENTS,
  DRIVER_UPLOAD_SHIPMENTS_IMAGES,
} from "../../Utils/apiUrl";
import { getHeaders } from "../../Utils/fetchUtil";
import LoadingCircle from "../LoadingCircle";
import { removeLoadedShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import {
  addLoadedShipments,
  cleanLoadedShipmentsData,
} from "../../actions/driverLoadAndDeliverShipmentsAction";
import { driverClearImages } from "../../actions/driverDeliverUploadImagesAction";
import { useDispatch, connect } from "react-redux";
import { getTokenFromCookie } from "../../Utils/doToken";
import DriverUploadImages from "../Layouts/DriverUploadImages";
import ConfirmationCodesInputLayout from "../Layouts/ConfirmationCodesInputLayout";
import FeedbackDialogLayout from "../Layouts/FeedbackDialogLayout";
import { useSelector } from "react-redux";

const useStyles = makeStyles(theme => ({
  scanInput: {
    justifyContent: "center",
    margin: "5px auto",
    gap: "5px",
  },
  scanButton: {
    marginLeft: "5px",
  },
  confirmButton: {
    color: "white",
  },
  centering: {
    display: "flex",
    justifyContent: "center",
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    maxWidth: "700px",
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    display: "flex",
    justifyContent: "space-around",
  },
  blocks: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "5px",
  },
}));

const getSteps = () => {
  return [
    "Review shipments that you are going to pickup.",
    "Take pictures before confirming pickup shipments (Recommended).",
    "Input confirmation codes.",
  ];
};

const LoadShipmentsPage = ({ loadedPackages }) => {
  const dataFlow = loadedPackages.driver_Loaded_packages;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [scanState, setScanState] = useState({
    activeScan: false,
    buttonWord: "Scan Tracking Number",
  });

  const [scannedTrackingNumbers, setScannedTrackingNumbers] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [loadInfo, setLoadInfo] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const [activeNext, setActiveNext] = useState([
    Object.keys(dataFlow).length === 0,
    false,
  ]);
  const [confirmationCodes, setConfirmationCodes] = useState([]);
  const [dialogState, setDialogState] = useState({
    openDialog: false,
    openBackdrop: false,
    openFeedbackDialog: false,
    feedbackContent: {},
    feedbackTarget: [],
  });

  const driverUploadedImages = useSelector(
    state => state.driver_uploaded_images.loadShipments
  );

  const LoadedShipmentNumbers = useSelector(
    state => state.driver_loaded_packages.loaded_shipment_numbers
  );

  const handleRemove = (pKey, sKey, t) => {
    console.log("clicked to remove tracking number", pKey, sKey, t);
    const payload = {
      package_number: pKey,
      shipment_number: sKey,
      tracking_number: t,
    };

    dispatch(removeLoadedShipments(payload));
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <>
            {dataFlow &&
              Object.keys(dataFlow).length > 0 &&
              Object.keys(dataFlow).map((pKey, index) => {
                const p = dataFlow[pKey];
                const s = p.shipment_numbers;
                let tracking_count = 0;
                for (let key in s) {
                  tracking_count += Object.keys(s[key]).length;
                }
                return (
                  <>
                    <li
                      key={p.package_number}
                      style={{ listStyleType: "none" }}
                      className={classes.center}
                    >
                      <Paper className={classes.paper}>
                        <Typography variant="subtitle1" align="center">
                          {p.item_name}
                        </Typography>
                        {p.cover_image !== "" && (
                          <img
                            className={classes.img}
                            alt="package"
                            src={p.cover_image}
                          />
                        )}
                        <Divider
                          variant="middle"
                          style={{ margin: "5px auto" }}
                        />
                        <Grid container className={classes.container}>
                          <Grid item>
                            <Typography variant="subtitle2">
                              Total Shipments: {Object.keys(s).length}
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Typography variant="subtitle2" align="center">
                              Total Tracking Number(s): {tracking_count}
                            </Typography>
                          </Grid>
                          {Object.keys(s).map((sKey, index) => {
                            return (
                              <Grid
                                item
                                container
                                className={classes.blocks}
                                key={sKey}
                              >
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle1"
                                    align="center"
                                  >
                                    Shipment #: <i>{sKey}</i>{" "}
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography
                                    variant="subtitle2"
                                    align="center"
                                  >
                                    Total Tracking Number(s):{" "}
                                    {Object.keys(s[sKey]).length}
                                  </Typography>
                                </Grid>
                                {Object.keys(s[sKey]).map(t => {
                                  return (
                                    <Grid
                                      container
                                      className={classes.row}
                                      key={t}
                                    >
                                      <Grid
                                        item
                                        xs={6}
                                        className={classes.center}
                                      >
                                        <Typography variant="subtitle2">
                                          {t}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <IconButton
                                          aria-label="delete"
                                          onClick={() =>
                                            handleRemove(pKey, sKey, t)
                                          }
                                        >
                                          <DeleteIcon fontSize="small" />
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            );
                          })}
                        </Grid>
                      </Paper>
                    </li>
                  </>
                );
              })}
          </>
        );
      case 1:
        return <DriverUploadImages type="load" />;
      case 2:
        return (
          <ConfirmationCodesInputLayout
            codesCallback={handleConfirmationCodes}
          />
        );
      default:
        return "Unknown step";
    }
  };

  const handleConfirmationCodes = codes => {
    setConfirmationCodes(codes);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const loadShipment = async () => {
    //call API to load shipments
    let loadShipmentRes = {},
      uploadImagesRes = [];

    try {
      let res = await axios.put(
        DRIVER_LOAD_SHIPMENTS,
        {},
        {
          params: {
            shipment_numbers: loadedPackages.loaded_shipment_numbers
              .join(",")
              .toString(),
            codes: confirmationCodes.join(",").toString(),
          },
          headers: getHeaders(),
        }
      );

      console.log("res of loading", res);

      if (res.status === 200) {
        loadShipmentRes.shipmentNumbers =
          loadedPackages.loaded_shipment_numbers;
        loadShipmentRes.status = 200;
      }
    } catch (err) {
      loadShipmentRes.shipmentNumbers = loadedPackages.loaded_shipment_numbers;
      loadShipmentRes.status = "Failed";
      loadShipmentRes.message = err;
    }

    if (driverUploadedImages.length > 0) {
      for (let i = 0; i < driverUploadedImages.length; i++) {
        let formData = new FormData();
        formData.append("file", driverUploadedImages[i].raw);

        for (let j = 0; j < LoadedShipmentNumbers.length; j++) {
          formData.append("shipments[]", LoadedShipmentNumbers[j]);
        }

        try {
          let res = await axios({
            method: "post",
            url: DRIVER_UPLOAD_SHIPMENTS_IMAGES,
            data: formData,
            headers: {
              Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
              "Content-Type": "multipart/form-data;",
            },
          });

          console.log("res of upload images", res);

          if (res.status === 200) {
            uploadImagesRes.push({
              status: "success",
              name: driverUploadedImages[i].name,
            });
          }
        } catch (err) {
          uploadImagesRes.push({
            status: "failed",
            name: driverUploadedImages[i].name,
          });
        }
      }
    }

    setDialogState({
      ...dialogState,
      openDialog: false,
      openFeedbackDialog: true,
      feedbackContent: { loadShipmentRes, uploadImagesRes },
    });
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      setDialogState({
        ...dialogState,
        openDialog: true,
        openBackdrop: true,
      });
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleClickScan = () => {
    if (!scanState.activeScan) {
      setScanState({
        activeScan: true,
        buttonWord: "Close Scan",
      });
    } else {
      setScanState({
        activeScan: false,
        buttonWord: "Scan Tracking Number",
      });
    }
  };

  const fetchInfo = async tNums => {
    setLoadInfo(true);

    //if get numbers from the scanner
    if (tNums) {
      console.log("tracking numbers from the scanner", tNums);

      //make API calls
      const header = {
        headers: {
          Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
        },
      };

      for (let i = 0; i < tNums.length; i++) {
        const trackingInfo = await axios.get(
          GET_TRACKING_INFO_BY_TRACKING_NUMBER(tNums[i]),
          header
        );

        //returning nothing, the data property is empty, go next
        if (!trackingInfo.data || Object.keys(trackingInfo.data).length === 0) {
          continue;
        }

        if (trackingInfo) {
          console.log("tracking infor from API call", trackingInfo);
          const shipmentNum = trackingInfo.data.shipment_number;
          const packageNum = trackingInfo.data.package_number;

          //get package info and shipment info
          const packageInfo = await axios.get(
            GET_PACKAGES_BY_ID_API(packageNum),
            header
          );
          console.log("package infor", packageInfo);
          if (packageInfo !== null) {
            const p = packageInfo.data;
            const payload = {
              package_number: p.package_number,
              item_name: p.item_name,
              cover_image: p.cover_image,
              shipment_number: shipmentNum,
              tracking_number: [trackingInfo.data.tracking_number],
            };
            dispatch(addLoadedShipments(payload));
          }
        }
      }
      console.log("fetch tracking info of scanned nums DONE");
      setLoadInfo(false);
    } else {
      //numbers not come from scanner
      try {
        const header = {
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        };
        const trackingInfo = await axios.get(
          GET_TRACKING_INFO_BY_TRACKING_NUMBER(textInput),
          header
        );
        if (trackingInfo) {
          const shipmentNum = trackingInfo.data.shipment_number;
          const packageNum = trackingInfo.data.package_number;

          //get package info and shipment info
          const packageInfo = await axios.get(
            GET_PACKAGES_BY_ID_API(packageNum),
            header
          );
          console.log("package infor", packageInfo);
          if (packageInfo !== null) {
            const p = packageInfo.data;
            const payload = {
              package_number: p.package_number,
              item_name: p.item_name,
              cover_image: p.cover_image,
              shipment_number: shipmentNum,
              tracking_number: [trackingInfo.data.tracking_number],
            };
            dispatch(addLoadedShipments(payload));
          }

          setLoadInfo(false);
        } else {
          setLoadInfo(false);
          return;
        }
      } catch (error) {
        setLoadInfo(false);
      }
    }
  };

  const handleEnter = () => {
    if (textInput.length === 0) return;

    fetchInfo();
  };

  useEffect(() => {
    return () => {
      dispatch(cleanLoadedShipmentsData());
    };
  }, []);

  //input for tracking number
  const handleChange = e => {
    setTextInput(e.target.value);
  };

  const handleCancel = () => {
    setDialogState({
      ...dialogState,
      openBackdrop: false,
      openDialog: false,
    });
  };

  const handleFeedbackConfirm = () => {
    dispatch(cleanLoadedShipmentsData());
    dispatch(driverClearImages("load"));
    setActiveStep(0);
    setActiveNext([true, true]);
    setDialogState({
      ...dialogState,
      openDialog: false,
      openBackdrop: false,
      openFeedbackDialog: false,
    });
  };

  return (
    <>
      <Typography variant="h6" align="center">
        Load Shipments
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container className={classes.scanInput}>
          <form>
            <TextField
              label="Tracking Number"
              type="number"
              variant="outlined"
              onChange={e => handleChange(e)}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnter()}
            className={classes.scanButton}
          >
            Enter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickScan()}
            className={classes.scanButton}
          >
            {scanState.buttonWord}
          </Button>
        </Grid>
        {scanState.activeScan && (
          <Grid item xs={12}>
            <ScanBarcode
              onGetBarcode={nums => {
                fetchInfo(nums);
              }}
            />
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      {
        loadInfo ? (
          <LoadingCircle />
        ) : (
          <div className={classes.root}>
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              style={{ width: "94%", maxWidth: "700px" }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                  <StepContent className={classes.stepCotent}>
                    <Typography>{getStepContent(index)}</Typography>
                    <div className={classes.actionsContainer}>
                      <div>
                        <Button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className={classes.button}
                        >
                          Back
                        </Button>
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                          disabled={activeNext[activeStep]}
                        >
                          {activeStep === steps.length - 1 ? "Submit" : "Next"}
                        </Button>
                      </div>
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        ) //type can be 'load' or 'deliver'
      }
      <FeedbackDialogLayout
        openDialog={dialogState.openDialog}
        openBackDrop={dialogState.openBackDrop}
        title={"Confirm to load shipment(s)"}
        content={"Shipment Number(s):"}
        shipmentNumbers={loadedPackages.loaded_shipment_numbers}
        handleConfirm={loadShipment}
        handleCancel={handleCancel}
        openFeedbackDialog={dialogState.openFeedbackDialog}
        feedbackContent={dialogState.feedbackContent}
        feedbackTarget={dialogState.feedbackTarget}
        handleFeedbackConfirm={handleFeedbackConfirm}
      />
    </>
  );
};

const mapStateToProps = state => {
  return {
    loadedPackages: state.driver_loaded_packages,
  };
};

export default connect(mapStateToProps)(LoadShipmentsPage);

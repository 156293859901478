import React, { useState } from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import { Button, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

// import Slide from 'react-reveal';
import axios from "axios";
import { CustomInput } from "../shared";
import { errHandling } from "../../Utils/fetchUtil";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ResetPasswordPage = () => {
  const query = useQuery();
  // const history = useHistory();

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");

  const [error, setError] = useState("");
  const [rstSuccess, setSuccessState] = useState(false);

  const handleSetPassword = () => {
    if (password1 !== password2) {
      setError("Password mismatch");
      return;
    }
    setError("");
    const email = query.get("email");
    const encrypt = query.get("encrypt");
    const seq = query.get("seq");
    const url = `https://api.uucargo.ca/api/userprofile/new_password?email=${email}&password=${password1}&encrypt=${encrypt}&seq=${seq}`;
    axios
      .post(url)
      .then((response) => {
        setSuccessState(true);
      })
      .catch((error) => {
        errHandling(error, setError);
      });
  };

  return (
    // <Slide down duration ={500}>
    <Box
      display="flex"
      flexDirection="column"
      fontFamily="Montserrat"
      justifyContent="center"
      alignItems="center"
      mt="18vh"
      letterSpacing="1.5px"
    >
      <Box fontSize="3rem" color="seagreen" mb="5vh" textAlign="center">
        Reset Password
      </Box>
      {rstSuccess ? (
        <Box fontSize="2rem" color="rgb(141, 173, 155)">
          Successful reset
        </Box>
      ) : (
        <>
          <Box textAlign="center" color="rgb(141, 173, 155)" pb="2vh">
            <p>Enter your new password:</p>
          </Box>
          <Box width="25vw" pb="2vh" minWidth="300px">
            <CustomInput
              placeholderValue="New Password"
              value={password1}
              onChange={(e) => setPassword1(e.target.value)}
              type="password"
            />
          </Box>
          <Box width="25vw" pt="2vh" pb="5vh" minWidth="300px">
            <CustomInput
              placeholderValue="Confirm New Password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              type="password"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSetPassword}
            style={{ color: "white", fontFamily: "Montserrat" }}
          >
            Set Password
          </Button>
        </>
      )}
      <Box fontSize={rstSuccess ? "1.5rem" : "1rem"} mt="3vh">
        <Link to="/">Go to sign in page</Link>
      </Box>
      <Box pt="1vh">
        {error ? (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error}
          </Alert>
        ) : (
          ""
        )}
      </Box>
    </Box>
    // </Slide>
  );
};

export default ResetPasswordPage;

import React, { useState, useEffect } from "react";
// import Slide from 'react-reveal';
// import "../styles/styles.css";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  Alert,
  // Pagination
} from "@material-ui/lab";

import axios from "axios";

import { getHeaders, errHandling } from "../../Utils/fetchUtil";
import LoadingContainer from "../shared/loadingContainer";

import {
  GET_UNCONFIRMED_DELIVERED_SHIPMENTS,
  GET_UNCONFIRMED_PICKEDUP_SHIPMENTS,
  // CONFIRM_SHIPMENTS_DELIVERY,
} from "../../Utils/apiUrl";

import { Box, Container, Card, Checkbox, Button } from "@material-ui/core";

const ConfirmShipments = () => {
  // const token = getAccessToken("access_token");

  // const dispatch = useDispatch();
  const classes = useStyles();

  const [claimedShipmentsLoading, setClaimedShipmentsLoading] = useState(true);

  const [claimedShipments, setClaimedShipments] = useState([]);

  const [claimedErrorMsg, setClaimedErrorMsg] = useState("");

  const { state } = useParams();

  const receiving = !state || state === "receipt";

  // const matches = useMediaQuery("(max-width:600px)");

  useEffect(() => {
    const url = receiving
      ? GET_UNCONFIRMED_DELIVERED_SHIPMENTS
      : GET_UNCONFIRMED_PICKEDUP_SHIPMENTS;
    axios
      .get(url, { headers: getHeaders() })
      .then((response) => {
        if (response.data) setClaimedShipments(response.data);
        // // setLoadingState(false);
        setClaimedShipmentsLoading(false);
      })
      .catch((err) => {
        setClaimedShipmentsLoading(false);
        errHandling(err, setClaimedErrorMsg);
      });
  }, []);

  const getDate = (date) => {
    return date.split(" ")[0];
  };

  // const confirmShipments = () => {
  //   axios({
  //     method: "put",
  //     url: CONFIRM_SHIPMENTS_DELIVERY(),
  //     headers: {
  //       ...getHeaders(),
  //       "Content-Type": "application/json",
  //       "X-Requested-With": "XMLHttpRequest",
  //     },
  //   })
  //     .then((res) => {})
  //     .catch((error) => {
  //       console.log("Error when trying to claim shipment: ", error);
  //     });
  // };

  const shipmentLists = (shipments) => {
    const allShipments = (
      <>
        {shipments.map((item) => {
          return (
            <Box
              // classes={{ root: classes.claimRoot }}
              className={`${classes.pkgWrapper} ${classes.claimRoot}`}
              key={item.shipment_number}
            >
              <Box
                className={classes.cardContent}
                display="flex"
                alignItems="center"
                // width="100%"
              >
                <Box mr={2}>
                  <Checkbox color="primary" />
                </Box>
                <Box display="flex" flexDirection="column" flexGrow="1">
                  <Box color="seagreen" mb={0.5} fontSize="0.9rem">
                    {item.shipment_number}
                  </Box>
                  <Box component="span" flexBasis="60%" fontWeight="700">
                    {item.item}
                  </Box>
                  <Box>
                    Pickup date:{" "}
                    <span style={{ fontStyle: "italic" }}>
                      {getDate(item.pickup_date)}
                    </span>
                  </Box>
                  <Box>
                    Deliver date:{" "}
                    <span style={{ fontStyle: "italic" }}>
                      {getDate(item.deliver_date)}
                    </span>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}
      </>
    );
    return allShipments;
  };

  const comingPickup = (_claimedShipments, _claimedShipmentsLoading) => {
    let lastestItem = <LoadingContainer />;

    if (!_claimedShipmentsLoading && _claimedShipments.length === 0)
      lastestItem = (
        <Box fontSize="1.5rem" m={1} textAlign="center">
          No claimed shipment found
        </Box>
      );

    if (!_claimedShipmentsLoading && _claimedShipments.length !== 0)
      lastestItem = (
        <>
          {_claimedShipments.map((item) => {
            return (
              <Card classes={{ root: classes.myRoot }} key={item.order_number}>
                <Box fontSize="1rem" pl={1} pr={1} pt={1}>
                  Order #: <b>{item.order_number}</b> <br />
                  Create data: <b>{getDate(item.date_created)}</b>
                </Box>
                {shipmentLists(item.shipments)}
              </Card>
            );
          })}
        </>
      );
    return lastestItem;
  };

  return (
    <Container>
      <Box>
        <Box fontSize="1.4rem" mt={1} mb={2} fontWeight="600">
          {receiving
            ? "Confirm shipments receipt:"
            : "Confirm shipments pickup:"}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={1}
          mb={3}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => {}}
            style={{
              textTransform: "unset",
              fontSize: "1rem",
              width: "6rem",
            }}
            disabled={claimedShipmentsLoading}
          >
            Submit
          </Button>
        </Box>

        {claimedErrorMsg ? (
          <Card classes={{ root: classes.smRoot }}>
            <Box pt={1} width="100%">
              <Alert severity="error">{claimedErrorMsg}</Alert>
            </Box>
          </Card>
        ) : (
          ""
        )}
        {!claimedErrorMsg &&
          comingPickup(claimedShipments, claimedShipmentsLoading)}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={3}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={() => {}}
          style={{
            textTransform: "unset",
            fontSize: "1rem",
            width: "6rem",
          }}
          disabled={claimedShipmentsLoading}
        >
          Submit
        </Button>
      </Box>
    </Container>
  );
};

const useStyles = makeStyles((theme) => ({
  myRoot: {
    width: "100%",
    margin: "15px auto",
  },
  smRoot: {
    minHeight: 100,
    margin: 10,
    marginLeft: 0,
    marginTop: 10,
    // width: '90%',
    width: "100%",
  },

  claimRoot: {
    // minHeight: 70,
    margin: 10,
    // width: '90%',
    // width: "100%",
    // display: "flex",
    // alignItems: "center",
  },
  pkgWrapper: {
    backgroundColor: "#fdf5e8",
    padding: "3px",
  },
  cardContent: {
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
  },
}));

export default ConfirmShipments;

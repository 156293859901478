import React from 'react'
import RoomIcon from '@material-ui/icons/Room'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  markerIcon:{
    color:'orange',
    transform:'scale(1.5)',
    '&:hover':{
      transform:'scale(2)'
    }
  }
}))

const MapMarker = ({shipmentNumber, title, lat, lng, handleMarkerClick}) => {
  const classes = useStyles()

  return (
    <div>
      {title}
      <RoomIcon className={classes.markerIcon} id={`markerIcon-${shipmentNumber}`} onClick={()=>handleMarkerClick(shipmentNumber)}/>
    </div>
  )
}

export default MapMarker
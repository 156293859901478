import React, { useEffect, useRef } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import { provinces } from "../constInfo";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  textfield: {
    width: "100%",
    backgroundColor: "white",
    padding: "0.4px",
    borderRadius: "5px",
    // boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: "0.4vw",
    paddingBottom: "0.4vw",
  },
}));

export default function GoogleMaps({
  type,
  handleChange,
  setProvinceValue,
  addressValue,
  cityValue,
  setCityValue,
  setCityValueUpperLevel,
  zipCodeValue,
  estimate,
  filter = false,
}) {
  const classes = useStyles();
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  // const autocompleteService = { current: null };
  const autocompleteService = useRef(null);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ&libraries=places",
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  useEffect(() => {
    if (filter) {
      if (type !== "city") return;

      if (value !== null) {
        setInputValue("");
      }
    }
  }, [value, filter]);

  // Load cityValue to value everytime the cityValue changes
  useEffect(() => {
    if (type === "city" && cityValue) {
      setValue(cityValue.city);
    }
  }, [cityValue, type]);

  // Load zipCode value to value everytime the zipCode value changes
  useEffect(() => {
    if (type === "postal" && zipCodeValue) {
      setValue(zipCodeValue);
    }
  }, [zipCodeValue, type]);

  // Load street value to value everytime the address value changes
  useEffect(() => {
    if (type === "street" && addressValue) setValue(addressValue || null);
  }, [addressValue, type]);

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const title = (_type) => {
    if (_type === "city") return "City *";
    if (_type === "postal") return "Postal / Zip Code";
    return "Add a street address *";
  };

  const getProvince = (_newValue) => {
    if (!_newValue) return "";
    if (
      _newValue.types[0] === "street_address" ||
      _newValue.types[0] === "premise"
    ) {
      const findRes = provinces.find(
        (province) =>
          province.value ===
          _newValue.structured_formatting.secondary_text
            .split(", ")[1]
            .split(" ")[0]
      );
      return findRes ? findRes.label : "";
    }
    return "";
  };

  const getCity = (_newValue) => {
    if (!_newValue) return "";
    if (
      _newValue.types[0] === "street_address" ||
      _newValue.types[0] === "premise"
    ) {
      const findRes =
        _newValue.structured_formatting.secondary_text.split(", ")[0];
      return findRes ? findRes : "";
    }
    return "";
  };

  return (
    <Autocomplete
      //   style={{ width: 300 }}
      getOptionLabel={(option) => {
        if (typeof option === "string") return option;
        if (type === "street")
          return option.types[0] === "street_address" ||
            option.types[0] === "premise"
            ? option.structured_formatting.main_text
            : "";
        if (type === "city")
          return option.types[0] === "locality"
            ? option.structured_formatting.main_text
            : "";
        if (type === "postal")
          return option.types[0] === "postal_code"
            ? option.structured_formatting.main_text
            : "";
        return "";
      }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      freeSolo={type === "street" || type === "postal"}
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (!newValue) {
          handleChange("");
          return;
        }
        // Update city and province value if the user input and select the address
        if (type === "street") {
          getProvince(newValue) && setProvinceValue(getProvince(newValue));
          const _city = getCity(newValue);
          if (_city) {
            // console.log(_city, "herehrer");
            setCityValue({ city: _city });
            setCityValueUpperLevel(_city);
          }
        }
        if (type === "city") {
          handleChange(
            newValue.types[0] === "locality"
              ? newValue.structured_formatting.main_text
              : ""
          );
        }
        if (type === "postal")
          handleChange(
            newValue.types[0] === "postal_code"
              ? newValue.structured_formatting.main_text
              : ""
          );
      }}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        if (type === "street" || type === "postal")
          handleChange(newInputValue || "");

        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        params.inputProps.autoComplete = "new-password";
        return (
          <TextField
            {...params}
            label={estimate ? "" : title(type)}
            className={estimate ? classes.textfield : ""}
            InputProps={
              estimate
                ? {
                    ...params.InputProps,
                    autoComplete: "off",
                    disableUnderline: true,
                  }
                : { ...params.InputProps, autoComplete: "off" }
            }
          />
        );
      }}
      renderOption={(option) => {
        const matches =
          option?.structured_formatting?.main_text_matched_substrings || [];
        const parts = parse(
          option?.structured_formatting?.main_text || "",
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <Grid container alignItems="center">
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid item xs>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}

              <Typography variant="body2" color="textSecondary">
                {option?.structured_formatting?.secondary_text}
              </Typography>
            </Grid>
          </Grid>
        );
      }}
      onBlur={() => {
        if (filter && type === "city") {
          setValue("");
        }
      }}
    />
  );
}

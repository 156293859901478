import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { makeStyles, Typography, Grid, Divider, Box } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import MarkunreadMailboxIcon from "@material-ui/icons/MarkunreadMailbox";
import WidgetsIcon from "@material-ui/icons/Widgets";
import LocalShippingIcon from "@material-ui/icons/LocalShipping";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FindInPageIcon from "@material-ui/icons/FindInPage";
// import { setNewLocation } from "../actions/locationActions";
import axios from "axios";
// import { connect } from "react-redux";
// import ShipmentsOfCurrentCityLayout from "./Layouts/ShipmentsOfCurrentCityLayout";
import ShipmentsLayout from "./Layouts/ShipmentsLayout";
import {
  GET_AVAILABLE_SHIPMENTS,
  GET_DRIVER_LOADED_SHIPMENTS,
} from "../Utils/apiUrl";
import {
  USER_CURRENT_CITY_COOKIE_NAME,
  USER_CURRENT_ADDRESS_COOKIE_TIME,
} from "../Utils/config";
import { getTokenFromCookie, getAccessToken } from "../Utils/doToken";
import Calculator from "./Images/icons/calculator";
import { getHeaders, getURLParams, errHandling } from "../Utils/fetchUtil";

const useStyles = makeStyles({
  driverLandingPage: {
    margin: "0 auto",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    paddingLeft: "1%",
    justifyContent: "flex-start",
    "@media (max-width: 600px)": {
      justifyContent: "space-evenly;",
    },
  },
  cardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    width: 150,
    height: "6rem",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    textAlign: "center",
    padding: "10px",
    "&:hover": {
      backgroundColor: "orange",
      color: "white",
    },
  },
  userName: {
    fontFamily: "Montserrat",
    fontSize: "2rem",
    letterSpacing: "2px",
    color: "green",
    marginTop: "2vh",
    marginLeft: "1%",
  },
  personalCardContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "20px",
    padding: "7px",
    "&:hover": {
      color: "white",
      textDecoration: "none",
      transactionProperty: "color",
      transitionDuration: "0.5s",
    },
  },
  personalCard: {
    width: "90%",
    backgroundColor: "#FEEAAE",
    minWidth: "155px",
    "&:hover": {
      backgroundColor: "#DFA801",
      transitionProperty: "background-color",
      transitionDuration: "0.5s",
    },
  },
});

const DriverLandingPage = ({ location }) => {
  // const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const userName = useSelector((state) => state.user.firstname);
  const getCityTimer = useRef(null);
  const [shipments, setShipments] = useState([]);

  const [warnShipments, setWarnShipments] = useState([]);

  const [warnShipmentsLoading, setWarnShipmentsLoading] = useState(true);
  const [randomShipmentsLoading, setRandomShipmentsLoading] = useState(true);

  const [findingCity, setFindingCityStatus] = useState(true);

  const [recommendErrorMsg, setRecommendErrorMsg] = useState("");
  const [warnShipmentsErrorMsg, setWarnShipmentsErrorMsg] = useState("");

  // const fetchRandomShipments = () => {
  //   axios
  //     .post(
  //       GET_AVAILABLE_SHIPMENTS,
  //       {},
  //       {
  //         headers: {
  //           Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
  //         },
  //       }
  //     )
  //     .then((res) => {
  //       if (getCityTimer.current) {
  //         window.clearTimeout(getCityTimer.current);
  //       }

  //       setShipments((presState) => {
  //         return [...new Set([...presState, ...res.data.data])];
  //       });
  //     });
  // };

  useEffect(() => {
    if (!getCityTimer.current) {
      getCityTimer.current = setTimeout(() => {
        //fetch shipments timeout
        if (findingCity) {
          setFindingCityStatus(false);
          getRandomShipments();
        }
      }, 1000 * 5);
    }

    const setCookie = (name, value) => {
      let date = new Date();
      date.setTime(date.getTime() + USER_CURRENT_ADDRESS_COOKIE_TIME);
      const expires = "expires=" + date.toUTCString();
      document.cookie =
        name + "=" + JSON.stringify(value) + "; " + expires + "; path=/";
    };

    const successFunc = (position) => {
      localStorage["authorizedGeoLocation"] = 1;
      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?&latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyDxbjdueUQWI1WFfiEviIkvspt8S95L-bQ&result_type=street_address`
        )
        .then((res) => {
          if (res.data?.results.length > 0) {
            const results = res.data.results;
            for (var i = 0; i < results[0].address_components.length; i++) {
              for (
                var b = 0;
                b < results[0].address_components[i].types.length;
                b++
              ) {
                //there are different types that might hold a city admin_area_lvl_1 usually does in come cases looking for sublocality type will be more appropriate
                if (results[0].address_components[i].types[b] === "locality") {
                  const city = results[0].address_components[i].long_name;
                  // dispatch(
                  //   setNewLocation(
                  //     city,
                  //     position.coords.latitude,
                  //     position.coords.longitude
                  //   )
                  // );
                  setCookie(USER_CURRENT_CITY_COOKIE_NAME, {
                    city,
                  });
                  if (findingCity) {
                    setFindingCityStatus(false);
                    getRandomShipments(city);
                  }
                  break;
                }
              }
            }
          } else if (findingCity) {
            setFindingCityStatus(false);
            getRandomShipments();
          }
          // dispatch(
          //   setNewLocation(
          //     "",
          //     position.coords.latitude,
          //     position.coords.longitude
          //   )
          // );
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    const errFunc = () => {
      localStorage["authorizedGeoLocation"] = 0;
    };

    const current_city_cache = getAccessToken(USER_CURRENT_CITY_COOKIE_NAME);

    if (window.navigator.geolocation && !current_city_cache) {
      navigator.geolocation.getCurrentPosition(successFunc, errFunc);
    }

    if (current_city_cache) {
      const { city } = JSON.parse(current_city_cache);
      console.log("city in cache", typeof city + " " + city);
      if (findingCity) {
        setFindingCityStatus(false);
        getRandomShipments(city);
      }
    }

    if (
      typeof localStorage["authorizedGeoLocation"] === "undefined" ||
      localStorage["authorizedGeoLocation"] === "0"
    ) {
      if (findingCity) {
        setFindingCityStatus(false);
        getRandomShipments();
      }
    }

    return () => {
      if (getCityTimer.current) {
        clearTimeout(getCityTimer.current);
      }
    };
  }, []);

  const getRandomShipments = (city) => {
    setRandomShipmentsLoading(true);
    axios
      .post(
        GET_AVAILABLE_SHIPMENTS,
        {},
        {
          params:
            city !== undefined && city !== ""
              ? {
                  page: 1,
                  pickup_cities: [city.toString()],
                }
              : {
                  page: 1,
                },
          headers: {
            Authorization: `Bearer ${getTokenFromCookie("access_token")}`,
          },
        }
      )
      .then((res) => {
        // if (getCityTimer.current) {
        //   window.clearTimeout(getCityTimer.current);
        // }
        setShipments(res.data.data);
        // if (res.data.data.length === 0) {
        //   fetchRandomShipments();
        // }
        setRandomShipmentsLoading(false);
      })
      .catch((err) => {
        errHandling(err, setRecommendErrorMsg);
        setRandomShipmentsLoading(false);
      });
  };

  useEffect(() => {
    axios
      .get(
        getURLParams(GET_DRIVER_LOADED_SHIPMENTS, {
          deliver_appointment_date: "n",
          option_deliver_appointment: "y",
        }),
        { headers: getHeaders() }
      )
      .then((res) => {
        setWarnShipmentsLoading(false);
        setWarnShipments(res.data.data);
      })
      .catch((err) => {
        errHandling(err, setWarnShipmentsErrorMsg);
        setWarnShipmentsLoading(false);
      });
  }, []);

  return (
    <>
      <div className={classes.driverLandingPage}>
        <Box ml={1} mr={1}>
          <h1 align="left" className={classes.userName}>
            Hello, {userName}
          </h1>
          <div className={classes.cardWrapper}>
            <Grid item xs={12}>
              <Box
                className={classes.personalCard}
                onClick={() => history.push("/profile")}
              >
                <Box className={classes.personalCardContent}>
                  <AccountCircleIcon />
                  <Typography>My account</Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className={classes.personalCard}
                onClick={() => history.push("/driver/bookkeeper")}
              >
                <Box className={classes.personalCardContent}>
                  <Calculator width="26" fill="currentColor" />
                  <Typography>My Bookkeeper</Typography>
                </Box>
              </Box>
            </Grid>
          </div>
        </Box>
        <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />

        <Grid container className={classes.gridContainer} alignItems="stretch">
          <div className={classes.cardWrapper}>
            <Box
              className={classes.card}
              onClick={() => history.push("/driver/available_shipments")}
            >
              <Box className={classes.cardContent}>
                <SearchIcon />
                <Typography>Search Shipments</Typography>
              </Box>
            </Box>
            <Box
              className={classes.card}
              onClick={() => history.push("/driver/claimed_shipments")}
            >
              <Box className={classes.cardContent}>
                <WidgetsIcon />
                <Typography>My shipments</Typography>
              </Box>
            </Box>
            <Box
              className={classes.card}
              onClick={() => history.push("/driver/load_shipments")}
            >
              <Box className={classes.cardContent}>
                <LocalShippingIcon />
                <Typography>Go to Pickup</Typography>
              </Box>
            </Box>
            <Box
              className={classes.card}
              onClick={() => history.push("/driver/deliver_shipments")}
            >
              <Box className={classes.cardContent}>
                <MarkunreadMailboxIcon />
                <Typography>Go to Deliver</Typography>
              </Box>
            </Box>
            <Box
              className={classes.card}
              onClick={() => history.push("/cargo-control-tracking/query")}
            >
              <Box className={classes.cardContent}>
                <FindInPageIcon />
                <Typography>Cargo Control Tracking</Typography>
              </Box>
            </Box>
          </div>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <ShipmentsLayout
              shipments={warnShipments}
              loading={warnShipmentsLoading}
              title="Need appointment reminder:"
              noResult="-- No shipment needs appointment --"
              errMsg={warnShipmentsErrorMsg}
              reminder
            />
          </Grid>
          <Grid item xs={12}>
            <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
            <ShipmentsLayout
              loading={randomShipmentsLoading}
              shipments={shipments}
              errMsg={recommendErrorMsg}
              title="Recommended shipments for you:"
              noResult="-- No nearby shipment found --"
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     location: state.location,
//   };
// };

// export default connect(mapStateToProps)(DriverLandingPage);
export default DriverLandingPage;

import React, { useState, useEffect } from "react";
import {
  Button,
  Grid,
  TextField,
  makeStyles,
  Divider,
  Typography,
} from "@material-ui/core";
import ScanBarcode from "../ScanComponents/ScanBarcode";
import DeliverShipmentsLayout from "../Layouts/DeliverShipmentsLayout";
import axios from "axios";
import {
  GET_TRACKING_INFO_BY_TRACKING_NUMBER,
  GET_PACKAGES_BY_ID_API,
  GET_DRIVER_LOADED_SHIPMENTS,
} from "../../Utils/apiUrl";
import { addDeliverShipments } from "../../actions/driverLoadAndDeliverShipmentsAction";
import store from "../../store/index";
import { useDispatch } from "react-redux";
import { getTokenFromCookie } from "../../Utils/doToken";
import { cleanDeliverShipmentsData } from "../../actions/driverLoadAndDeliverShipmentsAction";
import { getHeaders } from "../../Utils/fetchUtil";

const useStyles = makeStyles(theme => ({
  scanInput: {
    justifyContent: "center",
    margin: "5px auto",
  },
  scanButton: {
    marginLeft: "5px",
  },
  confirmButton: {
    color: "white",
  },
  centering: {
    display: "flex",
    justifyContent: "center",
  },
}));

const DeliverShipmentsPage = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openCamera, setOpenCamera] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonWord, setButtonWord] = useState("Scan");
  const [textInput, setTextInput] = useState("");
  const [trackingNum, setTrackingNum] = useState([]);
  const [fetchedTrackingNums, setFetchedTrackingNums] = useState([]);

  const constructData = async (pNum, sNum, tNum, itemName, coverImage) => {
    const payload = {
      package_number: pNum,
      item_name: itemName,
      cover_image: coverImage,
      shipment_number: sNum,
      tracking_number: tNum,
      checked: false,
    };
    store.dispatch(addDeliverShipments(payload));
  };

  const fetchLoadedShipments = async () => {
    setLoading(true);
    try {
      const loadedShipments = await axios.get(GET_DRIVER_LOADED_SHIPMENTS, {
        headers: getHeaders(),
      });
      if (loadedShipments) {
        loadedShipments.data.data.forEach(s => {
          const itemName = s.package.item_name;
          const coverImage = s.package.cover_image;
          const trackingNum = s.tracking_numbers;
          const shipmentNum = s.shipment_number;
          const packageNum = s.package_number;
          constructData(
            packageNum,
            shipmentNum,
            trackingNum,
            itemName,
            coverImage
          );
        });
        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      console.log("Error when fetching tracking numbers", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    //fetch tracking numbers related to the driver
    fetchLoadedShipments();
    return () => {
      dispatch(cleanDeliverShipmentsData());
    };
  }, []);

  const handleClickScan = () => {
    if (!openCamera) {
      setOpenCamera(true);
      setButtonWord("Close Scan");
    } else {
      setOpenCamera(false);
      setButtonWord("Scan");
    }
  };

  const fetchInfo = async () => {
    setLoading(true);

    const header = {
      headers: getHeaders(),
    };

    try {
      const trackingInfo = await axios.get(
        GET_TRACKING_INFO_BY_TRACKING_NUMBER(textInput),
        header
      );
      if (trackingInfo) {
        const shipmentNum = trackingInfo.data.shipment_number;
        const packageNum = trackingInfo.data.package_number;

        //get package info and shipment info
        const packageInfo = await axios.get(
          GET_PACKAGES_BY_ID_API(packageNum),
          header
        );

        if (packageInfo !== null) {
          const p = packageInfo.data;
          const payload = {
            package_number: p.package_number,
            item_name: p.item_name,
            cover_image: p.cover_image,
            shipment_number: shipmentNum,
            tracking_number: [trackingInfo.data.tracking_number],
          };
          //dispatch(addLoadedShipments(payload));
        }

        setLoading(false);
      } else {
        setLoading(false);
        return;
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const handleEnter = () => {
    if (textInput.length === 0 || fetchedTrackingNums.includes(textInput))
      return;

    fetchInfo();
  };

  //input for tracking number
  const handleChange = e => {
    setTextInput(e.target.value);
  };

  return (
    <>
      <Typography variant="h6" align="center">
        Deliver Shipment
      </Typography>
      <Grid container direction="column" alignItems="center">
        <Grid item xs={12} container className={classes.scanInput}>
          <form>
            <TextField
              label="Tracking Number"
              type="number"
              variant="outlined"
              onChange={e => handleChange(e)}
            />
          </form>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleEnter()}
            className={classes.scanButton}
          >
            Enter
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleClickScan()}
            className={classes.scanButton}
          >
            {buttonWord}
          </Button>
        </Grid>
        {openCamera && (
          <Grid item xs={12}>
            <ScanBarcode onGetBarcode={nums => setTrackingNum(nums)} />
          </Grid>
        )}
      </Grid>
      <Divider variant="middle" />
      {!loading && <DeliverShipmentsLayout />}
    </>
  );
};

export default DeliverShipmentsPage;

import React, { useState, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import LoadingDock from "../Images/icons/LoadingDock.png";
import NoLoadingDock from "../Images/icons/NoLoadingDock.png";
import AccessAlarmsIcon from "@material-ui/icons/AccessAlarms";
import EventIcon from "@material-ui/icons/Event";
const useStyles = makeStyles(theme => ({
  optionsWrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: "8px",
    justifyContent: "center",
    alignItems: "center",
    padding: "7px",
    margin: "0px auto",
    width: "100%",
  },
  displayOptionInfo: {
    position: "absolute",
    top: "5px",
    left: "-25px",
    width: "150px",
    background: "#D3D3D3",
    borderRadius: "5px",
    textAlign: "center",
    zIndex: "1000",
  },
}));

export default function ShipmentOptionsLayout({ optionList }) {
  const classes = useStyles();
  const [showOptionsInfo, setShowOptionInfo] = useState({
    pickupLoadingDock: false,
    deliverLoadingDock: false,
    loadingDocks: false,
    timeRanges: false,
    pickupTimeRange: false,
    deliverTimeRange: false,
    deliverAppointment: false,
  });

  const showInfo = target => {
    setShowOptionInfo({
      ...showOptionsInfo,
      [target]: true,
    });
  };

  const hideInfo = target => {
    setShowOptionInfo({
      ...showOptionsInfo,
      [target]: false,
    });
  };

  const handleIconClick = (event, target) => {
    // console.log("event", target);
    event.stopPropagation();
    showInfo(target);
    setTimeout(() => {
      hideInfo(target);
    }, 2000);
  };

  const optionBothTimeRanges = (
    <div>
      <AccessAlarmsIcon
        onMouseEnter={() => showInfo("timeRanges")}
        onMouseLeave={() => hideInfo("timeRanges")}
        onClick={() => showInfo("timeRanges")}
      />
      {showOptionsInfo.timeRanges && (
        <div style={{ position: "relative" }}>
          <Typography variant="caption" className={classes.displayOptionInfo}>
            Pick up: <b>{optionList.option_pickup_time_range}</b>, Deliver:{" "}
            <b>{optionList.option_deliver_time_range}</b>
          </Typography>
        </div>
      )}
    </div>
  );

  const optionBothLoadingDocks = (
    <div>
      {optionList?.option_pickup_loading_dock === "y" &&
      optionList?.option_deliver_loading_dock === "y" ? (
        <>
          <img
            src={LoadingDock}
            alt="Loading Dock"
            width="40px"
            align="bottom"
            style={{
              backgroundColor: "seagreen",
              borderRadius: "5px",
              zIndex: "1000",
            }}
            onMouseEnter={() => showInfo("loadingDocks")}
            onMouseLeave={() => hideInfo("loadingDocks")}
            onClick={e => handleIconClick(e, "loadingDocks")}
          />

          {showOptionsInfo.loadingDocks && (
            <div style={{ position: "relative" }}>
              <div className={classes.displayOptionInfo}>
                <>
                  <Typography variant="caption">
                    Pickup Loading Dock, Deliver Loading Dock
                  </Typography>
                </>
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          <img
            src={NoLoadingDock}
            alt="Loading Dock"
            width="40px"
            align="bottom"
            style={{
              backgroundColor: "lightgray",
              borderRadius: "5px",
            }}
            onMouseEnter={() => showInfo("loadingDocks")}
            onMouseLeave={() => hideInfo("loadingDocks")}
            onClick={e => handleIconClick(e, "loadingDocks")}
          />

          {showOptionsInfo.loadingDocks && (
            <div style={{ position: "relative" }}>
              <div className={classes.displayOptionInfo}>
                <>
                  <Typography variant="caption">
                    No Pickup Loading Dock, No Deliver Loading Dock
                  </Typography>
                </>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );

  const optionPickupLoadingDock =
    optionList?.option_pickup_loading_dock === "y" ? (
      <div>
        <img
          src={LoadingDock}
          alt="Loading Dock"
          width="40px"
          align="bottom"
          style={{
            backgroundColor: "seagreen",
            borderRadius: "5px",
          }}
          onMouseEnter={() => showInfo("pickupLoadingDock")}
          onMouseLeave={() => hideInfo("pickupLoadingDock")}
          onClick={e => handleIconClick(e, "pickupLoadingDock")}
        />
        {showOptionsInfo.pickupLoadingDock && (
          <div style={{ position: "relative" }}>
            <Typography variant="caption" className={classes.displayOptionInfo}>
              Pickup Loading Dock
            </Typography>
          </div>
        )}
      </div>
    ) : (
      <div>
        <img
          src={NoLoadingDock}
          alt="Loading Dock"
          width="40px"
          align="bottom"
          style={{
            borderRadius: "5px",
            backgroundColor: "lightgray",
          }}
          onMouseEnter={() => showInfo("pickupLoadingDock")}
          onMouseLeave={() => hideInfo("pickupLoadingDock")}
          onClick={e => handleIconClick(e, "pickupLoadingDock")}
        />
        {showOptionsInfo.pickupLoadingDock && (
          <div style={{ position: "relative" }}>
            <Typography variant="caption" className={classes.displayOptionInfo}>
              No Pickup Loading Dock
            </Typography>
          </div>
        )}
      </div>
    );

  const optionDeliverLoadingDock =
    optionList?.option_deliver_loading_dock === "y" ? (
      <div>
        <img
          src={LoadingDock}
          alt="Loading Dock"
          width="40px"
          align="bottom"
          style={{
            borderRadius: "5px",
            backgroundColor: "seagreen",
          }}
          onMouseEnter={() => showInfo("deliverLoadingDock")}
          onMouseLeave={() => hideInfo("deliverLoadingDock")}
          onClick={e => handleIconClick(e, "deliverLoadingDock")}
        />
        {showOptionsInfo.deliverLoadingDock && (
          <div style={{ position: "relative" }}>
            <Typography variant="caption" className={classes.displayOptionInfo}>
              Deliver Loading Dock
            </Typography>
          </div>
        )}
      </div>
    ) : (
      <div>
        <img
          src={NoLoadingDock}
          alt="Loading Dock"
          width="40px"
          align="bottom"
          style={{
            borderRadius: "5px",
            backgroundColor: "lightgray",
          }}
          onMouseEnter={() => showInfo("deliverLoadingDock")}
          onMouseLeave={() => hideInfo("deliverLoadingDock")}
          onClick={e => handleIconClick(e, "deliverLoadingDock")}
        />
        {showOptionsInfo.deliverLoadingDock && (
          <div style={{ position: "relative" }}>
            <Typography variant="caption" className={classes.displayOptionInfo}>
              No Deliver Loading Dock
            </Typography>
          </div>
        )}
      </div>
    );

  const optionPickupTimeRange = optionList?.option_pickup_time_range ? (
    <div>
      <AccessAlarmsIcon
        onMouseEnter={() => showInfo("pickupTimeRange")}
        onMouseLeave={() => hideInfo("pickupTimeRange")}
        onClick={e => handleIconClick(e, "pickupTimeRange")}
      />
      {showOptionsInfo.pickupTimeRange && (
        <div style={{ position: "relative" }}>
          <Typography variant="caption" className={classes.displayOptionInfo}>
            Pickup: <b>{optionList.option_pickup_time_range}</b>
          </Typography>
        </div>
      )}
    </div>
  ) : (
    ""
  );

  const optionDeliverTimeRange = optionList?.option_deliver_time_range ? (
    <div>
      <AccessAlarmsIcon
        onMouseEnter={() => showInfo("deliverTimeRange")}
        onMouseLeave={() => hideInfo("deliverTimeRange")}
        onClick={e => handleIconClick(e, "deliverTimeRange")}
      />
      {showOptionsInfo.deliverTimeRange && (
        <div style={{ position: "relative" }}>
          <Typography variant="caption" className={classes.displayOptionInfo}>
            Deliver: <b>{optionList.option_deliver_time_range}</b>
          </Typography>
        </div>
      )}
    </div>
  ) : (
    ""
  );

  const optionDeliverAppointment = optionList?.phone_deliver_appointment ? (
    <div>
      <EventIcon
        onMouseEnter={() => showInfo("deliverAppointment")}
        onMouseLeave={() => hideInfo("deliverAppointment")}
        onClick={e => handleIconClick(e, "deliverAppointment")}
      />

      {showOptionsInfo.deliverAppointment && (
        <div style={{ position: "relative" }}>
          <Typography variant="caption" className={classes.displayOptionInfo}>
            Deliver Appointment
          </Typography>
        </div>
      )}
    </div>
  ) : (
    ""
  );

  return (
    <>
      {optionList && (
        <div className={classes.optionsWrapper}>
          {(() => {
            if (
              (optionList.option_pickup_loading_dock === "y" &&
                optionList.option_deliver_loading_dock === "y") ||
              (optionList.option_pickup_loading_dock === "n" &&
                optionList.option_deliver_loading_dock === "n")
            ) {
              return optionBothLoadingDocks;
            } else {
              return [optionPickupLoadingDock, optionDeliverLoadingDock];
            }
          })()}
          {(() => {
            if (
              optionList.option_pickup_time_range !== null &&
              optionList.option_deliver_time_range !== null
            ) {
              return optionBothTimeRanges;
            } else {
              return [optionPickupTimeRange, optionDeliverTimeRange];
            }
          })()}

          {optionDeliverAppointment}
        </div>
      )}
    </>
  );
}

import React from "react";
import AzureAuthenticationContext from "./azure-authentication-context";

import { Button, Box } from "@material-ui/core";
import MicrosoftLogo from "../../../Images/icons/microsoftLogo";

import { makeStyles } from "@material-ui/core/styles";

const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const isIE = msie > 0 || msie11 > 0;

// Log In, Log Out button
const AzureAuthenticationButton = ({ onSuccess }) => {
  const classes = useStyles();
  // Azure client context
  const authenticationModule = new AzureAuthenticationContext();

  // const [authenticated, setAuthenticated] = useState(false);
  // const [user, setUser] = useState();

  const logIn = (method) => {
    const typeName = "loginPopup";
    const logInType = isIE ? "loginRedirect" : typeName;

    // Azure Login
    authenticationModule.login(logInType, returnedAccountInfo);
  };
  // const logOut = () => {
  //   if (user) {
  //     onAuthenticated(undefined);
  //     // Azure Logout
  //     authenticationModule.logout(user);
  //   }
  // };

  const returnedAccountInfo = (user) => {
    // set state
    // setAuthenticated(user?.name ? true : false);
    // email = response.email;
    // token = response.accessToken;
    const result = {
      email: user.username,
      accessToken: user.idTokenClaims.sub,
    };
    onSuccess(result);
    // setUser(user);
  };

  const showLogInButton = () => {
    return (
      <Button
        id="authenticationButton"
        variant="contained"
        classes={{ label: classes.socialButton }}
        onClick={() => logIn("loginPopup")}
        style={{
          backgroundColor: "#2c2c2e",
          color: "white",
          width: "17rem",
        }}
      >
        <Box mr={1} width="2rem">
          <MicrosoftLogo width="18px" />
        </Box>{" "}
        Sign in with Microsoft
      </Button>
    );
  };

  // const showLogOutButton = () => {
  //   return (
  //     <div id="authenticationButtonDiv">
  //       <div id="authentication">
  //         <button id="authenticationButton" onClick={() => logOut()}>
  //           Log out
  //         </button>
  //       </div>
  //     </div>
  //   );
  // };

  // const showButton = () => {
  //   return authenticated ? showLogOutButton() : showLogInButton();
  // };

  return (
    <div id="authentication">
      {authenticationModule.isAuthenticationConfigured ? (
        showLogInButton()
      ) : (
        <div>Authentication Client ID is not configured.</div>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  socialButton: {
    display: "flex",
    justifyContent: "start",
  },
}));

export default AzureAuthenticationButton;

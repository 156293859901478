
import React from 'react';
import {
    TextField,
    Grid,
    Typography,
    Box
} from "@material-ui/core";
// import { useStateValue } from "../../stateContext";
import {
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement
} from "@stripe/react-stripe-js";
import StripeInput from "./stripeInput";

const PaymentForm = () => {

    // const [{ formValues }, dispatch] = useStateValue();

    return <>
        <Box mb='3vh'>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6">Credit Card Data</Typography>
            </Grid>
        </Box>
        <Box mb='3vh'>
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Credit Card Number"
                    name="ccnumber"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardNumberElement
                        },
                    }}
                />
            </Grid>
        </Box>
        <Box mb='3vh'>
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Expiration Date"
                    name="ccexp"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardExpiryElement
                        },
                    }}
                />
            </Grid>
        </Box>
        <Box mb='3vh'>            
            <Grid item xs={12} sm={12}>
                <TextField
                    label="Security Code"
                    name="cvc"
                    variant="outlined"
                    required
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                        inputComponent: StripeInput,
                        inputProps: {
                            component: CardCvcElement
                        },
                    }}
                />
            </Grid>
        </Box>
    </>
}

export default PaymentForm;
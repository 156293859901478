import React from "react";
import { Grid, Paper, makeStyles, Typography } from "@material-ui/core";
import ShipmentOptionsLayout from "../Layouts/ShipmentOptionsLayout";
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    margin: "5px 2%",
    width: "96%",
    backgroundColor: "#fdf5e8",
    padding: "3px",
    cursor: "pointer",
    "&:hover": {
      boxShadow:
        "rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px",
    },
  },
  img: {
    margin: "auto",
    display: "block",
    maxHeight: "150px",
    maxWidth: "200px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  container: {
    width: "100%",
    textAlign: "center",
    backgroundColor: "#fbfbf8",
    border: "1px solid #ded47c",
    borderRadius: "5px",
    margin: "0px auto",
  },
  description: {
    display: "flex",
    flexDirection: "column",
  },
}));

const ShipmentList = ({
  imgSrc,
  itemName,
  shipmentType,
  width,
  weight,
  length,
  height,
  unitPrice,
  number,
  available_count,
  description,
  index,
  deliverCity,
  pickupCity,
  handleClick,
  pickupDate,
  trackingNumberCount,
  optionList,
}) => {
  const classes = useStyles();
  const image_src = imgSrc ? imgSrc : "../../../../Pallet_Box.jpg";
  const item_name = itemName ? itemName : "No name";
  const shipment_type = shipmentType ? shipmentType : "individual";
  const item_width = width ? width : "unknown size";
  const item_length = length ? length : "unknown size";
  const item_height = height ? height : "unknown size";
  const item_weight = weight ? weight : "unknown weight";
  const item_unit_price = unitPrice ? unitPrice : "unknown unit price";
  const item_number = number ? number : 0;

  const item_description = description ? description : "";
  const puDate = pickupDate.split(" ");
  const trackingNum =
    trackingNumberCount > 0 ? ` x ${trackingNumberCount}` : "";
  return (
    <>
      <Paper className={classes.paper} onClick={handleClick}>
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={4}>
            <img className={classes.img} src={image_src} alt="package"></img>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <Grid item xs={6} sm={12}>
              {item_name}
            </Grid>
            <Grid item xs={6}>
              {item_weight} lb
            </Grid>
            <Grid item xs={12} sm={6}>
              {shipment_type}
              {trackingNum}
            </Grid>
            <Grid item xs={12} className={classes.description}>
              {item_description}
              <Typography variant="caption">
                Pickup from: {pickupCity}
              </Typography>
              <Typography variant="caption">
                Deliver to: {deliverCity}
              </Typography>
              <Typography variant="caption">
                Pickup Date: {puDate[0]}
              </Typography>
            </Grid>
          </Grid>
          <Grid container item xs={12} sm={4}>
            <ShipmentOptionsLayout optionList={optionList} />
            <Grid item xs={4} sm={12}>
              ${item_unit_price}
            </Grid>
            <Grid item xs={4} sm={12}>
              {available_count > 0 ? `Available: ${available_count}` : ""}
            </Grid>
            <Grid item xs={4} sm={12}>
              {available_count > 0
                ? `Total: ${available_count * item_unit_price}`
                : ""}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default ShipmentList;

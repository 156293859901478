import { UPDATE_SEARCH_FILTER, FETCH_SEARCH_FILTER_STATIC } from './types'
export const updateSearchFilterSetting = (newState) =>{
  return{
    type: UPDATE_SEARCH_FILTER,
    payload: newState
  }
}

export const fetchSearchFilterStatic = (shipmentStatic) =>{
  return{
    type: FETCH_SEARCH_FILTER_STATIC,
    payload: shipmentStatic
  }
}
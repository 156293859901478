import { GET_SHIPMENTS } from '../actions/types'

const initialState = {
  orderShipmentData: [],
  fetching: false,
  selected_package_number:''
}

export default function orderReducer(state = initialState, action){
  switch(action.type){
    case GET_SHIPMENTS:
      return {
        ...state,
        orderShipmentData:[...state.orderShipmentData, ...action.payload]
      }
      default:
      {
        return state
      }
    }
}
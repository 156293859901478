import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

import { getAccessToken } from "../Utils/doToken";

const UserRouter = ({
  component: Component,
  path: Path,
  landing = false,
  ...rest
}) => {
  const dataLoaded = useSelector((state) => state.user.dataLoaded);
  const role = useSelector((state) => state.user.role);

  const guestUser = Boolean(getAccessToken("access_token")) === false;

  const isLandingPage = landing;

  const isUser = dataLoaded && role === "End User";

  const isDriver = dataLoaded && role === "Driver";

  const routerContent = (
    _guestUser,
    _isLandingPage,
    _isUser,
    _isDriver,
    _dataLoaded,
    _props
  ) => {
    if (_guestUser && !_isLandingPage)
      return (
        <Redirect
          to={{
            pathname: "/sign-in",
            state: { from: _props.location },
          }}
        />
      );

    if (_isDriver && !_guestUser && _dataLoaded)
      return (
        <Redirect
          to={{
            pathname: "/driver",
          }}
        />
      );

    if (!_isDriver && !_isUser && !_guestUser && _dataLoaded)
      return (
        <Redirect
          to={{
            pathname: "/profile",
          }}
        />
      );

    return <Component {..._props} />;
  };

  return (
    <Route
      {...rest}
      path={Path}
      render={(props) =>
        routerContent(
          guestUser,
          isLandingPage,
          isUser,
          isDriver,
          dataLoaded,
          props
        )
      }
    />
  );
};
export default UserRouter;

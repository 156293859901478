import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    orderData: [],
    isfetching: false,
    selected_order_number:''
  }

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setOrders: (state, { payload }) => {
      state.orderData = payload.orders;
    },

    addOrders: (state, { payload }) => {
        state.orderData = [...state.orderData, payload.orders];
      },
    
    fetehingOrders: state => {
      state.isFetching = true;    
    },

    finishedFetehingOrders: state => {
        state.isFetching = false;    
    },
    selectOrder: (state, { payload }) => {
        state.selected_order_number = payload.selected_order_number;    
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = ordersSlice;

export const {
    setOrders,
    fetehingOrders,
    finishedFetehingOrders,
    selectOrder,
    addOrders,
} = actions;

export const ordersReducer = reducer;

// Export selector
export const availableOrdersSelector =
  state => state.orders.orderData.filter(order => order.status === 'active');

export const unavailableOrdersSelector =
  state => state.orders.orderData.filter(order => order.status === 'pause');

export const cancelledOrdersSelector =
  state => state.orders.orderData.filter(order => order.status === 'cancelled by owner' || order.status === 'cancelled by backend');
import React, { useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
} from "@stripe/react-stripe-js";

import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Box } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import axios from "axios";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import StripeCardsSection from "./components/stripeCardsSection";
// import UserNavBar from "../NavBars/userBar";
import PropTypes from "prop-types";
import { CREDIT_CARD_API, BACKEND_PAYMENT_CONFIRM } from "../../Utils/apiUrl";
import { getHeaders, getURLParams, errHandling } from "../../Utils/fetchUtil";
import { redirectHome } from "../../Utils/redirect";
import { getUserProfile } from "../../slices";

const CardSetupForm = ({
  clientSecret,
  paymentProcess,
  orderNum,
  email,
  popUp,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const role = useSelector((state) => state.user.role);
  const dispatch = useDispatch();

  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const [errorMsg, setErrorMsg] = useState("");

  const [loading, setLoading] = useState(false);

  const [success, setSuccessState] = useState(false);

  const buttonString = paymentProcess ? "Submit order" : "Binding Credit Card";

  const handleSubmit = async (event) => {
    setLoading(true);
    setErrorMsg("");
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const stripeResult = paymentProcess
      ? await stripe.confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        })
      : await stripe.confirmCardSetup(clientSecret, {
          payment_method: {
            card: elements.getElement(CardNumberElement),
          },
        });

    if (stripeResult.error) {
      setErrorMsg(stripeResult.error.message);
      setLoading(false);
    } else if (!paymentProcess) {
      axios
        .put(
          getURLParams(CREDIT_CARD_API, {
            method: stripeResult.setupIntent.payment_method,
          }),
          "",
          { headers: { ...getHeaders(), "X-Requested-With": "XMLHttpRequest" } }
        )
        .then((res) => {
          setSuccessState(true);
          setLoading(false);
          dispatch(getUserProfile(true));
        })
        .catch((error) => {
          errHandling(error, setErrorMsg);
          // setErrorMsg(error.message);
          setLoading(false);
        });
      // The setup has succeeded. Display a success message and send
      // result.setupIntent.payment_method to your server to save the
      // card to a Customer
    } else {
      axios
        .post(
          BACKEND_PAYMENT_CONFIRM,
          JSON.stringify({
            order_number: orderNum,
          }),
          {
            headers: {
              "Content-Type": "application/json",
              "X-Requested-With": "XMLHttpRequest",
            },
          }
        )
        .then((res) => {
          setSuccessState(true);
          setLoading(false);
        })
        .catch((error) => {
          errHandling(error, setErrorMsg);
          // setErrorMsg(error.message);
          setLoading(false);
        });
    }
  };
  return (
    <>
      <div className={classes.mainBox}>
        {success ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            {paymentProcess ? (
              <Box mb={2}>
                <Box color="seagreen" fontSize="1.2rem" pb={2} fontWeight="700">
                  <CheckCircleOutlineIcon /> The payment is successful.
                </Box>
                <Box fontSize="1.2rem" pb={1}>
                  The order number is{" "}
                  <Box component="span" color="seagreen">
                    {orderNum}
                  </Box>
                </Box>
                <Box fontSize="1.2rem">
                  We've sent an email to{" "}
                  <Box
                    component="span"
                    // color="seagreen"
                    style={{ textDecoration: "underline" }}
                  >
                    {email}
                  </Box>{" "}
                  with the order summary and the access code.
                </Box>
              </Box>
            ) : (
              <Box fontSize="2rem" color="seagreen" mb="2vh">
                The credit card is successfully added.
              </Box>
            )}
            {!popUp && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  if (location.state?.from) history.push(location.state.from);
                  else redirectHome(history, role);
                }}
              >
                {location.state?.from
                  ? "Go to the previous page"
                  : "Go to home page"}
              </Button>
            )}
          </Box>
        ) : (
          <form
            onSubmit={handleSubmit}
            autoComplete="off"
            className={classes.form}
          >
            <StripeCardsSection />
            <Box width="100%">
              {errorMsg ? (
                <Alert severity="error" classes={{ root: classes.alertRoot }}>
                  {errorMsg}
                </Alert>
              ) : (
                ""
              )}
            </Box>
            <Grid container item justify="center">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type="submit"
                disabled={loading}
              >
                {loading ? <CircularProgress size={24} /> : buttonString}
              </Button>
            </Grid>
          </form>
        )}
      </div>
    </>
  );
};

const useStyles = makeStyles({
  mainBox: {
    position: "relative",
    marginTop: "-8px",
    // padding: "10px 20px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
  },
  button: {
    color: "white",
    width: "15rem",
  },
});

CardSetupForm.propTypes = {
  clientSecret: PropTypes.string.isRequired,
  orderNum: PropTypes.string,
  email: PropTypes.string,
  paymentProcess: PropTypes.bool.isRequired,
};

export default CardSetupForm;
